// @flow
import { createSelector } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import type { ReduxStore } from 'flow/redux';
import type {
  Category,
  Gender,
  Breed,
  Color,
  ColorTag,
  InspectionType,
  ServiceFee,
} from 'flow/resources';
import type {
  InspectionAnimal,
  InspectionCertificateType,
  LineItemSlug,
  LineItem,
  DisplayLineItem,
} from 'flow/inspection';
import {
  isInspectionFee,
  isInspectionMinimum,
  LINE_ITEM_SLUG,
  LINE_ITEM_TYPE,
} from 'constants/inspection';
import getPlatform from 'utils/getPlatform';
import { IS_DEV_ENVIRONMENT } from 'service/urls';
import { getServiceTypeFee } from 'utils/computeFees';
import { selectIsCreditCardTester } from './me';
import {
  selectAnimalCategories,
  selectBreeds,
  selectColors,
  selectGenders,
  selectServiceFees,
} from './resources';

const selectInspectionDomain = (
  state: ReduxStore,
  domain: 'inspectionCertificate' | 'inspection',
) => state[domain]?.data;

export const selectInspection = createSelector(
  selectInspectionDomain,
  (inspection: InspectionCertificateType) => {
    return inspection || {};
  },
);

export const selectReleasedAtNoCharge = createSelector(
  (state: ReduxStore) => state.inspection.data,
  (inspection: InspectionCertificateType) => {
    return inspection?.releasedAtNoCharge;
  },
);

export const selectInspectionLineItems = createSelector(
  selectAnimalCategories,
  selectGenders,
  selectBreeds,
  selectColors,
  (state: ReduxStore) => state.resources.animalTags,
  selectInspectionDomain,
  (
    animalCategories: Array<Category>,
    animalGenders: Array<Gender>,
    animalBreeds: Array<Breed>,
    animalColors: Array<Color>,
    animalTags: Array<ColorTag>,
    inspection: InspectionCertificateType,
  ) => {
    if (!isEmpty(inspection)) {
      const category = inspection?.category || inspection.animals[0].category;

      const { animals, lineItems } = inspection;

      if (lineItems.length === 0) return [];

      return animals.map<DisplayLineItem>((animal: InspectionAnimal) => {
        const animalCategory = animalCategories.find(
          (a: Category) => a.id === category,
        );

        const animalGender = animalGenders.find(
          (g: Gender) => g.id === animal.gender,
        );

        const animalBreed = animalBreeds.find(
          (b: Breed) => b.id === animal.breed,
        );

        const animalColor = animalColors.find(
          (c: Color) => c.id === animal.color,
        );

        const tagColor = animalTags.find(
          (t: ColorTag) => t.id === animal.tagColor,
        );

        function getDisplayFee(type: LineItemSlug): LineItem {
          const availableLineItems = lineItems.filter(
            ({ lineNumber, slug }: LineItem) => {
              return lineNumber === animal.lineNumber && slug === type;
            },
          );

          return (
            availableLineItems.find(
              (l: LineItem) => l.type === LINE_ITEM_TYPE.OVERRIDE,
            ) || availableLineItems[0]
          );
        }

        return {
          ...animal,
          name: animalCategory?.category || '',
          gender: animalGender?.gender || '',
          breed: animalBreed?.breed || '',
          color: animalColor?.color || '',
          tagColor: tagColor?.color || '',
          inspection: getDisplayFee(LINE_ITEM_SLUG.INSPECTION),
          predatorControl: getDisplayFee(LINE_ITEM_SLUG.PREDATOR_CONTROL),
          beefCouncil: getDisplayFee(LINE_ITEM_SLUG.BEEF_COUNCIL),
          beefTag: getDisplayFee(LINE_ITEM_SLUG.BEEF_TAG),
        };
      });
    }

    return [];
  },
);

const getAnimalCategories = (
  inspection: InspectionCertificateType,
): Array<number> => {
  const categoryFromInspection = inspection?.category;
  // the client supplied the inspection with a category set
  if (categoryFromInspection) {
    return [categoryFromInspection];
  }

  // no category attached, check the animals on the inspection for a category
  const { animals } = inspection;
  if (animals && animals?.length) {
    // I've seen a case where category was not passed on the animal line
    return animals
      .filter(({ category }: InspectionAnimal) => !!category)
      .map(({ category }: InspectionAnimal) => category);
  }

  return [];
};

const getInspectionMinimumTotal = (
  inspection: InspectionCertificateType,
  serviceFees: Array<ServiceFee>,
): number => {
  const types = inspection?.types || [];
  const typeIds = types.map((t: InspectionType | number) => {
    return typeof t === 'number' ? t : t.id;
  });
  const animalCategories = getAnimalCategories(inspection);

  const feesByAnimalCategoryAndInspectionType = serviceFees.filter(
    ({ service }: ServiceFee) =>
      animalCategories.includes(service.category) &&
      typeIds.includes(service.inspectionType),
  );

  const inspectionFee: $Shape<ServiceFee> = getServiceTypeFee(
    feesByAnimalCategoryAndInspectionType,
    'inspection_fee',
  );

  //  Fix needed for SR_61144
  //  const hasCalfPoolProducer = typeIds.includes(11);
  // return hasCalfPoolProducer ? 0 : inspectionFee?.minimumFee;
  return inspectionFee?.minimumFee;
};

const getWaivedLineItemsForDisplay = (
  serviceFees: Array<ServiceFee>,
  inspection: InspectionCertificateType,
): Array<$Shape<LineItem>> => {
  const lineItems = inspection?.lineItems || [];
  const releasedAtNoCharge = inspection?.releasedAtNoCharge || false;
  const releasedAtNoChargeReason = inspection?.releasedAtNoChargeReason || '';

  if (releasedAtNoCharge) {
    const price = lineItems.reduce((acc: number, lineItem: LineItem) => {
      return acc + lineItem.price;
    }, 0);

    return [
      {
        slug: 'released_at_no_charge',
        price,
        reason: releasedAtNoChargeReason,
      },
    ];
  }

  return lineItems
    .filter((lineItem: LineItem) => !!lineItem)
    .filter(({ slug, lineNumber, waived }: LineItem) => {
      const hasOtherServiceFee: boolean = lineItems.some((l) => {
        return (
          l.type === LINE_ITEM_TYPE.OVERRIDE &&
          slug === l.slug &&
          lineNumber === l.lineNumber
        );
      });

      return hasOtherServiceFee ? false : waived;
    })
    .map((lineItem: LineItem) => {
      const { slug, price } = lineItem;
      return {
        ...lineItem,
        price: isInspectionMinimum(slug)
          ? getInspectionMinimumTotal(inspection, serviceFees)
          : price,
      };
    });
};

export const selectWaivedLineItems = createSelector(
  selectServiceFees,
  selectInspectionDomain,
  getWaivedLineItemsForDisplay,
);

export const selectOverrideLineItems = createSelector(
  selectInspectionDomain,
  (inspection: InspectionCertificateType) => {
    return (inspection?.lineItems || [])
      .filter(({ type }: LineItem) => {
        return type === LINE_ITEM_TYPE.OVERRIDE;
      })
      .map<{ index: number } & LineItem>(
        (lineItem: LineItem, index: number) => {
          return { ...lineItem, index };
        },
      );
  },
);

const adjustForMinimum = (
  beginningTotal: number,
  inspection: InspectionCertificateType,
  serviceFees: Array<ServiceFee>,
): number => {
  if (inspection?.releasedAtNoCharge) {
    return beginningTotal;
  }
  const waivedItems = getWaivedLineItemsForDisplay(serviceFees, inspection);
  const minimumIsWaived = waivedItems.find(({ slug }: $Shape<LineItem>) =>
    isInspectionMinimum(slug),
  );

  if (!minimumIsWaived) {
    const minimumFee = getInspectionMinimumTotal(inspection, serviceFees);

    if (beginningTotal < minimumFee) {
      return minimumFee;
    }
  }
  return beginningTotal;
};

export const selectInspectionTotals = createSelector(
  selectServiceFees,
  selectInspectionDomain,
  (serviceFees: Array<ServiceFee>, inspection: InspectionCertificateType) => {
    const lineItems = inspection?.lineItems || [];
    const animals = inspection?.animals || [];

    function getTotal(matcher: (string) => boolean): number {
      return lineItems
        .filter(({ slug }: LineItem) => matcher(slug))
        .reduce((acc: number, lineItem: LineItem) => {
          if (lineItem.waived) return acc;

          return acc + lineItem.price;
        }, 0);
    }

    const totalInspectionFee = adjustForMinimum(
      getTotal(isInspectionFee),
      inspection,
      serviceFees,
    );

    const totalPredatorControlFee = getTotal(
      (slug) => slug === LINE_ITEM_SLUG.PREDATOR_CONTROL,
    );
    const totalBeefCouncilFee = getTotal(
      (slug) => slug === LINE_ITEM_SLUG.BEEF_COUNCIL,
    );
    const totalBeefTagFee = getTotal(
      (slug) => slug === LINE_ITEM_SLUG.BEEF_TAG,
    );
    const totalProcessingFee = getTotal(
      (slug) => slug === LINE_ITEM_SLUG.PROCESSING,
    );

    return {
      totalInspectionFee,
      totalPredatorControlFee,
      totalBeefCouncilFee,
      totalBeefTagFee,
      totalProcessingFee,
      total:
        totalInspectionFee +
        totalPredatorControlFee +
        totalBeefCouncilFee +
        totalBeefTagFee +
        totalProcessingFee,
      totalHeads: animals.reduce(
        (acc: number, animal: InspectionAnimal) => acc + animal.quantity,
        0,
      ),
    };
  },
);

export const selectInspectionMinimumLineItem = createSelector(
  selectInspectionDomain,
  (inspection: InspectionCertificateType) => {
    const lineItems = inspection?.lineItems || [];

    return lineItems.find(
      ({ slug }: LineItem) => slug === LINE_ITEM_SLUG.INSPECTION_MINIMUM,
    );
  },
);

export const selectProcessingFeeLineItem = createSelector(
  selectInspectionDomain,
  (inspection: InspectionCertificateType) => {
    const lineItems = inspection?.lineItems || [];

    return lineItems.find(
      ({ slug }: LineItem) => slug === LINE_ITEM_SLUG.PROCESSING,
    );
  },
);

export const selectHasProductionOrConsignment = createSelector(
  selectInspectionDomain,
  (_, domain: 'inspectionCertificate' | 'inspection') =>
    domain === 'inspectionCertificate',
  (state: ReduxStore) => state.resources.inspectionTypes,
  (
    inspection: InspectionCertificateType,
    isCreated: boolean,
    inspectionTypes: Array<InspectionType>,
  ) => {
    const types: Array<*> = inspection?.types || [];

    if (isCreated) {
      return types.some((t: InspectionType) => {
        return ['consignment-sale', 'production-sale'].includes(t.slug);
      });
    }

    const prodAndConsignmentSaleTypes = inspectionTypes
      .filter((t: InspectionType) => {
        return ['consignment-sale', 'production-sale'].includes(t.slug);
      })
      .map((t: InspectionType) => t.id);

    return types.some((type: number | InspectionType) => {
      // todo consolidate type differences between drafts and inspection types, the following line shouldn't be necessary
      const id = typeof type === 'number' ? type : type.id;
      return prodAndConsignmentSaleTypes.includes(id);
    });
  },
);

export const selectDisplayCreditCardOption = createSelector(
  selectIsCreditCardTester,
  (isCCTester: boolean): boolean =>
    (isCCTester && getPlatform() === 'tablet') || IS_DEV_ENVIRONMENT,
);
