// @flow
import React, { Fragment } from 'react';
import { Row, Col, Space, Typography } from 'antd';
import styled from 'styled-components';
import type { Inspection } from 'flow/inspection';
import { FLAG_DISABLE_GPS_ON_CERTIFICATES } from 'constants/inspection';
import udafLogo from 'assets/logo.png';
import sealOfUtah from 'assets/seal-of-utah.png';
import formatGpsCoordinates from 'utils/formatters/formatGpsCoordinates';
import formatPersonalDetails from 'utils/formatters/formatPersonalDetails';
import NetworkImage from 'components/NetworkImage';
import SelectedInspectionTypes from 'components/SelectedInspectionTypes';

const { Paragraph, Text, Title } = Typography;

type Props = {
  inspection: Inspection,
};

const StyledStripedWrapper = styled.div`
  > .ant-row {
    padding: 0.5em 0;
  }

  > .ant-row:nth-child(odd) {
    background-color: #eeeeee;
  }
`;

const Preview = ({ inspection }: Props) => {
  const {
    soldTo,
    premiseId,
    consignedTo,
    carrier,
    registrationNumber,
    destination,
    licenseNo,
    dealer,
    gpsCoordinate,
    owner,
    buyer,
    inspectionDate,
    types,
  } = inspection;

  return (
    <Row gutter={12}>
      <Col span={18}>
        <Space size="small" direction="vertical" style={{ width: '100%' }}>
          <Header />
          <Row gutter={12}>
            <Col span={8}>
              <Title level={5} style={{ margin: 0 }}>
                Owner
              </Title>
              <Text>
                {owner.name}
                <br />
                {formatPersonalDetails({
                  ...owner,
                  county: owner.county?.name,
                  state: owner.state?.name,
                })}
              </Text>
            </Col>
            <Col span={8}>
              <Title level={5} style={{ margin: 0 }}>
                Buyer
              </Title>
              <Text>
                {buyer?.name || 'N/A'}
                <br />
                {formatPersonalDetails({
                  ...buyer,
                  county: buyer.county?.name,
                  state: buyer.state?.name,
                })}
              </Text>
            </Col>
            {!FLAG_DISABLE_GPS_ON_CERTIFICATES && (
              <Col span={8}>
                <Title level={5} style={{ margin: 0 }}>
                  GPS Coordinate
                </Title>
                <Text>{formatGpsCoordinates(gpsCoordinate) || 'N/A'}</Text>
              </Col>
            )}
          </Row>
          <StyledStripedWrapper>
            {(!!soldTo ||
              !!consignedTo ||
              !!premiseId ||
              !!carrier ||
              !!destination ||
              !!licenseNo) && (
              <Fragment>
                <Row gutter={12}>
                  <Col span={12}>
                    <Text>
                      <strong>Sold to</strong> {soldTo || 'N/A'}
                    </Text>
                  </Col>
                  <Col span={12}>
                    <Text>
                      <strong>Premise No.</strong> {premiseId || 'N/A'}
                    </Text>
                  </Col>
                </Row>
                <Row gutter={12}>
                  <Col span={12}>
                    <Text>
                      <strong>Consigned to</strong> {consignedTo || 'N/A'}
                    </Text>
                  </Col>
                  <Col span={12}>
                    <Text>
                      <strong>License No.</strong> {licenseNo || 'N/A'}
                    </Text>
                  </Col>
                </Row>
                <Row gutter={12}>
                  <Col span={12}>
                    <Text>
                      <strong>Carrier</strong> {carrier || 'N/A'}
                    </Text>
                  </Col>
                  <Col span={12}>
                    <Text>
                      <strong>Destination</strong> {destination || 'N/A'}
                    </Text>
                  </Col>
                </Row>
              </Fragment>
            )}
            {(!!dealer?.license || !!registrationNumber) && (
              <Row gutter={12}>
                <Col span={12}>
                  <Text>
                    <strong>Dealers License No.</strong>{' '}
                    {dealer?.license || 'N/A'}
                  </Text>
                </Col>
                {!!registrationNumber && (
                  <Col span={12}>
                    <Text>
                      <strong>Registration No.</strong>{' '}
                      {registrationNumber || 'N/A'}
                    </Text>
                  </Col>
                )}
              </Row>
            )}
          </StyledStripedWrapper>
          <Title level={5} style={{ marginBottom: 0 }}>
            Inspected for
          </Title>
          <Paragraph>
            <SelectedInspectionTypes types={types} />
          </Paragraph>
        </Space>
      </Col>
      <Col span={6} style={{ textAlign: 'center' }}>
        <Space direction="vertical" align="center" size="middle">
          <NetworkImage
            width={90}
            src={sealOfUtah}
            alt="The Great Seal of the State of Utah"
          />
          {inspectionDate && <Paragraph>{inspectionDate}</Paragraph>}
        </Space>
      </Col>
    </Row>
  );
};

const Header = () => (
  <Space size="middle" align="center" style={{ marginBottom: 10 }}>
    <NetworkImage
      src={udafLogo}
      alt="Utah Department of Agriculture and Food"
      width={75}
    />
    <div>
      <Title level={4} style={{ margin: 0, fontWeight: 'normal' }}>
        Utah Department of
        <br />
        Agriculture and Food
      </Title>
      <Title level={4} style={{ margin: 0 }}>
        Brand Inspection Certificate
      </Title>
    </div>
  </Space>
);

export default Preview;
