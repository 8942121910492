// @flow
import React from 'react';
import { Row, Col, Card, Typography } from 'antd';
import { PieChart } from 'bizcharts';
import styled from 'styled-components';

const data = [
  {
    type: 'Today',
    value: 27,
  },
  {
    type: 'This week',
    value: 25,
  },
  {
    type: 'Last week',
    value: 18,
  },
  {
    type: 'Last month',
    value: 15,
  },
];

const StyledAmount = styled(Typography.Title).attrs({
  level: 3,
})`
  margin-bottom: 0 !important;
`;

const InspectionStats = () => {
  return (
    <Card title="Brand inspection" bodyStyle={{ textAlign: 'center' }}>
      <PieChart
        data={data}
        radius={0.9}
        angleField="value"
        colorField="type"
        height={200}
        autoFit
        label={{
          visible: true,
          type: 'outer',
          offset: 5,
        }}
      />
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <StyledAmount level={3}>$2,000</StyledAmount>
          <Typography.Text>Today</Typography.Text>
        </Col>
        <Col span={12}>
          <StyledAmount level={3}>$5,000</StyledAmount>
          <Typography.Text>This Week</Typography.Text>
        </Col>
        <Col span={12}>
          <StyledAmount level={3}>$7,000</StyledAmount>
          <Typography.Text>Last Week</Typography.Text>
        </Col>
        <Col span={12}>
          <StyledAmount level={3}>$38,123</StyledAmount>
          <Typography.Text>Last Month</Typography.Text>
        </Col>
        <Col span={12}>
          <StyledAmount level={3}>$10,000</StyledAmount>
          <Typography.Text>Total funds</Typography.Text>
        </Col>
        <Col span={12}>
          <StyledAmount level={3}>$49,000</StyledAmount>
          <Typography.Text>Total</Typography.Text>
        </Col>
      </Row>
    </Card>
  );
};

export default InspectionStats;
