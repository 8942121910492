// @flow
import Dexie, { Collection } from 'dexie';
import type { BrandType, Page } from 'flow/brands';
import { BRAND } from 'flow/brands';
import { getPaged, total } from './util';

const { FIRST_NAMES, LAST_NAMES } = BRAND;

const matched = (
  brand: BrandType,
  fieldName: string,
  nameToFind: string,
): boolean =>
  brand[fieldName].some((name: string) => name && name.includes(nameToFind));

const bothNamesMatch = (
  brand: BrandType,
  firstName: string,
  secondName: string,
): boolean =>
  matched(brand, FIRST_NAMES, firstName) &&
  matched(brand, LAST_NAMES, secondName);

const containAllNames = (
  atLeastOneNameMatches: Collection,
  names: Array<string>,
): Collection => {
  if (names.length > 1) {
    const [firstName, secondName] = names;
    return atLeastOneNameMatches.and(
      (brand: BrandType) =>
        bothNamesMatch(brand, firstName, secondName) ||
        bothNamesMatch(brand, secondName, firstName),
    );
  }
  return atLeastOneNameMatches;
};

export const queryByNames = async (
  db: Dexie,
  names: Array<string>,
  paging: Page = {},
): Promise<Array<Array<BrandType> | number>> => {
  const atLeastOneNameMatches = db.brands
    .where(FIRST_NAMES)
    .startsWithAnyOf(names)
    .or(LAST_NAMES)
    .startsWithAnyOf(names);

  const results = containAllNames(atLeastOneNameMatches, names);

  const count = total(results);

  const pagedResults = getPaged(results.distinct(), paging);

  return Promise.all([pagedResults, count]);
};
