// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import { Form, Row, Col, Button, type WrappedFormUtils } from 'antd';
import type { ReduxStore } from 'flow/redux';
import Page from 'components/Page';
import CodesAlertBanner from 'components/CodesAlertBanner';
import { selectHorseCategoryId } from 'selectors/resources';
import MainForm from './components/MainForm';

const CreateHorsePreSalePage = () => {
  const category: number = useSelector(selectHorseCategoryId);

  const loading: boolean = useSelector((state: ReduxStore) => {
    return state.createHorsePreSale.loading;
  });

  const [form]: [WrappedFormUtils] = Form.useForm();

  return (
    <Page>
      <CodesAlertBanner />

      <MainForm form={form} category={category} loading={loading} />

      <Row gutter={10} justify="end">
        <Col span={5}>
          <Button
            htmlType="submit"
            type="primary"
            block
            loading={loading}
            onClick={form.submit}
            id="savePresale"
          >
            Print
          </Button>
        </Col>
      </Row>
    </Page>
  );
};

export default CreateHorsePreSalePage;
