// @flow
import React, { Fragment, useState, type ComponentType } from 'react';
import { useDispatch, useSelector, type Dispatch } from 'react-redux';
import { Link, useHistory, type History } from 'react-router-dom';
import {
  MenuOutlined,
  LogoutOutlined,
  CloudSyncOutlined,
  LoadingOutlined,
  DashboardOutlined,
  LockOutlined,
  ExclamationCircleOutlined,
  ApiOutlined,
} from '@ant-design/icons';
import { Button, Drawer, Menu, Typography, Space, Tooltip, Modal } from 'antd';
import styled from 'styled-components';
import type { ReduxStore } from 'flow/redux';
import { fetchBrands } from 'store/brandsSlice';
import SyncOfflineMenuItem from 'features/SyncOfflineMenuItem';
import { ENVIRONMENT } from 'service/urls';
import { selectIsAdmin } from 'selectors/me';
import { selectIsOnline } from 'features/Initializer/initializerSlice';
import User from './User';
import MENU_ITEMS from '../constants';

export type MenuItemType = {
  name: string,
  to: string,
  Icon: ComponentType<*>,
};

const StyledLink = styled(Button).attrs({
  type: 'link',
})`
  padding: 0;
  color: inherit;
`;

const StyledIcon = styled(MenuOutlined)`
  font-size: 1.5em;
  display: flex !important;
`;

const { confirm } = Modal;

const Sidebar = () => {
  const history: History = useHistory();

  const dispatch: Dispatch = useDispatch();

  const online = useSelector(selectIsOnline);

  const syncing = useSelector((state: ReduxStore) => {
    return state.brands.syncing;
  });

  const isAdmin: boolean = useSelector(selectIsAdmin);

  const [open, setOpen] = useState<boolean>(false);

  const openDrawer = () => setOpen(true);

  const closeDrawer = () => setOpen(false);

  const handleSync = () => {
    dispatch(fetchBrands());
  };

  const logout = () => {
    history.push('/logout');
  };

  const handleLogout = () => {
    closeDrawer();

    if (online) {
      logout();
    } else {
      confirm({
        icon: <ExclamationCircleOutlined />,
        content: (
          <Typography.Text>
            Are you sure you want to logout? Logging out while offline may
            prevent you from using the application until you are able to connect
            to the network again.
          </Typography.Text>
        ),
        okText: 'Sign Out',
        onOk() {
          logout();
        },
        okButtonProps: {
          danger: true,
        },
      });
    }
  };

  return (
    <Fragment>
      <Space align="center">
        {syncing && (
          <Tooltip placement="bottom" title="Sync in progress...">
            <LoadingOutlined />
          </Tooltip>
        )}
        {!online && (
          <Tooltip placement="bottom" title="You are currently offline">
            <ApiOutlined style={{ fontSize: 28, color: '#fa541c' }} />
          </Tooltip>
        )}
        <User />
        <StyledLink onClick={openDrawer}>
          <StyledIcon />
        </StyledLink>
      </Space>
      <Drawer
        title="Utah Brand Inspection"
        footer={
          <Link to="/changelog" onClick={closeDrawer}>
            Build v{ENVIRONMENT.build.version}
          </Link>
        }
        placement="right"
        closable
        onClose={closeDrawer}
        visible={open}
        width={350}
        headerStyle={{ padding: 16 }}
        footerStyle={{ padding: 16 }}
        bodyStyle={{ padding: 0 }}
      >
        <Menu mode="vertical">
          {isAdmin && (
            <Menu.Item icon={<LockOutlined />}>
              <Link to="/admin" onClick={closeDrawer}>
                Admin
              </Link>
            </Menu.Item>
          )}
          {MENU_ITEMS.map(({ name, to, Icon }: $Shape<MenuItemType>) => (
            <Menu.Item key={name} icon={<Icon />}>
              <Link to={to} onClick={closeDrawer}>
                {name}
              </Link>
            </Menu.Item>
          ))}
          <Menu.Item
            icon={syncing ? <LoadingOutlined /> : <CloudSyncOutlined />}
            disabled={syncing}
          >
            <StyledLink disabled={syncing} onClick={handleSync}>
              {syncing ? <i>Brands data sync in progress</i> : 'Sync Brands'}
            </StyledLink>
          </Menu.Item>
          <SyncOfflineMenuItem />
          <Menu.Item icon={<DashboardOutlined />}>
            <Link to="/dashboard" onClick={closeDrawer}>
              Dashboard
            </Link>
          </Menu.Item>
          <Menu.Item icon={<LogoutOutlined />}>
            <StyledLink onClick={handleLogout}>Logout</StyledLink>
          </Menu.Item>
        </Menu>
      </Drawer>
    </Fragment>
  );
};

export default Sidebar;
