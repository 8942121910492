// @flow
import React from 'react';
import { Card, Select } from 'antd';
import { Chart, Interval, Tooltip } from 'bizcharts';

const { Option } = Select;

const data = [
  { month: 'Jan', monthFull: 'January', inspection: 38 },
  { month: 'Feb', monthFull: 'February', inspection: 52 },
  { month: 'Mar', monthFull: 'March', inspection: 61 },
  { month: 'Apr', monthFull: 'April', inspection: 45 },
  { month: 'May', monthFull: 'May', inspection: 48 },
  { month: 'Jun', monthFull: 'June', inspection: 38 },
  { month: 'Jul', monthFull: 'July', inspection: 38 },
  { month: 'Aug', monthFull: 'August', inspection: 38 },
  { month: 'Sept', monthFull: 'September', inspection: 48 },
  { month: 'Oct', monthFull: 'October', inspection: 38 },
  { month: 'Nov', monthFull: 'November', inspection: 38 },
  { month: 'Dec', monthFull: 'December', inspection: 38 },
];

const InspectionsChart = () => {
  return (
    <Card
      title="Total number of brand inspection"
      extra={
        <Select
          defaultValue=""
          style={{ width: 120 }}
          size="small"
          virtual={false}
        >
          <Option value="">This Year</Option>
          <Option value="2019">2019</Option>
          <Option value="2018">2018</Option>
        </Select>
      }
      bodyStyle={{ padding: '10px 20px 0 10px' }}
    >
      <Chart
        height={448}
        autoFit
        data={data}
        interactions={['active-region']}
        padding={[20, 0, 40, 30]}
      >
        <Interval position="month*inspection" color="#296e3e" />
        <Tooltip shared>
          {(title, items) => {
            const { monthFull, inspection } = items[0].data || {};

            return (
              <div style={{ padding: '1em 0.5em' }}>
                {monthFull}: {inspection}
              </div>
            );
          }}
        </Tooltip>
      </Chart>
    </Card>
  );
};

export default InspectionsChart;
