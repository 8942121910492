// @flow
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const fetchAdminLog = createAsyncThunk(
  'adminLog/fetchAdminLog',
  async (id: string, { extra, rejectWithValue }) => {
    const { inspectionClient } = extra;

    try {
      const { data } = await inspectionClient().get(`/offline-error-logs/${id}/`);

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

const adminLogSlice = createSlice({
  name: 'adminLog',
  initialState: {
    pending: true,
    data: null,
  },
  extraReducers: {
    [fetchAdminLog.pending]: (state) => {
      state.pending = true;
    },
    [fetchAdminLog.fulfilled]: (state, action) => {
      state.pending = false;
      state.data = action.payload;
    },
  },
});

export default adminLogSlice.reducer;
