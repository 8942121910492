// @flow
import React, { Fragment, useState } from 'react';
import { LIVESTOCK_PARTS } from 'constants/livestock';
import { getTargetPart } from './helpers';
import { StyledLabel, StyledPath, StyledPart } from './styles';

type Props = {
  image: string,
  selected: Array<*>,
  onSelect: (string) => void,
  visibleLabels: boolean,
};

const CattleLeftSvg = ({ image, selected, onSelect, visibleLabels }: Props) => {
  const [hoveredPart, setHoveredPart] = useState<string>('');

  const handleHover = (ev) => {
    const part = getTargetPart(ev.target);

    setHoveredPart(part);
  };

  const handleMouseLeave = () => {
    setHoveredPart('');
  };

  const handleSelect = (ev) => {
    const part = getTargetPart(ev.target);

    onSelect(part);
  };

  return (
    <svg
      viewBox="0 0 376 264"
      onMouseOver={handleHover}
      onMouseLeave={handleMouseLeave}
      onFocus={() => {}}
      onClick={handleSelect}
      style={{ maxWidth: '420px', margin: 'auto' }}
    >
      {visibleLabels && (
        <Fragment>
          <StyledLabel transform="matrix(1 0 0 1 131.9941 23)">
            Left Shoulder
          </StyledLabel>
          <StyledLabel transform="matrix(1 0 0 1 234.525 26)">
            Left Ribs
          </StyledLabel>
          <StyledLabel transform="matrix(1 0 0 1 303.5693 35)">
            Left Hip-Thigh
          </StyledLabel>
        </Fragment>
      )}
      <StyledPath d="M348.5,125c-0.7,19.2,2.2,33.9,2.2,33.9c0.7-10.5,0-63.1,0-63.1S349.2,105.8,348.5,125z" />
      <StyledPath
        d="M117,94.8c-2.8-21.9,3-41,12.3-52.3c-2.1,0.6-4,1.2-5.7,1.7c-7.7,2.2-28.4-2.2-28.4-2.2
          c2.2-5.5-3.2-6.7-9.5-6.5s-8.7,2.2-8.7,2.2c-6-2.2-12.5-5.7-14.5-7c-2-1.2,13-18.2-7-28.7c-1.3-0.7,9,15.5-1.5,21.1
          c-10.5,5.6-4.1,1.4-6,5.9c-2.5,6-9.5,7-14.2,11.7c-4.7,4.7-4,10.2-4,10.2s-2,1-5.5,5.7s-13.2,19-16.2,22.2s-6.7,5.2-6,9.2
          c0.7,4,5.7,7.2,5.7,7.2c0.5,2,5,3.5,9.2,3.5c4,0,5.1-1.8,5.2-2l0,0c4-1,9-0.2,17.5-0.2c8.5,0,27.9-3.7,27.9-3.7
          c13.7,6.5,31.4,26.9,34.4,36.2c3,9.2,9.2,13.2,12.5,18.2c3.2,5,7,15.2,11.7,21.2c4.5,5.7,16.1,3.1,17.3,2.8
          C148.9,141.5,121.3,128.4,117,94.8z"
      />
      <StyledPart
        hovered={hoveredPart === LIVESTOCK_PARTS.LEFT_SHOULDER}
        active={selected.includes(LIVESTOCK_PARTS.LEFT_SHOULDER)}
        data-id={LIVESTOCK_PARTS.LEFT_SHOULDER}
      >
        <StyledPath
          d="M206.1,108.8c-6.9-17.8-6.5-42,0-67c-5.5-0.3-10.3-0.5-13.2-0.6c-8.2-0.2-37.7-4-43.4-3.5
          c-4.5,0.4-13,2.7-20.2,4.8c-9.3,11.3-15,30.4-12.3,52.3c4.3,33.6,31.9,46.7,26.6,76.3c0.1,0,0.1,0,0.1,0s-0.2,7.2-1.7,19.5
          c-1.5,12.2-1,12-0.2,17.3c0.7,5.3,1.2,12.4,0.5,16.4c-0.7,4-3,10.7-4.7,18c-1.7,7.2-7,10-7,10s-7.2,5.3-7,8
          c0.2,2.7,20.7,1.9,20.7,1.9c2.2-1,3.9-5.6,3.9-5.6c1.7,1.5,17.8,0.6,17.8,0.6c3-1.2,4.2-6.5,3.5-8.7c-0.7-2.2-0.5-3.7-0.5-3.7h5.3
          c0,0-0.3-8.3-1.3-12c-1-3.7-4.3-25.6-4.5-29.9c-0.2-4.3,2.1-6.3,0.8-9c-1.3-2.7-1.7-26.3-1.7-26.3l1.6-4.3c0,0,9.1-4.3,13.1-4.3
          c3.6,0,35.6,10.2,53.9,13.2C237.4,152.3,216.4,135.3,206.1,108.8z"
        />
        <image
          href={image}
          width="75"
          height="75"
          transform="matrix(0.4630768090090642 0 0 0.4659036501107351 145.57719572944788 80.129376)"
        />
      </StyledPart>
      <StyledPart
        hovered={hoveredPart === LIVESTOCK_PARTS.LEFT_RIB}
        active={selected.includes(LIVESTOCK_PARTS.LEFT_RIB)}
        data-id={LIVESTOCK_PARTS.LEFT_RIB}
      >
        <StyledPath
          d="M304.4,49.5c-6-0.1-10.2,1.1-15.5,0.6c-8.5-0.7-40.9-4-53.1-5.7c-7.8-1.1-19.9-2-29.7-2.6
          c-6.4,25-6.9,49.2,0,67c10.3,26.5,31.2,43.5,29.9,63.3c2.1,0.3,4,0.6,5.7,0.7c16.2,1.2,26.7-3.7,26.7-3.7c1.7,1.5,7,2.2,7,2.2
          s-0.2,5.5,1.5,5.7c1.7,0.2,2-4.2,2-4.2c3.2,1,7.5,0.7,7.5,0.7s0.2,5.5,2,5.2c1.7-0.2,1.5-5,1.5-5l3.3-0.7
          c3.4-13.9,2.8-36.8-3.7-63.7C282.9,82.3,289.5,61.1,304.4,49.5z"
        />
        <image
          href={image}
          width="75"
          height="75"
          transform="matrix(0.4630768090090642 0 0 0.4659036501107351 230.57719572944788 75.129376)"
        />
      </StyledPart>
      <StyledPart
        hovered={hoveredPart === LIVESTOCK_PARTS.LEFT_THIGH}
        active={selected.includes(LIVESTOCK_PARTS.LEFT_THIGH)}
        data-id={LIVESTOCK_PARTS.LEFT_THIGH}
      >
        <StyledPath
          d="M369.2,239.7c0,0-3.5-12-6.3-24.2c-2.8-12.2-1.2-28.2-0.2-32.7c1-4.5-2-7.2-3.5-11.5
          c-1.5-4.2-2.2-29.2-2.5-40.4s-0.2-28.7,1-38.2c1.2-9.5,0-21.7-6.2-27.4c-6.2-5.7-18-9.5-33.2-13.7c-5.8-1.6-10.2-2.1-14-2.1
          c-14.9,11.6-21.4,32.8-14.9,60c6.5,26.8,7,49.8,3.7,63.7l0.2-0.1c0,0,3.5,16.2,4.5,23.4s-2,19.7-5.2,27.9c-3.2,8.2-7.2,15-7.2,15
          c-3.3,1.7-3.5,4.5-3.5,4.7l0,0c-3.2,1-7.7,6.5-6.5,8.2c1.2,1.7,17.7,1.2,17.7,1.2c3-2.2,2-5.5,2.5-7.7c0.5-2.2,2.5-5.5,2.5-5.5
          s4.2,3.7,5.7,2.5c1.5-1.2-1.5-6-1.5-6s5.2-19.2,8.2-28.9c3-9.7,4.5-17,7.5-21.7c3-4.7-1.7-11.2-1.7-11.2c8.7-1.2,14.5-9.5,14.5-9.5
          c1.7,8.5,10.7,18,12.7,22.9c2,5,11,30.9,11.7,36.2c0.7,5.2,0.5,18.7-1,21.4c-1.5,2.7-2.2,5.7-2.2,5.7s-7,6.2-5.2,8.7
          c1.7,2.5,18,1,18,1c1.7-2.2,3.2-6,2.5-9.7s0.7-7.2,0.7-7.2s3.5,2.5,4,0.7C372.4,243.7,369.2,239.7,369.2,239.7z M350.8,158.9
          c0,0-2.9-14.7-2.2-33.9c0.7-19.2,2.2-29.2,2.2-29.2S351.5,148.4,350.8,158.9z"
        />
        <image
          href={image}
          width="75"
          height="75"
          transform="matrix(0.4630768090090642 0 0 0.4659036501107351 305.57719572944788 85.129376)"
        />
      </StyledPart>
    </svg>
  );
};

export default CattleLeftSvg;
