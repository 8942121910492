// @flow
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Col, Row, Table, Typography } from 'antd';
import formatMoney, { centsToDollars } from 'utils/formatters/formatMoney';
import Footnote from 'features/MinimumFeeFootnote/footnote';

type Props = {
  totalInspectionFee: number,
  totalPredatorControlFee: number,
  totalBeefCouncilFee: number,
  totalBeefTagFee: number,
  totalHeads: number,
  hasBeefTag: boolean,
  inspectionFeeFootnote: boolean,
  isCattle: boolean,
  handleInspectionMinimumWaiving?: ?() => void,
};

const SubTotal = ({
  totalInspectionFee,
  totalPredatorControlFee,
  totalBeefCouncilFee,
  totalBeefTagFee,
  totalHeads,
  hasBeefTag,
  inspectionFeeFootnote,
  isCattle,
  handleInspectionMinimumWaiving,
}: Props) => {
  return (
    <Table.Summary.Row className="subtotals">
      <Table.Summary.Cell colSpan={1}>
        <Row>
          <Col span={3} style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>
            {totalHeads} head
          </Col>
          <Col span={21} style={{ textAlign: 'right' }}>
            Sub Totals
          </Col>
        </Row>
      </Table.Summary.Cell>
      {hasBeefTag && <Field fee={totalBeefTagFee} />}
      {isCattle && (
        <Fragment>
          <Field fee={totalBeefCouncilFee} />
          <Field fee={totalPredatorControlFee} />
        </Fragment>
      )}
      <Field
        fee={totalInspectionFee}
        footnoteCharacter={inspectionFeeFootnote}
        action={handleInspectionMinimumWaiving}
      />
    </Table.Summary.Row>
  );
};

SubTotal.defaultProps = {
  handleInspectionMinimumWaiving: () => {},
};

const StyledCellBtn = styled.button.attrs({
  type: 'button',
})`
  border: none;
  background: transparent;
  padding: 0;
  display: block;
  width: 100%;
  cursor: pointer;
  outline: none;
  text-align: right;
`;

const Field = ({
  fee,
  footnoteCharacter,
  action,
}: {
  fee: number,
  footnoteCharacter?: boolean,
  action?: ?() => void,
}) => (
  <Table.Summary.Cell>
    <StyledCellBtn onClick={action}>
      <Typography.Text>
        {formatMoney({ value: centsToDollars(fee) })}
        {footnoteCharacter && <Footnote />}
      </Typography.Text>
    </StyledCellBtn>
  </Table.Summary.Cell>
);

Field.defaultProps = {
  footnoteCharacter: false,
  action: null,
};

export default SubTotal;
