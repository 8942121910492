// @flow
import { inspectionsDb, codesDb } from 'service/idb';
import { showErrorMessage } from 'utils/showMessage';

const isV2Code = (code: string) => {
  // A code has 4 parts
  const codeParts = code.split('-');

  // v2 generated codes
  // the last part should be the type abbreviation
  return ['BI', 'PSH', 'LTP', 'YTP'].includes(codeParts[3]);
};

const pickCodes = (
  data: Array<*>,
  key: 'inspectionCode' | 'permitCode' | 'presaleCode',
): Array<number> => {
  return data
    .filter((item: *) => isV2Code(item[key]))
    .map((item: *) => {
      const parts = item[key].split('-');

      // 3rd part of the certificate code should be the
      // actual unique sequential code
      return parseInt(parts[2], 10);
    });
};

export const deleteEntityCode = async (entityCode: string) => {
  try {
    const parts = entityCode.split('-');

    // parts[2] contains the code
    const code = parseInt(parts[2], 10);

    await codesDb.codes.where('code').equals(code).delete();

    return Promise.resolve();
  } catch (err) {
    return Promise.reject(err);
  }
};

export const mapOfflineUsedV2Codes = async () => {
  const [
    offlineInspections,
    offlinePermits,
    offlinePresale,
  ] = await Promise.all([
    inspectionsDb.offlineInspections.toArray(),
    inspectionsDb.offlineTravelPermit.toArray(),
    inspectionsDb.offlinePreSaleInspections.toArray(),
  ]);

  return [
    ...pickCodes(offlineInspections, 'inspectionCode'),
    ...pickCodes(offlinePermits, 'permitCode'),
    ...pickCodes(offlinePresale, 'presaleCode'),
  ].flat();
};

export default async (type: 'BI' | 'LTP' | 'YTP' | 'PSH') => {
  try {
    // Gather all offline certificate codes
    const offlineEntityCodes = await mapOfflineUsedV2Codes();

    // remove all duplicating codes
    if (offlineEntityCodes.length > 0) {
      await codesDb.codes
        .where('code')
        .anyOf(...offlineEntityCodes)
        .delete();
    }

    // get entry at the top
    const first = await codesDb.codes.orderBy('code').first();

    if (first) {
      const user = await inspectionsDb.user.orderBy('id').first();

      const userId = String(user.id).padStart(5, '0');

      const code = String(first.code).padStart(6, '0');

      return Promise.resolve({
        code: `UDAF-${userId}-${code}-${type}`,
        publicId: first.publicId,
      });
    }

    showErrorMessage(
      'You have run out of Certificate Numbers. In order to write more inspections, please connect to the Internet and sync.',
    );

    return null;
  } catch (err) {
    return Promise.reject(err);
  }
};
