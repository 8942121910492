// @flow
import React from 'react';
import { Select } from 'antd';
import usePaymentTypeQuery from 'utils/hooks/usePaymentTypeQuery';

const { Option } = Select;

const PAYMENT_TYPES = [
  {
    text: 'All',
    value: '',
  },
  {
    text: 'Check',
    value: 'check',
  },
  {
    text: 'Credit card',
    value: 'creditcard',
  },
  {
    text: 'Cash',
    value: 'cash',
  },
  {
    text: 'Pay Later',
    value: 'pay_later',
  },
  {
    text: 'Released at no charge',
    value: 'released_at_no_charge',
  },
  {
    text: 'Invoice',
    value: 'invoice',
  },
];

const PaymentTypeFilter = () => {
  const [paymentType, setPaymentType] = usePaymentTypeQuery();

  return (
    <Select
      style={{ width: 180 }}
      onChange={setPaymentType}
      value={paymentType}
    >
      {PAYMENT_TYPES.map((type: { text: string, value: string }) => {
        return (
          <Option value={type.value} key={type.value}>
            {type.text}
          </Option>
        );
      })}
    </Select>
  );
};

export default PaymentTypeFilter;
