// @flow
import React from 'react';
import type { BrandType } from 'flow/brands';
import { getPositionLabel } from 'flow/brands';
import { Pill } from 'components/InspectionCertificateTemplate/styles';

type Props = {
  brands: Array<BrandType>,
};
const Brands = ({ brands }: Props): Array<*> =>
  brands.map(({ id, position, imageUrl }: $Shape<BrandType>) => {
    return (
      <Pill key={id}>
        {imageUrl && <img src={imageUrl} alt={position} />}
        {getPositionLabel(position)}
      </Pill>
    );
  });

export default Brands;
