// @flow
import { createSlice } from '@reduxjs/toolkit';

const loadingMaskSlice = createSlice({
  name: 'loadingMask',
  initialState: {
    loading: false,
  },
  reducers: {
    toggleLoading: (state) => {
      state.loading = !state.loading;
    },
  },
});

export const { toggleLoading } = loadingMaskSlice.actions;

export default loadingMaskSlice.reducer;
