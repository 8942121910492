// @flow
import { createSlice } from '@reduxjs/toolkit';

const appUpdateSlice = createSlice({
  name: 'appUpdate',
  initialState: { updateVisible: false },
  reducers: {
    toggleNotifierVisibility: (state) => {
      state.updateVisible = !state.updateVisible;
    },
  },
});

export const { toggleNotifierVisibility } = appUpdateSlice.actions;

export default appUpdateSlice.reducer;
