// @flow
import React from 'react';
import { Steps } from 'antd';
import styled from 'styled-components';

type Props = {
  pathname: string,
};

const StyledWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto 2em;
`;

const { Step } = Steps;

const Stepper = ({ pathname }: Props) => {
  const getCurrentStep = () => {
    switch (pathname) {
      case '/brand-inspection/animals':
        return 1;
      case '/brand-inspection/preview':
        return 2;
      case '/brand-inspection':
      default:
        return 0;
    }
  };

  return (
    <StyledWrapper>
      <Steps current={getCurrentStep()}>
        <Step title="Owner Information" />
        <Step title="Animal Information" />
        <Step title="Preview and Print" />
      </Steps>
    </StyledWrapper>
  );
};

export default Stepper;
