// @flow
import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import {
  Form,
  Typography,
  Radio,
  Row,
  Col,
  Input,
  Button,
  type WrappedFormUtils,
} from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useDispatch, useSelector, type Dispatch } from 'react-redux';
import type { ReduxStore } from 'flow/redux';
import type { Payment } from 'flow/payment';
import { selectDisplayCreditCardOption } from 'selectors/inspection';
import useCreditCardCheckout, {
  type WrappedCheckoutUtils,
  type PaymentListenerProps,
} from 'utils/hooks/useCreditCardCheckout';
import { settleEntityPayment } from './settlePaymentSlice';

type Props = {
  payment: $Shape<Payment>,
  entityId: number | string,
  entityType: 'inspection' | 'permit' | 'presale',
};

const { Title, Text } = Typography;

const SettlePaymentForm = ({ payment, entityId, entityType }: Props) => {
  const [form]: [WrappedFormUtils] = Form.useForm();

  const READING_CARD = 'Reading credit card';
  const [isSwiped, setIsSwiped] = useState<boolean>(false);
  const [ccMessage, setCcMessage] = useState<string>(READING_CARD);
  const [paymentSuccess, setPaymentSuccess] = useState<boolean>(false);

  const { id, name, status } = payment;

  const dispatch: Dispatch = useDispatch();

  const isLoading: boolean = useSelector(
    (state: ReduxStore) => state.settlePayment.loading,
  );

  const displayCreditCardOption = useSelector(selectDisplayCreditCardOption);

  const addNSecondsDelay = (n) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, n * 1000);
    });
  };

  // const simulateSwipe = async () => {
  //   setIsSwiped(true);
  //   await addNSecondsDelay(1);
  //   setCcMessage('Processing payment');
  //   await addNSecondsDelay(5);
  //   setPaymentSuccess(true);
  //   setIsSwiped(false);
  //   setCcMessage(READING_CARD);
  // };

  const submit = (values: $Shape<Payment>) => {
    dispatch(
      settleEntityPayment({
        paymentId: id,
        entityId,
        entityType,
        payment: values,
      }),
    );
  };

  const paymentListener = ({ success }: PaymentListenerProps) => {
    if (success) {
      setPaymentSuccess(true);
      const values: $Shape<Payment> = form.getFieldsValue();

      submit(values);
    }
  };

  const { checkout }: WrappedCheckoutUtils =
    useCreditCardCheckout(paymentListener);

  const handleSubmit = (values: $Shape<Payment>) => {
    if (!isLoading) {
      const isCardPayment = values.paymentType === 'creditcard';

      if (isCardPayment) {
        const { total, referenceId } = payment;

        checkout({
          amountInCents: total,
          publicId: referenceId,
          entityType,
        });
      } else {
        submit(values);
      }
    }
  };

  const isFailedPayment = status === 'FAILED';

  return (
    <div className="hidden-print">
      <Form
        form={form}
        onFinish={handleSubmit}
        initialValues={{ paymentType: '' }}
        name="SettlePaymentForm"
        colon={false}
        layout="vertical"
      >
        <StyledRow gutter={20} wrap={false}>
          {name && (
            <Col>
              <Title level={5}>Billed to</Title>
              <Text>{name}</Text>
            </Col>
          )}

          {isFailedPayment && (
            <Fragment>
              <Title level={5} type="danger">
                Payment failed
              </Title>

              <Text type="danger">
                Credit card payment failed. Try again or use another card.
              </Text>
            </Fragment>
          )}

          <Col>
            <Title level={5}>Pay now</Title>

            <Form.Item
              name="paymentType"
              rules={[
                {
                  required: true,
                  message: 'Please select a payment option',
                },
              ]}
              style={{ marginBottom: 5 }}
            >
              <Radio.Group disabled={isLoading}>
                <Radio value="check">Check</Radio>
                {displayCreditCardOption && (
                  <Radio value="creditcard">Credit Card</Radio>
                )}
                <Radio value="cash">Cash</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              shouldUpdate={(prevValues, curValues) =>
                prevValues?.paymentType !== curValues?.paymentType
              }
              noStyle
            >
              {(form) => {
                const paymentType = form.getFieldValue('paymentType') || '';

                if (paymentType === 'check') {
                  return (
                    <Row gutter={0}>
                      <Col style={{ width: '9em', paddingRight: '10px' }}>
                        <Form.Item
                          label="Check Number"
                          name="checkNumber"
                          rules={[
                            {
                              required: true,
                              message: 'Check Number is required',
                            },
                          ]}
                        >
                          <Input placeholder="XXXXXXX" disabled={isLoading} />
                        </Form.Item>
                      </Col>
                      <Col style={{ width: '9em' }}>
                        <Form.Item
                          label="Payee"
                          name="checkPayee"
                          rules={[
                            {
                              required: true,
                              message: 'Payee is required',
                            },
                          ]}
                        >
                          <Input placeholder="" style={{ maxWidth: '300px' }} />
                        </Form.Item>
                      </Col>
                    </Row>
                  );
                }

                if (paymentType === 'creditcard') {
                  return (
                    <Col>
                      {/* {!isSwiped && !paymentSuccess && (
                        <Button
                          htmlType="button"
                          block
                          onClick={simulateSwipe}
                          type="primary"
                          ghost
                        >
                          Simulate Swipe
                        </Button>
                      )} */}
                      {isSwiped && (
                        <Typography.Paragraph
                          style={
                            ccMessage === READING_CARD
                              ? { color: '#f00', paddingLeft: '20px' }
                              : { color: '#36a500', paddingLeft: '20px' }
                          }
                        >
                          {ccMessage}... &nbsp;
                          <LoadingOutlined />
                        </Typography.Paragraph>
                      )}
                      {paymentSuccess && (
                        <Typography.Title level={5}>
                          Payment success!
                        </Typography.Title>
                      )}
                    </Col>
                  );
                }

                return null;
              }}
            </Form.Item>
          </Col>
          <Col align="middle" style={{ padding: '1.5em' }}>
            <Button htmlType="submit" type="primary" loading={isLoading}>
              Pay now
            </Button>
          </Col>
        </StyledRow>
      </Form>
    </div>
  );
};

const StyledRow = styled(Row)`
  max-width: 520px;
  padding-bottom: 1em;
`;

export default SettlePaymentForm;
