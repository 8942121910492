// @flow
import React, { Fragment } from 'react';
import { Form, Typography, Checkbox } from 'antd';

const OwnerConsentFormField = () => {
  return (
    <Fragment>
      <Typography.Title level={5} style={{ marginBottom: 5 }}>
        Owner Consent
      </Typography.Title>
      <Form.Item
        name="consent"
        valuePropName="checked"
        rules={[
          {
            required: true,
            message: 'Owner must agree to the Terms and Conditions to proceed.',
          },
        ]}
      >
        <Checkbox>
          The information I have provided is true and accurate, and I agree to
          the terms and conditions of this inspection.
        </Checkbox>
      </Form.Item>
    </Fragment>
  );
};

export default OwnerConsentFormField;
