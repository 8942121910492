// @flow
import React, { Fragment } from 'react';
import { useSelector, useDispatch, type Dispatch } from 'react-redux';
import { useEffectOnce, useUpdateEffect, useUnmount } from 'react-use';
import { useRouteMatch, useHistory, type History } from 'react-router-dom';
import moment from 'moment';
import { Modal, Button, Table, Tag } from 'antd';
import type { Payment } from 'flow/payment';
import {
  PAYMENT_DESCRIPTIONS,
  PAYMENT_STATUS_COLORS,
  PAYMENT_STATUS_TEXT,
} from 'constants/inspection';
import {
  fetchPaymentHistory,
  clearAll,
  selectPaymentHistory,
  selectLoading,
} from './slice';

type Props = {
  id: number,
  entityType: 'BI' | 'PSH' | 'TPH',
};

const COLUMNS = [
  {
    title: 'Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    defaultSortOrder: 'ascend',
    sorter: () => -1,
    render: (createdAt: string) =>
      moment(createdAt).format('MM/DD/YYYY hh:MM:ss A'),
  },
  {
    title: 'Type',
    dataIndex: 'paymentType',
    key: 'paymentType',
    render: (paymentType: string) => PAYMENT_DESCRIPTIONS[paymentType],
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status: string, { paymentType }: $Shape<Payment>) => {
      if (paymentType === 'creditcard') {
        return (
          <Tag color={PAYMENT_STATUS_COLORS[status]}>
            {PAYMENT_STATUS_TEXT[status] || 'Pending'}
          </Tag>
        );
      }

      if (paymentType === 'pay_later' || paymentType === 'invoice') {
        return <Tag color="warning">Pending</Tag>;
      }

      return <Tag color="success">Completed</Tag>;
    },
  },
];

const PaymentHistory = ({ id, entityType }: Props) => {
  const dispatch: Dispatch = useDispatch();

  const history: History = useHistory();

  const basePath = {
    BI: 'inspections',
    TPH: 'travel-permits',
    PSH: 'horse-presale-listing',
  }[entityType];

  const modalVisible: boolean = !!useRouteMatch(`/${basePath}/:id/history`);

  const paymentHistory: Array<Payment> = useSelector(selectPaymentHistory);

  const loading: boolean = useSelector(selectLoading);

  const handleShow = () => {
    history.push(`/${basePath}/${id}/history`);
  };

  const handleClose = () => {
    history.push(`/${basePath}/${id}`);
  };

  const clear = () => dispatch(clearAll());

  const fetchData = () => {
    if (modalVisible) {
      dispatch(fetchPaymentHistory({ entityId: id, entityType }));
    } else {
      clear();
    }
  };

  useEffectOnce(() => {
    fetchData();
  });

  useUpdateEffect(() => {
    fetchData();
  }, [modalVisible]);

  useUnmount(() => {
    clear();
  });

  return (
    <Fragment>
      <Button onClick={handleShow} type="primary" ghost>
        View payment history
      </Button>

      <Modal
        title="Payment History"
        visible={modalVisible}
        onCancel={handleClose}
        bodyStyle={{ padding: 10 }}
        width={550}
        footer={null}
      >
        <Table
          rowKey="id"
          size="small"
          columns={COLUMNS}
          dataSource={paymentHistory}
          loading={loading}
          pagination={false}
          bordered
        />
      </Modal>
    </Fragment>
  );
};

export default PaymentHistory;
