// @flow
import { createSelector } from '@reduxjs/toolkit';
import type { ReduxStore, ReduxState } from 'flow/redux';
import type { Role, UserType } from 'flow/user';
import {
  ADMIN_ROLE,
  CREDIT_CARD_TESTER_ROLE,
  INSPECTOR_ROLE,
  RECORDER_ROLE,
} from 'flow/user';

const selectMeDomain = (state: ReduxStore) => state.me;

export const selectMe = createSelector(
  selectMeDomain,
  (state: ReduxState) => state.data,
);

export const selectMePending = createSelector(
  selectMeDomain,
  (state: ReduxState) => state.pending,
);

export const selectIsInspector = createSelector(selectMe, (user: UserType) =>
  (user?.roles || []).some(({ role }: { role: Role }) =>
    [INSPECTOR_ROLE, RECORDER_ROLE, ADMIN_ROLE].includes(role),
  ),
);

const hasRole = (user: UserType, neededRole: Role): boolean =>
  (user?.roles || []).some(({ role }: { role: Role }) => role === neededRole);

export const selectIsAdmin = createSelector(
  selectMe,
  (user: UserType): boolean => hasRole(user, ADMIN_ROLE),
);

export const selectIsCreditCardTester = createSelector(
  selectMe,
  (user: UserType): boolean => hasRole(user, CREDIT_CARD_TESTER_ROLE),
);
