// @flow
import React, { type ElementRef, type Node } from 'react';
import { Select } from 'antd';
import type { ColorTag } from 'flow/resources';
import useResources from 'utils/hooks/useResources';
import selectFilterOptions from 'utils/form/selectFilterOptions';

const { Option } = Select;

type Props = {
  value: string,
  onChange: (string) => void,
  forwardedRef: ElementRef<*>,
  size?: 'small' | 'medium' | 'large',
  placeholder?: string,
};

const AnimalTagsFormField = ({ forwardedRef, ...rest }: Props) => {
  const tags: Array<ColorTag> = useResources<ColorTag>('animalTags');

  return (
    <Select
      {...rest}
      ref={forwardedRef}
      virtual={false}
      showSearch
      filterOption={selectFilterOptions}
    >
      {tags.map(({ id, color }: $Shape<ColorTag>) => (
        <Option value={id} key={id}>
          {color}
        </Option>
      ))}
    </Select>
  );
};

AnimalTagsFormField.defaultProps = {
  size: 'medium',
  placeholder: 'Choose Ranch Tag Color',
};

export default React.forwardRef<*, Node>((props: Props, ref: ElementRef<*>) => {
  return <AnimalTagsFormField forwardedRef={ref} {...props} />;
});
