// @flow
import React, { type ElementRef, type Node } from 'react';
import { Select } from 'antd';
import type { Breed } from 'flow/resources';
import useResources from 'utils/hooks/useResources';
import selectFilterOptions from 'utils/form/selectFilterOptions';

const { Option } = Select;

type Props = {
  value: string,
  category: number,
  sort?: (Breed, Breed) => number,
  onChange: (string) => void,
  forwardedRef: ElementRef<*>,
  size?: 'small' | 'medium' | 'large',
  placeholder?: string,
};

const AnimalBreedsFormField = ({
  forwardedRef,
  category,
  sort,
  ...rest
}: Props) => {
  const breeds: Array<Breed> = useResources<Breed>('animalBreeds');

  const filteredOptions = breeds
    .filter((b) => {
      return b.category === category;
    })
    .sort((a: Breed, b: Breed) => {
      if (sort) {
        return sort(a, b);
      }

      return 1;
    });

  return (
    <Select
      {...rest}
      ref={forwardedRef}
      virtual={false}
      showSearch
      filterOption={selectFilterOptions}
    >
      {filteredOptions.map(({ id, breed }: $Shape<Breed>) => (
        <Option value={id} key={id}>
          {breed}
        </Option>
      ))}
    </Select>
  );
};

AnimalBreedsFormField.defaultProps = {
  size: 'medium',
  placeholder: 'Choose Breed',
  sort: () => 1,
};

export default React.forwardRef<*, Node>((props: Props, ref: ElementRef<*>) => {
  return <AnimalBreedsFormField forwardedRef={ref} {...props} />;
});
