// @flow
import React, { Fragment, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, type Dispatch, Link } from 'react-router-dom';
import { useEffectOnce, useUpdateEffect, useUnmount } from 'react-use';
import { Breadcrumb } from 'antd';
import { ProfileOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import type { ReduxStore } from 'flow/redux';
import type {
  Inspection,
  LineItem,
  DisplayLineItem,
  SubtotalType,
} from 'flow/inspection';
import SettlePaymentForm from 'features/SettlePaymentForm';
import PaymentHistory from 'features/PaymentHistory';
import ContentLoader from 'components/ContentLoader';
import Page from 'components/Page';
import InspectionCertificateTemplate from 'components/InspectionCertificateTemplate';
import VoidModal from 'components/VoidModal';
import { PrintButton, VoidButton } from 'components/Buttons';
import ButtonContainer from 'components/Buttons/ButtonContainer';
import {
  selectInspection,
  selectInspectionLineItems,
  selectInspectionTotals,
  selectHasProductionOrConsignment,
  selectInspectionMinimumLineItem,
} from 'selectors/inspection';
import {
  fetchInspection,
  fetchPublicInspection,
  clear,
  voidInspection,
} from './inspectionCertificateSlice';

type Props = {
  publicTemplate: boolean,
};

const StyledPrintWrapper = styled.div.attrs({
  id: 'print-template',
  className: 'print-wrapper',
})`
  max-width: 950px;
  min-width: 700px;
  width: 100%;
  margin: 1em 0;
  border: 1px dashed #cccccc;
  @media print {
    margin: 0;
    padding: 0;
    border: 0;
  }
`;

const SpaceStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 1em auto;
  @media print {
    margin: 0;
  }
`;

const BrandInspectionCertificatePage = ({ publicTemplate }: Props) => {
  const { id: inspectionIdParam } = useParams();

  const dispatch: Dispatch = useDispatch();

  const [printType, setPrintType] = useState<string>('');

  const [voidModalOpen, setVoidModalOpen] = useState<boolean>(false);

  const inspection: Inspection = useSelector((state: ReduxStore) =>
    selectInspection(state, 'inspectionCertificate'),
  );

  const displayLineItems: Array<DisplayLineItem> = useSelector(
    (state: ReduxStore) => {
      return selectInspectionLineItems(state, 'inspectionCertificate');
    },
  );

  const subtotal: SubtotalType = useSelector((state: ReduxStore) =>
    selectInspectionTotals(state, 'inspectionCertificate'),
  );

  const hasProductionOrConsignment: boolean = useSelector((state: ReduxStore) =>
    selectHasProductionOrConsignment(state, 'inspectionCertificate'),
  );

  const inspectionMinimumFee: LineItem = useSelector((state: ReduxStore) =>
    selectInspectionMinimumLineItem(state, 'inspectionCertificate'),
  );

  const inspectionPending: boolean = useSelector((state) => {
    return (
      state.inspectionCertificate.pending || !state.inspectionCertificate.data
    );
  });

  const voidLoading: boolean = useSelector(
    (state: ReduxStore) => state.inspectionCertificate.loading,
  );

  const handlePrint = (type: string) => {
    setPrintType(type);
    
    setTimeout(() => {
      window.print();
    }, 100);
  };

  const handleVoidModalClose = () => setVoidModalOpen(false);

  const handleConfirm = async (reason: string) => {
    await dispatch(voidInspection(reason));

    handleVoidModalClose();
  };

  const { payment, isVoid, id: inspectionId, publicId } = inspection;

  const { paymentType, status: paymentStatus } = payment || {};

  const shouldSettlePayment =
    paymentType === 'pay_later' || paymentStatus === 'FAILED';

  useEffectOnce(() => {
    if (publicTemplate) {
      dispatch(fetchPublicInspection(inspectionIdParam));
    } else {
      dispatch(fetchInspection(inspectionIdParam));
    }
  });

  useUpdateEffect(() => {
    if (printType) {
      //window.print();
    }
  }, [printType]);

  useUnmount(() => {
    dispatch(clear());
  }, []);

  if (inspectionPending) {
    return <ContentLoader message="Loading certificate..." />;
  }

  const buildActions = () => {
    if (publicTemplate || isVoid) return null;

    const handle = (newPrintType: string) => () => handlePrint(newPrintType);

    return (
      <div className="hidden-print">
        <ButtonContainer>
          <PrintButton
            id="printBuyerCopy"
            label="Print Buyer Copy"
            onClick={handle('buyer')}
          />
          <PrintButton
            id="printSellerCopy"
            label="Print Seller Copy"
            onClick={handle('owner')}
          />
          <PrintButton
            id="printTransportationCopy"
            label="Print Transportation Copy"
            onClick={handle('transportation')}
          />
          {payment?.id && <PaymentHistory id={inspectionId} entityType="BI" />}
          <VoidButton
            id="voidInspections"
            onClick={() => setVoidModalOpen(true)}
          />
        </ButtonContainer>
      </div>
    );
  };

  return (
    <Fragment>
      <Page>
        {!publicTemplate && (
          <div className="hidden-print">
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/inspections">
                  <ProfileOutlined />
                  <strong> Brand Inspections</strong>
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <strong>{inspection?.inspectionCode || ''}</strong>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        )}

        <SpaceStyle>
          {!publicTemplate && !isVoid && shouldSettlePayment && (
            <SettlePaymentForm
              payment={payment}
              entityId={inspectionId || publicId}
              entityType="inspection"
            />
          )}

          {buildActions()}
          <StyledPrintWrapper>
            <InspectionCertificateTemplate
              inspection={inspection}
              displayLineItems={displayLineItems}
              subtotal={subtotal}
              hasProductionOrConsignment={hasProductionOrConsignment}
              inspectionMinimumFee={inspectionMinimumFee}
              printType={printType}
            />
          </StyledPrintWrapper>
          {buildActions()}

          {!publicTemplate && (
            <VoidModal
              visible={voidModalOpen}
              onCancel={handleVoidModalClose}
              onConfirm={handleConfirm}
              confirmLoading={voidLoading}
            />
          )}
        </SpaceStyle>
      </Page>
    </Fragment>
  );
};

BrandInspectionCertificatePage.defaultProps = {
  publicTemplate: false,
};

export default BrandInspectionCertificatePage;