// @flow
import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector, type Dispatch } from 'react-redux';
import { useEffectOnce, useUnmount } from 'react-use';
import { useHistory, useParams, Link, type History } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import { ProfileOutlined } from '@ant-design/icons';
import isEmpty from 'lodash/isEmpty';
import type { ReduxStore } from 'flow/redux';
import type { TravelPermitType } from 'flow/travelPermit';
import SettlePaymentForm from 'features/SettlePaymentForm';
import PaymentHistory from 'features/PaymentHistory';
import Page from 'components/Page';
import ContentLoader from 'components/ContentLoader';
import PermitCertificateTemplate from 'components/PermitCertificateTemplate';
import VoidModal from 'components/VoidModal';
import ButtonContainer from 'components/Buttons/ButtonContainer';
import { PrintButton, VoidButton } from 'components/Buttons';
import {
  fetchTravelPermit,
  fetchPublicTravelPermit,
  clearTravelPermit,
  voidTravelPermit,
} from './travelPermitSlice';

const StyledPrintWrapper = styled.div.attrs({
  id: 'print-template',
  className: 'print-wrapper',
})`
  max-width: 950px;
  min-width: 700px;
  width: 100%;
  margin: 1em 0;
  border: 1px dashed #cccccc;
  @media print {
    margin: 0;
    padding: 0;
    border: 0;
  }
`;

const SpaceStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 1em auto;
  @media print {
    margin: 0;
  }
`;

type Props = {
  publicTemplate: boolean,
};

const TravelPermitPage = ({ publicTemplate }: Props) => {
  const { id: travelPermitId } = useParams();

  const [voidModalOpen, setVoidModalOpen] = useState<boolean>(false);

  const dispatch: Dispatch = useDispatch();

  const history: History = useHistory();

  const pending: boolean = useSelector((state: ReduxStore) => {
    return state.travelPermit.pending;
  });

  const voidLoading: boolean = useSelector(
    (state: ReduxStore) => state.travelPermit.loading,
  );

  const travelPermit: $Shape<TravelPermitType> = useSelector(
    (state: ReduxStore) => {
      return state.travelPermit.data || {};
    },
  );

  const { id, publicId, payment, owner, permitCode, isVoid } = travelPermit;

  const handlePrint = () => {
    window.print();
  };

  const handleNewPermit = () => {
    history.push(`/travel-permit`, { ...owner });
  };

  const handleVoidModalClose = () => setVoidModalOpen(false);

  const handleConfirm = async (reason: string) => {
    await dispatch(voidTravelPermit(reason));

    handleVoidModalClose();
  };

  const shouldSettlePayment = payment && payment?.paymentType === 'pay_later';

  useEffectOnce(() => {
    if (publicTemplate) {
      dispatch(fetchPublicTravelPermit(travelPermitId));
    } else {
      dispatch(fetchTravelPermit(travelPermitId));
    }
  });

  useUnmount(() => {
    dispatch(clearTravelPermit());
  });

  if (pending || isEmpty(travelPermit)) {
    return <ContentLoader message="Loading certificate..." />;
  }

  const buildActions = () => {
    if (!publicTemplate && !isVoid) {
      return (
        <div className="hidden-print">
          <ButtonContainer>
            <PrintButton
              id="printPermit"
              label="Print Permit"
              onClick={handlePrint}
            />
            <PrintButton
              id="newPermit"
              label="New Permit"
              onClick={handleNewPermit}
            />
            {payment?.id && <PaymentHistory id={id} entityType="TPH" />}
            <VoidButton
              id="voidPermit"
              onClick={() => setVoidModalOpen(true)}
            />
          </ButtonContainer>
        </div>
      );
    }

    return null;
  };

  return (
    <Fragment>
      <Page>
        {!publicTemplate && (
          <Breadcrumb className="hidden-print">
            <Breadcrumb.Item>
              <Link to="/travel-permits">
                <ProfileOutlined />
                <strong> Travel Permits</strong>
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item className="hidden-print">
              <strong>{permitCode}</strong>
            </Breadcrumb.Item>
          </Breadcrumb>
        )}
        <SpaceStyle>
          {!publicTemplate && shouldSettlePayment && (
            <SettlePaymentForm
              payment={payment}
              entityId={id || publicId}
              entityType="permit"
            />
          )}

          {buildActions()}

          <StyledPrintWrapper>
            <PermitCertificateTemplate travelPermit={travelPermit} />
          </StyledPrintWrapper>

          {buildActions()}

          {!publicTemplate && (
            <VoidModal
              visible={voidModalOpen}
              onCancel={handleVoidModalClose}
              onConfirm={handleConfirm}
              confirmLoading={voidLoading}
            />
          )}
        </SpaceStyle>
      </Page>
    </Fragment>
  );
};

TravelPermitPage.defaultProps = {
  publicTemplate: false,
};

export default TravelPermitPage;
