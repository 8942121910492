// @flow
import React from 'react';
import { Input } from 'antd';
import useQuery from 'utils/hooks/useQuery';

const { Search } = Input;

const SearchFilter = () => {
  const { query, patchQueries } = useQuery();

  const handleSearchChange = (searchStr: string) => {
    patchQueries({
      query: searchStr,
    });
  };

  const handleSearchBlur = ({ target }: { target: HTMLInputElement }) => {
    patchQueries({
      query: target.value,
    });
  };

  return (
    <Search
      placeholder="Search certificates"
      onSearch={handleSearchChange}
      onBlur={handleSearchBlur}
      defaultValue={query}
      allowClear
    />
  );
};

export default SearchFilter;
