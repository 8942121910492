// @flow
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { Payment } from 'flow/payment';
import { fetchInspection } from 'pages/BrandInspectionCertificatePage/inspectionCertificateSlice';
import { fetchTravelPermit } from 'pages/TravelPermitPage/travelPermitSlice';
import { fetchPreSaleInspection } from 'pages/HorsePreSalePage/horsePreSaleSlice';
import { showWarningMessage, showErrorMessage } from 'utils/showMessage';
import { inspectionClient } from 'service/client';
import { inspectionsDb } from 'service/idb';

export const syncPayment = async (data: $Shape<Payment>) => {
  try {
    // save payment offline
    await inspectionsDb.offlinePayments.put(data);

    // try persisting to the BE
    await inspectionClient().post(`/payments/settle/`, data);

    // remove offline payment instance if persisted
    await inspectionsDb.offlinePayments.delete(data.id);

    // payment flag is used for syncAll
    return { payment: true, data, error: null };
  } catch (error) {
    console.log('HERE err: ', error);
    // payment flag is used for syncAll
    return { payment: true, data: null, error };
  }
};

export const settleEntityPayment = createAsyncThunk(
  'settlePayment/settleEntityPayment',
  async (
    {
      paymentId,
      entityId,
      entityType,
      payment,
    }: {
      paymentId: ?number,
      entityId: number | string,
      entityType: 'inspection' | 'permit' | 'presale',
      payment: $Shape<Payment>,
    },
    { extra, rejectWithValue, dispatch },
  ) => {
    const { inspectionsDb: db, createError } = extra;

    try {
      let apiError;

      // only synced entities has the paymentId
      if (paymentId) {
        const { error } = await syncPayment({
          id: paymentId,
          ...payment,
          status: 'PENDING',
        });

        apiError = error;
      } else {
        const tableName = {
          inspection: 'offlineInspections',
          permit: 'offlineTravelPermit',
          presale: 'offlinePreSaleInspections',
        }[entityType];

        const data = await db[tableName].get(entityId);

        await db[tableName].put({ ...data, payment });
      }

      if (entityType === 'inspection') {
        await dispatch(fetchInspection(entityId));
      } else if (entityType === 'permit') {
        await dispatch(fetchTravelPermit(entityId));
      } else if (entityType === 'presale') {
        await dispatch(fetchPreSaleInspection(entityId));
      }

      if (apiError) {
        throw apiError;
      }

      return true;
    } catch (err) {
      if (paymentId) {
        showWarningMessage(
          'You made an offline payment. Please sync as soon as possible.',
        );
      } else {
        showErrorMessage(err?.message || 'Payment failed. Please try again.');

        createError({
          payload: { ...payment, id: entityId },
          endpoint: 'OFFLINE: settlePayment/settleEntityPayment',
          errorStack: err?.stack,
        });
      }

      return rejectWithValue(err);
    }
  },
);

const settlePaymentSlice = createSlice({
  name: 'settlePayment',
  initialState: {
    loading: false,
  },
  extraReducers: {
    [settleEntityPayment.pending]: (state) => {
      state.loading = true;
    },
    [settleEntityPayment.fulfilled]: (state) => {
      state.loading = false;
    },
    [settleEntityPayment.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default settlePaymentSlice.reducer;
