import React from 'react';
import { Space, Image } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import udafLogo from 'assets/logo.png';

const StyledLink = styled(Link)`
  color: inherit;

  img {
    @media (max-width: 768px) {
      width: 32px;
    }
  }

  span {
    font-size: 1em;
    line-height: 1.125em;
    font-weight: 600;

    @media (max-width: 768px) {
      display: none;
    }
  }
`;

const Brand = () => {
  return (
    <StyledLink to="/">
      <Space size="small" align="end">
        <Image
          src={udafLogo}
          alt="Utah Department of Agriculture and Food"
          preview={false}
          width={40}
        />
        <span>
          <div>Utah Department</div>
          <div>of Agriculture and Food</div>
        </span>
      </Space>
    </StyledLink>
  );
};

export default Brand;
