// @flow
import React, { Fragment, type Node } from 'react';
import { Row, Col, Typography, Divider } from 'antd';
import formatMoney, { centsToDollars } from 'utils/formatters/formatMoney';
import { ENABLE_CONSIGNMENT_ADDITIONAL_UI } from 'constants/inspection';

type Props = {
  totalInspectionFee: number,
  totalPredatorControlFee: number,
  totalBeefCouncilFee: number,
  totalBeefTagFee: number,
  totalProcessingFee: number,
  hasBeefTag: boolean,
  hasProductionOrConsignment: boolean,
  isCattle: boolean,
  showLineItems: boolean,
  footnote?: Node,
};

const textStyle = { fontSize: 14 };

const InspectionTotal = ({
  totalInspectionFee,
  totalPredatorControlFee,
  totalBeefCouncilFee,
  totalBeefTagFee,
  totalProcessingFee,
  hasBeefTag,
  hasProductionOrConsignment,
  isCattle,
  showLineItems,
  footnote,
}: Props) => {
  const paidInOffice =
    hasProductionOrConsignment && ENABLE_CONSIGNMENT_ADDITIONAL_UI ? 25000 : 0;
  const subtotal =
    totalInspectionFee +
    totalBeefCouncilFee +
    totalPredatorControlFee +
    totalBeefTagFee +
    totalProcessingFee -
    paidInOffice;
  const total = subtotal > 0 ? subtotal : 0;
  const showPredatorAndBeefCouncilFees = isCattle && showLineItems;
  const showProcessingFee = totalProcessingFee > 0;
  const showPaidInOffice =
    hasProductionOrConsignment && ENABLE_CONSIGNMENT_ADDITIONAL_UI;
  // inspection fee is assumed as the first line item in subtotals
  const moreThanOneSubtotalItem =
    hasBeefTag ||
    showProcessingFee ||
    showPredatorAndBeefCouncilFees ||
    showPaidInOffice;

  return (
    <Row gutter={4} style={textStyle}>
      <Col span={12}>
        <Typography.Text underline>
          Validation of brand certificates
        </Typography.Text>
        <Typography.Paragraph>
          Brand inspection certificates, except for “Change of ownership”and
          “Travel permits” shall be automatically cancelled and void one week
          after time of issuance. This certificate void if altered.
        </Typography.Paragraph>
      </Col>
      <Col span={10} offset={2}>
        <Row gutter={4}>
          {hasBeefTag && <FeeRow label="Beef Tag" fee={totalBeefTagFee} />}
          {showProcessingFee && (
            <FeeRow label="Processing Fee" fee={totalProcessingFee} />
          )}
          {showPredatorAndBeefCouncilFees && (
            <Fragment>
              <FeeRow label="Beef Council" fee={totalBeefCouncilFee} />
              <FeeRow label="Predator Control" fee={totalPredatorControlFee} />
            </Fragment>
          )}
          {moreThanOneSubtotalItem && (
            <FeeRow label="Inspection Fee" fee={totalInspectionFee} />
          )}
          {showPaidInOffice && (
            <FeeRow
              label="Was already paid in the office"
              sign="-"
              fee={25000}
            />
          )}
          {moreThanOneSubtotalItem && <HR span={24} />}
          <FeeRow label="Inspection Charge Total" fee={total} />
          {footnote && (
            <Fragment>
              <Col span={23} style={{ paddingTop: '1em' }}>
                <Typography.Title
                  level={5}
                  style={{ textAlign: 'right', fontSize: '1em', color: '#ccc' }}
                >
                  {footnote}
                </Typography.Title>
              </Col>
            </Fragment>
          )}
        </Row>
      </Col>
    </Row>
  );
};

InspectionTotal.defaultProps = {
  footnote: null,
};

type FeeProps = {
  label: string,
  fee: number,
  sign?: string,
};

const FeeRow = ({ label, fee, sign = '' }: FeeProps) => {
  const dollars = formatMoney({ value: centsToDollars(fee) });
  const cost = sign + dollars;
  return (
    <Fragment>
      <Col span={19}>
        <Typography.Title level={5} style={textStyle}>
          {label}
        </Typography.Title>
      </Col>
      <Col span={4} style={{ whiteSpace: 'nowrap', textAlign: 'right' }}>
        <Typography.Text>{cost}</Typography.Text>
      </Col>
    </Fragment>
  );
};

FeeRow.defaultProps = {
  sign: '',
};

const HR = ({ span }: { span: number }) => (
  <Col span={span}>
    <Divider style={{ margin: '0 0 5px' }} />
  </Col>
);

export default InspectionTotal;
