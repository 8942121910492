// @flow
import React, { Fragment, useState, useRef } from 'react';
import { Modal, Steps, Button } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { useUpdateEffect } from 'react-use';
import { UdafBrandEditor } from '@acclaim/udaf-kit';
import type { BrandType } from 'flow/brands';
import { showWarningMessage } from 'utils/showMessage';
import LocationSelector from './LocationSelector';

type Props = {
  visible: boolean,
  onCancel: () => void,
  onConfirm: (Array<BrandType>) => void,
};

const { Step } = Steps;

const BrandCreatorModal = ({ visible, onCancel, onConfirm }: Props) => {
  const editorRef = useRef<UdafBrandEditor>();

  const [step, setStep] = useState<number>(0);

  const [image, setImage] = useState<string>('');

  const [positions, setPositions] = useState<Array<string>>([]);

  const handleNext = () => {
    if (editorRef.current.canUndo() || image) {
      setImage(editorRef.current.toDataURL());
      setStep(1);
    } else {
      showWarningMessage('You must draw your brand first.');
    }
  };

  const handleSelectedPosition = (key) => {
    if (key) {
      if (positions.includes(key)) {
        const index = positions.indexOf(key);

        const newPositions = [...positions];

        newPositions.splice(index, 1);

        setPositions(newPositions);
      } else {
        setPositions([...positions, key]);
      }
    }
  };

  const handleBrandCreation = () => {
    if (positions.length > 0) {
      if (onConfirm) {
        // brand image group ID
        const ref = uuidv4();

        const brands = positions.map<BrandType>((position: string) => {
          return {
            id: uuidv4(),
            brandNumber: '',
            position,
            letters: [],
            firstNames: [],
            lastNames: [],
            counties: [],
            zipcodes: [],
            isSheep: '',
            expired: 'false',
            imageUrl: image,
            // TEMP field
            // to group brands with the same image before POST
            ref,
          };
        });

        onConfirm(brands);
      }
    } else {
      showWarningMessage('Please select the position of your brand.');
    }
  };

  useUpdateEffect(() => {
    if (step === 0 && !!image) {
      editorRef.current.setBackgroundFromDataUrl(image);
    }
  }, [step]);

  useUpdateEffect(() => {
    if (!visible) {
      setImage('');
      setStep(0);
      setPositions([]);
    }
  }, [visible]);

  return (
    <Modal
      title="Brand Creator"
      visible={visible}
      width={700}
      okButtonProps={{
        disabled: !image && step === 0,
      }}
      onCancel={onCancel}
      onOk={handleBrandCreation}
    >
      <Steps current={step} style={{ margin: '0 auto 30px', maxWidth: 500 }}>
        <Step title="Draw Brand" />
        <Step title="Select Location" />
      </Steps>

      {step === 0 && visible && (
        <UdafBrandEditor
          hasText
          hasUpload
          hasCameraOption
          onNext={handleNext}
          sketchRef={editorRef}
        />
      )}

      {step === 1 && visible && (
        <Fragment>
          <LocationSelector
            image={image}
            selectedPositions={positions}
            onSelect={handleSelectedPosition}
          />
          <center>
            <Button onClick={() => setStep(0)}>Go back</Button>
          </center>
        </Fragment>
      )}
    </Modal>
  );
};

export default BrandCreatorModal;
