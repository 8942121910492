// @flow
import React from 'react';
import { Row, Col, Form, Input, Select } from 'antd';
import type { InspectionType } from 'flow/resources';
import StatesFormField from 'components/form/StatesFormField';
import CountiesFormField from 'components/form/CountiesFormField';
import { validateZipCode } from 'utils/validators/zipCode';
import selectFilterOptions from 'utils/form/selectFilterOptions';
import { LIFETIME_TRAVEL_PERMIT, YEARLY_TRAVEL_PERMIT } from 'selectors/resources';

type Props = {
  types: Array<InspectionType>,
};

const { Option } = Select;

const OwnerDetails = ({ types }: Props) => {
  return (
    <Row gutter={16}>
      <Col lg={6} md={8}>
        <Form.Item
          shouldUpdate={(prevValues, curValues) =>
            prevValues.type !== curValues.type
          }
          noStyle
        >
          {(form) => {
            const type = form.getFieldValue('type');

            const inspectionType =
              types.find((t: InspectionType) => {
                return t.id === type;
              }) || {};

            if (inspectionType?.slug === LIFETIME_TRAVEL_PERMIT) {
              return (
                <Form.Item
                  label="Cost of permit"
                  name="cost"
                  rules={[
                    {
                      required: true,
                      message: 'Cost is required',
                    },
                  ]}
                >
                  <Select showSearch filterOption={selectFilterOptions}>
                    <Option value={55}>First Horse $55.00</Option>
                    <Option value={35}>Each Additional Horse $35.00</Option>
                  </Select>
                </Form.Item>
              );
            }

            return (
              <Form.Item
                label="Cost of permit"
                name="cost"
                rules={[
                  {
                    required: true,
                    message: 'Cost is required',
                  },
                ]}
              >
                <Input
                  readOnly
                  prefix={
                    inspectionType?.slug === YEARLY_TRAVEL_PERMIT ? '$' : ''
                  }
                />
              </Form.Item>
            );
          }}
        </Form.Item>
      </Col>

      <Col lg={6} md={8}>
        <Form.Item
          label="Date"
          name="permitDate"
          rules={[
            {
              required: true,
              message: 'Date is required',
            },
          ]}
        >
          <Input readOnly />
        </Form.Item>
      </Col>

      <Col lg={6} md={8}>
        <Form.Item
          label="Owner"
          name={['owner', 'name']}
          rules={[
            {
              required: true,
              message: 'Owner is required',
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Col>

      <Col lg={6} md={8}>
        <Form.Item
          label="Address"
          name={['owner', 'address']}
          rules={[
            {
              required: true,
              message: 'Address is required',
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Col>

      <Col lg={6} md={8}>
        <Form.Item
          label="City"
          name={['owner', 'city']}
          rules={[
            {
              required: true,
              message: 'City is required',
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Col>

      <Col lg={6} md={8}>
        <Form.Item
          label="State"
          name={['owner', 'state']}
          rules={[
            {
              required: true,
              message: 'State is required',
            },
          ]}
        >
          <StatesFormField />
        </Form.Item>
      </Col>

      <Col lg={6} md={8}>
        <Form.Item
          shouldUpdate={(prevValues, curValues) => {
            return prevValues.owner?.state !== curValues.owner?.state;
          }}
          noStyle
        >
          {(form) => {
            const state = form.getFieldValue(['owner', 'state']);

            return (
              <Form.Item
                label="County"
                name={['owner', 'county']}
                rules={[{ required: true, message: 'County is required' }]}
              >
                <CountiesFormField state={state} />
              </Form.Item>
            );
          }}
        </Form.Item>
      </Col>

      <Col lg={6} md={8}>
        <Form.Item
          label="Zip code"
          name={['owner', 'zipCode']}
          rules={[
            {
              required: true,
              message: 'Zip code is required',
            },
            validateZipCode,
          ]}
        >
          <Input />
        </Form.Item>
      </Col>

      <Col lg={6} md={8}>
        <Form.Item
          label="Phone"
          name={['owner', 'phone']}
          rules={[
            {
              required: true,
              message: 'Phone is required',
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default OwnerDetails;
