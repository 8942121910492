// @flow

export const ADMIN_ROLE = 'Admin';
export const USER_ROLE = 'User';
export const INSPECTOR_ROLE = 'Brand Inspector';
export const RECORDER_ROLE = 'Brand Recorder';
export const CREDIT_CARD_TESTER_ROLE = 'Credit Card Tester';

export type Role =
  | typeof USER_ROLE
  | typeof ADMIN_ROLE
  | typeof INSPECTOR_ROLE
  | typeof RECORDER_ROLE
  | typeof CREDIT_CARD_TESTER_ROLE;

export type UserRoleType = {
  id: number,
  role: Role,
};

export type UserType = {
  id: number,
  name: string,
  firstName: string,
  lastName: string,
  email: string,
  signature: string,
  imageUrl: string,
  roles: Array<UserRoleType>,
};
