// @flow
import axios from 'axios';
import moment from 'moment';
import applyCaseMiddleware from 'axios-case-converter';
import { detect } from 'detect-browser';
import getPlatform from 'utils/getPlatform';
import { errorsDb } from './idb';
import { IS_DEV_ENVIRONMENT, getInspectionsApiUrl, ENVIRONMENT } from './urls';

type ErrorType = {
  errorId?: number,
  payload: string,
  endpoint: string,
  errorStack: string,
  platform: 'tablet' | 'desktop',
  browser: 'string',
  eventDate: string,
};

const errorClient = applyCaseMiddleware(
  axios.create({
    baseURL: getInspectionsApiUrl(),
    headers: {
      'Content-Type': 'application/json',
    },
  }),
);

export default async function createError(error: {
  payload: string,
  endpoint: string,
  errorStack: string,
}) {
  if (!IS_DEV_ENVIRONMENT) {
    const { name, version, os } = detect();
    const browser = name
      ? `${name}: v${version}, ${os}`
      : window.navigator.userAgent;
    const payload = {
      ...error,
      endpoint: `client version ${ENVIRONMENT.build.version} ${error.endpoint}`,
      payload: error.payload || 'EMPTY',
      platform: getPlatform(),
      eventDate: moment().format(),
      browser,
      userId: window.localStorage.getItem('user-id'),
    };

    try {
      await errorClient.post('/offline-error-logs/', payload);
    } catch (err) {
      await errorsDb.errors.add(payload);
    }
  } else {
    console.error('ERROR LOG:\n', error);
  }
}

const syncError = (error: ErrorType) =>
  errorClient
    .post('/offline-error-logs/', error)
    .then(() => {
      errorsDb.errors.delete(error.errorId);
    })
    .catch((err) => {
      console.error('LOG ERROR sync: ', err);
    });

export async function syncErrors() {
  if (!IS_DEV_ENVIRONMENT) {
    const errors: Array<ErrorType> = await errorsDb.errors.toArray();

    // todo can we use Promise.allSettled() here: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Promise/allSettled
    return Promise.all(errors.map(syncError));
  }
  return Promise.resolve();
}
