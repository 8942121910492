// @flow
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from '@reduxjs/toolkit';
import type { ReduxStore } from 'flow/redux';
import { INSPECTION_UPDATE_HISTORY } from 'flow/inspection';
import type { Filters } from 'flow/search';
import { showErrorMessage } from 'utils/showMessage';
import storeResourceData from 'utils/storeResourceData';
import { inspectionAndTotal } from 'utils/computeFees';
import { getDateParams, stringToTimestamp } from 'utils/dates';
import { filterInspections } from 'service/search/util';
import { syncIfNeeded, tableHasNoRecords } from 'service/sync';
import { monthUtil } from '@acclaim/udaf-kit';

const {
  TYPES: { INSPECTIONS },
} = INSPECTION_UPDATE_HISTORY;

const adapter = createEntityAdapter();

const initialState = adapter.getInitialState({
  loading: false,
  total: 0,
});

export const { selectAll: selectInspections } = adapter.getSelectors(
  (state: ReduxStore) => {
    return state.inspections;
  },
);

export const selectComputedInspections = createSelector(
  selectInspections,
  inspectionAndTotal,
);

export const fetchInspections = createAsyncThunk(
  'inspections/fetchInspections',
  async (filters: ?Filters, { extra, rejectWithValue, getState }) => {
    const { from, to, query, limit, offset, paymentStatus } = filters || {};
    const { inspectionClient, inspectionsDb: db, createError } = extra;
    const {
      me,
      initializer: { online },
    } = getState();
    const userId = me.data?.id;
    const { inspections: inspectionsTable } = db;

    try {
      const explicitReload = await tableHasNoRecords(userId, inspectionsTable);
      return syncIfNeeded(
        userId,
        INSPECTIONS,
        online,
        explicitReload,
        () => Promise.resolve(inspectionsTable),
        (everythingAfterDate: ?string, truncateData: boolean) => {
          const params = { ...getDateParams(everythingAfterDate), userId };
          params.monthsData = monthUtil.getmonthToken();
          return inspectionClient()
            .get('/inspections/', { params })
            .then(async ({ data }) => {
              const inspections = data.map((inspection) => ({
                ...inspection,
                inspectionDate: stringToTimestamp(inspection.inspectionDate),
              }));
              await storeResourceData(
                db,
                'inspections',
                inspections,
                truncateData,
              );
              return inspectionsTable;
            });
        },
        true,
      ).then(
        (inspections) =>
          filterInspections({
            range: { from, to, key: 'inspectionDate' },
            query,
            limit,
            offset,
            paymentStatus: paymentStatus || 'all',
            table: inspections,
          }),
        // no filters
        // return { total: 0, result: [] };
      );
    } catch (err) {
      createError({
        payload: '',
        endpoint: 'OFFLINE: inspections/fetchInspections',
        errorStack: err?.stack,
      });

      showErrorMessage(err?.message);

      return rejectWithValue(err);
    }
  },
);

const inspectionsSlice = createSlice({
  name: 'inspections',
  initialState,
  reducers: {
    clearAll: (state) => {
      state.total = 0;
      adapter.removeAll(state);
    },
  },
  extraReducers: {
    [fetchInspections.pending]: (state) => {
      state.loading = true;
    },
    [fetchInspections.fulfilled]: (state, { payload }) => {
      const { total, result } = payload;

      state.loading = false;
      state.total = total;
      adapter.setAll(state, result);
    },
    [fetchInspections.rejected]: (state) => {
      state.loading = false;
      state.total = 0;
      adapter.removeAll(state);
    },
  },
});

export const { clearAll } = inspectionsSlice.actions;

export default inspectionsSlice.reducer;
