// @flow
import React, { type ElementRef, type Node } from 'react';
import { Select } from 'antd';
import type { Category } from 'flow/resources';
import useResources from 'utils/hooks/useResources';
import selectFilterOptions from 'utils/form/selectFilterOptions';

const { Option } = Select;

type Props = {
  value: string,
  onChange: (string) => void,
  forwardedRef: ElementRef<*>,
  size?: 'small' | 'medium' | 'large',
  placeholder?: string,
};

const AnimalCategoriesFormField = ({ forwardedRef, ...rest }: Props) => {
  const categories: Array<Category> = useResources<Category>(
    'animalCategories',
  );

  return (
    <Select
      {...rest}
      ref={forwardedRef}
      virtual={false}
      showSearch
      filterOption={selectFilterOptions}
    >
      {categories.map(({ id, category }: $Shape<Category>) => (
        <Option value={id} key={id}>
          {category}
        </Option>
      ))}
    </Select>
  );
};

AnimalCategoriesFormField.defaultProps = {
  size: 'medium',
  placeholder: 'Choose Animal',
};

export default React.forwardRef<*, Node>((props: Props, ref: ElementRef<*>) => {
  return <AnimalCategoriesFormField forwardedRef={ref} {...props} />;
});
