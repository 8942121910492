/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
import createError from 'service/logger';

const StyledWrapper = styled.div`
  text-align: center;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 150px;
    margin-bottom: 30px;
  }
`;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error) {
    createError({
      payload: null,
      endpoint: 'CLIENT_ERROR',
      errorStack: JSON.stringify({
        stack: error.stack,
        message: error.message,
      }),
    });
  }

  render() {
    const { hasError } = this.state;

    if (hasError) {
      return (
        <StyledWrapper>
          <h1>500</h1>
          <h3>Oops!</h3>
          <p>
            Cannot load brand inspections app.
            <br />
            Please make sure you are connected to the internet and try again.
          </p>
        </StyledWrapper>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
