// @flow
import React, { Fragment } from 'react';
import { Row, Col, Typography } from 'antd';
import SheepSvg from 'components/AnimalsPartPicker/SheepSvg';
import CattleLeftSvg from 'components/AnimalsPartPicker/CattleLeftSvg';
import CattleRightSvg from 'components/AnimalsPartPicker/CattleRightSvg';
import HorseRightSvg from 'components/AnimalsPartPicker/HorseRightSvg';

type Props = {
  selectedPositions: Array<string>,
  onSelect: (string) => void,
  image: string,
};

const LocationSelector = ({ selectedPositions, onSelect, image }: Props) => {
  return (
    <Fragment>
      <Typography.Title level={5} style={{ textAlign: 'center' }}>
        Cattle or Horse
      </Typography.Title>

      <Row gutter={[20, 20]}>
        <Col span={12}>
          <CattleLeftSvg
            image={image}
            selected={selectedPositions}
            onSelect={onSelect}
            visibleLabels
          />
        </Col>

        <Col span={12}>
          <CattleRightSvg
            image={image}
            selected={selectedPositions}
            onSelect={onSelect}
            visibleLabels
          />
        </Col>

        <Col span={12}>
          <Typography.Title level={5} style={{ textAlign: 'center' }}>
            Sheep
          </Typography.Title>

          <SheepSvg
            image={image}
            selected={selectedPositions}
            onSelect={onSelect}
            visibleLabels
          />
        </Col>

        <Col span={12}>
          <Typography.Title level={5} style={{ textAlign: 'center' }}>
            Horse right jaw
          </Typography.Title>

          <HorseRightSvg
            image={image}
            selected={selectedPositions}
            onSelect={onSelect}
            visibleLabels
          />
        </Col>
      </Row>
    </Fragment>
  );
};

export default LocationSelector;
