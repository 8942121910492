// @flow
import React from 'react';
import { Card, Select } from 'antd';
import { Chart, Line, Tooltip } from 'bizcharts';
import MONTHS from 'constants/months';

const { Option } = Select;

const data = [
  { day: 1, amount: 84.0 },
  { day: 3, amount: 30.9 },
  { day: 10, amount: 55.6 },
  { day: 17, amount: 56.7 },
  { day: 22, amount: 63.2 },
  { day: 23, amount: 24.6 },
  { day: 25, amount: 14.0 },
  { day: 30, amount: 9.4 },
];

const PredatorFeesChart = () => {
  return (
    <Card
      title="Predator fees report"
      extra={
        <Select
          defaultValue=""
          style={{ width: 120 }}
          size="small"
          virtual={false}
        >
          <Option value="">This Month</Option>
          {MONTHS.map((month: string) => (
            <Option key={month.toLowerCase()} value={month.toLowerCase()}>
              {month}
            </Option>
          ))}
        </Select>
      }
      bodyStyle={{ padding: '10px 20px 0 10px' }}
    >
      <Chart
        scale={{ value: { min: 0 } }}
        padding={[10, 20, 50, 40]}
        autoFit
        height={300}
        data={data}
      >
        <Line shape="smooth" position="day*amount" color="#62C2CE" />
        <Tooltip shared>
          {(title, items) => {
            const { day, amount } = items[0].data || {};

            return (
              <div style={{ padding: '1em 0.5em' }}>
                {day}: ${amount}
              </div>
            );
          }}
        </Tooltip>
      </Chart>
    </Card>
  );
};

export default PredatorFeesChart;
