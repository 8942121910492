// @flow
import { Modal } from 'antd';
import { useSelector, useDispatch, type Dispatch } from 'react-redux';
import { useEffectOnce } from 'react-use';
import type { ReduxStore } from 'flow/redux';
import {
  PAYMENT_STATE,
  setPaymentState,
  type PaymentState,
} from 'features/PaymentStateLoader/slice';
import processCardCheckout from 'utils/processCardCheckout';
import { IS_DEV_ENVIRONMENT } from 'service/urls';

type CheckoutEntityTypes = 'inspection' | 'permit' | 'presale';

type Checkout = {
  amountInCents: number,
  publicId: string,
  entityType: CheckoutEntityTypes,
};

export type WrappedCheckoutUtils = {
  paymentState: PaymentState,
  checkout: (Checkout) => void,
};

export type PaymentResponse = {
  // FE custom field to instate timeout
  timeout: boolean,
  // fields from Square pos callback response data
  state: {
    id: string,
    type: string,
  },
  error_code?: string,
};

export type PaymentListenerProps = {
  paymentResponse: $Shape<PaymentResponse>,
  success: boolean,
};

export type PaymentListener = (PaymentListenerProps) => void;

// Local storage event key
const LISTENER_KEY = 'storage';

// Callback payment data storage key
export const PAYMENT_STATE_KEY = 'payment-state';

// Callback timeout defaults to 1 minute
// Smaller timeout setup in dev for testing
const CALLBACK_TIMEOUT = IS_DEV_ENVIRONMENT ? 10000 : 60000;

// For scoping event handles for proper disposing
let uniqueId;
let handleTimeout;

const useCreditCardCheckout = (
  paymentListener: PaymentListener,
): WrappedCheckoutUtils => {
  const dispatch: Dispatch = useDispatch();

  const paymentState: PaymentState = useSelector((state: ReduxStore) => {
    return state.paymentStateLoader.state;
  });

  const handleModalAfterClose = () => {
    // wait for all transitions from antd to finish
    setTimeout(() => {
      document?.body?.setAttribute('style', '');
    }, 500);
  };

  const handleStoreChangeListener = () => {
    const paymentResponseState = window.localStorage.getItem(PAYMENT_STATE_KEY);

    if (paymentResponseState) {
      window.localStorage.removeItem(PAYMENT_STATE_KEY);

      const paymentResponse = JSON.parse(paymentResponseState);

      // validated if the latest state belongs to the current listener
      if (paymentResponse.state.id === uniqueId) {
        clearTimeout(handleTimeout);

        const success = !('error_code' in paymentResponse);

        paymentListener({
          paymentResponse,
          success,
        });

        dispatch(setPaymentState(PAYMENT_STATE.IDLE));

        if (success) {
          Modal.success({
            title: 'Payment success',
            content: 'Your payment is now being processed.',
            afterClose: handleModalAfterClose,
          });
        } else {
          Modal.error({
            title: 'Payment failed',
            content:
              'Please try again, use another card or choose a different payment method.',
            afterClose: handleModalAfterClose,
          });
        }
      }
    }
  };

  const checkout = ({ amountInCents, publicId, entityType }: Checkout) => {
    if (paymentState === PAYMENT_STATE.IDLE) {
      if (IS_DEV_ENVIRONMENT) {
        console.log(
          `FAILED: https://inspect.brands.localhost.com/payment/summary?data={%20%22error_code%22:%20%22failed%22,%20%22state%22:%20{%20%22id%22:%20%22${publicId}%22,%20%22type%22:%20%22${entityType}%22%20}%20}`,
        );
        console.log(
          `SUCCESS: https://inspect.brands.localhost.com/payment/summary?data={%20%22state%22:%20{%20%22id%22:%20%22${publicId}%22,%20%22type%22:%20%22${entityType}%22%20}%20}`,
        );
      }

      // set unique ID for callback reference
      uniqueId = publicId;

      dispatch(setPaymentState(PAYMENT_STATE.PENDING));

      // initiate Square pos checkout
      processCardCheckout({
        amount: amountInCents,
        notes: JSON.stringify({
          id: publicId,
          type: entityType,
        }),
        autoReturn: true,
      });
      /*
        If callback didn't happen in 1 minute,
        fail the inspection payment.
        wait for BE to update status from the webhooks
      */
      handleTimeout = setTimeout(() => {
        paymentListener({
          paymentResponse: { timeout: true },
          success: false,
        });

        dispatch(setPaymentState(PAYMENT_STATE.IDLE));

        Modal.warning({
          title: 'Payment processing',
          content:
            'Transaction is taking longer than expected, please continue as though payment has succeeded',
          afterClose: handleModalAfterClose,
        });
      }, CALLBACK_TIMEOUT);
    }
  };

  useEffectOnce(() => {
    window.localStorage.removeItem(PAYMENT_STATE_KEY);

    window.addEventListener(LISTENER_KEY, handleStoreChangeListener);

    return () => {
      window.removeEventListener(LISTENER_KEY, handleStoreChangeListener);

      window.localStorage.removeItem(PAYMENT_STATE_KEY);

      clearTimeout(handleTimeout);

      handleTimeout = null;
    };
  });

  return { paymentState, checkout };
};

export default useCreditCardCheckout;
