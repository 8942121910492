// @flow
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import type { ReduxStore } from 'flow/redux';
import type { PreSaleInspectionType } from 'flow/presale';
import type { Filters } from 'flow/search';
import { showErrorMessage } from 'utils/showMessage';
import { filterInspections } from 'service/search/util';

const adapter = createEntityAdapter({
  selectId: (preSale: PreSaleInspectionType) => preSale.publicId,
});

const initialState = adapter.getInitialState({
  loading: false,
  syncing: false,
  total: 0,
});

export const {
  selectAll: selectOfflinePreSaleInspections,
} = adapter.getSelectors((state: ReduxStore) => {
  return state.offlinePreSaleInspections;
});

export const fetchOfflinePreSaleInspections = createAsyncThunk(
  'offlinePreSaleInspections/fetchOfflinePreSaleInspections',
  async (filters: ?Filters, { extra, rejectWithValue }) => {
    const { from, to, query, limit, offset, paymentStatus } = filters || {};
    const { inspectionsDb: db, createError } = extra;

    try {
      return await filterInspections({
        range: { from, to, key: 'presaleDate' },
        query,
        limit,
        offset,
        paymentStatus: paymentStatus || 'all',
        table: db.offlinePreSaleInspections,
      });
    } catch (err) {
      createError({
        payload: '',
        endpoint:
          'OFFLINE: offlinePreSaleInspections/fetchOfflinePreSaleInspections',
        errorStack: err?.stack,
      });

      showErrorMessage(
        'Failed to get offline pre-sale inspections. Please try again.',
      );

      return rejectWithValue(err);
    }
  },
);

const offlinePreSaleInspectionsSlice = createSlice({
  name: 'offlinePreSaleInspections',
  initialState,
  reducers: {
    clearAll: (state) => {
      state.total = 0;
      adapter.removeAll(state);
    },
  },
  extraReducers: {
    [fetchOfflinePreSaleInspections.pending]: (state) => {
      state.loading = true;
    },
    [fetchOfflinePreSaleInspections.fulfilled]: (state, { payload }) => {
      const { total, result } = payload;

      state.loading = false;
      state.total = total;
      adapter.setAll(state, result);
    },
    [fetchOfflinePreSaleInspections.rejected]: (state) => {
      state.loading = false;
      state.total = 0;
      adapter.removeAll(state);
    },
  },
});

export const { clearAll } = offlinePreSaleInspectionsSlice.actions;

export default offlinePreSaleInspectionsSlice.reducer;
