// @flow
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import { push } from 'connected-react-router';
import { showErrorMessage } from 'utils/showMessage';
import { syncOfflineData } from 'features/SyncOfflineMenuItem/syncOfflineSlice';
import { ENVIRONMENT } from 'service/urls';
import { toggleLoading } from 'features/LoadingMask/slice';

export const createPreSaleInspection = createAsyncThunk(
  'createHorsePreSale/createPreSaleInspection',
  async (formData, { extra, rejectWithValue, getState, dispatch }) => {
    const { inspectionsDb: db, createError } = extra;
    const {
      me: { data: user },
    } = getState();

    const presale = {
      ...formData,
      presaleDate: moment().format('MM/DD/YYYY'),
      user,
      clientVersion: ENVIRONMENT.build.version,
    };

    try {
      dispatch(toggleLoading());

      const id = await db.offlinePreSaleInspections.put(presale);

      await dispatch(syncOfflineData());

      const sycnedEntity = await db.preSaleInspections.get({ publicId: id });

      dispatch(push(`/horse-presale-listing/${sycnedEntity?.id || id}`));

      dispatch(toggleLoading());

      return presale;
    } catch (err) {
      dispatch(toggleLoading());

      createError({
        payload: JSON.stringify(presale),
        endpoint: 'OFFLINE: createHorsePreSale/createPreSaleInspection',
        errorStack: err?.stack,
      });

      showErrorMessage(err?.message);

      return rejectWithValue(err);
    }
  },
);

const createHorsePreSaleSlice = createSlice({
  name: 'createHorsePreSale',
  initialState: {
    data: null,
    loading: false,
  },
  extraReducers: {
    [createPreSaleInspection.pending]: (state) => {
      state.loading = true;
    },
    [createPreSaleInspection.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.loading = false;
    },
    [createPreSaleInspection.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default createHorsePreSaleSlice.reducer;
