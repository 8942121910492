// @flow
import React, { type Node } from 'react';
import { Typography } from 'antd';
import styled from 'styled-components';

type Props = {
  children: Node,
  rightChild?: Node,
  title?: string,
  dark?: boolean,
};

const StyledWrapper = styled.div`
  background-color: #ffffff;
`;

const StyledContent = styled.div`
  padding: 2vw 2.25vw;
  background-color: #ffffff;
  border: 1px solid rgba(0, 36, 17, 0.1);
  box-shadow: 0 12px 34px 0 rgba(224, 228, 226, 0.17);
  margin-bottom: 1em;

  @media (max-width: 1080px) {
    padding: 1.2em;
  }

  ${(props) =>
    props.dark &&
    // NOSONAR
    `
    background-color: rgba(0, 0, 0, 0.17);
    box-shadow: 0px 1px 13px -2px rgba(0,0,0,0.5) inset;
    -webkit-box-shadow: 0px 1px 13px -2px rgba(0,0,0,0.5) inset;
    -moz-box-shadow: 0px 1px 13px -2px rgba(0,0,0,0.5) inset;
  `}
`;

const StyledTitle = styled.div`
  padding: 1.25em 2.25em;
  background-color: #ffffff;
  border: 1px solid rgba(0, 36, 17, 0.1);
  border-bottom: none;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > h4 {
    margin: 0;
  }
`;

const Content = ({ children, rightChild, title, dark }: Props) => {
  return (
    <StyledWrapper>
      {title && (
        <StyledTitle>
          <Typography.Title level={4}>{title}</Typography.Title>
          {rightChild}
        </StyledTitle>
      )}
      <StyledContent dark={dark}>{children}</StyledContent>
    </StyledWrapper>
  );
};

Content.defaultProps = {
  title: '',
  rightChild: null,
  dark: false,
};

export default Content;
