// @flow
import React, { useState } from 'react';
import { LIVESTOCK_PARTS } from 'constants/livestock';
import { getTargetPart } from './helpers';
import { StyledLabel, StyledPath, StyledPart } from './styles';

type Props = {
  image: string,
  selected: Array<*>,
  onSelect: (string) => void,
  visibleLabels: boolean,
};

const HorseRightSvg = ({ image, selected, onSelect, visibleLabels }: Props) => {
  const [hoveredPart, setHoveredPart] = useState('');

  const handleHover = (ev) => {
    const part = getTargetPart(ev.target);

    setHoveredPart(part);
  };

  const handleMouseLeave = () => {
    setHoveredPart('');
  };

  const handleSelect = (ev) => {
    const part = getTargetPart(ev.target);

    onSelect(part);
  };

  return (
    <svg
      viewBox="0 0 80 60"
      onMouseOver={handleHover}
      onMouseLeave={handleMouseLeave}
      onFocus={() => {}}
      onClick={handleSelect}
      style={{ maxWidth: '190px', margin: 'auto', display: 'block' }}
    >
      <StyledPath
        d="M276.6,28.3c9.2-2.5,19.3-5,27.5-6.9c-6.9,16.5-5.8,34,3.2,52.4c-7.5,7.1-26.4,25.5-30.2,30.5
          c-4.2,5.5-10.7,15.3-11.3,22.4c0,0.2,0,0.5,0,0.8c-1.3-14.6-5.2-29.2-9.3-42.2c-5.7-18-17.1-29.4-30.8-32.8
          C234.2,47,261.9,32.4,276.6,28.3z"
        transform="matrix(0.4630768090090642 0 0 0.4659036501107351 -100 0)"
      />
      <StyledPart
        hovered={hoveredPart === LIVESTOCK_PARTS.HORSE_RIGHT_JAW}
        active={selected.includes(LIVESTOCK_PARTS.HORSE_RIGHT_JAW)}
        data-id={LIVESTOCK_PARTS.HORSE_RIGHT_JAW}
      >
        <StyledPath
          d="M304.1,21.5c5.2-1.2,9.7-2.1,12.7-2.7l7.1-2.2c0,0,4.4-6.2,6.6-9.3s4.9-9,5.7-2.4c1,8.2-0.8,11.2-0.8,11.2
          l3.2-0.1c0,0,5.1-6.9,7.3-11.3c2.2-4.4,7.3,1.1,0,16c0,0,2.1,3.1,3.8,6.9c1.7,3.8,2,7.5,3.9,8.6s3.1,3.5,2.7,8.2
          c-0.4,4.7,0.2,7.8,2.7,11.6c2.5,3.8,8,13.3,10.4,17.7c2.4,4.4,3.8,8.4,1.5,13.5c-2.4,5.1-0.4,7.3-5.1,8.4c-4.7,1.1-6,2.7-8.6,2.7
          s-7.6-4-8.6-7.1c-0.9-3.1-12.7-9.3-16.2-10.9c-3.5-1.6-8-0.6-10.2-3.2c-2.2-2.6-7.5-9.9-13.1-4.8c-0.5,0.4-1.1,1-1.8,1.7
          C298.3,55.4,297.2,38,304.1,21.5z"
          transform="matrix(0.4630768090090642 0 0 0.4659036501107351 -100 0)"
        />
        <image
          href={image}
          width="21"
          height="21"
          transform="matrix(0.4630768090090642 0 0 0.4659036501107351 44 21)"
        />
      </StyledPart>
      {visibleLabels && (
        <StyledLabel
          transform="matrix(1 0 0 1 38 46)"
          style={{ fontSize: '5px' }}
        >
          Right Jaw
        </StyledLabel>
      )}
    </svg>
  );
};

export default HorseRightSvg;
