// @flow
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import type { ReduxStore } from 'flow/redux';
import type { Filters } from 'flow/search';
import { showErrorMessage } from 'utils/showMessage';
import { LIFETIME_TRAVEL_PERMIT } from 'selectors/resources';
import {
  fetchAndFilterTravelPermits,
  filterTravelPermits,
} from 'service/travelPermits';

const adapter = createEntityAdapter({
  selectId: (permit) => permit.publicId,
});

const initialState = adapter.getInitialState({
  loading: false,
  total: 0,
});

export const filterLifetimeTravelPermits = createAsyncThunk(
  'lifetimeTravelPermits/filterLifetimeTravelPermits',
  async (filters: ?Filters, { extra, rejectWithValue }) => {
    const { inspectionsDb: db, createError } = extra;

    try {
      return Promise.resolve(db.travelPermits).then(
        filterTravelPermits(filters, LIFETIME_TRAVEL_PERMIT),
      );
    } catch (err) {
      createError({
        payload: '',
        endpoint: 'OFFLINE: lifetimeTravelPermits/fetchLifetimeTravelPermits',
        errorStack: err?.stack,
      });

      showErrorMessage(err?.message);

      return rejectWithValue(err);
    }
  },
);

export const fetchLifetimeTravelPermits = createAsyncThunk(
  'lifetimeTravelPermits/fetchLifetimeTravelPermits',
  async (filters: ?Filters, { extra, rejectWithValue, getState }) => {
    const { inspectionClient, inspectionsDb: db, createError } = extra;
    const {
      me,
      initializer: { online },
    } = getState();
    const userId = me.data?.id;

    try {
      return fetchAndFilterTravelPermits(
        userId,
        filters,
        LIFETIME_TRAVEL_PERMIT,
        online,
        db,
        inspectionClient,
      );
    } catch (err) {
      createError({
        payload: '',
        endpoint: 'OFFLINE: lifetimeTravelPermits/fetchLifetimeTravelPermits',
        errorStack: err?.stack,
      });

      showErrorMessage(err?.message);

      return rejectWithValue(err);
    }
  },
);

const lifetimeTravelPermitsSlice = createSlice({
  name: 'lifetimeTravelPermits',
  initialState,
  reducers: {
    clearAll: (state) => {
      state.total = 0;
      adapter.removeAll(state);
    },
  },
  extraReducers: {
    [fetchLifetimeTravelPermits.pending]: (state) => {
      state.loading = true;
    },
    [fetchLifetimeTravelPermits.fulfilled]: (state, { payload }) => {
      const { total, result } = payload;

      state.loading = false;
      state.total = total;
      adapter.setAll(state, result);
    },
    [fetchLifetimeTravelPermits.rejected]: (state) => {
      state.loading = false;
      state.total = 0;
      adapter.removeAll(state);
    },
    [filterLifetimeTravelPermits.pending]: (state) => {
      state.loading = true;
    },
    [filterLifetimeTravelPermits.fulfilled]: (state, action) => {
      state.loading = false;
      adapter.setAll(state, action.payload);
    },
    [filterLifetimeTravelPermits.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const { selectAll: selectLifetimeTravelPermits } = adapter.getSelectors(
  (state: ReduxStore) => {
    return state.lifetimeTravelPermits;
  },
);

export const { clearAll } = lifetimeTravelPermitsSlice.actions;

export default lifetimeTravelPermitsSlice.reducer;
