// @flow
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import type { ReduxStore } from 'flow/redux';
import type { Filters } from 'flow/search';
import { showErrorMessage } from 'utils/showMessage';
import { filterInspections } from 'service/search/util';

const adapter = createEntityAdapter({
  selectId: (permit) => permit.publicId,
});

const initialState = adapter.getInitialState({
  loading: false,
  syncing: false,
  total: 0,
});

export const { selectAll: selectOfflineTravelPermits } = adapter.getSelectors(
  (state: ReduxStore) => {
    return state.offlineTravelPermits;
  },
);

export const fetchOfflineTravelPermits = createAsyncThunk(
  'offlineTravelPermits/fetchOfflineTravelPermits',
  async (filters: ?Filters, { extra, rejectWithValue }) => {
    const { from, to, query, limit, offset, paymentStatus } = filters || {};
    const { inspectionsDb: db, createError } = extra;

    try {
      return await filterInspections({
        range: { from, to, key: 'permitDate' },
        query,
        limit,
        offset,
        paymentStatus: paymentStatus || 'all',
        table: db.offlineTravelPermit,
      });
    } catch (err) {
      createError({
        payload: '',
        endpoint: 'OFFLINE: offlineTravelPermits/fetchOfflineTravelPermits',
        errorStack: err?.stack,
      });

      showErrorMessage('Failed to get offline permits. Please try again.');

      return rejectWithValue(err);
    }
  },
);

const offlineTravelPermitsSlice = createSlice({
  name: 'offlineTravelPermits',
  initialState,
  reducers: {
    clearAll: (state) => {
      state.total = 0;
      adapter.removeAll(state);
    },
  },
  extraReducers: {
    [fetchOfflineTravelPermits.pending]: (state) => {
      state.loading = true;
    },
    [fetchOfflineTravelPermits.fulfilled]: (state, { payload }) => {
      const { total, result } = payload;

      state.loading = false;
      state.total = total;
      adapter.setAll(state, result);
    },
    [fetchOfflineTravelPermits.rejected]: (state) => {
      state.loading = false;
      state.total = 0;
      adapter.removeAll(state);
    },
  },
});

export const { clearAll } = offlineTravelPermitsSlice.actions;

export default offlineTravelPermitsSlice.reducer;
