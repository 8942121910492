// @flow
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import moment from 'moment';
import type {
  InspectionStepOneFormType,
  InspectionStepTwoFormType,
  LineItemSlug,
  LineItem,
} from 'flow/inspection';
// import type { InspectionType } from 'flow/resources';
import { showErrorMessage } from 'utils/showMessage';
import { createLineItems } from 'utils/lineItems';
import {
  DEFAULT_REASON_MINIMUM_WAIVED,
  // ENABLE_CONSIGNMENT_ADDITIONAL_UI,
  LINE_ITEM_TYPE,
  isInspectionFee,
  isInspectionMinimum,
} from 'constants/inspection';
import getOfflinePayload from 'features/OfflineBrandInspections/offlinePayload';
import { syncOfflineData } from 'features/SyncOfflineMenuItem/syncOfflineSlice';
import type { ServicePayment } from 'flow/payment';
import { getInspectionTypes } from 'utils/inspectionTypes';
import { toggleLoading } from 'features/LoadingMask/slice';
// import processCardCheckout from 'utils/processCardCheckout';
// import getPlatform from 'utils/getPlatform';
import initialState from './initialState';

export const getCurrentInspection = createAsyncThunk(
  'inspection/getCurrentInspection',
  async (_, { extra, dispatch }) => {
    const { inspectionsDb: db, createError } = extra;
    const inspectionId = parseInt(
      window.localStorage.getItem('udaf-inspection-id'),
      10,
    );

    try {
      if (inspectionId) {
        const data = await db.draftInspections.get(inspectionId);

        if (data) {
          return {
            ...data,
            inspectionDate: moment().format('MM/DD/YYYY'),
          };
        }

        window.localStorage.removeItem('udaf-inspection-id');
      }

      dispatch(push('/brand-inspection'));

      return initialState;
    } catch (err) {
      createError({
        payload: String(inspectionId),
        endpoint: 'OFFLINE: inspection/getCurrentInspection',
        errorStack: err?.stack,
      });

      dispatch(push('/brand-inspection'));

      return initialState;
    }
  },
);

export const updateOwnerInformation = createAsyncThunk(
  'inspection/updateOwnerInformation',
  async (
    stepOneFormData: $Shape<InspectionStepOneFormType>,
    { extra, getState, rejectWithValue, dispatch },
  ) => {
    const { inspectionsDb: db, createError } = extra;
    const {
      inspection: { data: currentInspection },
    } = getState();
    const updatedInspection = { ...currentInspection, ...stepOneFormData };

    try {
      if (currentInspection.id) {
        await db.draftInspections.put(updatedInspection);
      } else {
        const id = await db.draftInspections.add(updatedInspection);

        window.localStorage.setItem('udaf-inspection-id', id);

        updatedInspection.id = id;
      }

      dispatch(push('/brand-inspection/animals'));

      return updatedInspection;
    } catch (err) {
      createError({
        payload: JSON.stringify(updatedInspection),
        endpoint: 'OFFLINE: inspection/updateOwnerInformation',
        errorStack: err?.stack,
      });

      showErrorMessage(err?.message);

      return rejectWithValue(err);
    }
  },
);

export const updateAnimalInformation = createAsyncThunk(
  'inspection/updateAnimalInformation',
  async (
    stepTwoFormData: $Shape<InspectionStepTwoFormType>,
    { extra, getState, rejectWithValue, dispatch },
  ) => {
    const { inspectionsDb: db, createError } = extra;

    const {
      inspection: { data: currentInspection },
      resources,
    } = getState();

    const { serviceFees, inspectionTypes: types } = resources;

    const updatedInspection = {
      ...currentInspection,
      ...stepTwoFormData,
      types: getInspectionTypes(
        stepTwoFormData.types,
        types,
        stepTwoFormData.otherType,
      ),
      animals: stepTwoFormData.animals.map((a) => ({
        ...a,
        tagNo: a.tagNo === '' ? null : a.tagNo,
      })),
    };

    updatedInspection.lineItems = createLineItems({
      inspection: updatedInspection,
      serviceFees,
      category: updatedInspection.category,
      waivedFees: [],
    });

    try {
      await db.draftInspections.put(updatedInspection);

      return updatedInspection;
    } catch (err) {
      createError({
        payload: JSON.stringify(updatedInspection),
        endpoint: 'OFFLINE: inspection/updateAnimalInformation',
        errorStack: err?.stack,
      });

      showErrorMessage(err?.message);

      window.localStorage.removeItem('udaf-inspection-id');

      dispatch(push('/brand-inspection'));

      return rejectWithValue(err);
    }
  },
);

export const overrideLineItem = createAsyncThunk(
  'inspection/overrideLineItem',
  async (
    {
      defaultLineItem,
      reason,
    }: {
      defaultLineItem: *,
      reason: string,
    },
    { extra, getState, rejectWithValue },
  ) => {
    const { inspectionsDb: db, createError } = extra;
    const {
      inspection: { data },
    } = getState();

    const inspection = { ...data };

    try {
      inspection.lineItems = inspection.lineItems.map((lineItem) => {
        if (
          lineItem.lineNumber === defaultLineItem.lineNumber &&
          lineItem.slug === defaultLineItem.slug
        ) {
          return {
            ...lineItem,
            waived: true,
            reason: 'Overriden',
          };
        }

        return lineItem;
      });

      const { overrideFeeType, overrideFee } = defaultLineItem.calculatedFee;

      inspection.lineItems.push({
        ...defaultLineItem,
        type: LINE_ITEM_TYPE.OVERRIDE,
        price:
          overrideFeeType === 'fixed'
            ? overrideFee
            : overrideFee * defaultLineItem.quantity,
        reason,
      });

      await db.draftInspections.put(inspection);

      return inspection;
    } catch (err) {
      createError({
        payload: JSON.stringify(inspection),
        endpoint: 'OFFLINE: inspection/waiveLineItem',
        errorStack: err?.stack,
      });

      showErrorMessage(err?.message);

      return rejectWithValue(err);
    }
  },
);

export const removeOverridenLineItem = createAsyncThunk(
  'inspection/overrideLineItem',
  async (
    {
      lineNumber,
      slug,
    }: {
      lineNumber: string,
      slug: LineItemSlug,
    },
    { extra, getState, rejectWithValue },
  ) => {
    const { inspectionsDb: db, createError } = extra;
    const {
      inspection: { data },
    } = getState();

    const inspection = { ...data };

    try {
      inspection.lineItems = [...inspection.lineItems]
        .map((lineItem) => {
          if (
            lineItem.lineNumber === lineNumber &&
            lineItem.slug === slug &&
            lineItem.type === LINE_ITEM_TYPE.SERVICE
          ) {
            return {
              ...lineItem,
              waived: false,
              reason: '',
            };
          }

          return lineItem;
        })
        .filter((lineItem) => {
          return !(
            lineItem.lineNumber === lineNumber &&
            lineItem.slug === slug &&
            lineItem.type === LINE_ITEM_TYPE.OVERRIDE
          );
        });

      await db.draftInspections.put(inspection);

      return inspection;
    } catch (err) {
      createError({
        payload: JSON.stringify(inspection),
        endpoint: 'OFFLINE: inspection/waiveLineItem',
        errorStack: err?.stack,
      });

      showErrorMessage(err?.message);

      return rejectWithValue(err);
    }
  },
);

export const waiveLineItem = createAsyncThunk(
  'inspection/waiveLineItem',
  async (
    {
      lineNumber,
      reason,
      slug,
    }: {
      lineNumber: ?string,
      reason: string,
      slug: LineItemSlug,
    },
    { extra, getState, rejectWithValue },
  ) => {
    const { inspectionsDb: db, createError } = extra;
    const {
      inspection: { data },
    } = getState();

    const inspection = { ...data };

    try {
      inspection.lineItems = inspection.lineItems.map((lineItem) => {
        if (
          (lineItem.lineNumber === lineNumber && lineItem.slug === slug) ||
          (isInspectionMinimum(slug) && isInspectionMinimum(lineItem.slug))
        ) {
          return {
            ...lineItem,
            waived: true,
            reason,
          };
        }

        return lineItem;
      });

      if (slug === 'inspection_fee') {
        const areAllInspectionFeesWaived = inspection.lineItems
          .filter(({ slug }) => isInspectionFee(slug))
          .every(({ waived }) => waived);

        if (areAllInspectionFeesWaived) {
          inspection.lineItems = inspection.lineItems.map((lineItem) => {
            if (isInspectionMinimum(lineItem.slug)) {
              return {
                ...lineItem,
                waived: true,
                reason: DEFAULT_REASON_MINIMUM_WAIVED,
              };
            }

            return lineItem;
          });
        }
      }

      await db.draftInspections.put(inspection);

      return inspection;
    } catch (err) {
      createError({
        payload: JSON.stringify(inspection),
        endpoint: 'OFFLINE: inspection/waiveLineItem',
        errorStack: err?.stack,
      });

      showErrorMessage(err?.message);

      return rejectWithValue(err);
    }
  },
);

export const unwaiveLineItem = createAsyncThunk(
  'inspection/unwaiveLineItem',
  async (
    {
      lineNumber,
      slug,
    }: {
      lineNumber: ?string,
      slug: LineItemSlug,
    },
    { extra, getState, rejectWithValue },
  ) => {
    const { inspectionsDb: db, createError } = extra;
    const {
      inspection: { data },
    } = getState();

    const inspection = { ...data };

    try {
      inspection.lineItems = inspection.lineItems.map((lineItem) => {
        if (
          (lineItem.lineNumber === lineNumber && lineItem.slug === slug) ||
          (isInspectionMinimum(lineItem.slug) && isInspectionFee(slug)) ||
          (isInspectionMinimum(slug) && isInspectionMinimum(lineItem.slug))
        ) {
          return {
            ...lineItem,
            waived: false,
            reason: '',
          };
        }

        return lineItem;
      });

      await db.draftInspections.put(inspection);

      return inspection;
    } catch (err) {
      createError({
        payload: JSON.stringify(inspection),
        endpoint: 'OFFLINE: inspection/unwaiveLineItem',
        errorStack: err?.stack,
      });

      showErrorMessage(err?.message);

      return rejectWithValue(err);
    }
  },
);

export const createInspection = createAsyncThunk(
  'inspection/createInspection',
  async (
    { payment, generatedCode }: { payment: ServicePayment, generatedCode: * },
    { extra, rejectWithValue, getState, dispatch },
  ) => {
    const { code, publicId } = generatedCode;

    if (!code && !publicId) return {};

    const { inspectionsDb: db, createError } = extra;
    const {
      me: { data: user },
      inspection: { data: inspection },
    } = getState();

    const offlinePayload = await getOfflinePayload(inspection, payment, user);

    console.log(offlinePayload);

    const offlineInspection = {
      ...offlinePayload,
      publicId,
      inspectionCode: code,
    };

    console.log(offlineInspection);

    try {
      dispatch(toggleLoading());

      const id = await db.offlineInspections.add(offlineInspection);

      await db.draftInspections.delete(inspection.id);

      await dispatch(syncOfflineData());

      const sycnedEntity = await db.inspections.get({ publicId: id });

      dispatch(push(`/inspections/${sycnedEntity?.id || id}`));

      dispatch(toggleLoading());

      return offlineInspection;
    } catch (err) {
      dispatch(toggleLoading());

      createError({
        payload: JSON.stringify(offlineInspection),
        endpoint: 'OFFLINE: inspection/createInspection',
        errorStack: err?.stack,
      });

      showErrorMessage('Something went wrong. Please try again.');
      return rejectWithValue(err);
    }
  },
);

const inspectionSlice = createSlice({
  name: 'inspection',
  initialState: {
    data: null,
    loading: false,
    pending: true,
  },
  reducers: {
    applyPaymentOption: (state, { payload }) => {
      const { lineItems, releasedAtNoCharge } = state.data;

      let updatedLineItems = [...lineItems];

      if (payload.releasedAtNoCharge && !releasedAtNoCharge) {
        updatedLineItems = lineItems
          .map((lineItem: LineItem) => {
            return {
              ...lineItem,
              waived: true,
              reason: 'Released at no charge',
            };
          })
          .filter(({ type }: LineItem) => {
            return type !== LINE_ITEM_TYPE.OVERRIDE;
          });
      }

      if (!payload.releasedAtNoCharge && releasedAtNoCharge) {
        updatedLineItems = lineItems.map((lineItem: LineItem) => {
          return {
            ...lineItem,
            waived: false,
            reason: '',
          };
        });
      }

      state.data = {
        ...state.data,
        ...payload,
        lineItems: updatedLineItems,
      };
    },
    clearInspection: (state) => {
      state.data = initialState;
      state.pending = true;
    },
  },
  extraReducers: {
    [getCurrentInspection.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.pending = false;
    },
    [getCurrentInspection.rejected]: (state, { payload }) => {
      state.data = payload;
      state.pending = false;
    },
    [updateOwnerInformation.pending]: (state) => {
      state.loading = true;
    },
    [updateOwnerInformation.fulfilled]: (
      state,
      { payload }: { payload: $Shape<InspectionStepOneFormType> },
    ) => {
      state.data = {
        ...state.data,
        ...payload,
      };
      state.loading = false;
    },
    [updateOwnerInformation.rejected]: (state) => {
      state.loading = false;
    },
    [updateAnimalInformation.pending]: (state) => {
      state.loading = true;
    },
    [updateAnimalInformation.fulfilled]: (
      state,
      { payload }: { payload: $Shape<InspectionStepTwoFormType> },
    ) => {
      state.data = {
        ...state.data,
        ...payload,
      };
      state.loading = false;
    },
    [updateAnimalInformation.rejected]: (state) => {
      state.loading = false;
    },
    [unwaiveLineItem.pending]: (state) => {
      state.loading = true;
    },
    [unwaiveLineItem.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.loading = false;
    },
    [unwaiveLineItem.rejected]: (state) => {
      state.loading = false;
    },
    [waiveLineItem.pending]: (state) => {
      state.loading = true;
    },
    [waiveLineItem.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.loading = false;
    },
    [waiveLineItem.rejected]: (state) => {
      state.loading = false;
    },
    [overrideLineItem.pending]: (state) => {
      state.loading = true;
    },
    [overrideLineItem.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.loading = false;
    },
    [overrideLineItem.rejected]: (state) => {
      state.loading = false;
    },
    [removeOverridenLineItem.pending]: (state) => {
      state.loading = true;
    },
    [removeOverridenLineItem.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.loading = false;
    },
    [removeOverridenLineItem.rejected]: (state) => {
      state.loading = false;
    },
    [createInspection.pending]: (state) => {
      state.loading = true;
    },
    [createInspection.fulfilled]: (state) => {
      state.loading = false;
    },
    [createInspection.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const { clearInspection, applyPaymentOption } = inspectionSlice.actions;

export default inspectionSlice.reducer;
