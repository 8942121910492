// @flow
import React from 'react';
import { Wrapper, Label, SignOnTheLine, Signature } from './styles';

type Props = {
  label: ?string,
  date: ?string,
  signature: ?string,
  x: ?boolean,
};

const SignatureField = ({ label, date, signature, x }: Props) => {
  const altText = `${label || ''} signature`;
  return (
    <Wrapper>
      {!!signature && <Signature src={signature} alt={altText} />}
      <SignOnTheLine>
        <strong>{x && 'X'} </strong>
        <hr />
      </SignOnTheLine>
      <Label>{label}</Label>
      {!!date && <Label>{date}</Label>}
    </Wrapper>
  );
};

SignatureField.defaultProps = {
  label: 'Sign here',
  signature: '',
  date: '',
  x: true,
};

export default SignatureField;
