// @flow
import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment';
import type {
  InspectionStepOneFormType,
  InspectionStepTwoFormType,
} from 'flow/inspection';
import { selectUtahState, selectInspectionTypes } from 'selectors/resources';
import type { ReduxStore, ReduxState } from 'flow/redux';
import type { State, InspectionType } from 'flow/resources';
import { getInspectionTypeIds } from 'utils/inspectionTypes';
import { getPrimitivePerson, getPrimitiveRequiredPerson } from 'utils/persons';

const selectInspectionDomain = (state: ReduxStore) => state.inspection;

export const selectInspection = createSelector(
  selectInspectionDomain,
  (state: ReduxState) => state.data || {},
);

export const selectInspectionPending = createSelector(
  selectInspectionDomain,
  (state: ReduxState) => state.pending,
);

export const selectInspectionLoading = createSelector(
  selectInspectionDomain,
  (state: ReduxState) => state.loading,
);

export const selectCalfPoolReceivingStation = createSelector(
  selectInspectionTypes,
  (inspectionTypes: Array<InspectionType>) => {
    return inspectionTypes.find(
      ({ slug }: InspectionType) =>
        slug === 'calf-pool-receiving-station-location',
    );
  },
);

export const selectOther = createSelector(
  selectInspectionTypes,
  (inspectionTypes: Array<InspectionType>) => {
    return inspectionTypes.find(({ slug }: InspectionType) => slug === 'other');
  },
);

export const selectStepOneFormData = createSelector(
  selectInspectionDomain,
  selectUtahState,
  (
    {
      data: {
        owner: ownerFromInspection,
        buyer: buyerFromInspection,
        inspectionDate,
        soldTo,
        consignedTo,
        destination,
        carrier,
        licenseNo,
        registrationNumber,
      },
    }: {
      data: $Shape<InspectionStepOneFormType>,
    },
    utahState: State,
  ) => {
    const owner = getPrimitiveRequiredPerson(ownerFromInspection);
    const buyer = getPrimitivePerson(buyerFromInspection);
    return {
      owner: {
        ...owner,
        state: owner?.state || utahState.id,
      },
      buyer,
      inspectionDate: inspectionDate
        ? moment(inspectionDate, 'MM/DD/YYYY')
        : moment(moment().format('MM/DD/YYYY'), 'MM/DD/YYYY'),
      soldTo,
      consignedTo,
      destination,
      carrier,
      licenseNo,
      registrationNumber,
    };
  },
);

export const selectStepTwoFormData = createSelector(
  selectInspectionDomain,
  ({
    data: {
      animals,
      types,
      otherType,
      premiseId,
      gpsCoordinate,
      dealer,
      category,
    },
  }: {
    data: $Shape<InspectionStepTwoFormType>,
  }) => {
    const formReadyTypes = getInspectionTypeIds(types);
    return {
      animals,
      types: formReadyTypes,
      otherType,
      premiseId,
      gpsCoordinate,
      dealer,
      category,
    };
  },
);
