// @flow
import type { UserType } from 'flow/user';
import type { ServicePayment } from 'flow/payment';
import type { Inspection, OfflineInspection } from 'flow/inspection';
// import generateEntityCode from 'utils/generateEntityCode';
import { ENVIRONMENT } from 'service/urls';

const validateInspectionDate = (inspection): string => {
  const { inspectionDate } = inspection;
  if (!inspectionDate) {
    throw new Error('Inspection date is required for a valid inspection!');
  }
  return inspectionDate;
};

const getOfflinePayload = async (
  inspection: Inspection,
  payment: ServicePayment,
  user: UserType,
): Promise<OfflineInspection> => {
  const {
    payment: { paymentType },
  } = payment;
  const { animals, category } = inspection;

  const animalsWithCategory = animals.map((animal) => ({
    category,
    ...animal,
  }));

  const inspectionDate = validateInspectionDate(inspection);

  const inspectionToPersist: OfflineInspection = {
    ...inspection,
    ...payment,
    releasedAtNoCharge: paymentType === 'released_at_no_charge',
    animals: animalsWithCategory,
    draft: false,
    inspectionDate,
    user,
    clientVersion: ENVIRONMENT.build.version,
  };

  // temporary UI fields
  delete inspectionToPersist.id;
  delete inspectionToPersist.otherType;
  delete inspectionToPersist.category;

  return inspectionToPersist;
};

export default getOfflinePayload;
