export function getTargetPart(el) {
  if (el) {
    const isParent = el.tagName.toLowerCase() === 'g';

    if (isParent) {
      return el.dataset.id;
    }

    const parent = el.parentNode;

    if (parent.tagName.toLowerCase() === 'g') {
      return parent.dataset.id;
    }

    return '';
  }

  return '';
}
