// @flow
import React, { useState, useRef, Fragment } from 'react';
import { Modal, Row, Col, Space, Button, Input } from 'antd';
import {
  UndoOutlined,
  ClearOutlined,
  SaveOutlined,
  CheckCircleTwoTone,
} from '@ant-design/icons';
import { useUpdateEffect } from 'react-use';
import styled from 'styled-components';
import { UdafSketch } from '@acclaim/udaf-kit';
import horseFace from 'assets/img-horse-face.jpg';
import horseLeft from 'assets/img-horse-left.jpg';
import horseRight from 'assets/img-horse-right.jpg';
import type { ValueType } from '..';

type Props = {
  open: boolean,
  value: ValueType,
  onCancel: () => void,
  onChange: (ValueType) => void,
};

const StyledTab = styled(Button).attrs({
  ghost: true,
})`
  display: block;
  width: 100%;
  height: 165px;
  border-radius: 3px;
  position: relative;

  &:active > span,
  &:focus > span {
    position: absolute;
  }

  > img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
  }
`;

const StyledIconCheck = styled(CheckCircleTwoTone).attrs({
  twoToneColor: '#8cd5db',
})`
  font-size: 24px;
  position: absolute;
  top: 10px;
  right: 10px;
`;

const StyledControls = styled.div`
  position: absolute;
  top: 0;
  right: 0;

  > button {
    margin-right: 5px;
  }
`;

const { SketchField, Tools } = UdafSketch;

const markings = {
  LEFT: 'left',
  RIGHT: 'right',
  FACE: 'face',
};

const DEFAULT = { image: '', notes: '' };

const MarkingsModal = ({ open, value, onCancel, onChange }: Props) => {
  const [initialized, setInitialized] = useState<boolean>(false);

  const [activeTab, setActiveTab] = useState<string>(markings.LEFT);

  const [sketchValue, setSketchValue] = useState<ValueType>(
    value || {
      [markings.LEFT]: DEFAULT,
      [markings.RIGHT]: DEFAULT,
      [markings.FACE]: DEFAULT,
    },
  );

  const sketchRef = useRef<SketchField>();

  const getBackground = () => {
    switch (activeTab) {
      case markings.LEFT:
        return horseLeft;
      case markings.RIGHT:
        return horseRight;
      case markings.FACE:
        return horseFace;
      default:
        return null;
    }
  };

  const setSketchBackground = (image: ?string) => {
    if (sketchRef.current && image) {
      sketchRef.current.clear();
      sketchRef.current.setBackgroundFromDataUrl(image, {
        sketchWidth: 550,
        sketchHeight: 550,
        stretched: true,
        originY: 0,
        originX: 0,
      });
    }
  };

  const updateSketchValues = () => {
    if (sketchRef.current) {
      setSketchBackground(sketchValue[activeTab]?.image || getBackground());

      if (!initialized) {
        setInitialized(true);
      }
    } else if (!initialized) {
      setTimeout(() => {
        updateSketchValues();
      }, 500);
    }
  };

  useUpdateEffect(() => {
    updateSketchValues();
  }, [activeTab]);

  useUpdateEffect(() => {
    if (open) {
      updateSketchValues();
    }
  }, [open]);

  const handleSketchChange = () => {
    if (sketchRef.current && sketchRef.current.canUndo()) {
      const image = sketchRef.current.toDataURL();
      const currentValue = {
        [activeTab]: {
          image,
          notes: sketchValue[activeTab].notes,
        },
      };

      // wait for sketch changes to take place
      setTimeout(() => {
        setSketchValue({
          ...sketchValue,
          ...currentValue,
        });
      }, 500);
    }
  };

  const handleNotesChange = ({
    target: { value },
  }: {
    target: { value: string },
  }) => {
    setSketchValue({
      ...sketchValue,
      [activeTab]: {
        image: sketchValue[activeTab].image,
        notes: value,
      },
    });
  };

  const clearMarkings = () => {
    setSketchValue({
      ...sketchValue,
      [activeTab]: DEFAULT,
    });
    setSketchBackground(getBackground());
  };

  const undoMarking = () => {
    const canUndo = sketchRef?.current?.canUndo() || false;

    if (canUndo) {
      sketchRef.current.undo();
    }
  };

  const label = {
    left: 'Left Side',
    right: 'Right Side',
    face: 'Face',
  }[activeTab];

  const handleSave = () => {
    if (onChange) {
      onChange(sketchValue);
      setSketchValue(sketchValue);
      onCancel();
    }
  };

  const handleClose = () => {
    setSketchValue(value);
    onCancel();
  };

  const hasLeftImage = !!sketchValue?.left?.image;
  const hasRightImage = !!sketchValue?.right?.image;
  const hasFaceImage = !!sketchValue?.face?.image;

  return (
    <Modal
      visible={open}
      onCancel={handleClose}
      title={`Horse Markings - ${label}`}
      width={900}
      footer={
        <Fragment>
          <Button type="primary" ghost onClick={handleClose}>
            Cancel
          </Button>
          <Button icon={<SaveOutlined />} type="primary" onClick={handleSave}>
            Save
          </Button>
        </Fragment>
      }
    >
      <Row gutter={16}>
        <Col span={5}>
          <Space direction="vertical">
            <StyledTab
              type={activeTab === markings.LEFT ? 'primary' : ''}
              onClick={() => setActiveTab(markings.LEFT)}
            >
              {hasLeftImage && <StyledIconCheck />}
              <img
                src={sketchValue?.left?.image || horseLeft}
                alt="Horse left markings"
              />
            </StyledTab>
            <StyledTab
              type={activeTab === markings.RIGHT ? 'primary' : ''}
              onClick={() => setActiveTab(markings.RIGHT)}
            >
              {hasRightImage && <StyledIconCheck />}
              <img
                src={sketchValue?.right?.image || horseRight}
                alt="Horse right markings"
              />
            </StyledTab>
            <StyledTab
              type={activeTab === markings.FACE ? 'primary' : ''}
              onClick={() => setActiveTab(markings.FACE)}
            >
              {hasFaceImage && <StyledIconCheck />}
              <img
                src={sketchValue?.face?.image || horseFace}
                alt="Horse face markings"
              />
            </StyledTab>
          </Space>
        </Col>
        <Col span={19}>
          <Row align="middle" justify="center" style={{ position: 'relative' }}>
            <SketchField
              name="udafSketch"
              tool={Tools.Pencil}
              ref={sketchRef}
              width={550}
              height={550}
              lineColor="#000000"
              lineWidth={6}
              backgroundColor="#FFFFFF"
              onChange={handleSketchChange}
            />
            <StyledControls>
              <Button icon={<UndoOutlined />} onClick={undoMarking}>
                Undo
              </Button>
              <Button icon={<ClearOutlined />} onClick={clearMarkings}>
                Reset
              </Button>
            </StyledControls>
          </Row>
          <Row justify="center">
            <Col span={16}>
              <Input
                placeholder="Notes"
                value={
                  sketchValue[activeTab] ? sketchValue[activeTab].notes : ''
                }
                onChange={handleNotesChange}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default MarkingsModal;
