// @flow
import React, { Fragment } from 'react';
import type { Dispatch } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import type { LineItem } from 'flow/inspection';
import type { LineItemCombined, LineItemFootnote } from 'flow/footnotes';
import type { ReduxStore } from 'flow/redux';
import { selectWaivedLineItems } from 'selectors/inspection';
import { unwaiveLineItem } from 'pages/BrandInspectionPage/inspectionSlice';
import {
  DEFAULT_REASON_MINIMUM_WAIVED,
  FEE_DESCRIPTIONS,
  isInspectionMinimum,
  isReleasedAtNoCharge,
} from 'constants/inspection';
import {
  combineFeesForMessaging,
  formatPrice,
  getCertificateType,
  lineItemToLineItemCombine,
} from 'components/Footnotes/util';
import Footnote from 'components/Footnotes/Footnote';

const DEFAULT_REASON = ' fee waived';

const formatWaivedMessage = ({
  lineNumbers,
  slug,
  price,
  reason,
}: LineItemCombined): LineItemFootnote => {
  const safeReason = reason || '';
  const reasonExistsAndIsNotDefault =
    safeReason && safeReason !== DEFAULT_REASON_MINIMUM_WAIVED;

  let feeAddedFor;
  if (isInspectionMinimum(slug) && !reason) {
    feeAddedFor = DEFAULT_REASON;
  } else if (isReleasedAtNoCharge(slug) && reasonExistsAndIsNotDefault) {
    feeAddedFor = `, ${safeReason}`;
  } else if (reasonExistsAndIsNotDefault) {
    feeAddedFor = ` fee waived for ${safeReason}`;
  } else {
    feeAddedFor = DEFAULT_REASON;
  }
  return {
    message: `${formatPrice(price)} ${FEE_DESCRIPTIONS[slug]}${feeAddedFor}`,
    lineNumbers,
    slug,
  };
};

type Props = {
  editable: ?boolean,
};

const WaivedFees = ({ editable }: Props) => {
  const dispatch: Dispatch = useDispatch();

  const lineItems: Array<$Shape<LineItem>> =
    useSelector((state: ReduxStore) =>
      selectWaivedLineItems(state, getCertificateType(editable)),
    ) || [];
  const handleRemoveWaiveFeeFromTable = (values: {
    lineNumber: ?string,
    slug: string,
  }) => {
    dispatch(unwaiveLineItem(values));
  };
  return (
    <Fragment>
      {!!lineItems &&
        lineItems
          .map(lineItemToLineItemCombine)
          .reduce(combineFeesForMessaging, [])
          .map(formatWaivedMessage)
          .map((footnote: LineItemFootnote, i: number) => (
            <Footnote
              key={i}
              footnote={footnote}
              editable={editable}
              onRemove={handleRemoveWaiveFeeFromTable}
            />
          ))}
    </Fragment>
  );
};

WaivedFees.defaultProps = {
  editable: false,
};

export default WaivedFees;
