// @flow
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import { showErrorMessage, showWarningMessage } from 'utils/showMessage';
import { inspectionClient as inspectClient } from 'service/client';
import { inspectionsDb } from 'service/idb';

const fetchEntity = async (id: string) => {
  const client = await inspectClient();

  try {
    const { data } = await client.get(`/travel_permit/${id}/`);
    const offlinePayment = await inspectionsDb.offlinePayments.get(
      data.payment.id,
    );

    if (offlinePayment) {
      data.payment = {
        ...data.payment,
        ...offlinePayment,
      };
    }

    return { data, error: null };
  } catch (error) {
    return { error, data: null };
  }
};

export const fetchTravelPermit = createAsyncThunk(
  'travelPermit/fetchTravelPermit',
  async (id: string, { extra, rejectWithValue, dispatch, getState }) => {
    const { inspectionsDb: db, createError } = extra;
    const { online } = getState().initializer;

    try {
      const publicId = Number.isNaN(Number(id));
      let apiError;

      // First Attempt: if we are online, fetch the permit from the backend
      if (online && !publicId) {
        const { data, error } = await fetchEntity(id);

        apiError = error;

        if (data) {
          // cache permit locally
          await db.travelPermit.put(data);
          return data;
        }
      }

      // Second Attempt: locate permit in the local travel permit cache
      const cachedPermit = publicId
        ? await db.travelPermit.where('publicId').equals(id).first()
        : await db.travelPermit.get(parseInt(id, 10));
      if (cachedPermit) {
        return cachedPermit;
      }

      // Final Attempt: locate permit in the local offline travel permit table
      const offlinePermit = publicId && (await db.offlineTravelPermit.get(id));
      if (offlinePermit) {
        return offlinePermit;
      }

      // if every attemp fail, throw API error if available
      if (apiError) {
        throw apiError;
      }

      // Fail: message the user and display the cached travel permits
      showWarningMessage('Failed to load that certificate. Please try again');
      return dispatch(push('/travel-permits'));
    } catch (err) {
      createError({
        payload: id,
        endpoint: 'OFFLINE: travelPermit/fetchTravelPermit',
        errorStack: err?.stack,
      });

      showErrorMessage(err?.message);

      dispatch(push('/travel-permits'));

      return rejectWithValue(err);
    }
  },
);

export const fetchPublicTravelPermit = createAsyncThunk(
  'travelPermit/fetchPublicTravelPermit',
  async (id: string, { extra, rejectWithValue, dispatch }) => {
    const { inspectionClient } = extra;

    try {
      const { data } = await inspectionClient().get(
        `/travel_permit/public/${id}/`,
      );

      return data;
    } catch (err) {
      showErrorMessage(err?.response?.data?.message);

      dispatch(push('/'));

      return rejectWithValue(err);
    }
  },
);

export const voidTravelPermit = createAsyncThunk(
  'travelPermit/voidTravelPermit',
  async (voidReason: string, { extra, rejectWithValue, getState }) => {
    const { travelPermit } = getState();

    const { inspectionClient, inspectionsDb: db, createError } = extra;

    const { data } = travelPermit;

    try {
      const updated = {
        ...data,
        voidReason,
        isVoid: true,
      };

      if (updated.id) {
        await inspectionClient().patch(`/travel_permit/${data.id}/void/`, {
          voidReason,
        });

        try {
          await db.travelPermit.put(updated);
        } catch (err) {
          createError({
            payload: JSON.stringify(updated),
            endpoint: 'OFFLINE: travelPermit/voidTravelPermit/travelPermit/put',
            errorStack: err?.stack,
          });
        }
      } else {
        try {
          await db.offlineTravelPermit.put(updated);
        } catch (err) {
          createError({
            payload: JSON.stringify(updated),
            endpoint:
              'OFFLINE: travelPermit/voidTravelPermit/offlineTravelPermit/put',
            errorStack: err?.stack,
          });
        }
      }

      return updated;
    } catch (err) {
      showErrorMessage(err?.response?.data?.message);

      return rejectWithValue(err);
    }
  },
);

const travelPermitSlice = createSlice({
  name: 'travelPermit',
  initialState: {
    data: null,
    loading: false,
    pending: true,
  },
  reducers: {
    clearTravelPermit: (state) => {
      state.data = null;
      state.pending = true;
    },
  },
  extraReducers: {
    [fetchTravelPermit.pending]: (state) => {
      state.pending = true;
    },
    [fetchTravelPermit.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.pending = false;
    },
    [fetchTravelPermit.rejected]: (state) => {
      state.pending = false;
    },
    [fetchPublicTravelPermit.pending]: (state) => {
      state.pending = true;
    },
    [fetchPublicTravelPermit.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.pending = false;
    },
    [fetchPublicTravelPermit.rejected]: (state) => {
      state.pending = false;
    },
    [voidTravelPermit.pending]: (state) => {
      state.loading = true;
    },
    [voidTravelPermit.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.loading = false;
    },
    [voidTravelPermit.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const { clearTravelPermit } = travelPermitSlice.actions;

export default travelPermitSlice.reducer;
