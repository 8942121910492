// @flow
import React from 'react';
import { useEffectOnce, useUpdateEffect } from 'react-use';
import { useSelector, useDispatch, type Dispatch } from 'react-redux';
import { Table, Typography, Tag } from 'antd';
import type { TravelPermitType } from 'flow/travelPermit';
import useQuery from 'utils/hooks/useQuery';
import usePaymentTypeQuery from 'utils/hooks/usePaymentTypeQuery';
import formatMoney from 'utils/formatters/formatMoney';
import {
  getPaymentTypeColumn,
  // getPaymentStatusColumn,
} from 'utils/paymentColumn';
import {
  clearAll,
  fetchLifetimeTravelPermits,
  selectLoading,
  selectTotal,
  selectLifetimeTravelPermits,
} from './slice';

const COLUMNS = [
  {
    title: 'ID',
    dataIndex: 'permitCode',
    key: 'permitCode',
    render: (id: string, values: TravelPermitType) => (
      <Typography.Text>
        {values.isVoid && <Tag color="red">VOIDED</Tag>}
        <strong>{id}</strong>
      </Typography.Text>
    ),
  },
  {
    title: 'Owner',
    dataIndex: ['owner', 'name'],
    key: 'owner',
  },
  {
    title: 'Horse Name',
    dataIndex: 'registeredName',
    key: 'registeredName',
  },
  {
    title: 'City',
    dataIndex: ['owner', 'city'],
    key: 'city',
  },
  {
    title: 'Price',
    dataIndex: 'cost',
    key: 'cost',
    render: (value: number) => formatMoney({ value: value || 0 }),
  },
  getPaymentTypeColumn(),
  // getPaymentStatusColumn(),
  {
    title: 'Date',
    dataIndex: 'permitDate',
    key: 'permitDate',
  },
];
const AdminLifetimeTravelPermits = () => {
  const dispatch: Dispatch = useDispatch();

  const loading: boolean = useSelector(selectLoading);

  const total: number = useSelector(selectTotal);

  const lifetimeTravelPermits: Array<TravelPermitType> = useSelector(
    selectLifetimeTravelPermits,
  );

  const [paymentType] = usePaymentTypeQuery();

  const { query, page, limit, offset, patchQuery, from, to } = useQuery();

  const fetchData = () => {
    dispatch(
      fetchLifetimeTravelPermits({
        limit,
        offset,
        from,
        to,
        paymentType,
        query,
      }),
    );
  };

  const handleChange = ({ current }) => {
    patchQuery('page', current);
  };

  useEffectOnce(() => {
    fetchData();

    return () => {
      dispatch(clearAll());
    };
  });

  useUpdateEffect(() => {
    fetchData();
  }, [limit, offset, from, to]);

  useUpdateEffect(() => {
    if (page === 1) {
      fetchData();
    } else {
      patchQuery('page', 1);
    }
  }, [paymentType, query]);

  return (
    <Table
      rowKey="id"
      columns={COLUMNS}
      dataSource={lifetimeTravelPermits}
      loading={loading}
      size="small"
      rowClassName="ant-row-clickable"
      pagination={{
        current: page,
        pageSize: limit,
        total,
        showSizeChanger: false,
      }}
      onChange={handleChange}
      onRow={(record) => {
        return {
          onClick: () => {
            window.open(`/travel-permits/${record.id}`);
          },
        };
      }}
    />
  );
};

export default AdminLifetimeTravelPermits;
