// @flow
import styled from 'styled-components';
import { Button } from 'antd';

export const Text = styled.p`
  font-family: inherit;
  font-weight: 400;
  font-style: italic !important;
  text-align: right;
  color: #999999;
  margin: 0 2.1em 0;
`;

export const StyledButton = styled(Button)`
  margin: 0.25em 0 0.25em 0.5em;
`;
