// @flow
import React, { type Node } from 'react';
import { Typography } from 'antd';
import styled from 'styled-components';

type Props = {
  children: Node,
};

const Title = styled(Typography.Title)`
  position: relative;
  display: flex;
  align-items: center;

  &::after {
    content: '';
    display: block;
    flex: 1;
    height: 1px;
    background-color: #d9d9d9;
    margin-left: 0.5em;
  }
`;

const FormTitle = (props: Props) => {
  const { children, ...rest } = props;

  return <Title {...rest}>{children}</Title>;
};

export default FormTitle;
