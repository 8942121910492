// @flow
import { v4 as uuidv4 } from 'uuid';

export const animalDefault = {
  quantity: 1,
  age: '',
  rfid: '',
  usda: '',
  tagNo: null,
  category: null,
  breed: null,
  gender: null,
  color: null,
  owner: '',
  tagColor: null,
  brands: [],
  notes: '',
};

export default {
  owner: {
    name: '',
    address: '',
    city: '',
    state: null,
    county: null,
    zipCode: '',
  },
  buyer: {
    name: '',
    address: '',
    city: '',
    state: null,
    county: null,
    zipCode: '',
  },
  category: null,
  inspectionDate: '',
  soldTo: '',
  consignedTo: '',
  destination: '',
  carrier: '',
  licenseNo: '',
  registrationNo: '',
  animals: [
    {
      lineNumber: uuidv4(),
      ...animalDefault,
    },
  ],
  types: [],
  otherType: '',
  premiseId: '',
  gpsCoordinate: '',
  dealer: null, // { name: '', license: '' },
  draft: true,
  payment: {
    paymentType: '',
  },
  // lineNumber, type, reason
  waivedFees: [],
  releasedAtNoCharge: false,
  releasedAtNoChargeReason: '',
  lineItems: [],
};
