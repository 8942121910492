// @flow
type Props = {
  value: number,
  locale?: string,
  currency?: string,
};

export default ({ value, locale = 'en-US', currency = 'USD' }: Props) => {
  return new Intl.NumberFormat(locale, { style: 'currency', currency }).format(
    value,
  );
};

export const dollarToCents = (value: number) => {
  return value * 100;
};

export const centsToDollars = (value: number) => {
  return value / 100;
};
