// @flow
import React from 'react';
import type { TravelPermitType } from 'flow/travelPermit';
import Template, {
  Brands,
  Content,
  DisplayPayment,
  Horse,
  InspectionHeader,
  InspectorOwnerBuyer,
  Legalize,
  Owner,
  Section,
  SignatureContainer,
  SignatureRow,
  TearHR,
} from 'components/CertificateTemplate';
import SignatureField from 'components/SignatureField';
import { getInspectionUrl } from 'service/urls';
import PhotosAndMarkings, {
  displayPhotos,
  Markings,
} from 'components/CertificateTemplate/components/PhotosAndMarkings';
import {
  Card,
  CardBack,
  CardHeader,
  CardInstructions,
  CardMarkings,
  CardPhoto,
  Cards,
  Contact,
  NoPhotosOrMarkings,
  Photos,
} from './styles';

const getAvailableSpaces = (photoCount: number): number => {
  if (photoCount < 2) {
    return photoCount === 0 ? 3 : 1;
  }
  return 0;
};

type Props = {
  travelPermit: $Shape<TravelPermitType>,
};

const PermitCertificateTemplate = ({ travelPermit }: Props) => {
  const {
    owner,
    user,
    payment,
    permitDate,
    animal,
    permitCode,
    publicId,
    photos,
    markings,
    type: permitType,
    clientVersion,
    serviceVersion,
    isVoid,
  } = travelPermit;

  const { brands } = animal;

  const { type: permit } = permitType;

  const qrUrl = `${getInspectionUrl()}/public/travel-permit/${publicId}`;

  const photoCount = photos?.length || 0;
  const markingsCount = markings?.length || 0;

  const hasPhotos = photoCount > 0;
  const hasMarkings = markingsCount > 0;

  const markingsToDisplayOnCard = getAvailableSpaces(photoCount);

  return (
    <div>
      <Template>
        <InspectionHeader
          inspectionTitle={`EQUINE ${permit}`}
          code={permitCode}
          date={permitDate}
          url={qrUrl}
          version={{ clientVersion, serviceVersion }}
          isVoid={isVoid}
        />
        <Content>
          <InspectorOwnerBuyer>
            <Owner owner={owner} />
            <PermitHorse inspection={travelPermit} includeBrands />
            <Section style={{ minWidth: '2.6in' }}>
              <h5>PERMIT VOID WHEN OWNERS CHANGES</h5>
              <Legalize>
                This permit is issued in compliance with section 4-24-23 UCA.
                This permit must accompany the horse at all times when in
                transit in compliance with Utah laws governing inter-state
                movement.
                <span>
                  <b>phone:</b> (801) 982-2240 <b>web:</b>
                  &nbsp;https://ag.utah.gov
                </span>
              </Legalize>
            </Section>
          </InspectorOwnerBuyer>

          <PhotosAndMarkings photos={photos} markings={markings} />

          <DisplayPayment payment={payment} />

          <SignatureRow>
            <SignatureContainer>
              <SignatureField label={`Inspected by ${user.name}`} x={false} />
            </SignatureContainer>

            <SignatureContainer>
              <SignatureField label="Owner / Agent sign here" />
            </SignatureContainer>
          </SignatureRow>
        </Content>
      </Template>
      <TearHR />
      <Template>
        <Content>
          <CardInstructions>
            <h3>YOUR WALLET-SIZED PERMIT</h3>{' '}
            <span>Keep this with you at all times.</span>
          </CardInstructions>

          <Cards>
            <Card>
              <CardHeader>
                <InspectionHeader
                  inspectionTitle={`EQUINE ${permit}`}
                  code={permitCode}
                  date={permitDate}
                  url={qrUrl}
                  version={{ clientVersion, serviceVersion }}
                  isVoid={isVoid}
                  logoSize="48"
                  qrSize=".5in"
                  hideSeal
                />
              </CardHeader>
              <Content style={{ fontSize: '1em', padding: '0 1em' }}>
                <InspectorOwnerBuyer
                  style={{
                    fontSize: '.9em',
                    margin: '-1em 0 0',
                    paddingBottom: '1em',
                  }}
                >
                  <Owner owner={owner} />
                  <PermitHorse inspection={travelPermit} />
                </InspectorOwnerBuyer>
                <Brands brands={brands} />
              </Content>
            </Card>

            <CardBack>
              <Photos>
                {!hasPhotos && !hasMarkings && <NoPhotosOrMarkingsProvided />}
                {photos
                  .slice(0, 2)
                  .map(displayPhotos)
                  .map((photo, i) => (
                    <CardPhoto key={`card-photo-${i}`}>{photo}</CardPhoto>
                  ))}
                {!!markingsToDisplayOnCard && (
                  <CardMarkings>
                    <Markings
                      markings={markings.slice(0, markingsToDisplayOnCard)}
                      height="1in"
                    />
                  </CardMarkings>
                )}
              </Photos>
              <Legalize>
                <strong>Permit Void when owners changes</strong>
                This permit is issued in compliance with section 4-24-23 UCA.
                This permit must accompany the horse at all times when in
                transit in compliance with Utah laws governing inter-state
                movement.
                <Contact>
                  <b>phone:</b> (801) 982-2240 &nbsp; &nbsp;
                  <b>web:</b> https://ag.utah.gov
                </Contact>
              </Legalize>
            </CardBack>
          </Cards>
        </Content>
      </Template>
    </div>
  );
};

const NoPhotosOrMarkingsProvided = () => (
  <NoPhotosOrMarkings>no photos or markings provided</NoPhotosOrMarkings>
);

type PermitHorseProps = {
  inspection: $Shape<TravelPermitType>,
  includeBrands?: boolean,
};
const PermitHorse = ({ inspection, includeBrands }: PermitHorseProps) => {
  const { animal, registeredName, registrationNo, year, height, tattoo } =
    inspection;
  return (
    <Horse
      animal={animal}
      registeredName={registeredName}
      registrationNo={registrationNo}
      year={year}
      height={height}
      tattoo={tattoo}
      includeBrands={includeBrands}
    />
  );
};

PermitHorse.defaultProps = {
  includeBrands: false,
};

export default PermitCertificateTemplate;
