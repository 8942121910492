// @flow
import type { Animal, AnimalType } from 'flow/animal';
import type { Breed, Color, Gender } from 'flow/resources';
import type { BrandType } from 'flow/brands';

export const getAnimal = (
  animal: Animal,
  genders: Array<Gender>,
  colors: Array<Color>,
  breeds: Array<Breed>,
): AnimalType => {
  const { gender, color, breed } = animal;
  return {
    ...animal,
    gender: genders.find(({ id }: Gender) => id === gender),
    color: colors.find(({ id }: Color) => id === color),
    breed: breeds.find(({ id }: Breed) => id === breed),
  };
};

const isNoBrand = (brand: BrandType): boolean => {
  return (
    brand && !!brand.position && brand.position.toLowerCase() === 'no brand'
  );
};

export const hasBrand = (brands: Array<BrandType>): boolean => {
  const oneBrand = (brands || []).length === 1;

  if (oneBrand) {
    return !isNoBrand(brands[0]);
  }

  return brands && brands.length > 1;
};
