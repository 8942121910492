// @flow
import React from 'react';
import { type Dispatch, useDispatch, useSelector } from 'react-redux';
import {
  type History,
  type Location,
  Route,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { Button, Space, Tabs } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import Page from 'components/Page';
import LifetimeTravelPermits from 'features/LifetimeTravelPermits';
import YearlyTravelPermits from 'features/YearlyTravelPermits';
import OfflineTravelPermits from 'features/OfflineTravelPermits';
import DateRangeFilter from 'components/DateRangeFilter';
import SearchFilter from 'components/SearchFilter';
import PaymentTypeFilter from 'components/PaymentTypeFilter';
import { syncOfflineData } from 'features/SyncOfflineMenuItem/syncOfflineSlice';
import { DISPLAY_SYNC_RESULTS } from 'features/SyncOfflineMenuItem';
import usePaymentTypeQuery from 'utils/hooks/usePaymentTypeQuery';

const { TabPane } = Tabs;

const LifetimeTravelPermitsPage = () => {
  const { pathname }: Location = useLocation();
  const history: History = useHistory();
  const dispatch: Dispatch = useDispatch();
  const offlineTravelPermitsLoading: boolean = useSelector(
    (state) => state.offlineTravelPermits.loading,
  );

  const [paymentType] = usePaymentTypeQuery();

  const paymentStatus = paymentType || 'all';

  const handleTabChange = (key: string) => {
    history.push(key);
  };

  const handleSync = () => {
    dispatch(syncOfflineData(DISPLAY_SYNC_RESULTS));
  };

  return (
    <Page>
      <Tabs activeKey={pathname} onChange={handleTabChange}>
        <TabPane tab="Lifetime" key="/travel-permits/lifetime" />
        <TabPane tab="Yearly" key="/travel-permits/yearly" />
        <TabPane tab="Offline" key="/travel-permits/offline" />
      </Tabs>

      <Space align="center" style={{ marginBottom: 10 }}>
        <SearchFilter />

        <DateRangeFilter />

        <PaymentTypeFilter />

        <Route exact path="/travel-permits/offline">
          <Button
            type="primary"
            onClick={handleSync}
            loading={offlineTravelPermitsLoading}
            id="syncPermits"
          >
            <SyncOutlined />
            Sync
          </Button>
        </Route>
      </Space>

      <Switch>
        <Route exact path="/travel-permits/lifetime">
          <LifetimeTravelPermits paymentStatus={paymentStatus} />
        </Route>
        <Route exact path="/travel-permits/yearly">
          <YearlyTravelPermits paymentStatus={paymentStatus} />
        </Route>
        <Route exact path="/travel-permits/offline">
          <OfflineTravelPermits paymentStatus={paymentStatus} />
        </Route>
      </Switch>
    </Page>
  );
};

export default LifetimeTravelPermitsPage;
