// @flow
import React, { Fragment } from 'react';
import { useDispatch, useSelector, type Dispatch } from 'react-redux';
import { Link, useHistory, type History } from 'react-router-dom';
import { useEffectOnce, useUpdateEffect } from 'react-use';
import { Table, Typography, Tag } from 'antd';
import type { ReduxStore } from 'flow/redux';
import type { TravelPermitType } from 'flow/travelPermit';
import type { InspectionType } from 'flow/resources';
import formatMoney from 'utils/formatters/formatMoney';
import useQuery from 'utils/hooks/useQuery';
import EmptyOfflineMessage from 'components/EmptyOfflineMessage';
import {
  getPaymentTypeColumn,
  // getPaymentStatusColumn,
} from 'utils/paymentColumn';
import {
  fetchOfflineTravelPermits,
  selectOfflineTravelPermits,
  clearAll,
} from './offlineTravelPermitsSlice';

const COLUMNS = [
  {
    title: 'ID',
    dataIndex: 'permitCode',
    key: 'permitCode',
    defaultSortOrder: 'descend',
    sorter: (a, b) => { return a.permitCode.localeCompare(b.permitCode)},
    render: (id: string) => (
      <Typography.Text>
        <Tag color="warning">OFFLINE</Tag>
        <strong>{id}</strong>
      </Typography.Text>
    ),
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    render: ({ type }: InspectionType) => (
      <Typography.Text>{type}</Typography.Text>
    ),
  },
  {
    title: 'Owner',
    dataIndex: ['owner', 'name'],
    key: 'owner',
  },
  {
    title: 'Horse Name',
    dataIndex: 'registeredName',
    key: 'registeredName',
  },
  {
    title: 'City',
    dataIndex: ['owner', 'city'],
    key: 'city',
  },
  {
    title: 'Total Price',
    dataIndex: 'cost',
    key: 'cost',
    render: (value: number) => formatMoney({ value: value || 0 }),
  },
  getPaymentTypeColumn(),
  // getPaymentStatusColumn(),
  {
    title: 'Date',
    dataIndex: 'permitDate',
    key: 'permitDate',
  },
];

type Props = {
  paymentStatus: string,
};

const OfflineTravelPermits = ({ paymentStatus }: Props) => {
  const dispatch: Dispatch = useDispatch();

  const history: History = useHistory();

  const data: $Shape<TravelPermitType> = useSelector(
    selectOfflineTravelPermits,
  );

  const loading: boolean = useSelector(
    ({ offlineTravelPermits: { loading, syncing } }: ReduxStore) =>
      loading || syncing,
  );

  const total: number = useSelector(
    (state: ReduxStore) => state.offlineTravelPermits.total,
  );

  const syncing = useSelector(
    (state: ReduxStore) => state.offlineTravelPermits.syncing,
  );

  const { from, to, query, page, limit, offset, patchQuery } = useQuery();

  const fetchData = () => {
    dispatch(
      fetchOfflineTravelPermits({
        limit,
        offset,
        from,
        to,
        query,
        paymentStatus,
      }),
    );
  };

  const handleChange = ({ current }) => {
    patchQuery('page', current);
  };

  useEffectOnce(() => {
    fetchData();

    return () => {
      dispatch(clearAll());
    };
  });

  useUpdateEffect(() => {
    fetchData();
  }, [limit, offset, from, to]);

  useUpdateEffect(() => {
    if (page === 1) {
      fetchData();
    } else {
      patchQuery('page', 1);
    }
  }, [query, paymentStatus, syncing]);

  return (
    <Table
      rowKey="publicId"
      columns={COLUMNS}
      dataSource={data}
      size="small"
      bordered
      rowClassName="ant-row-clickable"
      loading={loading}
      pagination={{
        current: page,
        pageSize: limit,
        total,
        showSizeChanger: false,
      }}
      locale={{
        emptyText: (
          <EmptyOfflineMessage
            template={
              <Fragment>
                Your inspections have been completely synchronized, check the{' '}
                <Link to="/travel-permits/lifetime">Lifetime</Link> or{' '}
                <Link to="/travel-permits/yearly">Yearly</Link> tab to review
                those inspections.
              </Fragment>
            }
          />
        ),
      }}
      onChange={handleChange}
      onRow={(record: $Shape<TravelPermitType>) => {
        return {
          onClick: () => {
            history.push(`/travel-permits/${record.publicId}`);
          },
        };
      }}
    />
  );
};

OfflineTravelPermits.defaultProps = {
  paymentStatus: 'all',
};

export default OfflineTravelPermits;
