// @flow
import React from 'react';
import { useEffectOnce } from 'react-use';
import { useAuthentication } from '@acclaim/udaf-kit';
import ContentLoader from 'components/ContentLoader';

const Auth = () => {
  const { login } = useAuthentication();

  useEffectOnce(() => {
    login();
  });

  return <ContentLoader message="Redirecting..." />;
};

export default Auth;
