// @flow
import React, { Fragment } from 'react';
import { Form, Input, Row, Col } from 'antd';
import FormTitle from 'components/FormTitle';
import CountiesFormField from 'components/form/CountiesFormField';
import StatesFormField from 'components/form/StatesFormField';
import { validateZipCode } from 'utils/validators/zipCode';

const BuyerForm = () => {
  return (
    <Fragment>
      <FormTitle level={4}>Buyer</FormTitle>
      <Row gutter={12}>
        <Col lg={4} md={8}>
          <Form.Item label="Name" name={['buyer', 'name']}>
            <Input />
          </Form.Item>
        </Col>

        <Col lg={4} md={8}>
          <Form.Item label="Street Address" name={['buyer', 'address']}>
            <Input />
          </Form.Item>
        </Col>

        <Col lg={4} md={8}>
          <Form.Item label="City" name={['buyer', 'city']}>
            <Input />
          </Form.Item>
        </Col>

        <Col lg={4} md={8}>
          <Form.Item label="State" name={['buyer', 'state']}>
            <StatesFormField />
          </Form.Item>
        </Col>

        <Col lg={4} md={8}>
          <Form.Item
            shouldUpdate={(prevValues, curValues) => {
              return prevValues.buyer?.state !== curValues.buyer?.state;
            }}
            noStyle
          >
            {(form) => {
              const state = form.getFieldValue(['buyer', 'state']);

              return (
                <Form.Item label="County" name={['buyer', 'county']}>
                  <CountiesFormField state={state} />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>

        <Col lg={4} md={8}>
          <Form.Item
            label="Zip Code"
            name={['buyer', 'zipCode']}
            rules={[validateZipCode]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
    </Fragment>
  );
};

export default BuyerForm;
