// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import { CloseOutlined } from '@ant-design/icons';
import { isInspectionMinimum } from 'constants/inspection';
import type { LineItemFootnote } from 'flow/footnotes';
import type { RemoveLineItemFunction } from 'flow/inspection';
import { selectReleasedAtNoCharge } from 'selectors/inspection';
import { StyledButton, Text } from 'components/Footnotes/styles';

type Props = {
  footnote: LineItemFootnote,
  editable: ?boolean,
  onRemove: ?RemoveLineItemFunction,
};

const Footnote = ({ footnote, editable, onRemove }: Props) => {
  const { lineNumbers, message, slug } = footnote;
  const releasedAtNoCharge = useSelector(selectReleasedAtNoCharge);
  const hasLineNumbers = !!lineNumbers?.length;
  const displayRemoveButton =
    editable &&
    !releasedAtNoCharge && // released at no charge can only be removed by changing the payment method
    !!onRemove && // there must be a function for removing the item
    (hasLineNumbers || isInspectionMinimum(slug)); // inspection minimum is a special case for removal without lineNumber identifier
  return (
    <Text>
      {message}
      {displayRemoveButton && (
        <StyledButton
          ghost
          size="small"
          type="primary"
          shape="circle"
          icon={<CloseOutlined style={{ fontSize: 12 }} />}
          onClick={() => {
            if (onRemove) {
              if (lineNumbers?.length) {
                lineNumbers.forEach((lineNumber) => {
                  onRemove({ lineNumber, slug });
                });
              } else {
                onRemove({ lineNumber: null, slug });
              }
            }
          }}
        />
      )}
    </Text>
  );
};

Footnote.defaultProps = {
  editable: false,
};

export default Footnote;
