import type { Dispatch } from 'react-redux';
import type { PreSaleInspectionType } from 'flow/presale';
import { apiAnimal, persistBrandOnSingleAnimal } from 'service/sync/util';
import { getPrimitivePerson } from 'utils/persons';
import type { SyncApi } from 'flow/sync';
import { atomicSync } from 'service/sync';

class PresaleSync
  implements SyncApi<PreSaleInspectionType, PreSaleInspectionType> {
  apiUrl = '/presale/';

  offlineTable = 'offlinePreSaleInspections';

  serviceCacheTable = 'preSaleInspections';

  getId(inspection: PreSaleInspectionType): string {
    return inspection.publicId;
  }

  getCodeKey(inspection: PreSaleInspectionType): string {
    return inspection.presaleCode;
  }

  persistHandDrawnBrands(
    dispatch: Dispatch,
    inspection: PreSaleInspectionType,
  ): void {
    persistBrandOnSingleAnimal(
      inspection.animal,
      inspection.publicId,
      dispatch,
      inspection.presaleDate,
    );
  }

  apiAdapter(permit: PreSaleInspectionType): PreSaleInspectionType {
    const { owner, animal } = permit;
    return {
      ...permit,
      owner: getPrimitivePerson(owner),
      animal: apiAnimal(animal),
    };
  }
}

const api: SyncApi<
  PreSaleInspectionType,
  PreSaleInspectionType,
> = new PresaleSync();

const syncPresale = (dispatch: Dispatch) => atomicSync(api, dispatch);

export default syncPresale;
