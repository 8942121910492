// @flow
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import { showErrorMessage, showWarningMessage } from 'utils/showMessage';
import { inspectionClient as inspectClient } from 'service/client';
import { inspectionsDb } from 'service/idb';

const fetchEntity = async (id: string) => {
  const client = await inspectClient();

  try {
    const { data } = await client.get(`/presale/${id}/`);
    const offlinePayment = await inspectionsDb.offlinePayments.get(
      data.payment.id,
    );

    if (offlinePayment) {
      data.payment = {
        ...data.payment,
        ...offlinePayment,
      };
    }

    return { data, error: null };
  } catch (error) {
    return { error, data: null };
  }
};

export const fetchPreSaleInspection = createAsyncThunk(
  'horsePreSale/fetchPreSaleInspection',
  async (id: string, { extra, dispatch, getState }) => {
    const { inspectionsDb: db } = extra;
    const { rejectWithValue, createError } = extra;
    const { online } = getState().initializer;

    try {
      const publicId = Number.isNaN(Number(id));
      let apiError;

      // First Attempt: if we are online, fetch the presale from the backend
      if (online && !publicId) {
        const { data, error } = await fetchEntity(id);

        apiError = error;

        if (data) {
          // cache presale locally
          await db.preSaleInspections.put(data);
          return data;
        }
      }

      // Second Attempt: locate presale in the local presale cache
      const cachedPresale = publicId
        ? await db.preSaleInspections.where('publicId').equals(id).first()
        : await db.preSaleInspections.get(parseInt(id, 10));

      if (cachedPresale) {
        return cachedPresale;
      }

      // Final Attempt: locate presale in the local offline presale table
      const offlinePresale =
        publicId && (await db.offlinePreSaleInspections.get(id));

      if (offlinePresale) {
        return offlinePresale;
      }

      // if every attemp fail, throw API error if available
      if (apiError) {
        throw apiError;
      }

      // Fail: message the user and display the cached presale permits
      showWarningMessage('Failed to load that certificate. Please try again');
      return dispatch(push('/horse-presale-listing'));
    } catch (err) {
      createError({
        payload: JSON.stringify(id),
        endpoint: 'OFFLINE: horsePreSale/fetchPreSaleInspection',
        errorStack: err?.stack,
      });

      showErrorMessage('Could not find Pre-Sale Inspection.');

      dispatch(push('/horse-presale-listing'));

      return rejectWithValue(err);
    }
  },
);

export const fetchPublicPreSaleInspection = createAsyncThunk(
  'horsePreSale/fetchPublicPreSaleInspection',
  async (id: string, { extra, rejectWithValue, dispatch }) => {
    const { inspectionClient } = extra;

    try {
      const { data } = await inspectionClient().get(`/presale/public/${id}/`);

      return data;
    } catch (err) {
      showErrorMessage(
        err?.response?.data?.message ||
          'Something went wrong. Please try again.',
      );

      dispatch(push('/'));

      return rejectWithValue(err);
    }
  },
);

export const voidPreSale = createAsyncThunk(
  'horsePreSale/voidPreSale',
  async (voidReason: string, { extra, rejectWithValue, getState }) => {
    const { horsePreSale } = getState();

    const { inspectionClient, inspectionsDb: db, createError } = extra;

    const { data } = horsePreSale;

    try {
      const updated = {
        ...data,
        voidReason,
        isVoid: true,
      };

      if (updated.id) {
        await inspectionClient().patch(`/presale/${data.id}/void/`, {
          voidReason,
        });

        try {
          await db.preSaleInspections.put(updated);
        } catch (err) {
          createError({
            payload: JSON.stringify(updated),
            endpoint:
              'OFFLINE: horsePreSale/voidPreSale/preSaleInspections/put',
            errorStack: err?.stack,
          });
        }
      } else {
        try {
          await db.offlinePreSaleInspections.put(updated);
        } catch (err) {
          createError({
            payload: JSON.stringify(updated),
            endpoint:
              'OFFLINE: horsePreSale/voidPreSale/offlinePreSaleInspections/put',
            errorStack: err?.stack,
          });
        }
      }

      return updated;
    } catch (err) {
      showErrorMessage(err?.response?.data?.message);

      return rejectWithValue(err);
    }
  },
);

const horsePreSaleSlice = createSlice({
  name: 'horsePreSale',
  initialState: {
    data: null,
    loading: false,
    pending: true,
  },
  reducers: {
    clearPreSaleInspection: (state) => {
      state.data = null;
      state.pending = true;
    },
  },
  extraReducers: {
    [fetchPreSaleInspection.pending]: (state) => {
      state.pending = true;
    },
    [fetchPreSaleInspection.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.pending = false;
    },
    [fetchPreSaleInspection.rejected]: (state) => {
      state.pending = false;
    },
    [fetchPublicPreSaleInspection.pending]: (state) => {
      state.pending = true;
    },
    [fetchPublicPreSaleInspection.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.pending = false;
    },
    [fetchPublicPreSaleInspection.rejected]: (state) => {
      state.pending = false;
    },
    [voidPreSale.pending]: (state) => {
      state.loading = true;
    },
    [voidPreSale.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.loading = false;
    },
    [voidPreSale.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const { clearPreSaleInspection } = horsePreSaleSlice.actions;

export default horsePreSaleSlice.reducer;
