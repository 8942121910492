// @flow
import React, { Fragment, useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { Row, Typography, Button } from 'antd';
import styled from 'styled-components';

type Props = {
  id: string,
  label: string,
  value?: string,
  disabled: boolean,
  hasExisting?: boolean,
  onChange?: (string) => void,
};

const SignOnTheLine = styled.div`
  padding: 1em 2em;
  margin-top: -90px;
  margin-bottom: 2em;

  strong {
    font-size: 2em;
  }

  hr {
    margin-top: -0.6em;
  }
`;

const StyledSignitureCanvas = styled.div`
  border: 1px solid #cccccc;
  box-shadow: 0 12px 34px 0 rgba(224, 228, 226, 0.17);
  margin: 0.5em 0 1em;

  > .canvas {
    width: 100%;
    height: 150px;
    display: block;
  }

  ${(props) =>
    props.disabled &&
    `
    pointer-events: none;
    cursor: not-allowed;
  `}
`;

const StyledPreview = styled.div`
  width: 100%;
  height: 200px;
  display: block;
  background-image: url(${(props) => props.img});
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: not-allowed;
  background-color: #fafafa;
`;

const SignatureFormField = ({
  label,
  onChange,
  value,
  disabled,
  id,
  hasExisting,
}: Props) => {
  const sigRef = useRef<SignatureCanvas>();
  const wrapperRef = useRef();
  const [readonly, setReadonly] = useState<boolean>(hasExisting || false);

  const handleClear = () => {
    if (readonly) {
      setReadonly(false);
    } else if (sigRef.current) {
      sigRef.current.clear();
    }

    if (onChange) onChange('');
  };

  const handleEnd = () => {
    if (sigRef.current && onChange && !disabled) {
      const trimmedData = sigRef.current.getCanvas().toDataURL('image/png');

      onChange(trimmedData);
    }
  };

  return (
    <Fragment>
      <Row justify="space-between" align="middle">
        <Typography.Text>{label}</Typography.Text>
        <Button
          htmlType="button"
          type="primary"
          ghost
          size="small"
          onClick={handleClear}
        >
          Clear
        </Button>
      </Row>
      <StyledSignitureCanvas ref={wrapperRef} disabled={disabled}>
        {readonly ? (
          <StyledPreview img={value} />
        ) : (
          <SignatureCanvas
            clearOnResize={false}
            canvasProps={{
              className: 'canvas',
              id,
            }}
            ref={sigRef}
            onEnd={handleEnd}
          />
        )}
      </StyledSignitureCanvas>
      <SignOnTheLine>
        <strong>X</strong>
        <hr />
      </SignOnTheLine>
    </Fragment>
  );
};

SignatureFormField.defaultProps = {
  value: '',
  onChange: (value) => value,
  disabled: false,
  hasExisting: false,
  id: '',
};

export default SignatureFormField;
