// @flow
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import type { ReduxStore } from 'flow/redux';
import type { Filters } from 'flow/search';
import { showErrorMessage } from 'utils/showMessage';
import storeResourceData from 'utils/storeResourceData';
import { filterInspections } from 'service/search/util';
import { stringToTimestamp } from 'utils/dates';

const adapter = createEntityAdapter();

const initialState = adapter.getInitialState({
  loading: false,
  total: 0,
});

export const fetchTravelPermits = createAsyncThunk(
  'travelPermitsSlice/fetchTravelPermits',
  async (filters: ?Filters, { extra, rejectWithValue, getState }) => {
    const { inspectionClient, inspectionsDb: db, createError } = extra;
    const { me, initializer } = getState();
    const { online } = initializer;
    const userId = me.data?.id;

    try {
      if (online) {
        const { data } = await inspectionClient().get('/travel_permit/', {
          params: { userId },
        });

        const permits = data.map((permit) => ({
          ...permit,
          permitDate: stringToTimestamp(permit.permitDate),
        }));

        await storeResourceData(db, 'travelPermit', permits);
      }

      if (filters) {
        const { from, to, limit, offset, query, paymentStatus } = filters;

        return await filterInspections({
          range: { from, to, key: 'permitDate' },
          query,
          limit,
          offset,
          paymentStatus: paymentStatus || 'all',
          table: db.travelPermit,
        });
      }

      return { total: 0, result: [] };
    } catch (err) {
      createError({
        payload: '',
        endpoint: 'OFFLINE: travelPermitsSlice/fetchTravelPermits',
        errorStack: err?.stack,
      });

      showErrorMessage(err?.message);

      return rejectWithValue(err);
    }
  },
);

const travelPermitsSlice = createSlice({
  name: 'travelPermits',
  initialState,
  reducers: {
    clearAll: (state) => {
      state.total = 0;
      adapter.removeAll(state);
    },
  },
  extraReducers: {
    [fetchTravelPermits.pending]: (state) => {
      state.loading = true;
    },
    [fetchTravelPermits.fulfilled]: (state, { payload }) => {
      const { total, result } = payload;

      state.loading = false;
      state.total = total;
      adapter.setAll(state, result);
    },
    [fetchTravelPermits.rejected]: (state) => {
      state.loading = false;
      state.total = 0;
      adapter.removeAll(state);
    },
  },
});

export const { selectAll: selectTravelPermits } = adapter.getSelectors(
  (state: ReduxStore) => {
    return state.travelPermits;
  },
);

export const { clearAll } = travelPermitsSlice.actions;

export default travelPermitsSlice.reducer;
