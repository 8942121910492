// @flow
import React from 'react';
import { useDispatch } from 'react-redux';
import { useMount } from 'react-use';
import Page from 'components/Page';
import ContentLoader from 'components/ContentLoader';
import { logoutInspectionService } from 'store/meSlice';
import { clearCredentials } from 'service/client';

const Logout = () => {
  const dispatch = useDispatch();

  const handleLogout = async () => {
    dispatch(logoutInspectionService());

    await clearCredentials();

    window.location.href = '/';
  };

  useMount(() => {
    handleLogout().then(() => {
      console.info('Logout succeeded.');
    });
  });

  return (
    <Page>
      <center>
        <ContentLoader message="Logging out..." />
      </center>
    </Page>
  );
};

export default Logout;
