// @flow
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import type { ReduxStore } from 'flow/redux';
import { showErrorMessage } from 'utils/showMessage';
import { filterDraftInspections } from 'service/search/util';

const adapter = createEntityAdapter();

const initialState = adapter.getInitialState({
  loading: false,
});

export const { selectAll: selectDraftInspections } = adapter.getSelectors(
  (state: ReduxStore) => {
    return state.draftInspections;
  },
);

export const fetchDraftInspections = createAsyncThunk(
  'draftInspections/fetchDraftInspections',
  async (query: string, { extra, rejectWithValue }) => {
    const { inspectionsDb: db, createError } = extra;

    try {
      const data = await filterDraftInspections(
        { from: '', to: '', key: 'inspectionDate' },
        query,
        db.draftInspections,
      );

      return data;
    } catch (err) {
      createError({
        payload: '',
        endpoint: 'OFFLINE: draftInspections/fetchDraftInspections',
        errorStack: err?.stack,
      });

      showErrorMessage(err?.message);

      return rejectWithValue(err);
    }
  },
);

export const deleteDraftInspection = createAsyncThunk(
  'draftInspections/deleteDraftInspection',
  async (id: number, { extra, rejectWithValue }) => {
    const { inspectionsDb: db, createError } = extra;

    try {
      await db.draftInspections.where('id').anyOf(id).delete();

      return id;
    } catch (err) {
      createError({
        payload: '',
        endpoint: 'OFFLINE: draftInspections/deleteDraftInspection',
        errorStack: err?.stack,
      });

      showErrorMessage(err?.message);

      return rejectWithValue(err);
    }
  },
);

const draftInspectionsSlice = createSlice({
  name: 'draftInspections',
  initialState,
  extraReducers: {
    [fetchDraftInspections.pending]: (state) => {
      state.loading = true;
    },
    [fetchDraftInspections.fulfilled]: (state, action) => {
      state.loading = false;
      adapter.setAll(state, action.payload);
    },
    [fetchDraftInspections.rejected]: (state) => {
      state.loading = false;
    },
    [deleteDraftInspection.pending]: (state) => {
      state.loading = true;
    },
    [deleteDraftInspection.fulfilled]: (state, action) => {
      state.loading = false;
      adapter.removeOne(state, action.payload);
    },
    [deleteDraftInspection.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default draftInspectionsSlice.reducer;
