// @flow
import React from 'react';
import { Image, Button } from 'antd';
import { DeleteTwoTone } from '@ant-design/icons';
import styled from 'styled-components';

const StyledWrapper = styled.div`
  display: inline-block;
  position: relative;
`;

const StyledClose = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  color: #ffffff;
`;

type Props = {
  index: number,
  src: string,
  alt?: string,
  onClick: (number) => void,
};

const Photo = ({ src, alt, index, onClick }: Props) => {
  return (
    <StyledWrapper>
      <Image width={150} src={src} alt={alt} />
      <StyledClose
        type="link"
        icon={<DeleteTwoTone />}
        onClick={() => onClick(index)}
      />
    </StyledWrapper>
  );
};

Photo.defaultProps = {
  alt: '',
};

export default Photo;
