// @flow
import React, { useState, Fragment } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector, type Dispatch } from 'react-redux';
import { useHistory, useParams, Link, type History } from 'react-router-dom';
import { useEffectOnce, useUnmount } from 'react-use';
import { Breadcrumb } from 'antd';
import { ProfileOutlined } from '@ant-design/icons';
import isEmpty from 'lodash/isEmpty';
import type { ReduxStore } from 'flow/redux';
import type { PreSaleInspectionType } from 'flow/presale';
import Page from 'components/Page';
import ContentLoader from 'components/ContentLoader';
import PreSaleCertificateTemplate from 'components/PreSaleCertificateTemplate';
import VoidModal from 'components/VoidModal';
import ButtonContainer from 'components/Buttons/ButtonContainer';
import { PrintButton, VoidButton } from 'components/Buttons';
import SettlePaymentForm from 'features/SettlePaymentForm';
import PaymentHistory from 'features/PaymentHistory';
import {
  fetchPreSaleInspection,
  fetchPublicPreSaleInspection,
  clearPreSaleInspection,
  voidPreSale,
} from './horsePreSaleSlice';

const StyledPrintWrapper = styled.div.attrs({
  id: 'print-template',
  className: 'print-wrapper',
})`
  max-width: 950px;
  min-width: 700px;
  width: 100%;
  margin: 1em 0;
  border: 1px dashed #cccccc;
  @media print {
    margin: 0;
    padding: 0;
    border: 0;
  }
`;

const SpaceStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 1em auto;
  @media print {
    margin: 0;
  }
`;
type Props = {
  publicTemplate: boolean,
};

const HorsePreSalePage = ({ publicTemplate }: Props) => {
  const { id: presaleId } = useParams();

  const dispatch: Dispatch = useDispatch();

  const history: History = useHistory();

  const [voidModalOpen, setVoidModalOpen] = useState<boolean>(false);

  const pending: boolean = useSelector((state: ReduxStore) => {
    return state.horsePreSale.pending;
  });

  const presale: $Shape<PreSaleInspectionType> = useSelector(
    (state: ReduxStore) => {
      return state.horsePreSale.data || {};
    },
  );

  const { id, publicId, owner, presaleCode, isVoid, payment } = presale;

  const voidLoading: boolean = useSelector(
    (state: ReduxStore) => state.horsePreSale.loading,
  );

  const handlePrint = () => {
    window.print();
  };

  const handleVoidModalClose = () => setVoidModalOpen(false);

  const handleConfirm = async (reason: string) => {
    await dispatch(voidPreSale(reason));

    handleVoidModalClose();
  };

  const handlePreSaleInspection = () => {
    history.push(`/horse-pre-sale`, { ...owner });
  };

  const shouldSettlePayment = payment && payment?.paymentType === 'pay_later';

  useEffectOnce(() => {
    if (publicTemplate) {
      dispatch(fetchPublicPreSaleInspection(presaleId));
    } else {
      dispatch(fetchPreSaleInspection(presaleId));
    }
  });

  useUnmount(() => {
    dispatch(clearPreSaleInspection());
  });

  if (pending || isEmpty(presale)) {
    return <ContentLoader message="Loading certificate..." />;
  }

  const buildActions = () => {
    if (!publicTemplate && !isVoid) {
      return (
        <div className="hidden-print">
          <ButtonContainer>
            <PrintButton
              id="printPresale"
              label="Print Permit"
              onClick={handlePrint}
            />
            <PrintButton
              id="newPresale"
              label="New Pre-Sale"
              onClick={handlePreSaleInspection}
            />
            {payment?.id && <PaymentHistory id={id} entityType="PSH" />}
            <VoidButton
              id="voidPresale"
              onClick={() => setVoidModalOpen(true)}
            />
          </ButtonContainer>
        </div>
      );
    }

    return null;
  };
  return (
    <Fragment>
      <Page>
        {!publicTemplate && (
          <div className="hidden-print">
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/horse-presale-listing">
                  <ProfileOutlined />
                  <strong> Horse Pre-Sale Listings</strong>
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <strong>{presaleCode}</strong>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        )}

        <SpaceStyle>
          {!publicTemplate && shouldSettlePayment && (
            <SettlePaymentForm
              payment={payment}
              entityId={id || publicId}
              entityType="presale"
            />
          )}

          {buildActions()}

          <StyledPrintWrapper>
            <PreSaleCertificateTemplate presale={presale} />
          </StyledPrintWrapper>

          {buildActions()}

          {!publicTemplate && (
            <VoidModal
              visible={voidModalOpen}
              onCancel={handleVoidModalClose}
              onConfirm={handleConfirm}
              confirmLoading={voidLoading}
            />
          )}
        </SpaceStyle>
      </Page>
    </Fragment>
  );
};

HorsePreSalePage.defaultProps = {
  publicTemplate: false,
};

export default HorsePreSalePage;
