// @flow
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import type { ReduxStore } from 'flow/redux';
import { showErrorMessage, showWarningMessage } from 'utils/showMessage';

const adapter = createEntityAdapter();

const initialState = adapter.getInitialState({
  loading: false,
});

export const { selectAll: selectPaymentHistory } = adapter.getSelectors(
  (state: ReduxStore) => {
    return state.paymentHistory;
  },
);

export const fetchPaymentHistory = createAsyncThunk(
  'paymentHistory/fetchPaymentHistory',
  async (
    {
      entityId,
      entityType,
    }: {
      entityId: number,
      entityType: 'BI' | 'TPH' | 'PSH',
    },
    { extra, rejectWithValue, getState },
  ) => {
    const { initializer } = getState();
    const { inspectionClient } = extra;
    const { online } = initializer;

    try {
      if (online) {
        const { data } = await inspectionClient().get('/payments/', {
          params: { entityId, entityType },
        });

        return data.results;
      }

      showWarningMessage(
        'You must have internet connection to view payment history.',
      );

      return [];
    } catch (err) {
      showErrorMessage(err?.response?.data?.message);

      return rejectWithValue(err);
    }
  },
);

const paymentHistorySlice = createSlice({
  name: 'paymentHistory',
  initialState,
  reducers: {
    clearAll: adapter.removeAll,
  },
  extraReducers: {
    [fetchPaymentHistory.pending]: (state) => {
      state.loading = true;
    },
    [fetchPaymentHistory.fulfilled]: (state, { payload }) => {
      state.loading = false;
      adapter.setAll(state, payload);
    },
    [fetchPaymentHistory.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const selectLoading = (state: ReduxStore) =>
  state.paymentHistory.loading;

export const { clearAll } = paymentHistorySlice.actions;

export default paymentHistorySlice.reducer;
