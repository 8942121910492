// @flow
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, useLocation, useHistory } from 'react-router-dom';
import Page from 'components/Page';
import FormSkeleton from 'components/form/FormSkeleton';
import CodesAlertBanner from 'components/CodesAlertBanner';
import { getCurrentInspection, clearInspection } from './inspectionSlice';
import { selectInspectionPending } from './selectors';
import Stepper from './components/Stepper';
import BrandInspectionsOwnersForm from './components/BrandInspectionsOwnersForm';
import BrandInspectionAnimalForm from './components/BrandInspectionAnimalForm';
import BrandInspectionPreview from './components/BrandInspectionPreview';

const BrandInspectionPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const pending = useSelector(selectInspectionPending);
  const { search } = location;

  useEffect(() => {
    const query = new URLSearchParams(search);
    if (query.get('id')) {
      window.localStorage.setItem('udaf-inspection-id', query.get('id'));

      history.push('/brand-inspection');
    }
  }, [history, search]);

  useEffect(() => {
    dispatch(getCurrentInspection());

    return () => {
      window.localStorage.removeItem('udaf-inspection-id');
      dispatch(clearInspection());
    };
  }, [dispatch]);

  if (pending) {
    return <FormSkeleton />;
  }

  return (
    <Page>
      <CodesAlertBanner />
      <Stepper pathname={location.pathname} />
      <Switch>
        <Route exact path="/brand-inspection">
          <BrandInspectionsOwnersForm />
        </Route>
        <Route exact path="/brand-inspection/animals">
          <BrandInspectionAnimalForm />
        </Route>
        <Route exact path="/brand-inspection/preview">
          <BrandInspectionPreview />
        </Route>
      </Switch>
    </Page>
  );
};

export default BrandInspectionPage;
