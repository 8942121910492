import type { BrandQueryParts, BrandType, Page } from 'flow/brands';
import { decoratePosition } from 'flow/brands';
import Dexie from 'dexie';
import { parseBrandQueryParts } from './queryPartsFactory';
import { queryByLettersAndOneName } from './queryByLettersAndOneName';
import { queryByNames } from './queryByNames';

const search = (
  db: Dexie,
  query: BrandQueryParts,
  paging: Page = {},
): Promise<Array<Array<BrandType> | number>> => {
  const { possibleNames, definiteBrandLetters, possibleBrandLetters } = query;
  const nameCount = possibleNames.length;
  if (
    (definiteBrandLetters.length || possibleBrandLetters.length) &&
    nameCount <= 1 // if more that one name, a different search method should be used
  ) {
    const lettersToSearch = definiteBrandLetters.length
      ? definiteBrandLetters
      : possibleBrandLetters;
    return queryByLettersAndOneName(db, lettersToSearch, possibleNames, paging);
  } if (nameCount) {
    return queryByNames(db, possibleNames, paging);
  }

  return Dexie.Promise.resolve([[], 0]);
};
// const brandSearchSpecification = () => {
// brandAddressJoin =
//   getRoot().join(Brand_.addresses, JoinType.LEFT)
//     .join(BrandAddress_.address, JoinType.LEFT);
// imageJoin = getRoot().join(Brand_.brandImage);
// ownersJoin = getRoot().join(Brand_.brandOwners);
// ownerAddressJoin =
//   ownersJoin.join(BrandOwner_.brandOwnerAddresses, JoinType.LEFT)
//     .join(BrandOwnerAddress_.address, JoinType.LEFT);
//
// addBrandNumbers();
// addDefiniteLetters();
// addNamesOrPossibleLetters();
// addZipCode();
// addCounty();
// addPositions();
//
// return getQuery()
//   .where(getCriteria().toArray(new Predicate[0]))
//   .groupBy(getRoot().get(Brand_.brandId))
//   .getRestriction();
// }

const Search = (
  db: Dexie,
  query: string,
  paging: Page = {},
): Promise<Array<BrandType>> => {
  // process the query and organize it into categories of terms we can work with
  const queryParts: BrandQueryParts = parseBrandQueryParts(query);

  if (queryParts.hasSearchTerms) {
    return search(db, queryParts, paging).then((result) => {
      const [brands, count] = result;

      return {
        result: brands.map(decoratePosition),
        count,
      };
    });
  }

  return Dexie.Promise.resolve({
    result: [],
    count: 0,
  });
};
export default Search;
