// @flow
import { createSelector } from '@reduxjs/toolkit';
import type { ReduxStore, ReduxState } from 'flow/redux';

const selectBrandsDomain = (state: ReduxStore) => state.brands;

export const selectBrands = createSelector(
  selectBrandsDomain,
  (state: ReduxState) => state.data,
);

export const selectBrandsLoading = createSelector(
  selectBrandsDomain,
  (state: ReduxState) => state.loading,
);
