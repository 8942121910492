// @flow
import moment from 'moment';

export const toDateOrDash = (value?: string) =>
  value ? moment(value).format('MM/DD/YYYY') : '-';

export const timestampToString = (timestamp: ?number): string =>
  (timestamp && new Date(timestamp).toLocaleDateString()) || '';

export const stringToTimestamp = (string: ?string): number =>
  (string && Date.parse(string)) || 0;

/**
 * Used for tracking history details in the indexeddb history table.
 * Note: this format was specifically used to provide a natural sort order
 * and less than or greater than comparator on a string.
 *
 * @returns {string} a date formatted like "20210510" for May 5, 2021
 */
export const getHistoryDateString = () => moment().format('YYYYMMDD');

type StartAndEndParams = {
  start: string,
  end: string,
};

/**
 * returns a param list with the historyDate converted to
 * the start param format.
 *
 * @param fromDate a starting date generated with getHistoryDateString()
 * @returns {string} a params list like {..., start}
 */
export const getDateParams = (fromDate: ?string): StartAndEndParams => {
  if (!fromDate) {
    return {};
  }
  const year = fromDate.substring(0, 4);
  const month = fromDate.substring(4, 6);
  const day = fromDate.substring(6);
  const tomorrow = new Date(
    new Date().getTime() + 1000 * 60 * 60 * 24,
  ).toLocaleDateString();
  return { start: `${month}/${day}/${year}`, end: tomorrow };
};
