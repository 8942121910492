// @flow
import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Row, Col, Button, Typography, Modal } from 'antd';
import { PlusOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import type { InspectionType, Category } from 'flow/resources';
import type { InspectionAnimal } from 'flow/inspection';
import useResources from 'utils/hooks/useResources';
import { selectInspectionTypes } from 'selectors/resources';
import { ENABLE_CONSIGNMENT_ADDITIONAL_UI } from 'constants/inspection';
import Content from 'components/Content';
import AnimalForm from './components/AnimalForm';
import InspectedForForm from './components/InspectedForForm';
import {
  selectStepTwoFormData,
  selectInspectionLoading,
  selectCalfPoolReceivingStation,
  selectOther,
} from '../../selectors';
import { updateAnimalInformation } from '../../inspectionSlice';
import { animalDefault } from '../../initialState';

const CALF_POOL_RECEIVING_ANIMAL_LINEITEM = [
  { quantity: 1, lineNumber: uuidv4() },
];

const BrandInspectionAnimalForm = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const history = useHistory();
  const initialValues = useSelector(selectStepTwoFormData);
  const loading = useSelector(selectInspectionLoading);
  const calfPoolReceivingStation = useSelector(selectCalfPoolReceivingStation);
  const other = useSelector(selectOther);
  const inspectionTypes = useSelector(selectInspectionTypes);

  const categories: Array<Category> = useResources<Category>(
    'animalCategories',
  );
  const [animalCategory, setAnimalCategory] = useState(initialValues.category);

  const handleSubmit = (values) => {
    const dispatchAnimalInfoUpdate = () => {
      const isCalfPoolReceivingStation = values.types.includes(
        calfPoolReceivingStation.id,
      );

      if (isCalfPoolReceivingStation) {
        dispatch(
          updateAnimalInformation({
            ...values,
            category: animalCategory,
            animals: CALF_POOL_RECEIVING_ANIMAL_LINEITEM,
          }),
        );
      } else {
        dispatch(updateAnimalInformation(values));
      }

      history.push('/brand-inspection/preview');
    };

    const category = categories.find((c: Category) => values.category === c.id);

    const slug = category?.slug || '';

    if (slug === 'elk') {
      const elkRequiredIds = ['rfid', 'usda', 'tagColor'];

      const hasMissingIds = values.animals.some((a) => {
        return (
          elkRequiredIds.filter((key: string) => {
            return !!a[key];
          }).length < 2
        );
      });

      if (hasMissingIds) {
        return Modal.confirm({
          title: 'Identification Required',
          icon: <ExclamationCircleOutlined />,
          content:
            'You are expected to have two forms of id for each elk. You may choose to continue, but you really should make a best effort to provide a RFID, USDA and/or Ranch Tag information.',
          okText: 'Continue',
          cancelText: 'Provide Identification',
          onOk: dispatchAnimalInfoUpdate,
        });
      }
    } else if (['horse', 'cattle'].includes(slug)) {
      const hasProdAndConsignmentSale = values.types
        .map((id: number) =>
          inspectionTypes.find((type: InspectionType) => type.id === id),
        )
        .some((type: InspectionType) =>
          ['consignment-sale', 'production-sale'].includes(type.slug),
        );

      if (hasProdAndConsignmentSale && ENABLE_CONSIGNMENT_ADDITIONAL_UI) {
        const minimumHead = slug === 'horse' ? 120 : 90;
        const totalHead = values.animals.reduce(
          (acc: number, animal: $Shape<InspectionAnimal>) => {
            return acc + animal.quantity;
          },
          0,
        );

        const message = `${
          totalHead < minimumHead
            ? `The minimum head count for a consignment or production sale is ${minimumHead} head. To be clear, `
            : `Even though the minimum head count for a consignment or production sale is ${minimumHead} head, `
        }you should only enter the total head count over
           ${minimumHead}. Click [Continue] to acknowledge that you understand this rule.`;

        return Modal.confirm({
          title: 'Consignment and Production Sales',
          icon: <ExclamationCircleOutlined />,
          content: message,
          okText: 'Continue',
          cancelText: 'Cancel',
          onOk: dispatchAnimalInfoUpdate,
        });
      }
    }

    return dispatchAnimalInfoUpdate();
  };

  const resetAnimals = () => {
    const category = form.getFieldValue('category');

    setAnimalCategory(category);
    form.setFieldsValue({
      animals: [
        {
          ...animalDefault,
          lineNumber: uuidv4(),
        },
      ],
      types: [],
    });
  };

  const handleConfirmAnimalChange = () => {
    resetAnimals();
  };

  const handleBack = () => {
    dispatch(updateAnimalInformation(form.getFieldsValue()));
    history.push('/brand-inspection');
  };

  const handleCancelAnimalChange = () => {
    form.setFieldsValue({ category: animalCategory });
  };

  const confirmAnimalChange = () => {
    Modal.confirm({
      title: 'Warning',
      icon: <ExclamationCircleOutlined />,
      content:
        'Animal already selected, are you sure want to update? You will lose all entered information.',
      okText: 'Confirm',
      cancelText: 'Cancel',
      onCancel: handleCancelAnimalChange,
      onOk: handleConfirmAnimalChange,
    });
  };

  const handleChange = (value) => {
    if (value?.category) {
      if (animalCategory) {
        confirmAnimalChange();
      } else {
        resetAnimals();
      }
    }

    if (value?.types) {
      if (value.types.includes(calfPoolReceivingStation?.id)) {
        form.setFieldsValue({
          animals: CALF_POOL_RECEIVING_ANIMAL_LINEITEM,
        });
      }

      if (value.types.includes(other?.id)) {
        form.setFieldsValue({ otherType: '' });
      }
    }

    if (value?.releasedAtNoCharge) {
      form.setFieldsValue({ releasedAtNoChargeReason: '' });
    }
  };

  const handleAddAnimal = () => {
    const animals = [...form.getFieldValue('animals')];

    form.setFieldsValue({
      animals: [
        ...animals,
        {
          ...animalDefault,
          lineNumber: uuidv4(),
        },
      ],
    });
  };

  const handleRemoveAnimal = (index: number) => {
    const animals = [...form.getFieldValue('animals')];

    animals.splice(index, 1);

    form.setFieldsValue({ animals: [...animals] });
  };

  const categorySlug =
    (categories.find((c: Category) => c.id === animalCategory) || {})?.slug ||
    '';

  return (
    <Form
      onFinish={handleSubmit}
      onValuesChange={handleChange}
      initialValues={initialValues}
      form={form}
      name="AnimalInformationForm"
      colon={false}
      layout="vertical"
    >
      <Form.Item
        shouldUpdate={(prevValues, curValues) =>
          prevValues.animals.length !== curValues.animals.length ||
          prevValues.types !== curValues.types
        }
        noStyle
      >
        {() => {
          const animals = form.getFieldValue('animals');
          const types = form.getFieldValue('types');
          const hasCategory = !!form.getFieldValue('category');
          const isCalfPoolReceivingStation = types.includes(
            calfPoolReceivingStation.id,
          );

          if (isCalfPoolReceivingStation) {
            return null;
          }

          return (
            <Fragment>
              <Content>
                {animals.map((_, index: number) => (
                  <AnimalForm
                    index={index}
                    key={index}
                    handleRemoveAnimal={handleRemoveAnimal}
                    categories={categories}
                    categorySlug={categorySlug}
                  />
                ))}
              </Content>
              <Row justify="center" style={{ marginBottom: '2em' }}>
                <Col span={6}>
                  <Button
                    type="primary"
                    block
                    icon={<PlusOutlined />}
                    onClick={handleAddAnimal}
                    disabled={!hasCategory}
                  >
                    Add Animal
                  </Button>
                </Col>
              </Row>
            </Fragment>
          );
        }}
      </Form.Item>
      <Form.Item
        shouldUpdate={(prevValues, curValues) =>
          prevValues.category !== curValues.category
        }
        noStyle
      >
        {() => {
          const category = form.getFieldValue('category');

          return (
            <Content title="Inspected For">
              <InspectedForForm category={category} />
            </Content>
          );
        }}
      </Form.Item>
      <Row justify="end" gutter={10}>
        <Col span={13}>
          <Typography.Text underline>
            Validation of brand certificates
          </Typography.Text>
          <Typography.Paragraph>
            Brand inspection certificates, except for “Change of ownership”and
            “Travel permits” shall be automatically cancelled and void one week
            after time of issuance. This certificate void if altered.
          </Typography.Paragraph>
        </Col>
        <Col lg={{ span: 4, offset: 3 }} md={{ span: 5, offset: 1 }}>
          <Button
            htmlType="button"
            block
            onClick={handleBack}
            id="backToInspectionStepOne"
          >
            Previous
          </Button>
        </Col>
        <Col lg={4} md={5}>
          <Button
            htmlType="submit"
            type="primary"
            block
            loading={loading}
            id="confirmInspectionStepTwo"
          >
            Next
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default BrandInspectionAnimalForm;
