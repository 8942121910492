// @flow
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import { Tabs, Button, Space } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import Page from 'components/Page';
import BrandInspections from 'features/BrandInspections';
import OfflineBrandInspections from 'features/OfflineBrandInspections';
import DraftBrandInspections from 'features/DraftBrandInspections';
import DateRangeFilter from 'components/DateRangeFilter';
import SearchFilter from 'components/SearchFilter';
import PaymentTypeFilter from 'components/PaymentTypeFilter';
import { syncOfflineData } from 'features/SyncOfflineMenuItem/syncOfflineSlice';
import { DISPLAY_SYNC_RESULTS } from 'features/SyncOfflineMenuItem';
import usePaymentTypeQuery from 'utils/hooks/usePaymentTypeQuery';

const { TabPane } = Tabs;

const BrandInspectionCertificatesPage = () => {
  const { pathname } = useLocation();

  const history = useHistory();

  const offlineInspectionsSyncing = useSelector(
    (state) => state.offlineInspections.syncing,
  );

  const [paymentType] = usePaymentTypeQuery();

  const paymentStatus = paymentType || 'all';

  const dispatch = useDispatch();

  const handleChange = (key: string) => {
    history.push(key);
  };

  const handleSync = () => {
    dispatch(syncOfflineData(DISPLAY_SYNC_RESULTS));
  };

  return (
    <Page>
      <Tabs activeKey={pathname} onChange={handleChange}>
        <TabPane tab="Certificates" key="/inspections" />
        <TabPane tab="Offline Certificates" key="/inspections/offline" />
        <TabPane tab="Draft" key="/inspections/draft" />
      </Tabs>

      <Space align="center" style={{ marginBottom: 10 }}>
        <SearchFilter />

        <Route exact path={['/inspections', '/inspections/offline']}>
          <DateRangeFilter />
        </Route>

        <Route exact path={['/inspections', '/inspections/offline']}>
          <PaymentTypeFilter />
        </Route>

        <Route exact path="/inspections/offline">
          <Button
            type="primary"
            onClick={handleSync}
            loading={offlineInspectionsSyncing}
            id="syncInspections"
          >
            <SyncOutlined />
            Sync
          </Button>
        </Route>
      </Space>

      <Switch>
        <Route exact path="/inspections">
          <BrandInspections paymentStatus={paymentStatus} />
        </Route>
        <Route exact path="/inspections/offline">
          <OfflineBrandInspections paymentStatus={paymentStatus} />
        </Route>
        <Route exact path="/inspections/draft">
          <DraftBrandInspections />
        </Route>
      </Switch>
    </Page>
  );
};

export default BrandInspectionCertificatesPage;
