// @flow
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import type { ReduxStore } from 'flow/redux';

const adapter = createEntityAdapter();

const initialState = adapter.getInitialState({
  loading: false,
  total: 0,
});

export const { selectAll: selectAdminLogs } = adapter.getSelectors(
  (state: ReduxStore) => {
    return state.adminLogs;
  },
);

export const fetchAdminLogs = createAsyncThunk(
  'adminLogs/fetchAdminLogs',
  async (
    { page = 1, limit = 20 }: { page: number, limit: number },
    { extra, rejectWithValue },
  ) => {
    const { inspectionClient } = extra;

    try {
      const { data } = await inspectionClient().get('/offline-error-logs/', {
        params: {
          limit,
          offset: (page - 1) * limit,
        },
      });

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

const adminLogsSlice = createSlice({
  name: 'adminLogs',
  initialState,
  extraReducers: {
    [fetchAdminLogs.pending]: (state) => {
      state.loading = true;
    },
    [fetchAdminLogs.fulfilled]: (state, action) => {
      const { results, count } = action.payload;

      state.loading = false;
      state.total = count;

      adapter.setAll(state, results);
    },
    [fetchAdminLogs.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default adminLogsSlice.reducer;
