// @flow
import React from 'react';
import { Typography, Spin, Space } from 'antd';
import styled from 'styled-components';
import logo from 'assets/logo.png';

type Props = {
  message: string,
};

const StyledWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.7);
`;

const StyledContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;

  > img {
    margin-bottom: 10px;
    display: block;
  }
`;

const ContentLoader = ({ message = 'Loading, please wait...' }: Props) => {
  return (
    <StyledWrapper>
      <StyledContent>
        <img src={logo} alt="UDAF" height="230" width="230" />
        <Space direction="horizontal" align="center">
          <Spin />
          <Typography.Title level={5} style={{ margin: 0 }}>
            {message}
          </Typography.Title>
        </Space>
      </StyledContent>
    </StyledWrapper>
  );
};

export default ContentLoader;
