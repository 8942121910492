// @flow
import React from 'react';
import { useUpdateEffect } from 'react-use';
import { Form, Modal, Select, Typography, Space, Button, Input } from 'antd';
import type { LineItem } from 'flow/inspection';
import formatMoney, { centsToDollars } from 'utils/formatters/formatMoney';

type Props = {
  open: boolean,
  record: ?LineItem,
  onClose: () => void,
  onSubmit: ({ reason: string }) => void,
  onClear: () => void,
};

const { Option } = Select;

const OverrideFeesModal = ({
  open,
  onClose,
  record,
  onSubmit,
  onClear,
}: Props) => {
  const [form] = Form.useForm();

  const calculatedFee = record?.calculatedFee || {};

  const reason = record?.reason || '';

  const overrideFee = calculatedFee?.overrideFee || 0;

  const quantity = record?.quantity || 0;

  const handleSubmit = (values) => {
    onSubmit(values);

    form.resetFields();
  };

  const isBeefTag = record?.slug === 'beef_tag_fee';

  useUpdateEffect(() => {
    if (open) {
      form.setFieldsValue({
        reason: isBeefTag ? 'Beef tag fee' : reason || '',
      });
    } else {
      form.resetFields();
    }
  }, [open]);

  return (
    <Modal title="Apply fee" visible={open} footer={null} onCancel={onClose}>
      <Form
        onFinish={handleSubmit}
        form={form}
        name="WaiveFeeForm"
        colon={false}
        layout="vertical"
      >
        <center>
          <Typography.Paragraph>
            Fee:{' '}
            <strong>
              {formatMoney({ value: centsToDollars(overrideFee * quantity) })} (
              {formatMoney({ value: centsToDollars(overrideFee) })} x {quantity}{' '}
              heads)
            </strong>
          </Typography.Paragraph>
        </center>
        <Form.Item
          name="reason"
          rules={[
            {
              required: true,
              message: 'Fee reason is required',
            },
          ]}
        >
          {isBeefTag ? (
            <Input placeholder="Reason" disabled style={{ width: '100%' }} />
          ) : (
            <Select
              placeholder="Select a reason"
              style={{ width: '100%' }}
              disabled={!!reason}
            >
              <Option value="Utah Cattle Pasture Change w/out Commuter Permit">
                Utah Cattle Pasture Change w/out Commuter Permit
              </Option>
              <Option value="Utah Calves Pasture Change Marketed out of state">
                Utah Calves Pasture Change Marketed out of state
              </Option>
              <Option value="Out of State Commuter Cattle Returning Home">
                Out of State Commuter Cattle Returning Home
              </Option>
              <Option value="Retained Ownership Feedlot Cattle">
                Retained Ownership Feedlot Cattle
              </Option>
            </Select>
          )}
        </Form.Item>
        <Space size="small">
          <Button type="ghost" onClick={onClose}>
            Cancel
          </Button>
          {reason ? (
            <Button type="primary" onClick={onClear}>
              Remove override fee
            </Button>
          ) : (
            <Button type="primary" htmlType="submit">
              Apply fee
            </Button>
          )}
        </Space>
      </Form>
    </Modal>
  );
};

export default OverrideFeesModal;
