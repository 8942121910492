import type { Dispatch } from 'react-redux';
import type { TravelPermitType } from 'flow/travelPermit';
import { apiAnimal, persistBrandOnSingleAnimal } from 'service/sync/util';
import { getPrimitivePerson } from 'utils/persons';
import type { SyncApi } from 'flow/sync';
import { atomicSync } from 'service/sync';

class TravelPermitSync implements SyncApi<TravelPermitType, TravelPermitType> {
  apiUrl = '/travel_permit/';

  offlineTable = 'offlineTravelPermit';

  serviceCacheTable = 'travelPermit';

  getId(inspection: TravelPermitType): string {
    return inspection.publicId;
  }

  getCodeKey(inspection: TravelPermitType): string {
    return inspection.permitCode;
  }

  persistHandDrawnBrands(dispatch: Dispatch, permit: TravelPermitType): void {
    persistBrandOnSingleAnimal(
      permit.animal,
      permit.publicId,
      dispatch,
      permit.permitDate,
    );
  }

  apiAdapter(permit: TravelPermitType): TravelPermitType {
    const { owner, type, animal } = permit;
    return {
      ...permit,
      owner: getPrimitivePerson(owner),
      animal: apiAnimal(animal),
      type: type.id,
    };
  }
}

const api: SyncApi<TravelPermitType, TravelPermitType> = new TravelPermitSync();

const syncTravelPermit = (dispatch: Dispatch) => atomicSync(api, dispatch);

export default syncTravelPermit;
