// @flow
import React, { Fragment, useRef, type ElementRef } from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { CameraOutlined } from '@ant-design/icons';

const StyledButton = styled(Button)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 11px;

  ${({ disabled }) =>
    disabled &&
    `
    pointer-events: none;
    cursor: not-allowed;
  `}
`;

type Props = {
  placeholder?: string,
  onChange: (Array<string>) => void,
  value: Array<string>,
  id: string,
};

const CameraFormField = ({ placeholder, onChange, value, id }: Props) => {
  const disabled = value.length === 4;

  const inputRef = useRef<ElementRef<'input'> | null>(null);

  const handleUploadFile = () => {
    if (inputRef.current && !disabled) {
      inputRef.current.click();
    }
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleFileChange = async () => {
    if (inputRef.current && inputRef.current?.files[0]) {
      const image = await toBase64(inputRef.current.files[0]);

      if (image && typeof image === 'string') {
        onChange([...value, image]);
      }

      if (inputRef.current?.value) {
        inputRef.current.value = '';
      }
    }
  };

  return (
    <Fragment>
      <StyledButton onClick={handleUploadFile} disabled={disabled}>
        {disabled ? '4 photos atteched' : placeholder}
        <CameraOutlined />
      </StyledButton>
      <input
        type="file"
        accept="image/*"
        ref={inputRef}
        onChange={handleFileChange}
        style={{ display: 'none' }}
        id={id}
      />
    </Fragment>
  );
};

CameraFormField.defaultProps = {
  placeholder: 'Attach/Capture Photo',
  // eslint-disable-next-line
  onChange: (value: Array<string>) => {},
  value: [],
  id: '',
};

export default CameraFormField;
