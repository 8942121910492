// @flow
export default {
  owner: {
    name: '',
    phone: '',
    address: '',
    city: '',
    zipCode: '',
    state: null,
    county: null,
  },
  markings: {
    left: { image: '', notes: '' },
    right: { image: '', notes: '' },
    face: { image: '', notes: '' },
  },
  brands: [],
  year: '',
  registeredName: '',
  registrationNo: '',
  tattoo: '',
  cost: null,
  permitDate: '',
  signature: '',
  permitCode: '',
  publicId: '',
  photos: [],
  breed: null,
  color: null,
  gender: null,
  type: null,
  payment: {
    paymentType: '',
    checkNumber: '',
  },
};
