// @flow
import React, { Fragment } from 'react';
import { Table, Tag, Typography } from 'antd';
import styled from 'styled-components';
import type {
  DisplayLineItem,
  LineItem,
  LineItemSlug,
  SubtotalType,
} from 'flow/inspection';
import { type BrandType, getPositionLabel } from 'flow/brands';
import formatMoney, { centsToDollars } from 'utils/formatters/formatMoney';
import NetworkImage from 'components/NetworkImage';
import {
  LINE_ITEM_TYPE,
  ENABLE_MINIMUM_SUMMARY_LINEITEM_WAIVING,
} from 'constants/inspection';
import SubTotal from './SubTotal';

type Props = {
  displayLineItems: Array<$Shape<DisplayLineItem>>,
  subtotal: SubtotalType,
  hasBeefTag: boolean,
  isCattle: boolean,
  inspectionMinimumFee: ?LineItem,
  handleInspectionMinimumWaiving?: ?() => void,
  handleRowAction?: (
    $Shape<DisplayLineItem>,
    LineItemSlug,
  ) => { onClick: () => void },
};

const StyledTable = styled(Table)`
  margin-bottom: 1em;

  .ant-table-small .ant-table-thead > tr > th {
    padding: 1px 0 0 0.5em;
    text-align: left;
  }
  .ant-table-cell.ant-clickable-cell {
    text-align: right;
  }
  .ant-table-small .ant-table-summary > tr > td {
    padding: 1px 0.5em 0;
    text-align: right;
  }
`;

const StyledPills = styled(Tag)`
  display: inline-block;
  align-items: center;
  padding: 1px 4px;
  margin: 1px;
  font-size: 11px;

  > .ant-image {
    margin-right: 2px;
    vertical-align: middle;
  }
`;

const isNoBrand = (brand: BrandType): boolean => {
  return (
    brand && !!brand.position && brand.position.toLowerCase() === 'no brand'
  );
};

const hasBrand = (brands: Array<BrandType>): boolean => {
  const oneBrand = brands && brands.length === 1;
  if (oneBrand) {
    return !isNoBrand(brands[0]);
  }
  return brands && brands.length > 1;
};

const InspectionLineItems = ({
  displayLineItems,
  inspectionMinimumFee,
  subtotal,
  hasBeefTag,
  handleRowAction,
  handleInspectionMinimumWaiving,
  isCattle,
}: Props) => {
  const {
    totalBeefCouncilFee,
    totalPredatorControlFee,
    totalInspectionFee,
    totalBeefTagFee,
    totalHeads,
  } = subtotal || {};

  const renderPriceColumn = (values: LineItem) => {
    const isOverride: boolean = values.type === LINE_ITEM_TYPE.OVERRIDE;

    if (isOverride) {
      return (
        <Fragment>
          <span
            style={{
              textDecoration: 'line-through',
            }}
          >
            {formatMoney({ value: 0 })}
          </span>
          <span>&nbsp;&#x2192;&nbsp;</span>
          <span
            style={{
              textDecoration: values?.waived || false ? 'line-through' : 'none',
            }}
          >
            {formatMoney({ value: centsToDollars(values?.price || 0) })}
          </span>
        </Fragment>
      );
    }

    return (
      <span
        style={{
          textDecoration: values?.waived || false ? 'line-through' : 'none',
        }}
      >
        {formatMoney({ value: centsToDollars(values?.price || 0) })}
      </span>
    );
  };

  const COLUMNS = [
    {
      title: 'Details',
      render: ({
        quantity,
        brands,
        color,
        breed,
        gender,
        name,
        age,
        rfid,
        tagColor,
        tagNo,
        usda,
        notes,
      }: DisplayLineItem) => {
        const isElk = name && name.toLowerCase() === 'elk';
        const hasTag = tagColor || tagNo;
        const displayBrands = !isElk || (isElk && hasBrand(brands));
        const plural = !isElk && quantity > 1 ? 's' : '';

        let description = '';

        if (color) {
          description += ` ${color}`;
        }

        if (breed) {
          description += ` ${breed}`;
        }

        if (gender) {
          description += ` ${gender}${plural}`;
        }

        if (name) {
          description += `${isElk ? ` ${name}` : ''}`;
        }

        if (age) {
          description = ` ${age} year old ${description}`;
        }

        if (quantity) {
          description = `${
            quantity === 1 ? 'A' : `${quantity} -`
          } ${description}`;
        }

        if (hasTag) {
          description += ` with ${tagColor ? `a ${tagColor}` : ''} Tag${
            tagNo ? ` #${tagNo}` : ''
          }`;
        }

        if (isElk) {
          if (displayBrands) {
            description += ` and brand `;
          }
        } else {
          description += ` with brands: `;
        }

        /* eslint-disable react/no-danger */
        return (
          <div>
            {description}
            {notes && (
              <span>
                <em> ({notes}) </em>
              </span>
            )}
            {displayBrands &&
              brands.map(({ id, position, imageUrl }: $Shape<BrandType>) => {
                return (
                  <StyledPills key={id}>
                    <NetworkImage
                      src={imageUrl}
                      alt={position}
                      width={20}
                      height={20}
                    />
                    {getPositionLabel(position)}
                  </StyledPills>
                );
              })}
            {rfid && (
              <span>
                <strong> RFID</strong>: {rfid}
              </span>
            )}
            {usda && (
              <span>
                <strong> USDA</strong>: {usda}
              </span>
            )}
          </div>
        );
      },
    },
    ...(isCattle
      ? [
          {
            title: 'Beef Council',
            dataIndex: 'beefCouncil',
            key: 'beefCouncil',
            className: handleRowAction ? 'ant-clickable-cell' : '',
            width: 100,
            render: renderPriceColumn,
            display: 'none',
            onCell: (record: DisplayLineItem) => {
              if (handleRowAction) {
                return handleRowAction(record, 'beef_council_fee');
              }

              return {};
            },
          },
          {
            title: 'Predator Control',
            dataIndex: 'predatorControl',
            key: 'predatorControl',
            className: handleRowAction ? 'ant-clickable-cell' : '',
            width: 120,
            render: renderPriceColumn,
            onCell: (record: DisplayLineItem) => {
              if (handleRowAction) {
                return handleRowAction(record, 'predator_control_fee');
              }

              return {};
            },
          },
        ]
      : []),
    {
      title: 'Inspection',
      dataIndex: 'inspection',
      key: 'inspection',
      className: handleRowAction ? 'ant-clickable-cell' : '',
      width: 80,
      render: renderPriceColumn,
      onCell: (record: DisplayLineItem) => {
        if (handleRowAction) {
          return handleRowAction(record, 'inspection_fee');
        }

        return {};
      },
    },
  ];

  if (hasBeefTag) {
    COLUMNS.splice(1, 0, {
      title: 'Beef Tag',
      dataIndex: 'beefTag',
      key: 'beefTag',
      className: handleRowAction ? 'ant-clickable-cell' : '',
      width: 100,
      render: renderPriceColumn,
      onCell: (record: DisplayLineItem) => {
        if (handleRowAction) {
          return handleRowAction(record, 'beef_tag_fee');
        }

        return {};
      },
    });
  }

  return (
    <StyledTable
      rowKey="lineNumber"
      columns={COLUMNS}
      dataSource={displayLineItems}
      pagination={false}
      bordered
      size="small"
      summary={() => {
        return (
          <Fragment>
            {ENABLE_MINIMUM_SUMMARY_LINEITEM_WAIVING && inspectionMinimumFee && (
              <Table.Summary.Row onClick={handleInspectionMinimumWaiving}>
                <Table.Summary.Cell
                  colSpan={hasBeefTag ? 4 : 3}
                  className={`${
                    handleInspectionMinimumWaiving ? 'ant-clickable-cell' : ''
                  } ant-cell-minimum`}
                >
                  A{' '}
                  {formatMoney({
                    value: centsToDollars(totalInspectionFee),
                  })}{' '}
                  Minimum Inspection Fee covers the cost of the inspectors time
                  and transportation.
                </Table.Summary.Cell>
                <Table.Summary.Cell
                  className={
                    handleInspectionMinimumWaiving ? 'ant-clickable-cell' : ''
                  }
                >
                  <Typography.Text
                    style={{
                      textDecoration:
                        inspectionMinimumFee?.waived || false
                          ? 'line-through'
                          : 'none',
                    }}
                  >
                    {formatMoney({
                      value: centsToDollars(inspectionMinimumFee.price),
                    })}
                  </Typography.Text>
                </Table.Summary.Cell>
              </Table.Summary.Row>
            )}
            <SubTotal
              hasBeefTag={hasBeefTag}
              totalBeefCouncilFee={totalBeefCouncilFee || 0}
              totalPredatorControlFee={totalPredatorControlFee || 0}
              totalInspectionFee={totalInspectionFee || 0}
              totalBeefTagFee={totalBeefTagFee || 0}
              totalHeads={totalHeads || 0}
              inspectionFeeFootnote={
                !!inspectionMinimumFee && !inspectionMinimumFee?.waived
              }
              isCattle={isCattle}
              handleInspectionMinimumWaiving={
                ENABLE_MINIMUM_SUMMARY_LINEITEM_WAIVING
                  ? null
                  : handleInspectionMinimumWaiving
              }
            />
          </Fragment>
        );
      }}
    />
  );
};

InspectionLineItems.defaultProps = {
  /* eslint-disable-next-line */
  handleRowAction: (lineItem, slug) => ({
    onClick: () => {},
  }),
  handleInspectionMinimumWaiving: null,
};

export default InspectionLineItems;
