// @flow
import React, { type ElementRef, type Node } from 'react';
import { Select } from 'antd';
import type { State } from 'flow/resources';
import useResources from 'utils/hooks/useResources';
import selectFilterOptions from 'utils/form/selectFilterOptions';

const { Option } = Select;

type Props = {
  value: string,
  onChange: (string) => void,
  forwardedRef: ElementRef<*>,
  size?: 'small' | 'medium' | 'large',
  placeholder?: string,
};

const StatesFormField = ({ forwardedRef, ...rest }: Props) => {
  const states: Array<State> = useResources<State>('states');

  const sortedStates = [...states].sort(({ code }: State) => {
    if (code === 'UT') {
      return -1;
    }

    return 1;
  });

  return (
    <Select
      {...rest}
      ref={forwardedRef}
      virtual={false}
      showSearch
      filterOption={selectFilterOptions}
    >
      {sortedStates.map(({ id, name }: State) => (
        <Option value={id} key={id}>
          {name}
        </Option>
      ))}
    </Select>
  );
};

StatesFormField.defaultProps = {
  size: 'medium',
  placeholder: 'Choose State',
};

export default React.forwardRef<*, Node>((props: Props, ref: ElementRef<*>) => {
  return <StatesFormField forwardedRef={ref} {...props} />;
});
