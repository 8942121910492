// @flow
import styled from 'styled-components';

export const CardHeader = styled.div`
  margin-bottom: 1em;
  h4 {
    font-size: 0.718em;
  }
  h2 {
    font-size: 1.12em;
  }
  span {
    font-size: 1.05em;
    line-height: 1.4em;
  }
`;

export const Card = styled.div`
  display: inline-block;
  width: 3.3125in;
  height: 2.125in;
  border: 1px dashed #d8f4d4;
  padding: 0.125in;
  background-color: #f9fff8;
  font-size: 0.8em;
  border-radius: 20px;
  border-right-width: 0;
`;

export const CardBack = styled(Card)`
  font-size: 0.7em;
  border-left-style: dotted;
  border-right-width: 1px;
  p {
    text-align: center;
    margin-top: 0.75em;
  }
  strong {
    display: block;
    margin-bottom: 0.3em;
  }
  img {
    height: 1.13in;
    margin: 0;
  }
`;

export const CardInstructions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin: 0;
  h3 {
    margin: 0;
    line-height: 1em;
  }
  span {
    margin: 0 0 1em;
    font-style: italic;
    color: #999999;
  }
`;

export const Cards = styled.div`
  display: flex;
  justify-content: center;
`;

export const Contact = styled.span`
  font-size: 1.1em !important;
  margin-top: 0.5em;
  b {
    display: inline;
  }
`;

export const Photos = styled.div`
  display: flex;
  align-items: stretch;
  align-content: stretch;
  flex-direction: row;
  flex-wrap: nowrap;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
  overflow: hidden;
  div:last-child {
    text-align: right;
  }
`;

export const CardMarkings = styled.div`
  flex: 1;
  background-color: white;
  border-top-right-radius: 16px;
  display: flex;
  justify-content: center;
`;

export const CardPhoto = styled.div`
  flex: 1;
  img {
    height: 1.15in;
  }
`;

export const NoPhotosOrMarkings = styled.div`
  width: 100%;
  height: 1.1in;
  line-height: 1.1in;
  text-align: center;
  font-size: 1.3em;
  background-color: #ffffff;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
`;
