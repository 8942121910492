// @flow
import type { UserType } from 'flow/user';
import type { InspectionType, ServiceFee } from 'flow/resources';
import type { BrandType } from './brands';
import type {
  Person,
  PrimitivePerson,
  PrimitiveRequiredPerson,
  RequiredPerson,
} from './person';
import type { Payment, PaymentType, ServicePayment } from './payment';
import type { Animal } from './animal';

export const INSPECTION_UPDATE_HISTORY = {
  ID: 'id',
  USER: 'user',
  DATE: 'date',
  TYPE: 'type',
  TYPES: {
    RESOURCES: 'resources',
    INSPECTIONS: 'inspections',
    PRESALES: 'presales',
    TRAVEL_PERMITS: 'travel permits',
  },
};

export type ServiceInspectionType = {
  type: number,
  inspectionType: string,
};

type Dealer = {
  name: string,
  license: string,
};

export type AddressDetails = {
  street: ?string,
  city: ?string,
  state: ?string,
  county: ?string,
  zipCode: ?string,
};

export type InspectionStepOneFormType = {
  owner: RequiredPerson,
  buyer: Person,
  inspectionDate?: string,
  soldTo?: string,
  consignedTo?: string,
  destination?: string,
  carrier?: string,
  licenseNo?: string,
  registrationNumber?: string,
};

export type InspectionAnimal = {
  lineNumber: string,
  category: number,
  quantity: number,
  age?: string,
  rfid?: string,
  usda?: string,
  tagNo?: string,
  breed?: number,
  gender?: number,
  color?: number,
  owner?: string,
  tagColor?: number,
  brands: Array<BrandType>,
  notes: string,
};

export type WaiveFeeType = {
  lineNumber: string,
  type: 'beef_council_fee' | 'predator_control_fee' | 'inspection_fee',
  amount: number,
  reason: string,
};

export type LineItemType = 'other_fee' | 'service_fee' | 'override_fee';

export type LineItemSlug =
  | 'inspection_fee'
  | 'inspection_minimum_fee'
  | 'predator_control_fee'
  | 'beef_council_fee'
  | 'beef_tag_fee'
  | 'released_at_no_charge'
  | 'processing_fee';

export type WaivedLineItem = {
  index: number,
  slug: LineItemSlug | PaymentType,
  price: number,
  reason: ?string,
};

export type LineItem = {
  name: string,
  type: LineItemType,
  lineNumber: ?string,
  calculatedFee: ?ServiceFee,
  inspection?: number,
  waived: boolean,
  reason: ?string,
  quantity: number,
  price: number,
  slug: LineItemSlug,
  serviceFees: Array<number>,
};

export type DisplayLineItem = {
  name: string,
  quantity: number,
  gender: string,
  breed: string,
  color: string,
  notes: string,
  age?: string,
  rfid?: string,
  tagColor?: string,
  tagNo?: string,
  usda?: string,
  brands: Array<BrandType>,
  inspection: LineItem,
  predatorControl: LineItem,
  beefCouncil: LineItem,
  beefTag: LineItem,
  lineNumber: ?string,
};

export type InspectionStepTwoFormType = {
  types: Array<number>,
  premiseId: number,
  gpsCoordinate: string,
  animals: Array<InspectionAnimal>,
  category: number,
  otherType: string,
  dealer: {
    id?: number,
    name: string,
    license: string,
  },
  releasedAtNoChargeReason: string,
};

export type Inspection = {
  id: number,
  publicId: string,
  inspectionCode: string,
  owner: RequiredPerson,
  buyer: Person,
  inspectionDate: ?string,
  soldTo: ?string,
  consignedTo: ?string,
  destination: ?string,
  carrier: ?string,
  category: ?number,
  licenseNo: ?string,
  registrationNumber: ?string,
  types: Array<InspectionType>,
  otherType: ?string,
  premiseId: ?string,
  dealer: ?Dealer,
  gpsCoordinate: ?string,
  animals: Array<InspectionAnimal>,
  payment: Payment,
  user: UserType,
  total?: number,
  isVoid: boolean,
  voidReason: string,
  releasedAtNoCharge: boolean,
  releasedAtNoChargeReason: string,
  lineItems: Array<LineItem>,
  waivedFees: ?Array<*>,
  clientVersion: ?string,
  serviceVersion: ?string,
};

export type OfflineInspection = {
  ...Inspection,
  ...ServicePayment,
  id?: number,
  inspectionDate: string,
  animals: Array<Animal>,
};

export type ServiceInspection = {
  ...OfflineInspection,
  owner: PrimitiveRequiredPerson,
  buyer: PrimitivePerson,
  types: Array<ServiceInspectionType>,
};

export type DraftInspection = {
  id: number,
  owner: RequiredPerson,
  buyer: Person,
  inspectionDate: ?string,
  soldTo: ?string,
  consignedTo: ?string,
  destination: ?string,
  carrier: ?string,
  licenseNo: ?string,
  registrationNumber: ?string,
  types: Array<number>,
  premiseId: ?string,
  dealer: ?Dealer,
  gpsCoordinate: ?string,
  animals: Array<InspectionAnimal>,
  payment: Payment,
  checkNumber: ?string,
  releasedAtNoCharge: boolean,
  releasedAtNoChargeReason: string,
  lineItems: Array<LineItem>,
  waivedFees: ?Array<*>,
  category: number,
};

export type InspectionCertificateType = DraftInspection | Inspection;

export type SubtotalType = {
  totalBeefCouncilFee: number,
  totalPredatorControlFee: number,
  totalInspectionFee: number,
  totalBeefTagFee: number,
  totalProcessingFee: number,
  total: number,
  totalHeads: number,
};

export type RemoveLineItemFunction = ({
  lineNumber: ?string,
  slug: string,
}) => void;
