// @flow
import React, { Fragment } from 'react';
import { Form, Typography, Space } from 'antd';
import Photo from './components/Photo';

type Props = {
  onRemoveAt: (number) => void,
};

const PhotoAttachments = ({ onRemoveAt }: Props) => {
  const handleClick = (index: number) => {
    onRemoveAt(index);
  };

  return (
    <Form.Item
      shouldUpdate={(prevValues, curValues) => {
        return (
          (prevValues.photos || []).length !== (curValues.photos || []).length
        );
      }}
      noStyle
    >
      {(form) => {
        const photos = form.getFieldValue('photos');

        if (photos.length > 0) {
          return (
            <Fragment>
              <Typography.Title level={4}>Attachments</Typography.Title>
              <Space style={{ marginBottom: 20 }}>
                {photos.map((image: string, index: number) => {
                  return (
                    <Photo
                      key={index}
                      index={index}
                      src={image}
                      alt=""
                      onClick={handleClick}
                    />
                  );
                })}
              </Space>
            </Fragment>
          );
        }

        return null;
      }}
    </Form.Item>
  );
};

export default PhotoAttachments;
