// @flow
import React from 'react';
import { useSelector, useDispatch, type Dispatch } from 'react-redux';
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useHistory,
  type History,
  type Location,
} from 'react-router-dom';
import { Tabs, Button, Space } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import Page from 'components/Page';
import PreSaleInspections from 'features/PreSaleInspections';
import OfflinePreSaleInspections from 'features/OfflinePreSaleInspections';
import DateRangeFilter from 'components/DateRangeFilter';
import SearchFilter from 'components/SearchFilter';
import PaymentTypeFilter from 'components/PaymentTypeFilter';
import { syncOfflineData } from 'features/SyncOfflineMenuItem/syncOfflineSlice';
import { DISPLAY_SYNC_RESULTS } from 'features/SyncOfflineMenuItem';
import usePaymentTypeQuery from 'utils/hooks/usePaymentTypeQuery';

const { TabPane } = Tabs;

const HorsePreSaleListingsPage = () => {
  const { pathname }: Location = useLocation();

  const history: History = useHistory();

  const dispatch: Dispatch = useDispatch();

  const offlinePreSaleInspectionsLoading: boolean = useSelector(
    (state) => state.offlinePreSaleInspections.loading,
  );

  const [paymentType] = usePaymentTypeQuery();

  const paymentStatus = paymentType || 'all';

  const handleTabChange = (key: string) => {
    history.push(key);
  };

  const handleSync = () => {
    dispatch(syncOfflineData(DISPLAY_SYNC_RESULTS));
  };

  return (
    <Page>
      <Tabs activeKey={pathname} onChange={handleTabChange}>
        <TabPane tab="Permits" key="/horse-presale-listing" />
        <TabPane tab="Offline" key="/horse-presale-listing/offline" />
      </Tabs>

      <Space align="center" style={{ marginBottom: 10 }}>
        <SearchFilter />

        <DateRangeFilter />

        <PaymentTypeFilter />

        <Route exact path="/horse-presale-listing/offline">
          <Button
            type="primary"
            onClick={handleSync}
            loading={offlinePreSaleInspectionsLoading}
            id="syncPresaleListings"
          >
            <SyncOutlined />
            Sync
          </Button>
        </Route>
      </Space>

      <Switch>
        <Route exact path="/horse-presale-listing">
          <PreSaleInspections paymentStatus={paymentStatus} />
        </Route>

        <Route exact path="/horse-presale-listing/offline">
          <OfflinePreSaleInspections paymentStatus={paymentStatus} />
        </Route>

        <Redirect to="/horse-presale-listing" />
      </Switch>
    </Page>
  );
};

export default HorsePreSaleListingsPage;
