// @flow
import Dexie, { Table } from 'dexie';
import { ApplyCaseMiddleware } from 'axios-case-converter';
import { INSPECTION_UPDATE_HISTORY } from 'flow/inspection';
import type { Filters } from 'flow/search';
import { getDateParams, stringToTimestamp } from 'utils/dates';
import storeResourceData from 'utils/storeResourceData';
import { syncIfNeeded, tableHasNoRecords } from 'service/sync';
import { filterInspections } from 'service/search/util';
import { monthUtil } from '@acclaim/udaf-kit';

const {
  TYPES: { TRAVEL_PERMITS },
} = INSPECTION_UPDATE_HISTORY;

const fetchTravelPermits = async (
  userId: number,
  online: boolean,
  db: Dexie,
  inspectionClient: ApplyCaseMiddleware,
): Promise<*> => {
  const { travelPermit: travelPermitTable } = db;
  const explicitReload = await tableHasNoRecords(userId, travelPermitTable);
  return syncIfNeeded(
    userId,
    TRAVEL_PERMITS,
    online,
    explicitReload,
    () => Promise.resolve(travelPermitTable),
    (everythingAfterDate: ?string, truncateData: boolean) => {
      const params = { ...getDateParams(everythingAfterDate), userId };
      params.monthsData = monthUtil.getmonthToken();
      return inspectionClient()
        .get('/travel_permit/', { params })
        .then(async ({ data }) => {
          const permits = data.map((permit) => ({
            ...permit,
            permitDate: stringToTimestamp(permit.permitDate),
          }));
          await storeResourceData(db, 'travelPermit', permits, truncateData);
          return travelPermitTable;
        });
    },
    true,
  );
};

export const filterTravelPermits = (filters: ?Filters, slug: string) => {
  const { from, to, limit, offset, query, paymentStatus } = filters || {};
  return (permits: Table): Promise<*> =>
    filterInspections({
      range: { from, to, key: 'permitDate' },
      query,
      limit,
      offset,
      slug,
      table: permits,
      paymentStatus: paymentStatus || 'all',
    });
};

export const fetchAndFilterTravelPermits = async (
  userId: number,
  filters: ?Filters,
  slug: string,
  online: boolean,
  db: Dexie,
  inspectionClient: ApplyCaseMiddleware,
): Promise<*> => {
  return fetchTravelPermits(userId, online, db, inspectionClient).then(
    filterTravelPermits(filters, slug),
  );
};

export default fetchTravelPermits;
