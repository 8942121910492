// @flow
import React from 'react';
import { Row, Col, Skeleton } from 'antd';
import Page from 'components/Page';
import Content from 'components/Content';

const FormSkeleton = () => {
  return (
    <Page>
      <Content>
        <Row justify="space-between" gutter={[10, 14]}>
          <Col lg={6} md={8}>
            <Skeleton paragraph={{ rows: 0 }} style={{ margin: 0 }} active />
            <Skeleton.Input style={{ width: 235 }} active size="small" />
          </Col>
          <Col lg={6} md={8}>
            <Skeleton paragraph={{ rows: 0 }} style={{ margin: 0 }} active />
            <Skeleton.Input style={{ width: 235 }} active size="small" />
          </Col>
          <Col lg={6} md={8}>
            <Skeleton paragraph={{ rows: 0 }} style={{ margin: 0 }} active />
            <Skeleton.Input style={{ width: 235 }} active size="small" />
          </Col>
          <Col lg={6} md={8}>
            <Skeleton paragraph={{ rows: 0 }} style={{ margin: 0 }} active />
            <Skeleton.Input style={{ width: 235 }} active size="small" />
          </Col>
          <Col lg={6} md={8}>
            <Skeleton paragraph={{ rows: 0 }} style={{ margin: 0 }} active />
            <Skeleton.Input style={{ width: 235 }} active size="small" />
          </Col>
          <Col lg={6} md={8}>
            <Skeleton paragraph={{ rows: 0 }} style={{ margin: 0 }} active />
            <Skeleton.Input style={{ width: 235 }} active size="small" />
          </Col>
          <Col lg={6} md={8}>
            <Skeleton paragraph={{ rows: 0 }} style={{ margin: 0 }} active />
            <Skeleton.Input style={{ width: 235 }} active size="small" />
          </Col>
          <Col lg={6} md={8}>
            <Skeleton paragraph={{ rows: 0 }} style={{ margin: 0 }} active />
            <Skeleton.Input style={{ width: 235 }} active size="small" />
          </Col>
          <Col lg={6} md={8}>
            <Skeleton paragraph={{ rows: 0 }} style={{ margin: 0 }} active />
            <Skeleton.Input style={{ width: 235 }} active size="small" />
          </Col>
          <Col lg={6} md={8}>
            <Skeleton paragraph={{ rows: 0 }} style={{ margin: 0 }} active />
            <Skeleton.Input style={{ width: 235 }} active size="small" />
          </Col>
          <Col lg={6} md={8}>
            <Skeleton paragraph={{ rows: 0 }} style={{ margin: 0 }} active />
            <Skeleton.Input style={{ width: 235 }} active size="small" />
          </Col>
          <Col lg={6} md={8}>
            <Skeleton paragraph={{ rows: 0 }} style={{ margin: 0 }} active />
            <Skeleton.Input style={{ width: 235 }} active size="small" />
          </Col>
          <Col lg={6} md={8}>
            <Skeleton paragraph={{ rows: 0 }} style={{ margin: 0 }} active />
            <Skeleton.Input style={{ width: 235 }} active size="small" />
          </Col>
          <Col lg={6} md={8}>
            <Skeleton paragraph={{ rows: 0 }} style={{ margin: 0 }} active />
            <Skeleton.Input style={{ width: 235 }} active size="small" />
          </Col>
          <Col lg={6} md={8}>
            <Skeleton paragraph={{ rows: 0 }} style={{ margin: 0 }} active />
            <Skeleton.Input style={{ width: 235 }} active size="small" />
          </Col>
          <Col lg={6} md={8}>
            <Skeleton paragraph={{ rows: 0 }} style={{ margin: 0 }} active />
            <Skeleton.Input style={{ width: 235 }} active size="small" />
          </Col>
          <Col lg={6} md={8}>
            <Skeleton paragraph={{ rows: 0 }} style={{ margin: 0 }} active />
            <Skeleton.Input style={{ width: 235 }} active size="small" />
          </Col>
          <Col lg={6} md={8}>
            <Skeleton paragraph={{ rows: 0 }} style={{ margin: 0 }} active />
            <Skeleton.Input style={{ width: 235 }} active size="small" />
          </Col>
          <Col lg={6} md={8}>
            <Skeleton paragraph={{ rows: 0 }} style={{ margin: 0 }} active />
            <Skeleton.Input style={{ width: 235 }} active size="small" />
          </Col>
          <Col lg={6} md={8}>
            <Skeleton paragraph={{ rows: 0 }} style={{ margin: 0 }} active />
            <Skeleton.Input style={{ width: 235 }} active size="small" />
          </Col>
          <Col lg={6} md={8}>
            <Skeleton paragraph={{ rows: 0 }} style={{ margin: 0 }} active />
            <Skeleton.Input style={{ width: 235 }} active size="small" />
          </Col>
          <Col lg={6} md={8}>
            <Skeleton paragraph={{ rows: 0 }} style={{ margin: 0 }} active />
            <Skeleton.Input style={{ width: 235 }} active size="small" />
          </Col>
          <Col lg={6} md={8}>
            <Skeleton paragraph={{ rows: 0 }} style={{ margin: 0 }} active />
            <Skeleton.Input style={{ width: 235 }} active size="small" />
          </Col>
          <Col lg={6} md={8}>
            <Skeleton paragraph={{ rows: 0 }} style={{ margin: 0 }} active />
            <Skeleton.Input style={{ width: 235 }} active size="small" />
          </Col>
        </Row>
      </Content>
    </Page>
  );
};

export default FormSkeleton;
