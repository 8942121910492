// @flow
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import type { ReduxStore } from 'flow/redux';
import type { Filters } from 'flow/search';
import { showErrorMessage } from 'utils/showMessage';
import { YEARLY_TRAVEL_PERMIT } from 'selectors/resources';
import { fetchAndFilterTravelPermits } from 'service/travelPermits';

const adapter = createEntityAdapter();

const initialState = adapter.getInitialState({
  loading: false,
  total: 0,
});

export const fetchYearlyTravelPermits = createAsyncThunk(
  'yearlyTravelPermits/fetchYearlyTravelPermits',
  async (filters: ?Filters, { extra, rejectWithValue, getState }) => {
    const { inspectionClient, inspectionsDb: db, createError } = extra;
    const {
      me,
      initializer: { online },
    } = getState();
    const userId = me.data?.id;

    try {
      return fetchAndFilterTravelPermits(
        userId,
        filters,
        YEARLY_TRAVEL_PERMIT,
        online,
        db,
        inspectionClient,
      );
    } catch (err) {
      createError({
        payload: '',
        endpoint: 'OFFLINE: yearlyTravelPermits/fetchYearlyTravelPermits',
        errorStack: err?.stack,
      });

      showErrorMessage(err?.message);

      return rejectWithValue(err);
    }
  },
);

const yearlyTravelPermitsSlice = createSlice({
  name: 'yearlyTravelPermits',
  initialState,
  reducers: {
    clearAll: (state) => {
      state.total = 0;
      adapter.removeAll(state);
    },
  },
  extraReducers: {
    [fetchYearlyTravelPermits.pending]: (state) => {
      state.loading = true;
    },
    [fetchYearlyTravelPermits.fulfilled]: (state, { payload }) => {
      const { total, result } = payload;

      state.loading = false;
      state.total = total;
      adapter.setAll(state, result);
    },
    [fetchYearlyTravelPermits.rejected]: (state) => {
      state.loading = false;
      state.total = 0;
      adapter.removeAll(state);
    },
  },
});

export const { selectAll: selectYearlyTravelPermits } = adapter.getSelectors(
  (state: ReduxStore) => {
    return state.yearlyTravelPermits;
  },
);

export const { clearAll } = yearlyTravelPermitsSlice.actions;

export default yearlyTravelPermitsSlice.reducer;
