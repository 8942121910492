// @flow
export default function isValidZipCode(value: string): boolean {
  return /^[0-9]{5}(?:-[0-9]{4})?$/.test(value);
}

export const validateZipCode = () => ({
  validator: (_: *, value: string) => {
    if (isValidZipCode(value) || value === '') {
      return Promise.resolve();
    }

    if (value.length < 5) {
      return Promise.reject(new Error('Enter a valid Zip code. i.e. 84020'));
    }

    return Promise.reject(new Error('Enter a valid Zip code. i.e. 84020-0001'));
  },
});
