// @flow
import styled from 'styled-components';

export const StyledPath = styled.path`
  fill: #cccccc;
  stroke: #000000;
  stroke-width: 2;
`;

export const StyledPart = styled.g`
  pointer-events: all;

  > path {
    fill: #ffffff;
    pointer-events: all;
    transition: all 0.1s;
    cursor: pointer;
  }

  > image {
    display: none;
  }

  ${(props) =>
    props.hovered &&
    `
    > path {
      fill: rgba(192, 198, 75, 0.6);
    }
  `}

  ${(props) =>
    props.active &&
    `
    > path {
      fill: rgba(192, 198, 75, 0.6);
    }

    > image {
      display: block;
    }
  `}

  ${(props) =>
    props.disabled &&
    `
    pointer-events: all;
    cursor: not-allowed;

    > path {
      fill: #717171;
    }

    > image {
      display: none;
    }
  `}
`;

export const StyledLabel = styled.text`
  fontfamily: #827f7f;
  fontsize: 10px;
`;
