// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import { Form, Checkbox, Row, Col, Input } from 'antd';
import isEqual from 'lodash/isEqual';
import type { ReduxStore } from 'flow/redux';
import type { InspectionType, ExcludedInspection } from 'flow/resources';
import DealerLicenseFormField from 'components/form/DealerLicenseFormField';
import GpsFormField from 'components/form/GpsFormField';
import { selectExcludedInspectionTypes } from 'selectors/resources';
import { INSPECTION_TYPE_STRICT_DICTIONARY } from 'constants/inspection';

type Props = {
  category: ?number,
};

const InspectedForForm = ({ category }: Props) => {
  const excludedInspectionTypes = useSelector(selectExcludedInspectionTypes);

  const inspectionTypes: Array<InspectionType> = useSelector(
    ({ resources }: ReduxStore) => resources.inspectionTypes,
  );

  const byExcludedCategory = (exclude: ExcludedInspection) =>
    exclude.categoryId === category;

  const byAnimalCategory = (type: InspectionType) =>
    (type.categories || []).includes(category);

  const byApprovedForBrandInspectionForm = (type: InspectionType) => {
    const typesToExclude = excludedInspectionTypes.find(byExcludedCategory);
    return !(typesToExclude && typesToExclude.exclude.includes(type.slug));
  };

  const typesByAnimal = inspectionTypes
    .filter(byAnimalCategory)
    .filter(byApprovedForBrandInspectionForm);

  return (
    <Row gutter={12}>
      {typesByAnimal.length > 0 && (
        <Col span={24}>
          <Form.Item
            shouldUpdate={(prevValues, curValues) =>
              !isEqual(prevValues.types, curValues.types)
            }
            noStyle
          >
            {(form) => {
              const typeValues = form.getFieldValue('types') || [];

              const disabledInspectionTypes: Array<string> = typeValues
                .map<string>((typeId: number) => {
                  const type =
                    typesByAnimal.find((t: InspectionType) => {
                      return t.id === typeId;
                    }) || {};

                  return INSPECTION_TYPE_STRICT_DICTIONARY[type?.slug] || [];
                })
                .flat();

              return (
                <Form.Item
                  name="types"
                  rules={[
                    {
                      required: true,
                      message: 'Please select at least one',
                    },
                  ]}
                  normalize={(
                    values: Array<number>,
                    prevValues: Array<number>,
                  ) => {
                    const errors: Array<string> = prevValues
                      .map<string>((typeId: number) => {
                        const type =
                          typesByAnimal.find((t: InspectionType) => {
                            return t.id === typeId;
                          }) || {};

                        const strictType =
                          INSPECTION_TYPE_STRICT_DICTIONARY[type?.slug];

                        if (strictType) {
                          const newTypeId = values.filter((value: number) => {
                            return !prevValues.includes(value);
                          })[0];

                          if (newTypeId) {
                            const newType =
                              typesByAnimal.find((t: InspectionType) => {
                                return t.id === newTypeId;
                              }) || {};

                            if (strictType.includes(newType?.slug)) {
                              return newType?.type;
                            }
                          }
                        }

                        return '';
                      })
                      .filter((error: string) => !!error);

                    const hasError = errors.length > 0;

                    if (hasError) {
                      return prevValues;
                    }

                    return values;
                  }}
                >
                  <Checkbox.Group style={{ width: '100%' }}>
                    <Row gutter={16} align="middle">
                      {typesByAnimal.map(
                        ({ id, type, slug }: $Shape<InspectionType>) => (
                          <Col lg={6} md={8} key={id}>
                            <Checkbox
                              value={id}
                              style={{
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                width: '100%',
                              }}
                              disabled={disabledInspectionTypes.includes(slug)}
                            >
                              {type}
                            </Checkbox>
                            {slug === 'other' &&
                              form.getFieldValue('types').includes(id) && (
                                <Form.Item
                                  name="otherType"
                                  rules={[
                                    {
                                      required: true,
                                      message: 'Other type is required',
                                    },
                                  ]}
                                  style={{ marginBottom: 0 }}
                                >
                                  <Input placeholder="Other Type" />
                                </Form.Item>
                              )}
                          </Col>
                        ),
                      )}
                    </Row>
                  </Checkbox.Group>
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>
      )}

      <Col lg={6} md={7}>
        <Form.Item label="Premise ID" name="premiseId">
          <Input />
        </Form.Item>
      </Col>
      <Col lg={6} md={7}>
        <Form.Item label="GPS Coordinate" name="gpsCoordinate">
          <GpsFormField />
        </Form.Item>
      </Col>
      <Col lg={12} md={10}>
        <Form.Item label="Dealers License #" name="dealer">
          <DealerLicenseFormField />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default InspectedForForm;
