// @flow
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import groupBy from 'lodash/groupBy';
import { v4 as uuidv4 } from 'uuid';
import {
  showSuccessMessage,
  showErrorMessage,
  showWarningMessage,
} from 'utils/showMessage';
import moment from 'moment';
import { BRAND_UPDATE_HISTORY, type BrandType } from 'flow/brands';
import search from 'service/search';
import b64toBlob from 'utils/b64toBlob';
import { Table } from 'dexie';
import { getHistoryDateString } from 'utils/dates';

const getRequestSync = async (
  history: Table,
  thisSyncDate: string,
): Promise<?string> => {
  const lastSync = await history.orderBy(BRAND_UPDATE_HISTORY.ID).last();

  const lastSyncDate = lastSync && lastSync.date;
  const syncHasNeverHappened = !lastSyncDate;
  const checkForDeltas = lastSyncDate <= thisSyncDate;

  if (syncHasNeverHappened || checkForDeltas) {
    const sinceLastSyncParam = syncHasNeverHappened
      ? ''
      : `?lastSyncDate=${lastSyncDate}`;
    return Promise.resolve(`/inspections/brands${sinceLastSyncParam}`);
  }
  return Promise.resolve(null);
};

export const fetchBrands = createAsyncThunk(
  'brands/fetchBrands',
  async (_, { extra, rejectWithValue }) => {
    const { createAuthClient, brandsDb: db } = extra;
    const client = createAuthClient();

    try {
      const thisSyncDate = getHistoryDateString();
      const requestSync = await getRequestSync(db.history, thisSyncDate);
      if (requestSync) {
        const { data: brands } = await client.get(requestSync);

        await db.transaction('rw!', db.brands, () => {
          /* eslint-disable no-await-in-loop, no-restricted-syntax */
          // note, this avoids the creation of possibly >15k promises with takes about 7 minutes as compared to 4 seconds
          for (const brand of brands) {
            db.brands.put(brand);
          }
        });
        await db.history.add({ date: thisSyncDate });

        showSuccessMessage('Brands synced successfully');
      } else {
        showSuccessMessage('Brands data is up to date');
      }

      return true;
    } catch (err) {
      showErrorMessage(
        err?.response?.data?.message || 'Sync failed. Please try again.',
      );

      return rejectWithValue(err);
    }
  },
);

export const fetchBrandsCacheVersion = createAsyncThunk(
  'brands/fetchBrandsCacheVersion',
  async (_, { extra, rejectWithValue, dispatch }) => {
    const { brandsDb: db } = extra;

    try {
      const lastSyncDate = await db.history
        .orderBy(BRAND_UPDATE_HISTORY.ID)
        .last();

      if (!lastSyncDate) {
        dispatch(fetchBrands());
      } else if (lastSyncDate < getHistoryDateString()) {
        return { syncAvailable: true };
      }

      return { syncAvailable: false };
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

export const searchBrands = createAsyncThunk(
  'brands/searchBrands',
  async (
    {
      query,
      page = 0,
      limit = 20,
    }: { query: string, page?: number, limit?: number },
    { extra, rejectWithValue },
  ) => {
    const { brandsDb: db } = extra;
    try {
      const { result, count } = await search(db, query, {
        offset: page * limit,
        limit,
      });

      return { result, query, page, count };
    } catch (err) {
      showWarningMessage('No results found.');

      return rejectWithValue(err);
    }
  },
);

export const createBrands = createAsyncThunk(
  'brands/createBrands',
  async (
    {
      brands,
      entityId,
      date,
    }: {
      brands: Array<BrandType>,
      entityId: number,
      date: string,
    },
    { extra, rejectWithValue, getState },
  ) => {
    if (brands.length === 0) return [];

    const { me } = getState();

    const { createAuthClient } = extra;

    const { data: user } = me;

    try {
      const brandsClient = createAuthClient('multipart/form-data');

      const grouped = groupBy(
        brands.filter((b) => !!b.ref),
        (b) => {
          return b.ref;
        },
      );

      const requests = Object.keys(grouped).map((key: string) => {
        const values = grouped[key] || [];

        const formData = new FormData();

        values.forEach((value) => {
          formData.append('twoLetterPosition', value.position);
        });

        formData.append('sellerId', user.id);
        formData.append('dateInspected', moment(date).format());

        const blob = b64toBlob(
          (values[0]?.imageUrl || '').replace('data:image/png;base64,', ''),
          'image/png',
        );

        const file = new File([blob], uuidv4(), {
          type: 'image/png',
          lastModified: Date.now(),
        });

        formData.append('image', file);

        return brandsClient.post(
          `/inspections/${entityId}/brandimage/${uuidv4()}`,
          formData,
        );
      });

      return Promise.all(requests);
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

const brandsSlice = createSlice({
  name: 'brands',
  initialState: {
    data: [],
    query: '',
    page: 0,
    count: 0,
    showSyncNotification: false,
    syncing: false,
    loading: false,
  },
  reducers: {
    closeSyncNotification: (state) => {
      state.showSyncNotification = false;
    },
    clearBrands: (state) => {
      state.data = [];
      state.query = '';
      state.page = 0;
      state.count = 0;
      state.loading = false;
    },
  },
  extraReducers: {
    [searchBrands.pending]: (state) => {
      state.loading = true;
    },
    [searchBrands.fulfilled]: (state, action) => {
      const { result, query, page, count } = action.payload;

      state.loading = false;
      state.data = page === 0 ? result : [...state.data, ...result];
      state.query = query;
      state.count = count;
      state.page = page;
    },
    [searchBrands.rejected]: (state) => {
      state.loading = false;
      state.data = [];
    },
    [fetchBrandsCacheVersion.fulfilled]: (state, action) => {
      state.showSyncNotification = action.payload.syncAvailable;
    },
    [fetchBrands.pending]: (state) => {
      state.syncing = true;
    },
    [fetchBrands.fulfilled]: (state) => {
      state.syncing = false;
    },
    [fetchBrands.rejected]: (state) => {
      state.syncing = false;
    },
  },
});

export const { clearBrands, closeSyncNotification } = brandsSlice.actions;

export default brandsSlice.reducer;
