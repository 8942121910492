// @flow
import React from 'react';
import { useDispatch, useSelector, type Dispatch } from 'react-redux';
import { useHistory, type History } from 'react-router-dom';
import { useEffectOnce, useUpdateEffect } from 'react-use';
import { Table, Typography, Tag } from 'antd';
import type { ReduxStore } from 'flow/redux';
import type { PreSaleInspectionType } from 'flow/presale';
import useQuery from 'utils/hooks/useQuery';
import {
  getPaymentTypeColumn,
  // getPaymentStatusColumn,
} from 'utils/paymentColumn';
import EmptyOfflineMessage from 'components/EmptyOfflineMessage';
import {
  fetchOfflinePreSaleInspections,
  selectOfflinePreSaleInspections,
  clearAll,
} from './offlinePreSaleInspectionsSlice';

const COLUMNS = [
  {
    title: 'ID',
    dataIndex: 'presaleCode',
    key: 'presaleCode',
    defaultSortOrder: 'descend',
    sorter: (a, b) => { return a.presaleCode.localeCompare(b.presaleCode)},
    render: (id: string) => (
      <Typography.Text>
        <Tag color="warning">OFFLINE</Tag>
        <strong>{id}</strong>
      </Typography.Text>
    ),
  },
  {
    title: 'Owner',
    dataIndex: ['owner', 'name'],
    key: 'owner',
  },
  {
    title: 'Horse Name',
    dataIndex: 'registeredName',
    key: 'registeredName',
  },
  {
    title: 'City',
    dataIndex: ['owner', 'city'],
    key: 'city',
  },
  getPaymentTypeColumn(),
  // getPaymentStatusColumn(),
  {
    title: 'Date',
    dataIndex: 'presaleDate',
    key: 'presaleDate',
  },
];

type Props = {
  paymentStatus: string,
};

const OfflinePreSaleListings = ({ paymentStatus }: Props) => {
  const dispatch: Dispatch = useDispatch();

  const history: History = useHistory();

  const data: $Shape<PreSaleInspectionType> = useSelector(
    selectOfflinePreSaleInspections,
  );

  const loading: boolean = useSelector(
    ({ offlinePreSaleInspections: { loading, syncing } }: ReduxStore) =>
      loading || syncing,
  );

  const total: number = useSelector(
    (state: ReduxStore) => state.offlinePreSaleInspections.total,
  );

  const syncing: boolean = useSelector(
    (state: ReduxStore) => state.offlinePreSaleInspections.syncing,
  );
  const { from, to, query, page, limit, offset, patchQuery } = useQuery();

  const fetchData = () => {
    dispatch(
      fetchOfflinePreSaleInspections({
        limit,
        offset,
        from,
        to,
        query,
        paymentStatus,
      }),
    );
  };

  const handleChange = ({ current }) => {
    patchQuery('page', current);
  };

  useEffectOnce(() => {
    fetchData();

    return () => {
      dispatch(clearAll());
    };
  });

  useUpdateEffect(() => {
    fetchData();
  }, [limit, offset, from, to]);

  useUpdateEffect(() => {
    if (page === 1) {
      fetchData();
    } else {
      patchQuery('page', 1);
    }
  }, [query, paymentStatus, syncing]);

  return (
    <Table
      rowKey="publicId"
      columns={COLUMNS}
      dataSource={data}
      size="small"
      bordered
      rowClassName="ant-row-clickable"
      loading={loading}
      pagination={{
        current: page,
        pageSize: limit,
        total,
        showSizeChanger: false,
      }}
      locale={{
        emptyText: (
          <EmptyOfflineMessage
            seeTabLabel="Permits"
            seeTabUrl="/horse-presale-listing"
          />
        ),
      }}
      onChange={handleChange}
      onRow={(record: $Shape<PreSaleInspectionType>) => {
        return {
          onClick: () => {
            history.push(`/horse-presale-listing/${record.publicId}`);
          },
        };
      }}
    />
  );
};

OfflinePreSaleListings.defaultProps = {
  paymentStatus: 'all',
};

export default OfflinePreSaleListings;
