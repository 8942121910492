// @flow
import React, { type Node } from 'react';
import { Image, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import fallbackPlaceholder from 'assets/img-fallback-placeholder.png';

const antIcon = <LoadingOutlined style={{ fontSize: 14 }} spin />;

type Props = {
  src: string,
  alt?: string,
  height?: number | string,
  width?: number | string,
  placeholder?: Node,
  fallback?: string,
  preview?: boolean,
};

const NetworkImage = (props: Props) => {
  return <Image {...props} />;
};

NetworkImage.defaultProps = {
  placeholder: <Spin indicator={antIcon} />,
  fallback: fallbackPlaceholder,
  height: 'auto',
  width: 'auto',
  alt: '',
  preview: false,
};

export default NetworkImage;
