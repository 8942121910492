// @flow
import React, { Fragment } from 'react';
import {
  Row,
  Col,
  Form,
  Input,
  Typography,
  Radio,
  type WrappedFormUtils,
} from 'antd';
import type { InspectionType, Breed } from 'flow/resources';
import BrandsFormField from 'components/form/BrandsFormField';
import HorseMarkingsFormField from 'components/form/HorseMarkingsFormField';
import CameraFormField from 'components/form/CameraFormField';
import AnimalBreedsFormField from 'components/form/AnimalBreedsFormField';
import AnimalColorsFormField from 'components/form/AnimalColorsFormField';
import AnimalGendersFormField from 'components/form/AnimalGendersFormField';
import PhotoAttachments from 'components/PhotoAttachments';
import normalizeFloatInput from 'utils/form/normalizeFloatInput';

type Props = {
  form: WrappedFormUtils,
  category: number,
  types: Array<InspectionType>,
};

const HorseDetails = ({ form, category, types }: Props) => {
  const handleRemovePhoto = (index: number) => {
    const photos = [...form.getFieldValue('photos')];

    photos.splice(index, 1);

    form.setFieldsValue({ photos });
  };

  return (
    <Fragment>
      <Form.Item
        label="Travel Permit Type"
        name="type"
        rules={[
          {
            required: true,
            message: 'Travel Permit Type is required',
          },
        ]}
      >
        <Radio.Group buttonStyle="solid">
          {types.map(({ id, type }: InspectionType) => (
            <Radio.Button value={id} key={id}>
              {type}
            </Radio.Button>
          ))}
        </Radio.Group>
      </Form.Item>
      <Row gutter={16}>
        <Col lg={6} md={8}>
          <Form.Item label="Breed" name={['animal', 'breed']}>
            <AnimalBreedsFormField
              category={category}
              sort={({ breed }: Breed) => {
                if (
                  ['quarter', 'grade', 'thoroughbred'].includes(
                    breed.toLowerCase(),
                  )
                ) {
                  return -1;
                }

                return 1;
              }}
            />
          </Form.Item>
        </Col>
        <Col lg={6} md={8}>
          <Form.Item
            label="Year Born"
            name="year"
            rules={[
              {
                required: true,
                message: 'Year Born is required',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col lg={6} md={8}>
          <Form.Item label="Color" name={['animal', 'color']}>
            <AnimalColorsFormField category={category} />
          </Form.Item>
        </Col>
        <Col lg={6} md={8}>
          <Form.Item
            label="Approximate Height (hands)"
            name="height"
            rules={[
              {
                required: true,
                message: 'Approximate Height is required',
              },
            ]}
            normalize={normalizeFloatInput}
          >
            <Input
              onBlur={(ev) => {
                if (ev?.target?.value) {
                  form.setFieldsValue({ height: parseFloat(ev.target.value) });
                }
              }}
            />
          </Form.Item>
        </Col>
        <Col lg={6} md={8}>
          <Form.Item
            label="Name"
            name="registeredName"
            rules={[
              {
                required: true,
                message: 'Name is required',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col lg={6} md={8}>
          <Form.Item label="Registration #" name="registrationNo">
            <Input />
          </Form.Item>
        </Col>
        <Col lg={6} md={8}>
          <Form.Item
            label="Gender"
            name={['animal', 'gender']}
            rules={[
              {
                required: true,
                message: 'Gender is required',
              },
            ]}
          >
            <AnimalGendersFormField category={category} />
          </Form.Item>
        </Col>
        <Col lg={6} md={8}>
          <Form.Item
            label="Brand"
            name={['animal', 'brands']}
            rules={[
              {
                required: true,
                message: 'Brand is required',
              },
            ]}
          >
            <BrandsFormField />
          </Form.Item>
        </Col>
        <Col lg={6} md={8}>
          <Form.Item label="Tattoo" name="tattoo">
            <Input />
          </Form.Item>
        </Col>
        <Col lg={6} md={8}>
          <Form.Item label="Animal Photo" name="photos">
            <CameraFormField />
          </Form.Item>
        </Col>
        <Col lg={6} md={8}>
          <Form.Item label="Draw Mark" name="markings">
            <HorseMarkingsFormField />
          </Form.Item>
        </Col>
      </Row>
      <PhotoAttachments onRemoveAt={handleRemovePhoto} />
      <Row gutter={16}>
        <Col span={12}>
          <Typography.Text underline>
            This permit void when animal changes owners,
          </Typography.Text>
          <Typography.Paragraph>
            This permit is issued in compliance with section 4-24-23 UCA 1985.
            This permit must accompany the horse at all times when in transit in
            compliance with Utah laws governing inter-state movement, and proof
            of ownership.
          </Typography.Paragraph>
        </Col>
      </Row>
    </Fragment>
  );
};

export default HorseDetails;
