// @flow
import React, { useState } from 'react';
import styled from 'styled-components';
import { useMount } from 'react-use';
import { Button, Col, Modal, Row } from 'antd';
import { ENVIRONMENT } from 'service/urls';
import ChangeLog from './ChangeLog';

const StyledModal = styled(Modal)`
  margin-top: -70px;
  h2 {
    padding-top: 0.5em;
  }
`;

const UpdateLogs = () => {
  const [visible, setVisible] = useState<boolean>(false);

  const handleClose = () => {
    setVisible(false);
  };

  useMount(() => {
    const hasChangelog =
      window.localStorage.getItem('show-changelog') === 'true';

    if (hasChangelog) {
      setVisible(true);
      window.localStorage.removeItem('show-changelog');
    }
  });

  return (
    <StyledModal
      title={
        <Row justify="space-between" align="middle">
          <Col>
            Application update <strong>v{ENVIRONMENT.build.version}</strong>
          </Col>

          <Col>
            <Button key="reload" type="primary" onClick={handleClose}>
              Confirm
            </Button>
          </Col>
        </Row>
      }
      visible={visible}
      width={767}
      footer={null}
      closable={false}
    >
      <ChangeLog inModal modalClose={handleClose} />
    </StyledModal>
  );
};

export default UpdateLogs;
