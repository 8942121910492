// @flow
import React from 'react';
import { useSelector, useDispatch, type Dispatch } from 'react-redux';
import moment from 'moment';
import { useLocation, type Location } from 'react-router-dom';
import { Form, Row, Col, Button, Divider } from 'antd';
import type { ReduxStore } from 'flow/redux';
import type { State } from 'flow/resources';
import type { DraftTravelPermitType } from 'flow/travelPermit';
import Page from 'components/Page';
import Content from 'components/Content';
import CodesAlertBanner from 'components/CodesAlertBanner';
import OwnerConsentFormField from 'components/form/OwnerConsentFormField';
import PaymentOptionsFormField from 'components/form/PaymentOptionsFormField';
import {
  LIFETIME_TRAVEL_PERMIT,
  YEARLY_TRAVEL_PERMIT,
  selectBreeds,
  selectColors,
  selectCounties,
  selectGenders,
  selectHorseCategoryId,
  selectStates,
  selectTravelPermitInspectionTypes,
  selectUtahState,
} from 'selectors/resources';
import { getInspectionType } from 'utils/inspectionTypes';
import { parseMarkings } from 'utils/markings';
import { getAnimal } from 'utils/animals';
import { getRequiredPerson } from 'utils/persons';
import generateEntityCode from 'utils/generateEntityCode';
import useCreditCardCheckout, {
  type WrappedCheckoutUtils,
  type PaymentListenerProps,
} from 'utils/hooks/useCreditCardCheckout';
import { dollarToCents } from 'utils/formatters/formatMoney';
import HorseDetails from './components/HorseDetails';
import OwnerDetails from './components/OwnerDetails';
import initialState from './initialState';
import { createTravelPermit } from './createTravelPermitSlice';

let generatedCode = {};

const CreateTravelPermitPage = () => {
  const location: Location = useLocation();
  const dispatch: Dispatch = useDispatch();

  const utahState: State = useSelector(selectUtahState);
  const states = useSelector(selectStates);
  const counties = useSelector(selectCounties);
  const colors = useSelector(selectColors);
  const breeds = useSelector(selectBreeds);
  const genders = useSelector(selectGenders);
  const category: number = useSelector(selectHorseCategoryId);
  const types = useSelector(selectTravelPermitInspectionTypes);

  const loading: boolean = useSelector((state: ReduxStore) => {
    return state.createTravelPermit.loading;
  });

  const [form] = Form.useForm();

  const getCost = (typeId) => {
    const { slug } = getInspectionType(typeId, types);

    if (YEARLY_TRAVEL_PERMIT === slug) return 25;

    if (LIFETIME_TRAVEL_PERMIT === slug) return 55;

    return null;
  };

  const updateCost = (value) => {
    const typeId = value.type;

    if (typeId) {
      form.setFieldsValue({ cost: getCost(typeId) });
    }
  };

  const fetchCodes = async (entityType: string) => {
    const code =
      (await generateEntityCode(
        entityType === 'lifetime-travel-permit' ? 'LTP' : 'YTP',
      )) || {};

    generatedCode = code;
  };

  const submit = (values: $Shape<DraftTravelPermitType>) => {
    const { publicId, code } = generatedCode;
    const { type: typeId, owner, animal, markings, ...rest } = values;
    const travelPermit = {
      ...rest,
      owner: getRequiredPerson(owner, counties, states),
      animal: {
        ...getAnimal(animal, genders, colors, breeds),
        category,
      },
      markings: parseMarkings(markings),
      type: getInspectionType(typeId, types),
      publicId,
      permitCode: code,
    };

    dispatch(createTravelPermit(travelPermit));
  };

  const paymentListener = ({ success }: PaymentListenerProps) => {
    if (success) {
      const values: $Shape<DraftTravelPermitType> = form.getFieldsValue();

      submit(values);
    }
  };

  const { checkout }: WrappedCheckoutUtils =
    useCreditCardCheckout(paymentListener);

  const handleSubmit = async (values: $Shape<DraftTravelPermitType>) => {
    if (!loading) {
      const { type: typeId, payment, cost } = values;
      const inspectionType = getInspectionType(typeId, types);
      const isCardPayment = payment?.paymentType === 'creditcard';

      await fetchCodes(inspectionType.slug);

      const { publicId } = generatedCode;

      if (publicId) {
        if (isCardPayment) {
          checkout({
            amountInCents: dollarToCents(cost),
            publicId,
            entityType: 'permit',
          });
        } else {
          submit(values);
        }
      }
    }
  };

  const handleChange = (value) => {
    if (value.type) {
      updateCost(value);
    }
  };

  const ownerState = location.state;

  const ownerDefaults = ownerState
    ? {
        ...ownerState,
        state: ownerState?.state?.id,
        county: ownerState?.county?.id,
      }
    : {
        ...initialState.owner,
        state: utahState.id,
      };

  const initialValues = {
    ...initialState,
    owner: ownerDefaults,
    permitDate: moment().format('MM/DD/YYYY'),
  };

  return (
    <Page>
      <CodesAlertBanner />

      <Form
        onFinish={handleSubmit}
        onValuesChange={handleChange}
        initialValues={initialValues}
        form={form}
        name="LifetimeHorseTravelPermitForm"
        colon={false}
        layout="vertical"
      >
        <Content>
          <HorseDetails form={form} category={category} types={types} />

          <Divider />

          <OwnerDetails types={types} />

          <Row justify="space-between" gutter={10}>
            <Col md={16}>
              <PaymentOptionsFormField />
            </Col>
          </Row>

          <OwnerConsentFormField />
        </Content>

        <Row gutter={10} justify="end">
          <Col span={5} offset={9}>
            <Button
              htmlType="submit"
              type="primary"
              block
              id="saveTravelPermit"
              loading={loading}
            >
              Print
            </Button>
          </Col>
        </Row>
      </Form>
    </Page>
  );
};

export default CreateTravelPermitPage;
