// @flow
import React from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';
import Page from 'components/Page';
import InspectionStats from './components/InspectionStats';
import InspectionsChart from './components/InspectionsChart';
import BeefPromotionFeesChart from './components/BeefPromotionFeesChart';
import PredatorFeesChart from './components/PredatorFeesChart';

const StyledWrapper = styled.div`
  position: relative;
  height: 100vh;
  overflow: hidden;
  pointer-events: none;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.9);
    filter: blur(2px);
    z-index: 1000;
  }
`;

const StyledNote = styled.div`
  padding: 17px 20px;
  text-align: center;
  position: absolute;
  z-index: 1001;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0 1px 2px -2px rgba(0, 0, 0, 0.16),
    0 3px 6px 0 rgba(0, 0, 0, 0.12), 0 5px 12px 4px rgba(0, 0, 0, 0.09);
  min-width: 230px;
  border-radius: 3px;

  h3 {
    font-style: italic;
    font-weight: bold;
    margin: 0;
  }
`;

const DashboardPage = () => {
  return (
    <StyledWrapper>
      <Page>
        <Row gutter={[16, 16]}>
          <Col span={8}>
            <InspectionStats />
          </Col>
          <Col span={16}>
            <InspectionsChart />
          </Col>
          <Col span={12}>
            <BeefPromotionFeesChart />
          </Col>
          <Col span={12}>
            <PredatorFeesChart />
          </Col>
          <Col span={8}>
            <InspectionStats />
          </Col>
          <Col span={16}>
            <InspectionsChart />
          </Col>
        </Row>
      </Page>
      <StyledNote>
        <h3>Coming Soon</h3>
      </StyledNote>
    </StyledWrapper>
  );
};

export default DashboardPage;
