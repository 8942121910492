// @flow
import Dexie from 'dexie';

const storeResourceData = (
  db: Dexie,
  keyval: string,
  data: Array<*>,
  truncateData: ?boolean,
) => {
  const store = db[keyval];

  return db.transaction('rw!', store, async () => {
    if (truncateData) {
      await store.clear();
    }
    return store.bulkPut(data).then((value) => {
      return value;
    });
  });
};

export default storeResourceData;
