// @flow
import {
  getCallbackUrl,
  IS_PROD_ENVIRONMENT,
  IS_STAGING_ENVIRONMENT,
} from 'service/urls';
import { CLIENT_ID } from 'constants/payments';

type Args = {
  amount: number,
  notes: string,
  autoReturn?: boolean,
};

const getClientId = () => {
  if (IS_PROD_ENVIRONMENT) {
    return CLIENT_ID.PRODUCTION;
  }
  if (IS_STAGING_ENVIRONMENT) {
    return CLIENT_ID.STAGING;
  }
  return CLIENT_ID.DEV;
};

export default function processCardCheckout({
  amount,
  notes,
  autoReturn = false,
}: Args) {
  // See https://developer.squareup.com/docs/pos-api/how-it-works & https://developer.squareup.com/docs/pos-api/build-mobile-web
  const callbackUrl = getCallbackUrl();
  const clientId = getClientId();
  // alert(`callbackUrl ${callbackUrl} and clientId ${clientId}`);

  const paymentObject = {
    amount_money: {
      // amount in cents
      amount,
      currency_code: 'USD',
    },
    callback_url: callbackUrl,
    client_id: clientId,
    version: '1.3',
    notes,
    // state is passed in the callback url query parameters
    state: notes,
    options: {
      supported_tender_types: ['CREDIT_CARD'],
      auto_return: autoReturn,
    },
  };

  window.location = `square-commerce-v1://payment/create?data=${encodeURIComponent(
    JSON.stringify(paymentObject),
  )}`;
}
