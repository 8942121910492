// @flow
import styled from 'styled-components';

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  button {
    margin: 0.3em 0.5em;
    flex-grow: 2;
  }
  button:last-child {
    flex-grow: 1;
  }
`;

export default ButtonContainer;
