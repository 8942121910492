// @flow
import React, { Fragment } from 'react';
import { Col, Row } from 'antd';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import logs from './logs';

type Props = {
  inModal?: boolean,
  modalClose?: () => void,
};

const count = (list?: Array<string>): number => (list && list.length) || 0;

const LINES_TO_DISPLAY = 10;

const ChangeLog = ({ inModal, modalClose }: Props) => {
  let linesCounted = 0;
  const latestChangelogs = !inModal
    ? logs
    : logs.filter(({ fixes, features }) => {
        linesCounted += count(fixes) + count(features);
        return linesCounted <= LINES_TO_DISPLAY;
      });

  return (
    <Fragment>
      <h2 style={{ marginTop: 0 }}>Change History</h2>
      {latestChangelogs
        .map(({ version, releaseDate, fixes, features }) => (
          <Version
            key={version}
            version={version}
            releaseDate={releaseDate}
            fixes={fixes}
            features={features}
          />
        ))
        .map((log, i) => (
          <Fragment>
            {i > 0 && <Line />}
            {log}
          </Fragment>
        ))}

      {inModal && (
        <Fragment>
          <FooterLine />
          <StyledLink to="/changelog" onClick={modalClose}>
            View the complete change history &gt;&gt;
          </StyledLink>
        </Fragment>
      )}
    </Fragment>
  );
};

const StyledLink = styled(Link)`
  display: block;
  margin: 0;
  text-align: right;
`;

const Line = styled.hr`
  margin: 0 0 1.5em;
`;

const FooterLine = styled.hr`
  margin: 0 0 1em;
`;

const WrapSafe = styled(Row)`
  strong {
    white-space: nowrap;
  }
`;

const Subtle = styled(WrapSafe)`
  color: #8d8d8d;
`;

const Emphasize = styled(WrapSafe)`
  color: #4f4f4f;
`;

const ListHeader = styled.strong`
  font-size: 0.9em;
`;

const VersionHeader = ({
  version,
  releaseDate,
}: {
  version: string,
  releaseDate: string,
}) => (
  <p>
    <strong>v{version}</strong> <em>{releaseDate}</em>
  </p>
);

const BugFixes = ({ fixes }: { fixes: Array<string> }) => (
  <Subtle>
    <Col span={3}>
      <ListHeader>IMPROVEMENTS</ListHeader>
    </Col>
    <Col span={21}>
      <List items={fixes} />
    </Col>
  </Subtle>
);

const Features = ({ features }: { features: Array<string> }) => (
  <Emphasize>
    <Col span={3}>
      <ListHeader>NEW FEATURES</ListHeader>
    </Col>
    <Col span={21}>
      <List items={features} />
    </Col>
  </Emphasize>
);

const List = ({ items }: { items: Array<string> }) => (
  <ul>
    {items.map((item, index) => (
      <li key={index}>{item}</li>
    ))}
  </ul>
);

const Version = ({
  version,
  releaseDate,
  fixes,
  features,
}: {
  version: string,
  releaseDate: string,
  fixes?: Array<string>,
  features?: Array<string>,
}) => (
  <Fragment>
    <VersionHeader version={version} releaseDate={releaseDate} />
    {fixes && <BugFixes fixes={fixes} />}
    {features && <Features features={features} />}
  </Fragment>
);

Version.defaultProps = {
  fixes: undefined,
  features: undefined,
};

ChangeLog.defaultProps = {
  inModal: false,
  modalClose: () => {},
};

export default ChangeLog;
