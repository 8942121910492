// @flow
import React, { Fragment, useState } from 'react';
import { LIVESTOCK_PARTS } from 'constants/livestock';
import { getTargetPart } from './helpers';
import { StyledLabel, StyledPath, StyledPart } from './styles';

type Props = {
  image: string,
  selected: Array<*>,
  onSelect: (string) => void,
  visibleLabels: boolean,
};

const SheepSvg = ({ image, selected, onSelect, visibleLabels }: Props) => {
  const [hoveredPart, setHoveredPart] = useState<string>('');

  const handleHover = (ev) => {
    const part = getTargetPart(ev.target);

    setHoveredPart(part);
  };

  const handleMouseLeave = () => {
    setHoveredPart('');
  };

  const handleSelect = (ev) => {
    const part = getTargetPart(ev.target);

    onSelect(part);
  };

  return (
    <svg
      viewBox="0 0 293 378"
      onMouseOver={handleHover}
      onMouseLeave={handleMouseLeave}
      onFocus={() => {}}
      onClick={handleSelect}
      style={{ maxWidth: '250px', margin: 'auto', display: 'block' }}
    >
      <g id="Sheep-SVG" transform="translate(-800.000000, -390.000000)">
        <g id="Sheep" transform="translate(801.000000, 391.000000)">
          <g id="Sheep-top">
            <StyledPath
              id="sheep-head"
              d="M151.7,81.3c-0.2-2.2-0.8-10-1.4-16.6c-0.3-1.9-2.1,5-11.6,6.7c-8.6,1.5-17.4-2-14.5-2.5c14.5-2.5,23.8-9.4,23.3-12c-1.9-10.9-3.5-21.4-2.3-22.7c2.4-2.5,9.2-4.6,10.7-18.2c1.4-12.5,11.3-12,13-11.8c1.7-0.2,11.6-0.7,13,11.8c1.5,13.7,8.3,15.7,10.7,18.2c1.3,1.3-0.4,11.8-2.3,22.7c-0.5,2.6,8.8,9.5,23.3,12c2.9,0.5-5.9,4.1-14.5,2.5c-9.5-1.7-11.3-8.5-11.5-6.7c-0.7,6.6-1.3,14.3-1.4,16.6H151.7z"
            />
            <StyledPart
              hovered={hoveredPart === LIVESTOCK_PARTS.WITHERS}
              active={selected.includes(LIVESTOCK_PARTS.WITHERS)}
              data-id={LIVESTOCK_PARTS.WITHERS}
            >
              <StyledPath
                id="sheep-whithers"
                d="M132.5,168.1c-6.5-19.5-1.5-46.8,9.3-63.1s10.5-16.2,9.9-23.7h34.5c-0.6,7.5-0.9,7.4,9.9,23.7s15.9,43.7,9.3,63.1H132.5z"
              />
              <image
                href={image}
                width="75"
                height="75"
                transform="matrix(0.4630768090090642 0 0 0.4659036501107351 152 110)"
              />
            </StyledPart>
            <StyledPart
              hovered={hoveredPart === LIVESTOCK_PARTS.CENTER}
              active={selected.includes(LIVESTOCK_PARTS.CENTER)}
              data-id={LIVESTOCK_PARTS.CENTER}
            >
              <StyledPath
                id="sheep-center-back"
                d="M131.9,253.4c0.1-5.9-2.2-10.7-3.9-29.7c-2.4-26.7,4.5-55.6,4.5-55.6h72.9c7.2,30.1,7,28.4,4.5,55.6c-1.6,17.4-3.6,22.9-3.9,28.2L131.9,253.4z"
              />
              <image
                href={image}
                width="75"
                height="75"
                transform="matrix(0.4630768090090642 0 0 0.4659036501107351 152 190)"
              />
            </StyledPart>
            <StyledPart
              hovered={hoveredPart === LIVESTOCK_PARTS.RUMP}
              active={selected.includes(LIVESTOCK_PARTS.RUMP)}
              data-id={LIVESTOCK_PARTS.RUMP}
            >
              <StyledPath
                id="sheep-rump"
                d="M206,251.9c-0.1,2.9,0.2,5.7,1.5,10.3c3.5,13.2,12,34.2,1.9,50.6s-33,17.6-33,17.6s12.3,17.6,3.7,29.8c-6.6,9.4-9.8,11.4-10.9,11.8v0.1c0,0-0.1,0-0.2,0c-0.2,0-0.3,0-0.3,0V372c-1.1-0.4-4.3-2.4-10.9-11.8c-8.6-12.2,3.7-29.8,3.7-29.8s-23-1.3-33-17.6s-1.7-37.4,1.9-50.6c1-3.8,1.4-6.4,1.5-8.8L206,251.9z"
              />
              <image
                href={image}
                width="75"
                height="75"
                transform="matrix(0.4630768090090642 0 0 0.4659036501107351 152 270)"
              />
            </StyledPart>
          </g>
        </g>
        {visibleLabels && (
          <Fragment>
            <StyledLabel transform="matrix(0.841 0 0 1 1024.3369 520.9165)">
              WITHERS
            </StyledLabel>
            <StyledLabel transform="matrix(0.841 0 0 1 1029.1851 589.4014)">
              CENTER
            </StyledLabel>
            <StyledLabel transform="matrix(0.841 0 0 1 1035.6851 611.4014)">
              BACK
            </StyledLabel>
            <StyledLabel transform="matrix(0.841 0 0 1 1032.4766 684.4873)">
              RUMP
            </StyledLabel>
            <StyledLabel transform="matrix(1 0 0 1 840 471)">
              Sheep Brands
            </StyledLabel>
            <StyledLabel transform="matrix(1 0 0 1 840 491.4)">
              are read from{' '}
            </StyledLabel>
            <StyledLabel transform="matrix(1 0 0 1 840 511.8)">
              the HEAD to
            </StyledLabel>
            <StyledLabel transform="matrix(1 0 0 1 840 532.2)">
              the RUMP
            </StyledLabel>
            <StyledLabel transform="matrix(1 7.617310e-03 -7.617310e-03 1 854.4609 568.7155)">
              Example:{' '}
            </StyledLabel>
            <StyledLabel transform="matrix(0.9999 -1.294132e-02 1.294132e-02 0.9999 877.2787 610.0973)">
              A
            </StyledLabel>
            <StyledLabel transform="matrix(-7.617310e-03 1 -1 -7.617310e-03 876.4474 681.5873)">
              A
            </StyledLabel>
            <StyledLabel transform="matrix(1 7.617310e-03 -7.617310e-03 1 877.0438 640.9127)">
              or
            </StyledLabel>
            <StyledLabel transform="matrix(1 7.617310e-03 -7.617310e-03 1 866.8229 669.8249)">
              LAZY
            </StyledLabel>
          </Fragment>
        )}
      </g>
    </svg>
  );
};

export default SheepSvg;
