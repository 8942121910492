// @flow
import React, { Fragment, useState } from 'react';
import { LIVESTOCK_PARTS } from 'constants/livestock';
import { getTargetPart } from './helpers';
import { StyledLabel, StyledPath, StyledPart } from './styles';

type Props = {
  image: string,
  selected: Array<*>,
  onSelect: (string) => void,
  visibleLabels: boolean,
};

const CattleRightSvg = ({
  image,
  selected,
  onSelect,
  visibleLabels,
}: Props) => {
  const [hoveredPart, setHoveredPart] = useState<string>('');

  const handleHover = (ev) => {
    const part = getTargetPart(ev.target);

    setHoveredPart(part);
  };

  const handleMouseLeave = () => {
    setHoveredPart('');
  };

  const handleSelect = (ev) => {
    const part = getTargetPart(ev.target);

    onSelect(part);
  };

  return (
    <svg
      viewBox="0 0 376 264"
      onMouseOver={handleHover}
      onMouseLeave={handleMouseLeave}
      onFocus={() => {}}
      onClick={handleSelect}
      style={{ maxWidth: '420px', margin: 'auto' }}
    >
      {visibleLabels && (
        <Fragment>
          <StyledLabel transform="matrix(1 0 0 1 171.9941 23)">
            Right Shoulder
          </StyledLabel>
          <StyledLabel transform="matrix(1 0 0 1 100.525 26)">
            Right Ribs
          </StyledLabel>
          <StyledLabel transform="matrix(1 0 0 1 0.5693 35)">
            Right Hip-Thigh
          </StyledLabel>
        </Fragment>
      )}

      <StyledPath d="M25.2,95.8c0,0-0.7,52.6,0,63.1c0,0,2.9-14.7,2.2-33.9C26.8,105.8,25.2,95.8,25.2,95.8z" />
      <StyledPath
        d="M367.9,78.8c-3-3.2-12.7-17.5-16.2-22.2s-5.5-5.7-5.5-5.7s0.7-5.5-4-10.2c-4.7-4.7-11.7-5.7-14.2-11.7
          c-1.9-4.5,4.6-0.3-6-5.9c-10.5-5.6-0.2-21.8-1.5-21.1c-20,10.5-5,27.4-7,28.7c-2,1.2-8.5,4.7-14.5,7c0,0-2.5-2-8.7-2.2
          c-6.2-0.2-11.7,1-9.5,6.5c0,0-20.7,4.5-28.4,2.2c-1.4-0.4-2.9-0.8-4.5-1.3c8.4,11.8,13.7,31.3,11.1,52
          c-4.3,33.8-36.5,57.1-26.7,76.6c0-0.2,0-0.3,0-0.3s12.7,3.2,17.5-2.7c4.7-6,8.5-16.2,11.7-21.2c3.2-5,9.5-9,12.5-18.2
          c3-9.2,20.7-29.7,34.4-36.2c0,0,19.5,3.7,27.9,3.7s13.5-0.7,17.5,0.2l0,0c0.1,0.2,1.2,2,5.2,2c4.2,0,8.7-1.5,9.2-3.5
          c0,0,5-3.2,5.7-7.2C374.7,84.1,370.9,82.1,367.9,78.8z"
      />
      <StyledPath d="M25.2,95.8c0,0-0.7,52.6,0,63.1c0,0,2.9-14.7,2.2-33.9C26.8,105.8,25.2,95.8,25.2,95.8z" />
      <StyledPath d="M25.2,95.8c0,0-0.7,52.6,0,63.1c0,0,2.9-14.7,2.2-33.9C26.8,105.8,25.2,95.8,25.2,95.8z" />
      <StyledPart
        hovered={hoveredPart === LIVESTOCK_PARTS.RIGHT_SHOULDER}
        active={selected.includes(LIVESTOCK_PARTS.RIGHT_SHOULDER)}
        data-id={LIVESTOCK_PARTS.RIGHT_SHOULDER}
      >
        <StyledPath
          d="M247.9,42.8c-7.4-2.2-16.7-4.8-21.4-5.2c-5.7-0.5-35.2,3.2-43.4,3.5c-3,0.1-7.8,0.3-13.4,0.6
          c6.2,24.7,6.9,49.5,0.2,67c-10,25.9-30.2,43.9-30,63.3c18.3-3,50.4-13.2,54-13.2c4,0,13.1,4.3,13.1,4.3l1.6,4.3
          c0,0-0.4,23.6-1.7,26.3c-1.3,2.7,1,4.7,0.8,9c-0.2,4.3-3.5,26.3-4.5,29.9c-1,3.7-1.3,12-1.3,12h5.3c0,0,0.2,1.5-0.5,3.7
          c-0.7,2.2,0.5,7.5,3.5,8.7c0,0,16,0.9,17.8-0.6c0,0,1.7,4.6,3.9,5.6c0,0,20.5,0.9,20.7-1.9c0.2-2.7-7-8-7-8s-5.2-2.8-7-10
          c-1.7-7.2-4-14-4.7-18c-0.7-4-0.2-11,0.5-16.4c0.7-5.3,1.2-5.1-0.2-17.3c-1.3-10.7-1.7-17.6-1.7-19.1c-9.8-19.5,22.5-42.9,26.7-76.6
          C261.6,74.2,256.4,54.7,247.9,42.8z"
        />
        <image
          href={image}
          width="75"
          height="75"
          transform="matrix(0.4630768090090642 0 0 0.4659036501107351 195.57719572944788 80.129376)"
        />
      </StyledPart>
      <StyledPart
        hovered={hoveredPart === LIVESTOCK_PARTS.RIGHT_RIB}
        active={selected.includes(LIVESTOCK_PARTS.RIGHT_RIB)}
        data-id={LIVESTOCK_PARTS.RIGHT_RIB}
      >
        <StyledPath
          d="M169.7,41.8c-9.8,0.6-21.7,1.5-29.5,2.6c-12.2,1.7-44.6,5-53.1,5.7c-5.1,0.5-9.2-0.6-15-0.6
          c14.2,11.9,20.8,33.4,14.4,60c-6.4,26.7-6.9,49.8-3.6,63.7l3.2,0.7c0,0-0.2,4.7,1.5,5c1.7,0.2,2-5.2,2-5.2s4.2,0.2,7.5-0.7
          c0,0,0.2,4.5,2,4.2c1.7-0.2,1.5-5.7,1.5-5.7s5.2-0.7,7-2.2c0,0,10.5,5,26.7,3.7c1.7-0.1,3.6-0.4,5.6-0.7c-0.2-19.4,20-37.5,30-63.3
          C176.7,91.3,175.9,66.5,169.7,41.8z"
        />
        <image
          href={image}
          width="75"
          height="75"
          transform="matrix(0.4630768090090642 0 0 0.4659036501107351 110.57719572944788 75.129376)"
        />
      </StyledPart>
      <StyledPart
        hovered={hoveredPart === LIVESTOCK_PARTS.RIGHT_THIGH}
        active={selected.includes(LIVESTOCK_PARTS.RIGHT_THIGH)}
        data-id={LIVESTOCK_PARTS.RIGHT_THIGH}
      >
        <StyledPath
          d="M86.5,109.5c6.4-26.6-0.2-48.1-14.4-60c-3.8,0-8.4,0.5-14.4,2.1c-15.2,4.2-26.9,8-33.2,13.7
          c-6.2,5.7-7.5,18-6.2,27.4c1.2,9.5,1.2,26.9,1,38.2s-1,36.2-2.5,40.4c-1.5,4.2-4.5,7-3.5,11.5c1,4.5,2.6,20.5-0.2,32.7
          c-2.8,12.2-6.3,24.2-6.3,24.2s-3.2,4-2.7,5.7c0.5,1.7,4-0.7,4-0.7s1.5,3.5,0.7,7.2s0.7,7.5,2.5,9.7c0,0,16.2,1.5,18-1
          c1.7-2.5-5.2-8.7-5.2-8.7s-0.7-3-2.2-5.7c-1.5-2.7-1.7-16.2-1-21.4c0.7-5.2,9.7-31.2,11.7-36.2c2-5,11-14.5,12.7-22.9
          c0,0,5.7,8.2,14.5,9.5c0,0-4.7,6.5-1.7,11.2c3,4.7,4.5,12,7.5,21.7c3,9.7,8.2,28.9,8.2,28.9s-3,4.7-1.5,6c1.5,1.2,5.7-2.5,5.7-2.5
          s2,3.2,2.5,5.5c0.5,2.2-0.5,5.5,2.5,7.7c0,0,16.5,0.5,17.7-1.2c1.2-1.7-3.2-7.2-6.5-8.2l0,0c0-0.3-0.2-3.1-3.5-4.7c0,0-4-6.7-7.2-15
          c-3.2-8.2-6.2-20.7-5.2-27.9s4.5-23.4,4.5-23.4l0.3,0.1C79.6,159.3,80.1,136.2,86.5,109.5z M25.2,158.9c-0.7-10.5,0-63.1,0-63.1
          s1.5,10,2.2,29.2C28.2,144.2,25.2,158.9,25.2,158.9z"
        />
        <image
          href={image}
          width="75"
          height="75"
          transform="matrix(0.4630768090090642 0 0 0.4659036501107351 35.57719572944788 85.129376)"
        />
      </StyledPart>
    </svg>
  );
};

export default CattleRightSvg;
