// @flow
import React from 'react';
import { useEffectOnce, useUpdateEffect } from 'react-use';
import { useSelector, useDispatch, type Dispatch } from 'react-redux';
import { Table, Typography, Tag } from 'antd';
import moment from 'moment';
import type { Inspection } from 'flow/inspection';
import useQuery from 'utils/hooks/useQuery';
import usePaymentTypeQuery from 'utils/hooks/usePaymentTypeQuery';
import formatMoney, { centsToDollars } from 'utils/formatters/formatMoney';
import {
  getPaymentTypeColumn,
  // getPaymentStatusColumn,
} from 'utils/paymentColumn';
import {
  clearAll,
  fetchInspections,
  selectLoading,
  selectTotal,
  selectComputedInspections,
} from './slice';

const COLUMNS = [
  {
    title: 'ID',
    dataIndex: 'inspectionCode',
    key: 'inspectionCode',
    render: (value: string, values: Inspection) => (
      <Typography.Text>
        {values.isVoid && <Tag color="red">VOIDED</Tag>}
        <strong>{value}</strong>
      </Typography.Text>
    ),
  },
  {
    title: 'Owner',
    dataIndex: ['owner', 'name'],
    key: 'name',
    render: (value: string) => value || '-',
  },
  {
    title: 'Buyer',
    dataIndex: ['buyer', 'name'],
    key: 'buyer',
    render: (value: string) => value || '-',
  },
  {
    title: 'Destination',
    dataIndex: 'destination',
    key: 'destination',
    render: (value: string) => value || '-',
  },
  {
    title: 'Price',
    dataIndex: 'total',
    key: 'total',
    render: (value: number) =>
      formatMoney({ value: centsToDollars(value || 0) }),
  },
  getPaymentTypeColumn(),
  // getPaymentStatusColumn(),
  {
    title: 'Date',
    dataIndex: 'inspectionDate',
    key: 'inspectionDate',
    render: (value: string) => {
      return value ? moment(value).format('MM/DD/YYYY') : '-';
    },
  },
];

const AdminInspections = () => {
  const dispatch: Dispatch = useDispatch();

  const loading: boolean = useSelector(selectLoading);

  const total: number = useSelector(selectTotal);

  const inspections: Array<Inspection> = useSelector(selectComputedInspections);

  const [paymentType] = usePaymentTypeQuery();

  const { query, page, limit, offset, patchQuery, from, to } = useQuery();

  const fetchData = () => {
    dispatch(
      fetchInspections({
        limit,
        offset,
        from,
        to,
        paymentType,
        query,
      }),
    );
  };

  const handleChange = ({ current }) => {
    patchQuery('page', current);
  };

  useEffectOnce(() => {
    fetchData();

    return () => {
      dispatch(clearAll());
    };
  });

  useUpdateEffect(() => {
    fetchData();
  }, [limit, offset, from, to]);

  useUpdateEffect(() => {
    if (page === 1) {
      fetchData();
    } else {
      patchQuery('page', 1);
    }
  }, [paymentType, query]);

  return (
    <Table
      rowKey="id"
      columns={COLUMNS}
      dataSource={inspections}
      loading={loading}
      size="small"
      rowClassName="ant-row-clickable"
      pagination={{
        current: page,
        pageSize: limit,
        total,
        showSizeChanger: false,
      }}
      onChange={handleChange}
      onRow={(record) => {
        return {
          onClick: () => {
            window.open(`/inspections/${record.id}`);
          },
        };
      }}
    />
  );
};

export default AdminInspections;
