// @flow
import React, { Fragment } from 'react';
import { Form, Input, Row, Col, DatePicker } from 'antd';
import FormTitle from 'components/FormTitle';

const TransportForm = () => {
  return (
    <Fragment>
      <FormTitle level={4}>Transport</FormTitle>
      <Row gutter={12}>
        <Col lg={4} md={8}>
          <Form.Item label="Date" name="inspectionDate">
            <DatePicker style={{ width: '100%' }} format="MM/DD/YYYY" />
          </Form.Item>
        </Col>

        <Col lg={4} md={8}>
          <Form.Item label="Sold To" name="soldTo">
            <Input />
          </Form.Item>
        </Col>

        <Col lg={4} md={8}>
          <Form.Item label="Consigned To" name="consignedTo">
            <Input />
          </Form.Item>
        </Col>

        <Col lg={4} md={8}>
          <Form.Item label="Destination" name="destination">
            <Input />
          </Form.Item>
        </Col>

        <Col lg={4} md={8}>
          <Form.Item label="Carrier" name="carrier">
            <Input />
          </Form.Item>
        </Col>

        <Col lg={4} md={8}>
          <Form.Item label="License No." name="licenseNo">
            <Input />
          </Form.Item>
        </Col>
      </Row>
    </Fragment>
  );
};

export default TransportForm;
