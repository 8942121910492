// @flow
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { getMe } from 'store/meSlice';
import type { ReduxStore } from 'flow/redux';
import type { Code } from 'flow/codes';
import { mapOfflineUsedV2Codes } from 'utils/generateEntityCode';
import { syncOfflineData } from 'features/SyncOfflineMenuItem/syncOfflineSlice';

export const fetchCodesV2 = createAsyncThunk(
  'initializer/fetchCodesV2',
  async (_, { extra, getState, rejectWithValue }) => {
    const { inspectionClient, codesDb } = extra;
    const { me } = getState();
    const codesTable = codesDb.codes;

    try {
      const [{ deviceId }, offlineEntityCodes] = await Promise.all([
        codesDb.devices.orderBy('id').first(),
        /*
          gather all offline entities that uses
          the new code blocks format
        */
        mapOfflineUsedV2Codes(),
      ]);

      const { data } = await inspectionClient().post('/blocks/get_or_create/', {
        userId: me.data.id,
        deviceId,
      });

      /*
        trim out all the used codes from offline
        create entities
      */
      const filteredCodes = data.filter((block: Code) => {
        return !offlineEntityCodes.includes(block.code);
      });

      await codesDb.transaction('rw!', codesTable, async () => {
        await codesTable.clear();

        return codesTable.bulkAdd(filteredCodes);
      });

      return filteredCodes;
    } catch (err) {
      const availableCodes = await codesTable.toArray();

      return rejectWithValue(availableCodes);
    }
  },
);

export const createDeviceId = createAsyncThunk(
  'initializer/createDeviceId',
  async (_, { extra, rejectWithValue, dispatch, getState }) => {
    const { codesDb, createError } = extra;
    const { online } = getState().initializer;

    try {
      await dispatch(getMe());

      let deviceId;

      // device id is unique per device and must only be generated once
      // unless the user cleared his cache/indexedDb
      const device = await codesDb.devices.orderBy('id').first();

      if (device) {
        // eslint-disable-next-line
        deviceId = device.deviceId;
      } else {
        deviceId = uuidv4();
        await codesDb.devices.put({ deviceId });
      }

      if (online) {
        await dispatch(syncOfflineData());
      }

      return deviceId;
    } catch (err) {
      createError({
        payload: null,
        endpoint: 'OFFLINE: initializer/createDeviceId',
        errorStack: err?.stack,
      });

      return rejectWithValue(err);
    }
  },
);

export const ping = createAsyncThunk(
  'network/ping',
  async (_, { extra, rejectWithValue }) => {
    const { inspectionClient } = extra;

    try {
      const { data } = await inspectionClient().get('/ping/');

      return data;
    } catch (err) {
      return rejectWithValue(err);
    }
  },
);

const initializerSlice = createSlice({
  name: 'initializer',
  initialState: {
    pending: true,
    deviceId: null,
    codesPending: true,
    codesCount: 0,
    online: true,
  },
  extraReducers: {
    [createDeviceId.pending]: (state) => {
      state.pending = true;
    },
    [createDeviceId.fulfilled]: (state, { payload }) => {
      state.deviceId = payload;
      state.pending = false;
    },
    [createDeviceId.rejected]: (state) => {
      state.pending = false;
    },
    [fetchCodesV2.fulfilled]: (state, { payload }) => {
      state.codesPending = false;
      state.codesCount = payload.length;
    },
    [fetchCodesV2.rejected]: (state, { payload }) => {
      state.codesPending = false;
      state.codesCount = payload.length;
    },
    [ping.fulfilled]: (state) => {
      state.online = true;
    },
    [ping.rejected]: (state) => {
      state.online = false;
    },
  },
});

export const selectIsOnline = (state: ReduxStore) => state.initializer.online;

export default initializerSlice.reducer;
