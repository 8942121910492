// @flow
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from '@reduxjs/toolkit';
import type { ReduxStore } from 'flow/redux';
import type { Inspection } from 'flow/inspection';
import type { Filters } from 'flow/search';
import { showErrorMessage } from 'utils/showMessage';
import { inspectionAndTotal } from 'utils/computeFees';
import { filterInspections } from 'service/search/util';

const adapter = createEntityAdapter({
  selectId: (inspection: Inspection) => inspection.publicId,
});

const initialState = adapter.getInitialState({
  loading: false,
  syncing: false,
  total: 0,
});

export const { selectAll: selectOfflineInspections } = adapter.getSelectors(
  (state: ReduxStore) => {
    return state.offlineInspections;
  },
);

export const selectComputedInspections = createSelector(
  selectOfflineInspections,
  inspectionAndTotal,
);

export const fetchOfflineInspections = createAsyncThunk(
  'offlineInspections/fetchOfflineInspections',
  async (filters: ?Filters, { extra, rejectWithValue }) => {
    const { from, to, query, limit, offset, paymentStatus } = filters || {};
    const { inspectionsDb: db, createError } = extra;

    try {
      return await filterInspections({
        range: { from, to, key: 'inspectionDate' },
        query,
        limit,
        offset,
        paymentStatus: paymentStatus || 'all',
        table: db.offlineInspections,
      });
    } catch (err) {
      createError({
        payload: '',
        endpoint: 'OFFLINE: offlineInspections/fetchOfflineInspections',
        errorStack: err?.stack,
      });

      showErrorMessage('Failed to get offline inspections. Please try again.');

      return rejectWithValue(err);
    }
  },
);

const offlineInspectionsSlice = createSlice({
  name: 'offlineInspections',
  initialState,
  reducers: {
    clearAll: (state) => {
      state.total = 0;
      adapter.removeAll(state);
    },
  },
  extraReducers: {
    [fetchOfflineInspections.pending]: (state) => {
      state.loading = true;
    },
    [fetchOfflineInspections.fulfilled]: (state, { payload }) => {
      const { total, result } = payload;

      state.loading = false;
      state.total = total;
      adapter.setAll(state, result);
    },
    [fetchOfflineInspections.rejected]: (state) => {
      state.loading = false;
      state.total = 0;
      adapter.removeAll(state);
    },
  },
});

export const { clearAll } = offlineInspectionsSlice.actions;

export default offlineInspectionsSlice.reducer;
