// @flow
import React, { useRef, type ElementRef } from 'react';
import { useSelector, useDispatch, type Dispatch } from 'react-redux';
import { useEffectOnce } from 'react-use';
import { useRouteMatch, type RouteMatch } from 'react-router-dom';
import { Typography, Button, Space } from 'antd';
import moment from 'moment';
import type { ReduxStore } from 'flow/redux';
import { fetchAdminLog } from './adminLogSlice';

const { Text, Title } = Typography;

const AdminLog = () => {
  const inputRef = useRef<ElementRef<'input'> | null>(null);

  const dispatch: Dispatch = useDispatch();

  const { params }: RouteMatch = useRouteMatch();

  const pending: boolean = useSelector(
    (state: ReduxStore) => state.adminLog.pending,
  );

  const data = useSelector((state: ReduxStore) => state.adminLog.data);

  useEffectOnce(() => {
    dispatch(fetchAdminLog(params.id));
  });

  if (pending) {
    return <div>loading</div>;
  }

  const {
    browser,
    platform,
    endpoint,
    payload,
    errorStack,
    eventDate,
    eventSynced,
    userId,
  } = data;

  const handleCopy = () => {
    if (inputRef.current) {
      inputRef.current.select();
      document.execCommand('copy');
    }
  };

  return (
    <Space direction="vertical" size={20} style={{ width: '100%' }}>
      <div>
        <Title level={5}>Browser</Title>
        <Text code copyable>
          {browser}
        </Text>

        <Title level={5}>Platform</Title>
        <Text code>{platform}</Text>

        <Title level={5}>Endpoint</Title>
        <Text code copyable>
          {endpoint}
        </Text>

        <Title level={5}>Payload</Title>
        <Text code copyable>
          {payload}
        </Text>

        <Title level={5}>Error Stack</Title>
        <Text code copyable>
          {errorStack}
        </Text>

        <Title level={5}>User ID</Title>
        <Text code copyable>
          {userId}
        </Text>

        <Title level={5}>Event Date</Title>
        <Text>{moment(eventDate).format('MM/DD/YYYY')}</Text>

        <Title level={5}>Event Synced</Title>
        <Text>{moment(eventSynced).format('MM/DD/YYYY')}</Text>
      </div>

      <div>
        <input
          value={JSON.stringify(data)}
          style={{ opacity: '0', position: 'absolute', zIndex: -99 }}
          ref={inputRef}
        />
        <Button type="primary" onClick={handleCopy}>
          Copy error object
        </Button>
      </div>
    </Space>
  );
};

export default AdminLog;
