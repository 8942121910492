// @flow
import React from 'react';
import { useDispatch, useSelector, type Dispatch } from 'react-redux';
import { Modal, Typography, Button, Row } from 'antd';
import type { ReduxStore } from 'flow/redux';
import { fetchBrands, closeSyncNotification } from 'store/brandsSlice';

const SyncModal = () => {
  const dispatch: Dispatch = useDispatch();

  const visible = useSelector((state: ReduxStore) => {
    return state.brands.showSyncNotification;
  });

  const close = () => {
    dispatch(closeSyncNotification());
  };

  const handleSync = () => {
    close();
    dispatch(fetchBrands());
  };

  return (
    <Modal
      visible={visible}
      title={null}
      footer={null}
      closable={false}
      width={300}
    >
      <Typography.Title level={5}>New brands data available!</Typography.Title>
      <Typography.Paragraph>Sync now?</Typography.Paragraph>
      <Row justify="end">
        <Button type="link" onClick={close}>
          Do it later
        </Button>
        <Button type="primary" onClick={handleSync}>
          Sync now
        </Button>
      </Row>
    </Modal>
  );
};

export default SyncModal;
