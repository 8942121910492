export const FLAG_DISABLE_GPS_ON_CERTIFICATES = true; // request from UDAF

export const ENABLE_CONSIGNMENT_ADDITIONAL_UI = false;

export const ENABLE_MINIMUM_SUMMARY_LINEITEM_WAIVING = false;

export const DEFAULT_REASON_MINIMUM_WAIVED = 'All Inspection fees are waived';

export const PAYMENT_LABEL_ONLY = ['pay_later', 'released_at_no_charge'];

export const LINE_ITEM_SLUG = {
  INSPECTION: 'inspection_fee',
  INSPECTION_MINIMUM: 'inspection_minimum_fee',
  PREDATOR_CONTROL: 'predator_control_fee',
  BEEF_TAG: 'beef_tag_fee',
  BEEF_COUNCIL: 'beef_council_fee',
  PROCESSING: 'processing_fee',
};

export const isInspectionFee = (slug: string) =>
  slug === LINE_ITEM_SLUG.INSPECTION;
export const isInspectionMinimum = (slug: string) =>
  slug === LINE_ITEM_SLUG.INSPECTION_MINIMUM;
export const isReleasedAtNoCharge = (slug: string) =>
  slug === 'released_at_no_charge';

export const PAYMENT_DESCRIPTIONS = {
  pay_later: 'Pay Later',
  creditcard: 'Credit card',
  check: 'Check',
  cash: 'Cash',
  released_at_no_charge: 'Released at no charge',
  invoice: 'Invoice'
};

export const PAYMENT_STATUS_COLORS = {
  FAILED: 'error',
  CANCELLED: 'error',
  PENDING: 'warning',
  APPROVED: 'success',
  COMPLETED: 'success',
};

export const PAYMENT_STATUS_TEXT = {
  FAILED: 'Failed',
  CANCELLED: 'Cancelled',
  PENDING: 'Processing',
  APPROVED: 'Completed',
  COMPLETED: 'Completed',
};

export const FEE_DESCRIPTIONS = {
  inspection_fee: 'Inspection',
  beef_council_fee: 'Beef Council',
  predator_control_fee: 'Predator Control',
  processing_fee: 'Processing',
  beef_tag_fee: 'Beef Tag',
  released_at_no_charge: 'Released at no charge',
  inspection_minimum_fee: 'Inspection Minimum',
};

export const LINE_ITEM_TYPE = {
  SERVICE: 'service_fee',
  OVERRIDE: 'override_fee',
  OTHER: 'other_fee',
};

export const INSPECTION_TYPE_STRICT_DICTIONARY = {
  'calf-pool-receiving-station-location': [
    'calf-pool-producer',
    'slaughter',
    'commuter-or-grazing',
    'yearly-travel-permit',
    'consignment-sale',
    'production-sale',
    'leaving-the-state',
    'change-of-ownership',
    'other',
  ],
  'calf-pool-producer': ['calf-pool-receiving-station-location'],
  slaughter: ['calf-pool-receiving-station-location'],
  'commuter-or-grazing': ['calf-pool-receiving-station-location'],
  'yearly-travel-permit': ['calf-pool-receiving-station-location'],
  'consignment-sale': ['calf-pool-receiving-station-location'],
  'production-sale': ['calf-pool-receiving-station-location'],
  'leaving-the-state': ['calf-pool-receiving-station-location'],
  'change-of-ownership': ['calf-pool-receiving-station-location'],
  other: ['calf-pool-receiving-station-location'],
};
