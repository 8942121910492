// @flow
import React from 'react';
import { useEffectOnce } from 'react-use';
import {
  Row,
  Col,
  Form,
  Input,
  Typography,
  Checkbox,
  Select,
  Divider,
  type WrappedFormUtils,
} from 'antd';
import moment from 'moment';
import { useDispatch, useSelector, type Dispatch } from 'react-redux';
import { useLocation, type Location } from 'react-router-dom';
import { selectMe } from 'selectors/me';
import type { UserType } from 'flow/user';
import type { State, Breed } from 'flow/resources';
import type { DraftPreSaleInspectionType } from 'flow/presale';
import Content from 'components/Content';
import BrandsFormField from 'components/form/BrandsFormField';
import HorseMarkingsFormField from 'components/form/HorseMarkingsFormField';
import CameraFormField from 'components/form/CameraFormField';
import PhotoAttachments from 'components/PhotoAttachments';
import StatesFormField from 'components/form/StatesFormField';
import CountiesFormField from 'components/form/CountiesFormField';
import AnimalGendersFormField from 'components/form/AnimalGendersFormField';
import AnimalBreedsFormField from 'components/form/AnimalBreedsFormField';
import AnimalColorsFormField from 'components/form/AnimalColorsFormField';
import PaymentOptionsFormField from 'components/form/PaymentOptionsFormField';
import normalizeNumberInput from 'utils/form/normalizeNumberInput';
import { validateZipCode } from 'utils/validators/zipCode';
import selectFilterOptions from 'utils/form/selectFilterOptions';
import generateEntityCode from 'utils/generateEntityCode';
import { dollarToCents } from 'utils/formatters/formatMoney';
import {
  selectBreeds,
  selectColors,
  selectCounties,
  selectGenders,
  selectStates,
  selectUtahState,
} from 'selectors/resources';
import { getAnimal } from 'utils/animals';
import { parseMarkings } from 'utils/markings';
import { getRequiredPerson } from 'utils/persons';
import useCreditCardCheckout, {
  type WrappedCheckoutUtils,
  type PaymentListenerProps,
} from 'utils/hooks/useCreditCardCheckout';
import { createPreSaleInspection } from '../createHorsePreSaleSlice';
import initialState from '../initialState';
import Signatures from './Signatures';

type Props = {
  category: number,
  form: WrappedFormUtils,
  loading: boolean,
};

const { Option } = Select;

let generatedCode;

const MainForm = ({ form, category, loading }: Props) => {
  const dispatch: Dispatch = useDispatch();

  const user: UserType = useSelector(selectMe);
  const states = useSelector(selectStates);
  const counties = useSelector(selectCounties);
  const colors = useSelector(selectColors);
  const breeds = useSelector(selectBreeds);
  const genders = useSelector(selectGenders);
  const location: Location = useLocation();

  const utahState: State = useSelector(selectUtahState);

  const handleChange = (value: { [string]: * }) => {
    if (value) {
      const key: string = Object.keys(value)[0];

      if (key === 'owner' && value[key]?.state) {
        const currentValues = form.getFieldValue(key);

        form.setFieldsValue({
          [key]: {
            ...currentValues,
            county: null,
          },
        });
      }

      if (['sellerSignature', 'inspectorSignature'].includes(key)) {
        form.setFieldsValue({
          [`${key}Date`]: moment().format('MM/DD/YYYY'),
        });
      }
    }
  };

  const submit = (values: $Shape<DraftPreSaleInspectionType>) => {
    const { publicId, code } = generatedCode;
    const { owner, animal, markings, ...rest } = values;

    dispatch(
      createPreSaleInspection({
        ...rest,
        owner: getRequiredPerson(owner, counties, states),
        animal: {
          ...getAnimal(animal, genders, colors, breeds),
          category,
        },
        markings: parseMarkings(markings),
        publicId,
        presaleCode: code,
      }),
    );
  };

  const paymentListener = ({ success }: PaymentListenerProps) => {
    if (success) {
      const values: $Shape<DraftPreSaleInspectionType> = form.getFieldsValue();

      submit(values);
    }
  };

  const { checkout }: WrappedCheckoutUtils = useCreditCardCheckout(
    paymentListener,
  );

  const handleSubmit = (values: $Shape<DraftPreSaleInspectionType>) => {
    if (!loading) {
      const { payment, cost } = values;
      const isCardPayment = payment?.paymentType === 'creditcard';
      const { publicId } = generatedCode;

      if (publicId) {
        if (isCardPayment) {
          checkout({
            amountInCents: dollarToCents(cost),
            publicId,
            entityType: 'presale',
          });
        } else {
          submit(values);
        }
      }
    }
  };

  const handleRemovePhoto = (index: number) => {
    const photos = [...form.getFieldValue('photos')];

    photos.splice(index, 1);

    form.setFieldsValue({ photos });
  };

  const ownerState = location.state;

  const ownerDefaults = ownerState
    ? {
        ...ownerState,
        state: ownerState?.state?.id,
        county: ownerState?.county?.id,
      }
    : {
        ...initialState.owner,
        state: utahState.id,
      };

  const hasExistingInspectorSignature = !!user?.signature;

  const initialValues = {
    ...initialState,
    owner: ownerDefaults,
    inspectorSignature: user?.signature || '',
    inspectorSignatureDate: hasExistingInspectorSignature
      ? moment().format('MM/DD/YYYY')
      : '',
    photos: [],
  };

  const fetchCodes = async () => {
    const code = (await generateEntityCode('PSH')) || {};

    generatedCode = code;
  };

  useEffectOnce(() => {
    fetchCodes();
  });

  return (
    <Form
      onFinish={handleSubmit}
      onValuesChange={handleChange}
      initialValues={initialValues}
      form={form}
      name="HorsePreSaleForm"
      colon={false}
      layout="vertical"
    >
      <Content>
        <Row gutter={16}>
          <Col lg={6} md={8}>
            <Form.Item label="Breed" name={['animal', 'breed']}>
              <AnimalBreedsFormField
                category={category}
                sort={({ breed }: Breed) => {
                  if (
                    ['quarter', 'grade', 'thoroughbred'].includes(
                      breed.toLowerCase(),
                    )
                  ) {
                    return -1;
                  }

                  return 1;
                }}
              />
            </Form.Item>
          </Col>

          <Col lg={6} md={8}>
            <Form.Item
              label="Year Born"
              name="year"
              normalize={normalizeNumberInput}
              rules={[
                {
                  required: true,
                  message: 'Year Born is required',
                },
                {
                  type: 'string',
                  message: 'Must only contain numbers',
                  pattern: /^\d+$/,
                },
                ({ getFieldValue }) => ({
                  validator() {
                    const year = parseInt(getFieldValue('year'), 10);

                    const currentYear = new Date().getFullYear();

                    if (year <= currentYear && year >= 1900) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error('Valid range is between 1900 to current year.'),
                    );
                  },
                }),
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col lg={6} md={8}>
            <Form.Item label="Color" name={['animal', 'color']}>
              <AnimalColorsFormField category={category} />
            </Form.Item>
          </Col>

          <Col lg={6} md={8}>
            <Form.Item label="Registered Name" name="registeredName">
              <Input />
            </Form.Item>
          </Col>

          <Col lg={6} md={8}>
            <Form.Item
              label="Gender"
              name={['animal', 'gender']}
              rules={[{ required: true, message: 'Gender is required' }]}
            >
              <AnimalGendersFormField category={category} />
            </Form.Item>
          </Col>

          <Col lg={6} md={8}>
            <Form.Item
              label="Brand"
              name={['animal', 'brands']}
              rules={[{ required: true, message: 'Brand is required' }]}
            >
              <BrandsFormField />
            </Form.Item>
          </Col>

          <Col lg={6} md={8}>
            <Form.Item label="Clearance" name="clearance">
              <Input />
            </Form.Item>
          </Col>

          <Col lg={6} md={8}>
            <Form.Item label="Animal Photo" name="photos">
              <CameraFormField />
            </Form.Item>
          </Col>

          <Col lg={6} md={8}>
            <Form.Item label="Draw Mark" name="markings">
              <HorseMarkingsFormField />
            </Form.Item>
          </Col>
        </Row>

        <Divider />

        <Row gutter={16}>
          <Col lg={6} md={13}>
            <Form.Item
              label="Cost"
              name="cost"
              rules={[{ required: true, message: 'Cost is required' }]}
            >
              <Select showSearch filterOption={selectFilterOptions}>
                <Option value={20}>$20.00 (minimum up to 10 head)</Option>
                <Option value={0}>$0.00 (paid on another certificate)</Option>
                <Option value={2}>
                  $2.00 (in addition to the $20.00 minimum)
                </Option>
              </Select>
            </Form.Item>
          </Col>

          <Col lg={6} md={11}>
            <Form.Item
              label="Owner"
              name={['owner', 'name']}
              rules={[{ required: true, message: 'Owner is required' }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col lg={6} md={8}>
            <Form.Item
              label="Address"
              name={['owner', 'address']}
              rules={[{ required: true, message: 'Address is required' }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col lg={6} md={8}>
            <Form.Item
              label="City"
              name={['owner', 'city']}
              rules={[{ required: true, message: 'City is required' }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col lg={6} md={8}>
            <Form.Item
              label="State"
              name={['owner', 'state']}
              rules={[{ required: true, message: 'State is required' }]}
            >
              <StatesFormField />
            </Form.Item>
          </Col>

          <Col lg={6} md={8}>
            <Form.Item
              shouldUpdate={(prevValues, curValues) => {
                return prevValues.owner?.state !== curValues.owner?.state;
              }}
              noStyle
            >
              {(form) => {
                const state = form.getFieldValue(['owner', 'state']);

                return (
                  <Form.Item
                    label="County"
                    name={['owner', 'county']}
                    rules={[{ required: true, message: 'County is required' }]}
                  >
                    <CountiesFormField state={state} />
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>

          <Col lg={6} md={8}>
            <Form.Item
              label="Zip code"
              name={['owner', 'zipCode']}
              rules={[
                {
                  required: true,
                  message: 'Zip code is required',
                },
                validateZipCode,
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col lg={6} md={8}>
            <Form.Item
              label="Phone"
              name={['owner', 'phone']}
              rules={[{ required: true, message: 'Phone is required' }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Signatures
          hasExistingInspectorSignature={hasExistingInspectorSignature}
        />

        <Row justify="space-between" gutter={10}>
          <Col md={16}>
            <PaymentOptionsFormField />
          </Col>
        </Row>

        <Typography.Title level={5} style={{ marginBottom: 5 }}>
          Owner Consent
        </Typography.Title>

        <Form.Item
          name="consent"
          valuePropName="checked"
          rules={[
            {
              required: true,
              message:
                'Owner must agree to the Terms and Conditions to proceed.',
            },
          ]}
        >
          <Checkbox>
            The information I have provided is true and accurate, and I agree to
            the terms and conditions of this inspection.
          </Checkbox>
        </Form.Item>

        <PhotoAttachments onRemoveAt={handleRemovePhoto} />
      </Content>
    </Form>
  );
};

export default MainForm;
