const { hostname: host } = window.location;
const {
  NODE_ENV,
  REACT_APP_VERSION,
  REACT_APP_INSPECTIONS_API_URL,
  REACT_APP_BRANDS_API_URL,
  REACT_APP_AUTH_URL,
  REACT_APP_BRANDS_URL,
  REACT_APP_INSPECTION_URL,
} = process.env;

const LOCALHOST = 'localhost';
const NGROK = 'ngrok';
const PRODUCTION = 'production';
const STAGING = 'staging';
const CALLBACK_PATH = '/payment/summary';

export const IS_DEV_ENVIRONMENT =
  host.includes(LOCALHOST) || host.includes(NGROK);
export const IS_STAGING_ENVIRONMENT = host.includes(STAGING);
export const IS_PROD_ENVIRONMENT =
  !IS_STAGING_ENVIRONMENT && !IS_DEV_ENVIRONMENT;

export const ENVIRONMENT = {
  current:
    (IS_PROD_ENVIRONMENT && PRODUCTION) ||
    (IS_STAGING_ENVIRONMENT && STAGING) ||
    'development',
  build: {
    type: NODE_ENV,
    version: REACT_APP_VERSION,
  },
};

const getStagingBaseDomain = () => host.substring(host.indexOf('-') + 1);
const getProdBaseDomain = () => host.substring(host.indexOf('.') + 1);

const getAppUrl = (app, localAlternativeUrl) => {
  if (
    IS_PROD_ENVIRONMENT ||
    (!IS_STAGING_ENVIRONMENT && !localAlternativeUrl) // if no env variable has been passed
  ) {
    return `https://${app}.${getProdBaseDomain()}`;
  }
  if (IS_STAGING_ENVIRONMENT) {
    return `https://${app}-${getStagingBaseDomain()}`;
  }
  return localAlternativeUrl;
};

export const getInspectionsApiUrl = () => {
  const baseInspectionApiUrl = getAppUrl(
    'inspect-api',
    REACT_APP_INSPECTIONS_API_URL,
  );

  return `${baseInspectionApiUrl}/api`;
};

export const getBrandsApiUrl = () => getAppUrl('api', REACT_APP_BRANDS_API_URL);
export const getAuthUrl = () => getAppUrl('auth', REACT_APP_AUTH_URL);
export const getInspectionUrl = () =>
  getAppUrl('inspect', REACT_APP_INSPECTION_URL);

export const getBrandPortalUrl = () => {
  if (
    IS_PROD_ENVIRONMENT ||
    (!IS_STAGING_ENVIRONMENT && !REACT_APP_BRANDS_URL) // if no env variable has been passed
  ) {
    return `https://${getProdBaseDomain()}`;
  }
  if (IS_STAGING_ENVIRONMENT) {
    return `https://${getStagingBaseDomain()}`;
  }
  return REACT_APP_BRANDS_URL;
};
export const getCallbackUrl = () => {
  if (IS_PROD_ENVIRONMENT) {
    return `https://inspect.${getProdBaseDomain()}${CALLBACK_PATH}`;
  }
  return `https://inspect-staging.brands.utah.gov${CALLBACK_PATH}`;
};
