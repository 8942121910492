// @flow
import Dexie from 'dexie';
import { BRAND_UPDATE_HISTORY } from 'flow/brands';
import type { Inspection, DraftInspection } from 'flow/inspection';
import { stringToTimestamp } from 'utils/dates';
import { convertWaivedFeesToNumber } from 'utils/dbUpgrade';
import { createLineItems } from 'utils/lineItems';
import { INSPECTION_UPDATE_HISTORY } from 'flow/inspection';

export const INSPECTIONS_DB_NAME = 'Inspections';

export const INSPECTIONS_DB_VERSION = 13;

export const inspectionsDb = (() => {
  const db = new Dexie(INSPECTIONS_DB_NAME);

  // Version 13 adding user.id as an index
  db.version(INSPECTIONS_DB_VERSION).stores({
    inspections:
      'id,inspectionDate,payment.paymentType,inspectionCode,publicId,user.id',
    offlineInspections:
      'publicId,inspectionDate,payment.paymentType,inspectionCode,user.id',
    preSaleInspections:
      'id,presaleDate,payment.paymentType,presaleCode,publicId,user.id',
    offlinePreSaleInspections:
      'publicId,presaleDate,payment.paymentType,presaleCode,user.id',
    travelPermit:
      'id,permitDate,payment.paymentType,permitCode,publicId,user.id',
    offlineTravelPermit:
      'publicId,permitDate,payment.paymentType,permitCode,user.id',
  });

  // adding inspections.history table for tracking synchronization
  db.version(12).stores({
    history: `++${INSPECTION_UPDATE_HISTORY.ID}, ${INSPECTION_UPDATE_HISTORY.TYPE}, ${INSPECTION_UPDATE_HISTORY.USER}, ${INSPECTION_UPDATE_HISTORY.DATE}`,
  });

  db.version(11).upgrade((trans) => {
    const { inspections, preSaleInspections, travelPermit } = trans;

    return Promise.all([
      inspections.toCollection().modify((inspection) => ({
        ...inspection,
        inspectionDate: stringToTimestamp(inspection.inspectionDate),
      })),
      preSaleInspections.toCollection().modify((presale) => ({
        ...presale,
        presaleDate: stringToTimestamp(presale.presaleDate),
      })),
      travelPermit.toCollection().modify((permit) => ({
        ...permit,
        permitDate: stringToTimestamp(permit.permitDate),
      })),
    ]);
  });

  db.version(10).stores({
    offlinePayments: 'id',
  });

  db.version(9).stores({
    inspections:
      'id,inspectionDate,payment.paymentType,inspectionCode,publicId',
    offlineInspections:
      'publicId,inspectionDate,payment.paymentType,inspectionCode',
    preSaleInspections:
      'id,presaleDate,payment.paymentType,presaleCode,publicId',
    offlinePreSaleInspections:
      'publicId,presaleDate,payment.paymentType,presaleCode',
    travelPermit: 'id,permitDate,payment.paymentType,permitCode,publicId',
    offlineTravelPermit: 'publicId,permitDate,payment.paymentType,permitCode',
  });

  db.version(8).stores({
    travelPermit: 'id,publicId,permitDate,payment.paymentType',
    preSaleInspections: 'id,publicId,presaleDate,payment.paymentType',
  });

  db.version(7).stores({
    inspections: 'id,inspectionDate,payment.paymentType',
    offlineInspections: 'publicId,inspectionDate,payment.paymentType',
    preSaleInspections: 'id,presaleDate,payment.paymentType',
    offlinePreSaleInspections: 'publicId,presaleDate,payment.paymentType',
    travelPermit: 'id,permitDate,payment.paymentType',
    offlineTravelPermit: 'publicId,permitDate,payment.paymentType',
  });

  db.version(6).upgrade(async (trans) => {
    const serviceFees = await trans.serviceFees.toArray();

    // todo make sure that this gets execute before any action
    // see old code in
    return trans.offlineInspections
      .toCollection()
      .modify((inspection: Inspection) => {
        const existingLineItems = inspection?.lineItems || [];

        if (inspection.waivedFees || existingLineItems.length === 0) {
          const lineItems = createLineItems({
            inspection,
            serviceFees: serviceFees || [],
            category: inspection?.animals[0].category,
            waivedFees: inspection?.waivedFees || [],
          });

          delete inspection.waivedFees;

          inspection.lineItems = lineItems;
        }
      });
  });

  db.version(5).stores({
    inspections: 'id,inspectionDate',
    offlineInspections: 'publicId,inspectionDate',
    preSaleInspections: 'id,presaleDate',
    offlinePreSaleInspections: 'publicId,presaleDate',
    travelPermit: 'id,permitDate',
    offlineTravelPermit: 'publicId,permitDate',
  });

  db.version(4).stores({
    services: 'id',
    serviceFees: 'id',
    prices: null,
  });

  db.version(3).stores({
    inspections: 'id,inspectionCode',
    offlineInspections: 'publicId,inspectionCode',
    preSaleInspections: 'id,presaleCode',
    offlinePreSaleInspections: 'publicId,presaleCode',
    travelPermit: 'id,permitCode',
    offlineTravelPermit: 'publicId,permitCode',
  });

  db.version(2).upgrade((trans) => {
    trans.offlineInspections.toCollection().modify((inspection: Inspection) => {
      inspection.waivedFees = convertWaivedFeesToNumber(inspection?.waivedFees);
    });

    return trans.draftInspections
      .toCollection()
      .modify((inspection: DraftInspection) => {
        inspection.waivedFees = convertWaivedFeesToNumber(
          inspection?.waivedFees,
        );
      });
  });

  db.version(1).stores({
    categories: 'id',
    breeds: 'id',
    colors: 'id',
    genders: 'id',
    tags: 'id',
    inspectionTypes: 'id',
    counties: 'id',
    states: 'id',
    prices: 'id',
    dealers: 'id',
    user: 'id',
    inspections: 'id',
    draftInspections: '++id',
    offlineInspections: 'publicId',
    preSaleInspections: 'id',
    offlinePreSaleInspections: 'publicId',
    travelPermit: 'id',
    offlineTravelPermit: 'publicId',
  });

  db.open().catch((err) =>
    console.error(`Failed to open inspections db: ${err.stack || err}`),
  );

  return db;
})();

export const brandsDb = (() => {
  const brandsDb = new Dexie('Brands');
  brandsDb.version(1).stores({
    history: `++${BRAND_UPDATE_HISTORY.ID}, ${BRAND_UPDATE_HISTORY.DATE}`,
    brands:
      '&id,brandNumber,position,*letters,*firstNames,*lastNames,*counties,*zipcodes,isSheep',
  });
  brandsDb
    .open()
    .catch((err) =>
      console.error(`Failed to open brands db: ${err.stack || err}`),
    );
  return brandsDb;
})();

export const errorsDb = (() => {
  const db = new Dexie('Errors');

  db.version(1).stores({
    errors: '++errorId,payload,endpoint,errorStack,platform,browser,eventDate',
  });

  db.open().catch((err) =>
    console.error(`Failed to open errors db: ${err.stack || err}`),
  );

  return db;
})();

export const codesDb = (() => {
  const db = new Dexie('Codes');

  db.version(2).stores({
    devices: '++id,deviceId',
    codes: 'id,code',
    inspectionCodes: null,
    travelPermitCodes: null,
    presaleCodes: null,
  });

  db.version(1).stores({
    devices: '++id,deviceId',
    inspectionCodes: 'id,&code',
    travelPermitCodes: 'id,&code',
    presaleCodes: 'id,&code',
  });

  db.open().catch((err) =>
    console.error(`Failed to open errors db: ${err.stack || err}`),
  );

  return db;
})();

export const clearCodes = () => {
  return Promise.all([
    codesDb.presaleCodes.clear(),
    codesDb.inspectionCodes.clear(),
    codesDb.travelPermitCodes.clear(),
  ]);
};
