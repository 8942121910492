// @flow
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { faceMiddle } from 'utils/markings';
import type { Marking } from 'flow/animal';

type Props = {
  photos: Array<string>,
  markings: Array<Marking>,
};

export const displayPhotos = (photo: string, index: number) => (
  <img src={photo} key={`cert-photo-${index}`} alt={`number ${index}`} />
);

export const getImageHeight = (totalImageCount: number): string => {
  const count = totalImageCount;
  if (count > 6) return '.8in';
  if (count === 6) return '.95in';
  if (count === 5) return '1.05in';
  if (count === 4) return '1.25in';
  return '1.4in';
};

const PhotosAndMarkings = ({ photos, markings }: Props) => {
  const photoCount = photos?.length || 0;
  const markingsCount = markings?.length || 0;

  const hasPhotos = photoCount > 0;
  const hasMarkings = markingsCount > 0;

  const imageHeight = getImageHeight(photoCount + markingsCount);

  return (
    <Images height={imageHeight}>
      {hasPhotos && photos.map(displayPhotos)}
      {hasMarkings && <Markings markings={markings} height={imageHeight} />}
    </Images>
  );
};

export const Images = styled.div`
  display: flex;
  flex: auto;
  padding: 0;
  justify-content: center;
  flex-wrap: nowrap;
  img {
    margin-right: 0.5em;
    height: ${(props) => props.height || ' 1.3in'};
    border-radius: 10px;
  }
  img:last-child {
    margin-right: 0;
  }
`;

const Mark = styled.div`
  padding: 0;
  margin: 0 auto;
  em {
    display: block;
    line-height: 1.1em;
    text-align: center;
  }
  img {
    margin: 0;
    border-radius: 10px;
    height: ${(props) => props.height || '1in'};
  }
`;

type MarkingProps = {
  markings: Array<Marking>,
  height: string,
};

export const Markings = ({ markings, height }: MarkingProps) => {
  return (
    <Fragment>
      {[...markings].sort(faceMiddle).map(({ image, notes }, i) => (
        <Mark key={`cert-markings-${i}`} height={height}>
          <img src={image} alt={`marking ${i}`} />
          <em>{notes}</em>
        </Mark>
      ))}
    </Fragment>
  );
};

export default PhotosAndMarkings;
