// @flow
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import omitBy from 'lodash/omitBy';
import type { ReduxStore } from 'flow/redux';
import type { PaymentType } from 'flow/payment';
import { showErrorMessage } from 'utils/showMessage';
import { timestampToString } from 'utils/dates';
import { monthUtil } from '@acclaim/udaf-kit';

const adapter = createEntityAdapter();

const initialState = adapter.getInitialState({
  loading: false,
  total: 0,
});

export const { selectAll: selectPreSaleInspections } = adapter.getSelectors(
  (state: ReduxStore) => {
    return state.adminPreSaleInspections;
  },
);

export const fetchPreSaleInspections = createAsyncThunk(
  'adminPreSaleInspections/fetchPreSaleInspections',
  async (
    filters: ?{
      limit: number,
      offset: number,
      from: ?number,
      to: ?number,
      query: ?string,
      paymentType: ?PaymentType,
    },
    { extra, rejectWithValue },
  ) => {
    const { limit, offset, from, to, query, paymentType } = filters || {};
    const { inspectionClient } = extra;

    try {
      const { data } = await inspectionClient().get('/presale/', {
        params: {
          limit,
          offset,
          monthsData: monthUtil.getmonthToken(),
          ...omitBy(
            {
              start: timestampToString(from),
              end: timestampToString(to),
              q: query,
              paymentType,
            },
            (v: string) => !v,
          ),
        },
      });

      return data;
    } catch (err) {
      showErrorMessage(err?.response?.data?.message);

      return rejectWithValue(err);
    }
  },
);

const adminPreSaleInspectionsSlice = createSlice({
  name: 'adminPreSaleInspections',
  initialState,
  reducers: {
    clearAll: adapter.removeAll,
  },
  extraReducers: {
    [fetchPreSaleInspections.pending]: (state) => {
      state.loading = true;
    },
    [fetchPreSaleInspections.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.total = payload.count;
      adapter.setAll(state, payload.results);
    },
    [fetchPreSaleInspections.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const selectLoading = (state: ReduxStore) =>
  state.adminPreSaleInspections.loading;

export const selectTotal = (state: ReduxStore) =>
  state.adminPreSaleInspections.total;

export const { clearAll } = adminPreSaleInspectionsSlice.actions;

export default adminPreSaleInspectionsSlice.reducer;
