// @flow
export const INSPECTION_TABLES = {
  ANIMAL_CATEGORIES: 'categories',
  ANIMAL_BREEDS: 'breeds',
  ANIMAL_COLORS: 'colors',
  ANIMAL_GENDERS: 'genders',
  ANIMAL_TAGS: 'tags',
  COUNTIES: 'counties',
  STATES: 'states',
  DEALERS: 'dealers',
  INSPECTION_TYPES: 'inspectionTypes',
  SERVICES: 'services',
  SERVICE_FEES: 'serviceFees',
};
