// @flow
import React, { Fragment, useState } from 'react';
import { Button, Space } from 'antd';
import { HighlightOutlined, CheckCircleTwoTone } from '@ant-design/icons';
import styled from 'styled-components';
import MarkingsModal from './components/MarkingsModal';

const StyledButton = styled(Button)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 11px;
`;

export type ValueType = {
  [string]: {
    [string]: string,
  },
};

type Props = {
  placeholder?: string,
  value: ValueType,
  onChange: (ValueType) => void,
  id: string,
};

const HorseMarkingsFormField = ({
  placeholder,
  value,
  onChange,
  id,
}: Props) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const handleModalOpen = () => setModalOpen(true);

  const handleModalClose = () => setModalOpen(false);

  const hasValue = Object.keys(value || {}).some((key: string) => {
    return !!value[key].image;
  });

  return (
    <Fragment>
      <StyledButton onClick={handleModalOpen} id={id}>
        {placeholder}
        <Space size="small" direction="horizontal">
          {hasValue && <CheckCircleTwoTone twoToneColor="#8cd5db" />}
          <HighlightOutlined />
        </Space>
      </StyledButton>
      <MarkingsModal
        open={modalOpen}
        value={value}
        onCancel={handleModalClose}
        onChange={onChange}
      />
    </Fragment>
  );
};

const DEFAULT = { image: '', notes: '' };

HorseMarkingsFormField.defaultProps = {
  placeholder: 'Draw Mark',
  // eslint-disable-next-line
  onChange: (value: ValueType) => {},
  value: {
    left: DEFAULT,
    right: DEFAULT,
    face: DEFAULT,
  },
  id: '',
};

export default HorseMarkingsFormField;
