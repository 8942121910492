// @flow
import type { Dispatch } from 'react-redux';
import type { SyncApi } from 'flow/sync';
import type { OfflineInspection, ServiceInspection } from 'flow/inspection';
import { persistBrandsOnManyAnimals } from 'service/sync/util';
import { atomicSync } from 'service/sync';
import { inspectionsDb as db } from 'service/idb';
import { getServiceInspectionTypes } from 'utils/inspectionTypes';
import { getPrimitivePerson, getPrimitiveRequiredPerson } from 'utils/persons';

class InspectionSync implements SyncApi<OfflineInspection, ServiceInspection> {
  apiUrl = '/inspections/';

  offlineTable = 'offlineInspections';

  serviceCacheTable = 'inspections';

  getId(inspection: OfflineInspection): string {
    return inspection.publicId;
  }

  getCodeKey(inspection: OfflineInspection): string {
    return inspection.inspectionCode;
  }

  persistHandDrawnBrands(
    dispatch: Dispatch,
    inspection: OfflineInspection,
  ): void {
    persistBrandsOnManyAnimals(
      inspection.animals,
      inspection.publicId,
      dispatch,
      inspection.inspectionDate,
    );
  }

  apiAdapter(inspection: OfflineInspection): ServiceInspection {
    const { owner, buyer, types } = inspection;
    return {
      ...inspection,
      owner: getPrimitiveRequiredPerson(owner),
      buyer: getPrimitivePerson(buyer),
      types: getServiceInspectionTypes(types),
    };
  }

  async customResponseHandler(inspection: ServiceInspection): Promise<void> {
    const { dealer } = inspection;
    if (dealer) {
      db.dealers.put(dealer);
    }
    return Promise.resolve();
  }
}

const api: SyncApi<OfflineInspection, ServiceInspection> = new InspectionSync();

const syncInspection = (dispatch: Dispatch) => atomicSync(api, dispatch);

export default syncInspection;
