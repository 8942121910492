// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import { Alert } from 'antd';
import type { ReduxStore } from 'flow/redux';
import styled from 'styled-components';

const StyledAlert = styled(Alert).attrs({
  showIcon: true,
})`
  margin-bottom: 20px;
`;

const CodesAlertBanner = () => {
  const codesCount: number = useSelector((state: ReduxStore) => {
    return state.initializer.codesCount;
  });

  if (codesCount === 10) {
    return (
      <StyledAlert
        type="warning"
        message="You only have ten available Certificate Numbers left. You will receive additional Certificate Numbers next time you sync."
      />
    );
  }

  if (codesCount === 50) {
    return (
      <StyledAlert
        type="info"
        message="You have used half of your available Certificate Numbers. You will receive additional Certificate Numbers next time you sync."
      />
    );
  }

  return null;
};

export default CodesAlertBanner;
