// @flow
import { useLocation, useHistory, type Location } from 'react-router-dom';
import { stringToTimestamp } from '../dates';

export type QueryType = 'query' | 'page' | 'from' | 'to';

export type Query = {
  page: number,
  query: string,
  from: string,
  to: string,
  patchQuery: (QueryType, number | string) => void,
  patchQueries: ({
    [QueryType]: string | number,
  }) => void,
  clearQuery: () => void,
};

const DEFAULT_LIMIT = 20;

const useQuery = () => {
  const { search, pathname }: Location = useLocation();

  const history = useHistory();

  const patchQuery = (type: QueryType, value: number | string) => {
    const params = new URLSearchParams(search);

    params.set(type, String(value));

    history.push({
      pathname,
      search: `?${params.toString()}`,
    });
  };

  const patchQueries = (queries: { [QueryType]: string | number }) => {
    const params = new URLSearchParams(search);

    Object.keys(queries).forEach((key: QueryType) => {
      if (queries[key]) {
        params.set(key, String(queries[key]));
      } else {
        params.delete(key);
      }
    });

    history.push({
      pathname,
      search: `?${params.toString()}`,
    });
  };

  const clearQuery = () => {
    history.push({
      pathname,
      search: '',
    });
  };

  const queryParams = new URLSearchParams(search);
  const page = parseInt(queryParams.get('page') || 1, 10);

  return {
    page,
    offset: (page - 1) * DEFAULT_LIMIT,
    limit: DEFAULT_LIMIT,
    query: queryParams.get('query') || '',
    from: stringToTimestamp(queryParams.get('from')),
    to: stringToTimestamp(queryParams.get('to')),
    patchQuery,
    patchQueries,
    clearQuery,
  };
};

export default useQuery;
