// @flow
import React, { useState } from 'react';
import { Modal, Input, Typography } from 'antd';

type Props = {
  visible: boolean,
  confirmLoading: boolean,
  onCancel: () => void,
  onConfirm: (string) => Promise<void>,
};

const { TextArea } = Input;

const VoidModal = ({ visible, confirmLoading, onCancel, onConfirm }: Props) => {
  const [reason, setReason] = useState<string>('');

  const handleReasonChange = (ev) => {
    setReason(ev.target.value);
  };

  return (
    <Modal
      title="Are you sure you want to void this item?"
      visible={visible}
      onCancel={onCancel}
      afterClose={() => setReason('')}
      okText="Confirm"
      onOk={() => onConfirm(reason)}
      confirmLoading={confirmLoading}
    >
      <br />
      <Typography.Paragraph>Reason (Optional)</Typography.Paragraph>
      <TextArea onChange={handleReasonChange} value={reason} />
    </Modal>
  );
};

export default VoidModal;
