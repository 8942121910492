// @flow
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Button, Row, Col } from 'antd';
import Content from 'components/Content';
import { updateOwnerInformation } from 'pages/BrandInspectionPage/inspectionSlice';
import {
  selectStepOneFormData,
  selectInspectionLoading,
} from 'pages/BrandInspectionPage/selectors';
import { selectCounties, selectStates } from 'selectors/resources';
import { getPerson, getRequiredPerson } from 'utils/persons';
import OwnerForm from './components/OwnerForm';
import BuyerForm from './components/BuyerForm';
import TransportForm from './components/TransportForm';

const BrandInspectionsOwnersForm = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const initialValues = useSelector(selectStepOneFormData);
  const states = useSelector(selectStates);
  const counties = useSelector(selectCounties);
  const loading = useSelector(selectInspectionLoading);

  const handleSubmit = async (values: { [string]: * }) => {
    const { owner, buyer } = values;
    const ownershipData = {
      ...values,
      owner: getRequiredPerson(owner, counties, states),
      buyer: getPerson(buyer, counties, states),
      inspectionDate: values.inspectionDate.format('MM/DD/YYYY'),
    };
    await dispatch(updateOwnerInformation(ownershipData));
  };

  const handleChange = (value: { [string]: * }) => {
    if (value) {
      const key: string = Object.keys(value)[0];

      if (['owner', 'buyer'].includes(key) && value[key]?.state) {
        const currentValues = form.getFieldValue(key);

        form.setFieldsValue({
          [key]: {
            ...currentValues,
            county: null,
          },
        });
      }
    }
  };

  return (
    <Form
      onFinish={handleSubmit}
      onValuesChange={handleChange}
      initialValues={initialValues}
      form={form}
      name="OwnerInformationForm"
      colon={false}
      layout="vertical"
    >
      <Content>
        <OwnerForm />
        <BuyerForm />
        <TransportForm />
      </Content>
      <Row justify="end">
        <Col lg={4} md={6}>
          <Button
            htmlType="submit"
            type="primary"
            block
            loading={loading}
            id="confirmInspectionStepOne"
          >
            Next
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default BrandInspectionsOwnersForm;
