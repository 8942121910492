// @flow
import React from 'react';
import { Row, Col, Form, InputNumber, Button, Input } from 'antd';
import styled from 'styled-components';
import { DeleteOutlined } from '@ant-design/icons';
import type { Category, Breed } from 'flow/resources';
import normalizeNumberInput from 'utils/form/normalizeNumberInput';
import BrandsFormField from 'components/form/BrandsFormField';
import AnimalCategoriesFormField from 'components/form/AnimalCategoriesFormField';
import AnimalBreedsFormField from 'components/form/AnimalBreedsFormField';
import AnimalColorsFormField from 'components/form/AnimalColorsFormField';
import AnimalGendersFormField from 'components/form/AnimalGendersFormField';
import AnimalTagsFormField from 'components/form/AnimalTagsFormField';

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;

  > .ant-row {
    flex: 1;
  }

  > button {
    margin-left: 0.75em;
  }
`;

type Props = {
  index: number,
  handleRemoveAnimal: (number) => void,
  categories: Array<Category>,
  categorySlug: string,
};

const AnimalForm = ({
  index,
  handleRemoveAnimal,
  categories,
  categorySlug,
}: Props) => {
  const isFirstEntry = index === 0;

  return (
    <StyledWrapper size="small">
      <Form.Item
        shouldUpdate={(prevValues, curValues) =>
          prevValues.category !== curValues.category
        }
        style={{ marginBottom: 0 }}
      >
        {(form) => {
          // TODO @ajmiclat: remove hardcoded fields
          const category = form.getFieldValue('category');

          const obj = categories.find((c: Category) => c.id === category) || {};

          const categoryFields = {
            horse: ['brands', 'color', 'breed', 'gender', 'quantity'],
            elk: [
              'brands',
              'gender',
              'age',
              'gender',
              'rfid',
              'usda',
              'tag',
              'tagColorNumber',
              'quantity',
            ],
            sheep: ['brands', 'breed', 'gender', 'quantity'],
            cattle: ['brands', 'color', 'breed', 'gender', 'quantity'],
          };

          const fields = categoryFields[obj?.slug] || [];

          const isBrandsRequired = ['horse', 'sheep', 'cattle'].includes(
            obj?.slug || '',
          );

          return (
            <Row gutter={12}>
              <Col lg={4} md={8} style={{ display: 'none' }}>
                <Form.Item name={['animals', index, 'lineNumber']}>
                  <Input />
                </Form.Item>
              </Col>
              <Col lg={4} md={8}>
                <Form.Item
                  label="Animal"
                  name="category"
                  rules={[
                    {
                      required: true,
                      message: 'Animal is required',
                    },
                  ]}
                >
                  <AnimalCategoriesFormField disabled={!isFirstEntry} />
                </Form.Item>
              </Col>

              {fields.includes('brands') && (
                <Col lg={4} md={8}>
                  <Form.Item
                    label="Brand"
                    name={['animals', index, 'brands']}
                    rules={[
                      {
                        required: isBrandsRequired,
                        message: 'Brand is required',
                      },
                    ]}
                  >
                    <BrandsFormField />
                  </Form.Item>
                </Col>
              )}

              {fields.includes('color') && (
                <Col lg={4} md={8}>
                  <Form.Item
                    label="Color"
                    name={['animals', index, 'color']}
                    rules={[
                      {
                        required: ['elk', 'sheep'].includes(categorySlug),
                        message: 'Color is required',
                      },
                    ]}
                  >
                    <AnimalColorsFormField category={category} />
                  </Form.Item>
                </Col>
              )}

              {fields.includes('breed') && (
                <Col lg={4} md={8}>
                  <Form.Item
                    label="Breed"
                    name={['animals', index, 'breed']}
                    rules={[
                      {
                        required: ['elk', 'sheep'].includes(categorySlug),
                        message: 'Breed is required',
                      },
                    ]}
                  >
                    <AnimalBreedsFormField
                      category={category}
                      sort={({ breed }: Breed) => {
                        if (
                          ['quarter', 'grade', 'thoroughbred'].includes(
                            breed.toLowerCase(),
                          ) &&
                          categorySlug === 'horse'
                        ) {
                          return -1;
                        }

                        return 1;
                      }}
                    />
                  </Form.Item>
                </Col>
              )}

              {fields.includes('gender') && (
                <Col lg={4} md={8}>
                  <Form.Item
                    label="Gender"
                    name={['animals', index, 'gender']}
                    rules={[
                      {
                        required: true,
                        message: 'Gender is required',
                      },
                    ]}
                  >
                    <AnimalGendersFormField category={category} />
                  </Form.Item>
                </Col>
              )}

              {fields.includes('age') && (
                <Col lg={4} md={8}>
                  <Form.Item
                    label="Age"
                    name={['animals', index, 'age']}
                    rules={[
                      {
                        required: true,
                        message: 'Age is required',
                      },
                    ]}
                    normalize={normalizeNumberInput}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              )}

              {fields.includes('rfid') && (
                <Col lg={4} md={8}>
                  <Form.Item label="RFID" name={['animals', index, 'rfid']}>
                    <Input />
                  </Form.Item>
                </Col>
              )}

              {fields.includes('usda') && (
                <Col lg={4} md={8}>
                  <Form.Item
                    label="USDA"
                    name={['animals', index, 'usda']}
                    rules={[
                      {
                        type: 'string',
                        message: 'Enter valid number (e.g 87AFE1234)',
                        pattern: /^[0-9]{2}[A-Z]{3}[0-9]{4}$/,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              )}

              {fields.includes('tag') && (
                <Col lg={4} md={8}>
                  <Form.Item
                    label="Ranch Tag Color"
                    name={['animals', index, 'tagColor']}
                  >
                    <AnimalTagsFormField />
                  </Form.Item>
                </Col>
              )}

              {fields.includes('tagColorNumber') && (
                <Col lg={4} md={8}>
                  <Form.Item
                    label="Ranch Tag Color #"
                    name={['animals', index, 'tagNo']}
                    normalize={normalizeNumberInput}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              )}

              {fields.includes('quantity') && (
                <Col lg={4} md={8}>
                  <Form.Item
                    label="Quantity"
                    name={['animals', index, 'quantity']}
                    rules={[
                      {
                        required: true,
                        message: 'Quantity is required',
                      },
                    ]}
                  >
                    <InputNumber
                      min={1}
                      style={{ width: '100%' }}
                      placeholder="Quantity"
                    />
                  </Form.Item>
                </Col>
              )}

              {category && (
                <Col lg={4} md={8}>
                  <Form.Item label="Notes" name={['animals', index, 'notes']}>
                    <Input />
                  </Form.Item>
                </Col>
              )}
            </Row>
          );
        }}
      </Form.Item>
      <Button
        htmlType="button"
        shape="circle"
        type="primary"
        danger
        disabled={isFirstEntry}
        style={{ opacity: isFirstEntry ? 0 : 1 }}
        onClick={() => handleRemoveAnimal(index)}
      >
        <DeleteOutlined />
      </Button>
    </StyledWrapper>
  );
};

export default AnimalForm;
