// @flow
import Dexie, { Collection, Table } from 'dexie';
import type { BrandType, Page } from 'flow/brands';
import { BRAND } from 'flow/brands';
import { getPaged, total } from './util';

const { FIRST_NAMES, LAST_NAMES, LETTERS } = BRAND;

const everyLetter = (
  lettersToSearch: Array<string>,
  lettersToFind: Array<string>,
) =>
  lettersToSearch.every((letter: string): boolean =>
    lettersToFind.includes(letter),
  );

const nameAndLettersDiffer = (name: string, letters: Array<string>): boolean =>
  !everyLetter(name.split(''), letters);

const hasName = (brands: Table, name: string): Collection =>
  brands.where(FIRST_NAMES).startsWith(name).or(LAST_NAMES).startsWith(name);

const containsAnyLetter = (brands: Table, letters: Array<string>): Collection =>
  brands.where(LETTERS).anyOf(letters);

const onlyBrandsContainingEveryLetter = (
  brandsWithSomeMatchingLetters: Collection,
  letters: Array<string>,
): Collection =>
  brandsWithSomeMatchingLetters.and((brand: BrandType): boolean =>
    everyLetter(letters, brand[LETTERS]),
  );

export const queryByLettersAndOneName = async (
  db: Dexie,
  letters: Array<string>,
  names: Array<string>,
  paging: Page = {},
): Promise<Array<Array<BrandType> | number>> => {
  const { brands } = db;

  const name = names[0];

  const preferNameSearch = names.length && nameAndLettersDiffer(name, letters);

  const foundBrands = preferNameSearch
    ? hasName(brands, name)
    : containsAnyLetter(brands, letters);

  const results = onlyBrandsContainingEveryLetter(foundBrands, letters);

  const count = total(results);

  const pagedResults = getPaged(results.distinct(), paging);

  return Promise.all([pagedResults, count]);
};
