import React, { Fragment } from 'react';
import { useEffectOnce } from 'react-use';
import { useLocation, Switch, Route, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DashboardPage from 'pages/DashboardPage';
import Navigation from 'components/Navigation';
import SyncModal from 'components/SyncModal';
import {
  selectMePending,
  selectIsInspector,
  selectIsAdmin,
} from 'selectors/me';
import { fetchResources } from 'store/resourcesSlice';
import { selectResourcesPending } from 'selectors/resources';
import ContentLoader from 'components/ContentLoader';
import NoPermission from 'components/NoPermission';
import { fetchBrandsCacheVersion } from 'store/brandsSlice';
import { syncErrors } from 'service/logger';
import { IS_PROD_ENVIRONMENT } from 'service/urls';

import LoadingMask from 'features/LoadingMask';
import PaymentStateLoader from 'features/PaymentStateLoader';
import BrandInspectionPage from 'pages/BrandInspectionPage';
import CreateTravelPermitPage from 'pages/CreateTravelPermitPage';
import CreateHorsePreSalePage from 'pages/CreateHorsePreSalePage';
import HorsePreSalePage from 'pages/HorsePreSalePage';
import BrandInspectionCertificatesPage from 'pages/BrandInspectionCertificatesPage';
import BrandInspectionCertificatePage from 'pages/BrandInspectionCertificatePage';
import TravelPermitsPage from 'pages/TravelPermitsPage';
import TravelPermitPage from 'pages/TravelPermitPage';
import HorsePreSaleListingsPage from 'pages/HorsePreSaleListingsPage';
import UnpaidCertificatesPage from 'pages/UnpaidCertificatesPage';
import AdminLogsPage from 'pages/AdminLogsPage';
import AdminEntitiesPage from 'pages/AdminEntitiesPage';
import ChangeLogPage from 'pages/ChangeLogPage';

const App = () => {
  const dispatch = useDispatch();

  const location = useLocation();

  const isMePending = useSelector(selectMePending);

  const isResourcesPending = useSelector(selectResourcesPending);

  const isInspector: boolean = useSelector(selectIsInspector);

  const isAdmin: boolean = useSelector(selectIsAdmin);

  useEffectOnce(() => {
    dispatch(fetchResources());
    dispatch(fetchBrandsCacheVersion());
    syncErrors().then(() => console.log('error logs synced'));
  });

  if (isMePending || isResourcesPending) {
    return <ContentLoader message="Initializing, please wait..." />;
  }

  if (!isInspector) {
    return <NoPermission />;
  }

  // Adding code to only allow admins to proceed
  if(!isAdmin){
    return <NoPermission />;
  }

  return (
    <main
      style={{ backgroundColor: IS_PROD_ENVIRONMENT ? '#fdfdfd' : '#fbe3e3' }}
    >
      {!location.pathname.includes('/print') && (
        <Fragment>
          <Navigation />
          <SyncModal />
        </Fragment>
      )}

      <LoadingMask />

      <PaymentStateLoader />

      <Switch>
        <Redirect exact from="/" to="/brand-inspection" />

        {isAdmin && (
          <Route exact path={['/admin/logs', '/admin/logs/:id']}>
            <AdminLogsPage />
          </Route>
        )}

        {isAdmin && <Redirect exact from="/admin" to="/admin/inspections" />}

        {isAdmin && (
          <Route
            exact
            path={[
              '/admin/inspections',
              '/admin/yearly-permits',
              '/admin/lifetime-permits',
              '/admin/pre-sale',
            ]}
          >
            <AdminEntitiesPage />
          </Route>
        )}

              


            
        <Route exact path="/dashboard">
          <DashboardPage />
        </Route>

        <Route
          exact
          path={[
            '/brand-inspection',
            '/brand-inspection/animals',
            '/brand-inspection/preview',
          ]}
        >
          <BrandInspectionPage />
        </Route>

        <Route exact path="/travel-permit">
          <CreateTravelPermitPage />
        </Route>

        <Route exact path="/horse-pre-sale">
          <CreateHorsePreSalePage />
        </Route>

        <Route
          exact
          path={['/inspections', '/inspections/offline', '/inspections/draft']}
        >
          <BrandInspectionCertificatesPage />
        </Route>

        <Route
          exact
          path={[
            '/inspections/:id',
            '/inspections/:id/history',
            '/inspections/:id/print/:type',
          ]}
        >
          <BrandInspectionCertificatePage />
        </Route>

        <Redirect exact from="/travel-permits" to="/travel-permits/lifetime" />

        <Route
          exact
          path={[
            '/travel-permits/lifetime',
            '/travel-permits/yearly',
            '/travel-permits/offline',
          ]}
        >
          <TravelPermitsPage />
        </Route>

        <Route
          exact
          path={[
            '/travel-permits/:id',
            '/travel-permits/:id/history',
            '/travel-permits/:id/print',
          ]}
        >
          <TravelPermitPage />
        </Route>

        <Route
          exact
          path={['/horse-presale-listing', '/horse-presale-listing/offline']}
        >
          <HorsePreSaleListingsPage />
        </Route>

        <Route
          exact
          path={[
            '/horse-presale-listing/:id',
            '/horse-presale-listing/:id/history',
            '/horse-presale-listing/:id/print',
          ]}
        >
          <HorsePreSalePage />
        </Route>

        <Redirect exact from="/unpaid" to="/unpaid/inspections" />

        <Route
          exact
          path={[
            '/unpaid/inspections',
            '/unpaid/offline-inspections',
            '/unpaid/presale',
            '/unpaid/offline-presale',
            '/unpaid/travel-permits',
            '/unpaid/offline-travel-permits',
          ]}
        >
          <UnpaidCertificatesPage />
        </Route>

        <Route exact path="/changelog">
          <ChangeLogPage />
        </Route>

        <Redirect to="/" />
      </Switch>
    </main>
  );
};

export default App;
