// @flow
import React from 'react';
import { useEffectOnce, useUpdateEffect } from 'react-use';
import { useDispatch, useSelector, type Dispatch } from 'react-redux';
import { useHistory, type History } from 'react-router-dom';
import moment from 'moment';
import { Table, Button, Modal } from 'antd';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import type { ReduxStore } from 'flow/redux';
import type { DraftInspection } from 'flow/inspection';
import useQuery from 'utils/hooks/useQuery';
import {
  fetchDraftInspections,
  deleteDraftInspection,
  selectDraftInspections,
} from './draftInspectionsSlice';

const DraftBrandInspections = () => {
  const dispatch: Dispatch = useDispatch();
  const draftInspections: Array<DraftInspection> = useSelector(
    selectDraftInspections,
  );
  const loading: boolean = useSelector(
    (state: ReduxStore) => state.draftInspections.loading,
  );

  const history: History = useHistory();

  const deleteInspection = (id: number) => {
    dispatch(deleteDraftInspection(id));
  };

  const COLUMNS = [
    {
      title: 'Owner',
      dataIndex: ['owner', 'name'],
      key: 'name',
      render: (value: string) => value || '-',
    },
    {
      title: 'Buyer',
      dataIndex: ['buyer', 'name'],
      key: 'buyer',
      render: (value: string) => value || '-',
    },
    {
      title: 'Destination',
      dataIndex: 'destination',
      key: 'destination',
      render: (value: string) => value || '-',
    },
    {
      title: 'Date',
      dataIndex: 'inspectionDate',
      key: 'inspectionDate',
      width: 200,
      render: (value: string) => {
        return value ? moment(value).format('MM/DD/YYYY') : '-';
      },
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      width: 80,
      align: 'center',
      render: (value: number) => {
        return (
          <Button
            type="danger"
            shape="circle"
            icon={<DeleteOutlined />}
            onClick={(ev) => {
              ev.stopPropagation();
              Modal.confirm({
                title: 'Confirm',
                icon: <ExclamationCircleOutlined />,
                content: 'Are you sure you want to delete this draft?',
                okText: 'Delete',
                cancelText: 'Cancel',
                onOk: () => {
                  deleteInspection(value);
                },
              });
            }}
          />
        );
      },
    },
  ];

  const { query } = useQuery();

  useEffectOnce(() => {
    dispatch(fetchDraftInspections(query));
  });

  useUpdateEffect(() => {
    dispatch(fetchDraftInspections(query));
  }, [query]);

  return (
    <Table
      rowKey="id"
      columns={COLUMNS}
      dataSource={draftInspections}
      size="small"
      bordered
      rowClassName="ant-row-clickable"
      loading={loading}
      pagination={{ pageSize: 20, showSizeChanger: false }}
      onRow={(record) => {
        return {
          onClick: () => {
            history.push(`/brand-inspection?id=${record.id}`);
          },
        };
      }}
    />
  );
};

export default DraftBrandInspections;
