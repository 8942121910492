// @flow
import {
  FormOutlined,
  FileTextOutlined,
  TagOutlined,
  TagsOutlined,
  FileSearchOutlined,
  FileProtectOutlined,
} from '@ant-design/icons';
import type { MenuItemType } from './components/Sidebar';

const MENU_ITEMS: Array<$Shape<MenuItemType>> = [
  {
    name: 'Brand Inspection Form',
    to: '/brand-inspection',
    Icon: FormOutlined,
  },
  {
    name: 'Travel Permit Form',
    to: '/travel-permit',
    Icon: FileTextOutlined,
  },
  {
    name: 'Horse Pre-Sale Form',
    to: '/horse-pre-sale',
    Icon: TagOutlined,
  },
  {
    name: 'Brand Inspection Certificates',
    to: '/inspections',
    Icon: FileSearchOutlined,
  },
  {
    name: 'Horse Travel Permits',
    to: '/travel-permits',
    Icon: FileProtectOutlined,
  },
  {
    name: 'Horse Pre-Sale Listings',
    to: '/horse-presale-listing',
    Icon: TagsOutlined,
  },
  {
    name: 'Unpaid Certificates',
    to: '/unpaid',
    Icon: TagsOutlined,
  },
];

export default MENU_ITEMS;
