// @flow
import React, { type Node } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { tokenUtil } from '@acclaim/udaf-kit';

type Props = {
  children: Node,
};

export default function PrivateRoute(props: Props) {
  const { children, ...rest } = props;
  const token = tokenUtil.getToken();

  return (
    <Route {...rest}>
      {token ? children : <Redirect to="/auth-redirect" />}
    </Route>
  );
}
