// @flow
import type { Marking, Markings, MarkingLocations } from 'flow/animal';

export const faceMiddle = (
  { location: locationA }: Marking,
  { location: locationB }: Marking,
): number => {
  return locationA === 'right' || (locationA === 'face' && locationB === 'left')
    ? -1
    : 0;
};

export const parseMarkings = (markings: Markings): Array<Marking> =>
  Object.keys(markings)
    .filter((location: MarkingLocations) => !!markings[location]?.image)
    .map((location: MarkingLocations) => {
      return {
        ...markings[location], // id, image, notes
        location,
      };
    })
    .sort(faceMiddle);
