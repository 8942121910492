// @flow
import React from 'react';
import styled from 'styled-components';
import type { PreSaleInspectionType } from 'flow/presale';
import { getInspectionUrl } from 'service/urls';
import Template, {
  Content,
  DisplayPayment,
  Horse,
  InspectionHeader,
  InspectorOwnerBuyer,
  Owner,
  Section,
  SignatureContainer,
  SignatureRow,
  TearHR,
} from 'components/CertificateTemplate';
import SignatureField from 'components/SignatureField';
import PhotosAndMarkings from 'components/CertificateTemplate/components/PhotosAndMarkings';

type Props = {
  presale: $Shape<PreSaleInspectionType>,
};

const PreSaleCertificateTemplate = ({ presale }: Props) => {
  const {
    owner,
    user,
    payment,
    presaleDate,
    presaleCode,
    sellerSignature,
    inspectorSignature,
    publicId,
    photos,
    markings,
    clientVersion,
    serviceVersion,
    isVoid,
  } = presale;

  const inspectionUrl = getInspectionUrl();

  const qrUrl = `${inspectionUrl}/public/pre-sale/${publicId}`;

  return (
    <div>
      <Template>
        <InspectionHeader
          inspectionTitle="EQUINE PRESALE INSPECTION"
          code={presaleCode}
          date={presaleDate}
          url={qrUrl}
          version={{ clientVersion, serviceVersion }}
          isVoid={isVoid}
        />
        <Content>
          <InspectorOwnerBuyer>
            <Owner owner={owner} />
            <PresaleHorse inspection={presale} includeBrands />
          </InspectorOwnerBuyer>

          <PhotosAndMarkings photos={photos} markings={markings} />

          <DisplayPayment payment={payment} />

          <SignatureRow>
            <SignatureContainer>
              <SignatureField
                label={`Inspected by ${user.name}`}
                x={false}
                signature={inspectorSignature}
              />
            </SignatureContainer>

            <SignatureContainer>
              <SignatureField label="Seller / Agent (if applicable)" />
            </SignatureContainer>

            <SignatureContainer>
              <SignatureField
                label="Owner sign here"
                x={false}
                signature={sellerSignature}
              />
            </SignatureContainer>
          </SignatureRow>
        </Content>
      </Template>

      <TearHR />

      <Template>
        <PaperTemplate>
          <Content>
            <PaperFormHeader>
              <InspectionHeader
                inspectionTitle="EQUINE PRESALE INSPECTION: PROOF of SALE"
                code={presaleCode}
                date={presaleDate}
                url={qrUrl}
                version={{ clientVersion, serviceVersion }}
                isVoid={isVoid}
                logoSize="60"
                sealSize="70"
                qrSize=".7in"
              />
            </PaperFormHeader>
            <Section>
              <h5>THE PRE-SALE HORSE BRAND INSPECTION</h5>
              <p>
                Seller must complete information below to validate sale. Failure
                to do so may result in citation as per UCA 4-24-302.
              </p>

              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ width: '5.25in' }}>
                  <LeavingTheState>
                    <Checkbox /> Leaving the state
                  </LeavingTheState>

                  <Block>
                    <Row>
                      <b>Seller</b>
                      <span>
                        Name <em>(Please print)</em>:
                      </span>{' '}
                      <Underline />
                      <span>Phone:</span> <Underline />
                    </Row>
                    <Row>
                      <span>Seller Signature:</span> <Underline />
                      <span>Date:</span> <Underline />
                    </Row>
                  </Block>
                  <Block>
                    <Row>
                      <b>Buyer</b>
                      <span>
                        Name <em>(Please print)</em>:
                      </span>{' '}
                      <Underline />
                      <span>Phone:</span>
                      <Underline />
                    </Row>
                    <Row>
                      <span>Address:</span> <Underline />
                    </Row>
                    <Row>
                      <span>City:</span> <Underline />
                      <span>State:</span> <Underline />
                      <span>ZIP Code:</span> <Underline />
                    </Row>
                    <Row>
                      <span>Buyer Signature:</span> <Underline />
                      <span>Date:</span> <Underline />
                    </Row>
                  </Block>
                </div>
                <FinePrint>
                  I hereby certify that I am the buyer of the horse described
                  here in and will obtain an equine post-sale brand inspection
                  within 10 days of purchase. Failure to complete the post-sale
                  brand inspection is a violation of the Utah Brand and
                  Anti-theft Act section 4-24.{' '}
                  <VoidAfter>
                    This permit is void after 10 days of purchase.
                  </VoidAfter>{' '}
                  This permit must accompany the horse at all times when in
                  transit in compliance with Utah laws governing inter-state
                  movement, and proof of ownership.
                </FinePrint>
              </div>
            </Section>
          </Content>
        </PaperTemplate>
      </Template>
    </div>
  );
};

type PresaleHorseProps = {
  inspection: $Shape<PreSaleInspectionType>,
  includeBrands?: boolean,
};
const PresaleHorse = ({ inspection, includeBrands }: PresaleHorseProps) => {
  const { animal, registeredName, clearance, year } = inspection;
  return (
    <Horse
      animal={animal}
      registeredName={registeredName}
      year={year}
      clearance={clearance}
      includeBrands={includeBrands}
    />
  );
};

PresaleHorse.defaultProps = {
  includeBrands: false,
};

const PaperFormHeader = styled.div`
  h2 {
    font-size: 1.1em;
  }
  span {
    font-size: 0.9em !important;
    line-height: 1.4em !important;
  }
`;

const VoidAfter = styled.strong`
  display: inline !important;
  color: #ff0000 !important;
`;

export const Underline = styled.hr`
  flex: 1;
  margin-top: 1.3em;
  border: 0 none transparent;
  border-bottom: 1px solid #002d06;
  height: 1px;
  display: block;
  width: auto;
`;

const Checkbox = styled.div`
  margin: 0.3em 0.5em 0 0;
  border: 1px solid #002d06;
  width: 1em;
  height: 1em;
  display: block;
`;

const LeavingTheState = styled.div`
  line-height: normal;
  display: flex;
  margin: 0.8em 0 1.2em;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: auto;
  height: 2.1em;
  flex-wrap: nowrap;
  align-content: stretch;
  b {
    margin-right: 0.5em;
  }
  span {
    white-space: nowrap;
    margin-left: 0.5em;
  }
  span:first-child {
    margin-left: 0;
  }
  em {
    font-size: 0.8em;
    display: inline-block;
  }
`;

const Block = styled.div`
  margin-bottom: 1.1em;
  div {
    margin-left: 0.5in;
  }
  div:first-child {
    margin-left: 0;
  }
`;

const PaperTemplate = styled.div`
  width: 7.5in;
  margin: auto;
`;

const FinePrint = styled.p`
  margin-top: 1.3em !important;
  width: 2.25in;
  padding-left: 2em;
  font-size: 0.8em;
`;

export default PreSaleCertificateTemplate;
