// @flow
import type {
  Inspection,
  InspectionAnimal,
  LineItem,
  WaiveFeeType,
} from 'flow/inspection';
import type {
  ServiceFee,
  InspectionType,
  ServiceTypeName,
} from 'flow/resources';
import { getFees, getPrice } from 'utils/computeFees';
import { LINE_ITEM_SLUG, LINE_ITEM_TYPE } from 'constants/inspection';

const getServiceFees = (
  serviceFees: Array<ServiceFee>,
  slug: ServiceTypeName,
) => {
  return serviceFees
    .filter((fee: ServiceFee) => fee.serviceFeeType.name === slug)
    .map((fee: ServiceFee) => fee.id);
};

const createLineItem = ({
  type,
  serviceFee,
  quantity,
  lineItems,
  lineNumber,
  waivedFees,
  fees,
}: {
  type: 'inspection' | 'predatorControl' | 'beefCouncil' | 'beefTag',
  serviceFee: ServiceFee,
  quantity: number,
  lineItems: Array<LineItem>,
  lineNumber: string,
  waivedFees: Array<WaiveFeeType>,
  fees: Array<ServiceFee>,
}) => {
  const name = {
    inspection: 'Inspection fee',
    predatorControl: 'Predator control fee',
    beefCouncil: 'Beef council fee',
    beefTag: 'Beef tag fee',
  }[type];

  const slug = {
    inspection: 'inspection_fee',
    predatorControl: 'predator_control_fee',
    beefCouncil: 'beef_council_fee',
    beefTag: 'beef_tag_fee',
  }[type];

  const price = getPrice(serviceFee, quantity);

  lineItems.push({
    name,
    slug,
    type: LINE_ITEM_TYPE.SERVICE,
    calculatedFee: serviceFee,
    // only validated for backwards compatibility
    waived: waivedFees.some(
      (w: WaiveFeeType) => w.type === slug && w.lineNumber === lineNumber,
    ),
    reason: '',
    quantity,
    price,
    lineNumber,
    serviceFees: getServiceFees(fees, slug),
  });
};

export const createLineItems = ({
  inspection,
  serviceFees,
  category,
  waivedFees,
}: {
  inspection: $Shape<Inspection>,
  serviceFees: Array<ServiceFee>,
  category: number,
  waivedFees?: Array<WaiveFeeType>,
}) => {
  const { types, animals } = inspection;

  const typeIds = types.map((type: InspectionType) => type.id);

  const fees: Array<ServiceFee> = serviceFees.filter(
    ({ service }: ServiceFee) =>
      service.category === category && typeIds.includes(service.inspectionType),
  );

  const calfPoolProducer = types.find((t: InspectionType) => {
    return t.slug === 'calf-pool-producer';
  });

  const calculatedFees = getFees(fees);

  if (calfPoolProducer) {
    const calfPoolProducerFee = fees.find(
      ({ serviceFeeType, service }: ServiceFee) => {
        return (
          serviceFeeType.name === LINE_ITEM_SLUG.INSPECTION &&
          service.inspectionType === calfPoolProducer.id
        );
      },
    );

    if (calfPoolProducerFee) {
      const {
        additionalFee,
        additionalFeeType,
        overrideFee,
        overrideFeeType,
        flatRate,
        maximumQty,
        minimumFee,
        price,
      } = calfPoolProducerFee;

      calculatedFees.inspection = {
        additionalFee,
        additionalFeeType,
        overrideFee,
        overrideFeeType,
        flatRate,
        maximumQty,
        minimumFee,
        price,
      };
    }
  }

  const lineItems: Array<LineItem> = [];

  animals.forEach(({ quantity, lineNumber }: InspectionAnimal) => {
    Object.keys(calculatedFees).forEach(
      (
        key:
          | 'inspection'
          | 'predatorControl'
          | 'beefCouncil'
          | 'beefTag'
          | 'processing',
      ) => {
        if (key !== 'processing') {
          createLineItem({
            type: key,
            serviceFee: calculatedFees[key],
            quantity,
            lineItems,
            lineNumber,
            waivedFees: waivedFees || [], // has to be processed for backwards compatibility
            fees,
          });
        }
      },
    );
  });

  if (calculatedFees.processing?.price > 0) {
    lineItems.push({
      name: 'Processing Fee',
      slug: LINE_ITEM_SLUG.PROCESSING,
      type: LINE_ITEM_TYPE.OTHER,
      calculatedFee: calculatedFees.processing,
      waived: false,
      reason: '',
      quantity: 1,
      price: calculatedFees.processing.price,
      lineNumber: null,
      serviceFees: getServiceFees(fees, 'processing_fee'),
    });
  }

  const inspectionMinimumFee = calculatedFees.inspection?.minimumFee || 0;

  const totalInspectionFee = lineItems
    .filter(({ slug }: LineItem) => slug === 'inspection_fee')
    .reduce((acc: number, { price }: LineItem) => {
      return acc + price;
    }, 0);

  const applyInspectionMinimumFee =
    calculatedFees.inspection.minimumFee === 0
      ? false
      : totalInspectionFee < inspectionMinimumFee;

  if (applyInspectionMinimumFee) {
    lineItems.push({
      name: 'Inspection Minimum Fee',
      slug: LINE_ITEM_SLUG.INSPECTION_MINIMUM,
      type: LINE_ITEM_TYPE.OTHER,
      calculatedFee: null,
      waived: false,
      reason: '',
      quantity: 0,
      /*
        inspection minimum fee price should only be the remaining
        amount to fill up the required minimum amount
        {fee.minimum - inspection line items total price}
      */
      price: inspectionMinimumFee - totalInspectionFee,
      lineNumber: null,
      serviceFees: getServiceFees(fees, 'inspection_fee'),
    });
  }

  return lineItems;
};
