// @flow
import type { InspectionType } from 'flow/resources';
import type { Animal, AnimalType } from 'flow/animal';
import type { ServiceInspectionType } from 'flow/inspection';
import type { Dispatch } from 'react-redux';
import { createBrands } from 'store/brandsSlice';

const inspectionType = (
  { id, inspectionType }: InspectionType
): ServiceInspectionType =>
  ({ type: id,
     inspectionType: inspectionType || '',
  });

export const inspectionTypes = (
  inspectionTypes: Array<InspectionType>,
): Array<ServiceInspectionType> => inspectionTypes.map(inspectionType);

export const apiAnimal = (animal: AnimalType) => {
  const { color, breed, gender } = animal;
  return {
    ...animal,
    gender: gender?.id,
    color: color?.id,
    breed: breed?.id,
  };
};

export const persistBrandOnSingleAnimal = (
  animal: Animal,
  inspectionPublicId: string,
  dispatch: Dispatch,
  date: string,
): void => {
  dispatch(
    createBrands({
      brands: animal?.brands || [],
      entityId: inspectionPublicId,
      date,
    }),
  );
};

export const persistBrandsOnManyAnimals = (
  animals: Array<Animal>,
  inspectionPublicId: string,
  dispatch: Dispatch,
  date: string,
): void => {
  animals.forEach((animal) =>
    persistBrandOnSingleAnimal(animal, inspectionPublicId, dispatch, date),
  );
};
