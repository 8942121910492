// @flow
import React from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';
import logo from 'assets/logo.png';
import { getBrandPortalUrl } from 'service/urls';

const StyledWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    display: block;
    margin: 0 auto 20px;
    width: 100px;
  }

  > section {
    width: 480px;
    text-align: center;
  }
`;

const NoPermission = () => {
  const brandPortalUrl = getBrandPortalUrl();

  return (
    <StyledWrapper>
      <section>
        <img
          src={logo}
          alt="Utah Department of Agriculture and Food"
          width="200"
          height="200"
        />
        <Typography.Title level={4}>
          You don&apos;t have the necessary permission.
        </Typography.Title>
        <Typography.Paragraph>
          Please contact the Brand Recorder to gain access
          <br />
          to the Brand Inspection App.
        </Typography.Paragraph>
        <a href={brandPortalUrl}>Go Back to UDAF Brands Portal</a>
      </section>
    </StyledWrapper>
  );
};

export default NoPermission;
