// @flow
import { message } from 'antd';

const DEFAULT_DURATION = 4;

const DEFAULT_ERROR_MESSAGE = 'Something went wrong. Please try again.';

export const showErrorMessage = (msg?: string, duration?: number) => {
  message.error(msg || DEFAULT_ERROR_MESSAGE, duration || DEFAULT_DURATION);
};

export const showSuccessMessage = (msg: string, duration?: number) => {
  message.success(msg, duration || DEFAULT_DURATION);
};

export const showWarningMessage = (msg: string, duration?: number) => {
  message.warning(msg, duration || DEFAULT_DURATION);
};
