import React, { Tag } from 'antd';
import type { Payment } from 'flow/payment';
import {
  PAYMENT_DESCRIPTIONS,
  PAYMENT_STATUS_COLORS,
  PAYMENT_STATUS_TEXT,
} from 'constants/inspection';

export const getPaymentTypeColumn = () => ({
  title: 'Payment',
  dataIndex: 'payment',
  key: 'payment',
  render: (payment: Payment) => {
    return PAYMENT_DESCRIPTIONS[payment.paymentType] || 'Paid';
  },
});

export const getPaymentStatusColumn = () => ({
  title: 'Payment Status',
  dataIndex: 'payment',
  key: 'payment',
  render: (payment: Payment) => {
    const { status = '', paymentType } = payment;

    // dynamic payment status is only applicable to credit card payments
    if (paymentType === 'creditcard') {
      return (
        <Tag color={PAYMENT_STATUS_COLORS[status]}>
          {PAYMENT_STATUS_TEXT[status] || 'Pending'}
        </Tag>
      );
    }

    if (paymentType === 'pay_later' || paymentType === 'invoice') {
      return <Tag color="warning">Pending</Tag>;
    }

    return <Tag color="success">Completed</Tag>;
  },
});
