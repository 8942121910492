// @flow
import React, { type ElementRef, type Node } from 'react';
import { Select } from 'antd';
import type { Color } from 'flow/resources';
import useResources from 'utils/hooks/useResources';
import selectFilterOptions from 'utils/form/selectFilterOptions';

const { Option } = Select;

type Props = {
  value: string,
  category: number,
  onChange: (string) => void,
  forwardedRef: ElementRef<*>,
  size?: 'small' | 'medium' | 'large',
  placeholder?: string,
};

const AnimalColorsFormField = ({ forwardedRef, category, ...rest }: Props) => {
  const colors: Array<Color> = useResources<Color>('animalColors');

  const filteredOptions = colors.filter((c) => {
    return c.category === category;
  });

  return (
    <Select
      {...rest}
      ref={forwardedRef}
      virtual={false}
      showSearch
      filterOption={selectFilterOptions}
    >
      {filteredOptions.map(({ id, color }: $Shape<Color>) => (
        <Option value={id} key={id}>
          {color}
        </Option>
      ))}
    </Select>
  );
};

AnimalColorsFormField.defaultProps = {
  size: 'medium',
  placeholder: 'Choose Color',
};

export default React.forwardRef<*, Node>((props: Props, ref: ElementRef<*>) => {
  return <AnimalColorsFormField forwardedRef={ref} {...props} />;
});
