// @flow
import React from 'react';
import styled from 'styled-components';
import Page from 'components/Page';
import ChangeLog from 'components/UpdateLogs/ChangeLog';

const StyledPage = styled(Page)`
  margin: 2em auto;
  padding: 2em 2em 4em;
  background-color: #ffffff;
`;

const ChangeLogPage = () => (
  <StyledPage>
    <ChangeLog />
  </StyledPage>
);

export default ChangeLogPage;
