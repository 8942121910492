// @flow
import React from 'react';
import moment from 'moment';
import { useEffectOnce, useUpdateEffect } from 'react-use';
import { useDispatch, useSelector, type Dispatch } from 'react-redux';
import { Table } from 'antd';
import type { ReduxStore } from 'flow/redux';
import useQuery from 'utils/hooks/useQuery';
import { fetchAdminLogs, selectAdminLogs } from './adminLogsSlice';

const COLUMNS = [
  {
    title: 'User ID',
    dataIndex: 'userId',
    key: 'userId',
  },
  {
    title: 'Endpoint',
    dataIndex: 'endpoint',
    key: 'endpoint',
  },
  {
    title: 'Error',
    dataIndex: 'errorStack',
    key: 'errorStack',
  },
  {
    title: 'Date',
    dataIndex: 'eventDate',
    key: 'eventDate',
    render: (value: string) => {
      return value ? moment(value).format('MM/DD/YYYY') : '-';
    },
  },
];

const AdminLogs = () => {
  const dispatch: Dispatch = useDispatch();

  const { page, limit, patchQuery } = useQuery();

  const loading: boolean = useSelector(
    (state: ReduxStore) => state.adminLogs.loading,
  );

  const total: number = useSelector(
    (state: ReduxStore) => state.adminLogs.total,
  );

  const adminLogs = useSelector(selectAdminLogs);

  const handleChange = ({ current }) => {
    patchQuery('page', current);
  };

  const fetchData = () => dispatch(fetchAdminLogs({ page, limit }));

  useEffectOnce(() => {
    fetchData();
  });

  useUpdateEffect(() => {
    fetchData();
  }, [page]);

  return (
    <Table
      rowKey="id"
      columns={COLUMNS}
      dataSource={adminLogs}
      loading={loading}
      size="small"
      rowClassName="ant-row-clickable"
      pagination={{
        current: page,
        pageSize: limit,
        total,
        showSizeChanger: false,
      }}
      onChange={handleChange}
      onRow={(record) => {
        return {
          onClick: () => {
            window.open(`/admin/logs/${record.id}`);
          },
        };
      }}
    />
  );
};

export default AdminLogs;
