// @flow
import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { Typography } from 'antd';
import {
  CloseCircleTwoTone,
  CheckCircleTwoTone,
  SettingTwoTone,
} from '@ant-design/icons';
import { PAYMENT_STATE_KEY } from 'utils/hooks/useCreditCardCheckout';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  padding: 30px;
  text-align: center;
`;

const { Title, Paragraph } = Typography;

const iconStyle = {
  fontSize: 80,
  marginBottom: 20,
};

// type SquareResponse = {
//   clientTransactionId: string,
//   status: string,
//   transactionId: ?string,
//   errorCode: ?string,
//   state: ?string,
// };

const PAYMENT_CANCELED = 'payment_canceled';

const translateError = (errorCode: string): string => {
  switch (errorCode) {
    case 'amount_invalid_format':
      return 'The request has a missing or invalid amount to charge.';
    case 'amount_too_large':
      return 'The request amount to charge was too large.';
    case 'amount_too_small':
      return 'The request amount to charge was too small.';
    case 'client_not_authorized_for_user':
      return 'Point of Sale versions prior to 4.53 require the developer to guide sellers through OAuth before allowing them to take payments with the Point of Sale API. As of Square Point of Sale 4.53, this error type is deprecated. For more information, see How OAuth Works.';
    case 'could_not_perform':
      return 'The request could not be performed. This is usually because there is an unfinished transaction pending in Square Point of Sale. The seller must open Square Point of Sale and complete the transaction before initiating a new request.';
    case 'currency_code_mismatch':
      return 'The currency code provided in the request does not match the currency associated with the current business.';
    case 'currency_code_missing':
      return 'The currency code provided in the request is missing or invalid.';
    case 'customer_management_not_supported':
      return 'This seller account does not support customer management and therefore cannot associate transactions with customers.';
    case 'data_invalid':
      return 'The URL sent to Square Point of Sale had missing or invalid information.';
    case 'invalid_customer_id':
      return "The customer ID provided in the request does not correspond to a customer signed in to the seller's Customer Directory.";
    case 'invalid_tender_type':
      return 'The request included an invalid tender type.';
    case 'no_network_connection':
      return 'The transaction failed because the device has no network connection.';
    case 'not_logged_in':
      return 'A seller is not currently logged in to Square Point of Sale.';
    case PAYMENT_CANCELED:
      return 'Please close this tab and choose another payment method.';
    case 'unsupported_api_version':
      return 'The installed version of Square Point of Sale does not support the specified version of the Point of Sale API.';
    case 'unsupported_currency_code':
      return 'The currency code provided in the request is not currently supported by the Point of Sale API.';
    case 'unsupported_tender_type':
      return 'The request included a tender type that is not currently supported by the Point of Sale API.';
    case 'user_id_mismatch':
      return 'The business location currently signed in to Square Point of Sale does not match the location represented by the location_id you provided in your request.';
    case 'user_not_active':
      return 'The currently signed in location has not activated card processing.';
    default:
      return `errorCode ${errorCode} is unrecognized.`;
  }
};

const CallbackPage = () => {
  const search = decodeURI(window.location.search);
  const queryParams = new URLSearchParams(search);
  const transactionInfo = JSON.parse(queryParams.get('data') || '{}');
  const {
    status: passedStatus,
    error_code: errorCode,
    // todo make use of the transaction ids
    // client_transaction_id: clientTransactionId,
    // transaction_id: transactionId,
    state,
  } = transactionInfo;

  const parsedState = (state && JSON.parse(state)) || {};
  const [error] = useState(
    passedStatus === 'error' && translateError(errorCode),
  );

  const status = error ? 'failed' : 'completed';
  console.log({ transactionInfo, error, status });

  window.localStorage.setItem(
    PAYMENT_STATE_KEY,
    JSON.stringify({ ...transactionInfo, state: parsedState }),
  );

  let errorTitle = null;
  if (error) {
    errorTitle =
      errorCode === PAYMENT_CANCELED ? 'Payment Canceled' : 'Payment Failed';
  }

  const title = error ? errorTitle : 'Payment Successful';

  return (
    <Wrapper>
      {status === 'pending' && <SettingTwoTone style={iconStyle} spin />}

      {error && <CloseCircleTwoTone twoToneColor="#eb2f96" style={iconStyle} />}

      {status === 'completed' && (
        <CheckCircleTwoTone twoToneColor="#52c41a" style={iconStyle} />
      )}

      {status === 'pending' ? (
        <Fragment>
          <Title level={3}>Processing payment</Title>

          <Paragraph>
            Please don&quot;t close this tab while we process your payment.
          </Paragraph>
        </Fragment>
      ) : (
        <Fragment>
          <Title level={3}>{title}</Title>
          {error && <Paragraph>{error}</Paragraph>}

          <Paragraph>
            <strong>You can now close this tab.</strong>
          </Paragraph>
        </Fragment>
      )}
    </Wrapper>
  );
};

export default CallbackPage;
