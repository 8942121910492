// @flow
import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.75);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.15s ease-in-out;

  ${(props) =>
    props.$loading &&
    `
    visibility: visible;
    opacity: 1;
  `}
`;

export const Message = styled.p`
  font-size: 18px;
  font-weight: 600;
  font-style: italic;
  margin: 10px 0;
  text-align: center;
`;
