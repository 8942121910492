// @flow
import React from 'react';
import moment from 'moment';
import { DatePicker } from 'antd';
import useQuery from 'utils/hooks/useQuery';

const { RangePicker } = DatePicker;

const DateRangeFilter = () => {
  const { from, to, patchQueries } = useQuery();

  const handleCalendarChange = (_, dateString) => {
    if (dateString[0] && dateString[1]) {
      patchQueries({
        page: '',
        from: dateString[0],
        to: dateString[1],
      });
    } else {
      patchQueries({
        page: '',
        from: '',
        to: '',
      });
    }
  };

  const rangeValue = [from ? moment(from) : '', to ? moment(to) : ''];

  return (
    <RangePicker
      value={rangeValue}
      format="MM/DD/YYYY"
      onCalendarChange={handleCalendarChange}
    />
  );
};

export default DateRangeFilter;
