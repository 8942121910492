// @flow
import React, { Fragment } from 'react';
import { Button, Modal } from 'antd';
import { useSelector } from 'react-redux';
import type { ReduxStore } from 'flow/redux';

const AppUpdate = () => {
  const visible: boolean = useSelector(
    (state: ReduxStore) => state.appUpdate.updateVisible,
  );

  const handleReload = () => {
    window.location.reload();
  };

  return (
    <Modal
      visible={visible}
      width={400}
      closable={false}
      footer={null}
      bodyStyle={{ padding: 20, textAlign: 'center' }}
    >
      <Fragment>
        Application update is now available!
        <Button
          key="reload"
          type="primary"
          onClick={handleReload}
          style={{ marginLeft: '1em' }}
        >
          Upgrade Now
        </Button>
      </Fragment>
    </Modal>
  );
};

export default AppUpdate;
