// @flow
import React from 'react';
import { useEffectOnce, useUpdateEffect } from 'react-use';
import moment from 'moment';
import { Table, Typography, Tag } from 'antd';
import { useDispatch, useSelector, type Dispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import type { ReduxStore } from 'flow/redux';
import type { Inspection } from 'flow/inspection';
import formatMoney, { centsToDollars } from 'utils/formatters/formatMoney';
import useQuery from 'utils/hooks/useQuery';
import EmptyOfflineMessage from 'components/EmptyOfflineMessage';
import {
  getPaymentTypeColumn,
  // getPaymentStatusColumn,
} from 'utils/paymentColumn';
import {
  fetchOfflineInspections,
  selectComputedInspections,
  clearAll,
} from './offlineInspectionsSlice';

const COLUMNS = [
  {
    title: 'ID',
    dataIndex: 'inspectionCode',
    key: 'inspectionCode',
    defaultSortOrder: 'descend',
    sorter: (a, b) => { return a.inspectionCode.localeCompare(b.inspectionCode)},
    render: (value: string, values: Inspection) => (
      <Typography.Text>
        <Tag color="warning">OFFLINE</Tag>
        {values.isVoid && <Tag color="red">VOIDED</Tag>}
        <strong>{value}</strong>
      </Typography.Text>
    ),
  },
  {
    title: 'Owner',
    dataIndex: ['owner', 'name'],
    key: 'name',
    render: (value: string) => value || '-',
  },
  {
    title: 'Buyer',
    dataIndex: ['buyer', 'name'],
    key: 'buyer',
    render: (value: string) => value || '-',
  },
  {
    title: 'Destination',
    dataIndex: 'destination',
    key: 'destination',
    render: (value: string) => value || '-',
  },
  {
    title: 'Price',
    dataIndex: 'total',
    key: 'total',
    render: (value: number) =>
      formatMoney({ value: centsToDollars(value || 0) }),
  },
  getPaymentTypeColumn(),
  // getPaymentStatusColumn(),
  {
    title: 'Date',
    dataIndex: 'inspectionDate',
    key: 'inspectionDate',
    render: (value: string) => {
      return value ? moment(value).format('MM/DD/YYYY') : '-';
    },
  },
];

type Props = {
  paymentStatus: string,
};

const OfflineBrandInspections = ({ paymentStatus }: Props) => {
  const dispatch: Dispatch = useDispatch();

  const loading: boolean = useSelector(
    ({ offlineInspections: { loading, syncing } }: ReduxStore) =>
      loading || syncing,
  );

  const total: number = useSelector(
    (state: ReduxStore) => state.offlineInspections.total,
  );

  const syncing: boolean = useSelector(
    (state: ReduxStore) => state.offlineInspections.syncing,
  );

  const { from, to, query, page, limit, offset, patchQuery } = useQuery();

  const inspections = useSelector(selectComputedInspections);

  const history = useHistory();

  const fetchData = () => {
    if (paymentStatus) {
      dispatch(
        fetchOfflineInspections({
          limit,
          offset,
          from,
          to,
          query,
          paymentStatus,
        }),
      );
    }
  };

  const handleChange = ({ current }) => {
    patchQuery('page', current);
  };

  useEffectOnce(() => {
    fetchData();

    return () => {
      dispatch(clearAll());
    };
  });

  useUpdateEffect(() => {
    fetchData();
  }, [limit, offset, from, to]);

  useUpdateEffect(() => {
    if (page === 1) {
      fetchData();
    } else {
      patchQuery('page', 1);
    }
  }, [query, paymentStatus, syncing]);

  return (
    <Table
      rowKey="publicId"
      columns={COLUMNS}
      dataSource={inspections}
      size="small"
      bordered
      rowClassName="ant-row-clickable"
      loading={loading}
      pagination={{
        current: page,
        pageSize: limit,
        total,
        showSizeChanger: false,
      }}
      locale={{
        emptyText: (
          <EmptyOfflineMessage
            seeTabLabel="Certificates"
            seeTabUrl="/inspections"
          />
        ),
      }}
      onChange={handleChange}
      onRow={(record) => {
        return {
          onClick: () => {
            history.push(`/inspections/${record.publicId}`);
          },
        };
      }}
    />
  );
};

OfflineBrandInspections.defaultProps = {
  paymentStatus: 'all',
};

export default OfflineBrandInspections;
