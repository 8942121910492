// @flow
const formatGpsCoordinates = (latLng: ?string) => {
  const values = (latLng || '').split(' ');
  const latitude = values[0] ? parseFloat(values[0]) : '';
  const longitude = values[1] ? parseFloat(values[1]) : '';

  if (latitude && longitude) {
    const convertLat = Math.abs(latitude);
    const latDeg = Math.floor(convertLat);
    const latMin = parseFloat(
      Math.floor((convertLat - latDeg) * 60).toFixed(2),
    );
    const latSeconds = ((convertLat - latDeg - latMin / 60) * 60 ** 2).toFixed(
      2,
    );
    const latCardinal = latitude > 0 ? 'N' : 'S';

    const convertLng = Math.abs(longitude);
    const lngDeg = Math.floor(convertLng);
    const lngMin = parseFloat(
      Math.floor((convertLng - lngDeg) * 60).toFixed(2),
    );
    const lngSeconds = ((convertLng - lngDeg - lngMin / 60) * 60 ** 2).toFixed(
      2,
    );
    const lngCardinal = longitude > 0 ? 'E' : 'W';

    return `${latDeg}°${latMin}'${latSeconds}"${latCardinal} ${lngDeg}°${lngMin}'${lngSeconds}"${lngCardinal}`;
  }

  return '';
};

export default formatGpsCoordinates;
