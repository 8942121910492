// @flow
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { showWarningMessage } from 'utils/showMessage';
import { fetchOfflineTravelPermits } from 'features/OfflineTravelPermits/offlineTravelPermitsSlice';
import { fetchOfflinePreSaleInspections } from 'features/OfflinePreSaleInspections/offlinePreSaleInspectionsSlice';
import { fetchOfflineInspections } from 'features/OfflineBrandInspections/offlineInspectionsSlice';
import { fetchInspections } from 'features/BrandInspections/inspectionsSlice';
import { fetchPreSaleInspections } from 'features/PreSaleInspections/preSaleInspectionsSlice';
import { fetchLifetimeTravelPermits } from 'features/LifetimeTravelPermits/lifetimeTravelPermitsSlice';
import { fetchYearlyTravelPermits } from 'features/YearlyTravelPermits/yearlyTravelPermitsSlice';
import { fetchCodesV2 } from 'features/Initializer/initializerSlice';
import { displaySyncResults } from 'service/sync';
import syncPresale from 'features/OfflinePreSaleInspections/sync';
import syncTravelPermit from 'features/OfflineTravelPermits/sync';
import syncInspection from 'features/OfflineBrandInspections/sync';
import { syncPayment } from 'features/SettlePaymentForm/settlePaymentSlice';

export const syncOfflineData = createAsyncThunk(
  'syncOffline/syncOfflineData',
  async (displayMessaging: boolean, { extra, dispatch, getState }) => {
    const { inspectionsDb: db } = extra;
    const travelPermitsToSync = await db.offlineTravelPermit.toArray();
    const presalesToSync = await db.offlinePreSaleInspections.toArray();
    const inspectionsToSync = await db.offlineInspections.toArray();
    const paymentsToSync = await db.offlinePayments.toArray();
    const { online } = getState().initializer;

    if (!online) {
      await dispatch(fetchCodesV2());

      if (displayMessaging) {
        showWarningMessage(
          'Offline. Please connect to the Internet and try again.',
        );
      }

      return true;
    }

    const hasTravelPermits = travelPermitsToSync.length;
    const hasPresales = presalesToSync.length;
    const hasInspections = inspectionsToSync.length;
    const hasPendingPayments = paymentsToSync.length;

    if (
      !hasTravelPermits &&
      !hasPresales &&
      !hasInspections &&
      !hasPendingPayments
    ) {
      if (displayMessaging) {
        showWarningMessage('Nothing to sync.');
      }

      await dispatch(fetchCodesV2());

      return true;
    }

    const inspectionsToReload = [];

    if (hasTravelPermits || hasPendingPayments) {
      inspectionsToReload.push(
        fetchOfflineTravelPermits(),
        fetchYearlyTravelPermits(),
        fetchLifetimeTravelPermits(),
      );
    }
    if (hasPresales || hasPendingPayments) {
      inspectionsToReload.push(
        fetchOfflinePreSaleInspections(),
        fetchPreSaleInspections(),
      );
    }
    if (hasInspections || hasPendingPayments) {
      inspectionsToReload.push(fetchOfflineInspections(), fetchInspections());
    }

    // STEP 1: Sync
    return (
      Promise.all([
        ...presalesToSync.map(syncPresale(dispatch)),
        ...travelPermitsToSync.map(syncTravelPermit(dispatch)),
        ...inspectionsToSync.map(syncInspection(dispatch)),
        ...paymentsToSync.map(syncPayment),
      ])

        // STEP 2: display messaging if appropriate
        .then((results) => {
          if (displayMessaging) {
            return displaySyncResults()(results);
          }
          return Promise.resolve();
        })

        // STEP 3: reload inspection collections
        .then(async () => {
          return Promise.all([
            ...inspectionsToReload.map((fetch) => dispatch(fetch)),
            dispatch(fetchCodesV2()),
          ]);
        })
    );
  },
);

const syncOfflineSlice = createSlice({
  name: 'syncOffline',
  initialState: {
    syncing: false,
  },
  extraReducers: {
    [syncOfflineData.pending]: (state) => {
      state.syncing = true;
    },
    [syncOfflineData.fulfilled]: (state) => {
      state.syncing = false;
    },
    [syncOfflineData.rejected]: (state) => {
      state.syncing = false;
    },
  },
});

export default syncOfflineSlice.reducer;
