// @flow
import React from 'react';
import { Row, Col, Form, Input } from 'antd';
import SignatureFormField from 'components/form/SignatureFormField';

type Props = {
  hasExistingInspectorSignature: boolean,
};

const Signatures = ({ hasExistingInspectorSignature }: Props) => {
  return (
    <Row gutter={16}>
      <Col lg={8} md={12}>
        <Form.Item
          name="sellerSignature"
          rules={[
            {
              required: true,
              message: 'Signature is required',
            },
          ]}
          style={{ marginBottom: 0 }}
        >
          <SignatureFormField label="Signature of Seller/Agent" />
        </Form.Item>
        <Form.Item
          label="Date"
          name="sellerSignatureDate"
          rules={[
            {
              required: true,
              message: 'Date of signature is required',
            },
          ]}
        >
          <Input readOnly />
        </Form.Item>
      </Col>
      <Col lg={8} md={12}>
        <Form.Item
          name="inspectorSignature"
          rules={[
            {
              required: true,
              message: 'Signature is required',
            },
          ]}
          style={{ marginBottom: 0 }}
        >
          <SignatureFormField
            label="Brand Inspector signature"
            hasExisting={hasExistingInspectorSignature}
          />
        </Form.Item>
        <Form.Item
          label="Date"
          name="inspectorSignatureDate"
          rules={[
            {
              required: true,
              message: 'Date of signature is required',
            },
          ]}
          style={{ display: 'none' }}
        >
          <Input readOnly />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default Signatures;
