// @flow
import { createSelector } from '@reduxjs/toolkit';
import type { ReduxStore } from 'flow/redux';
import type {
  Breed,
  Category,
  Color,
  Dealer,
  Gender,
  InspectionType,
  ResourcesState,
  ServiceFee,
  State,
} from 'flow/resources';

export const YEARLY_TRAVEL_PERMIT = 'yearly-travel-permit';
export const LIFETIME_TRAVEL_PERMIT = 'lifetime-travel-permit';
const TRAVEL_PERMITS = [YEARLY_TRAVEL_PERMIT, LIFETIME_TRAVEL_PERMIT];

const selectResourcesDomain = (state: ReduxStore) => state.resources;

export const selectAnimalCategories = createSelector(
  selectResourcesDomain,
  ({ animalCategories }: ResourcesState): Array<Category> => animalCategories,
);

export const selectServiceFees = createSelector(
  selectResourcesDomain,
  ({ serviceFees }: ResourcesState): Array<ServiceFee> => serviceFees,
);

export const selectExcludedInspectionTypes = createSelector(
  selectAnimalCategories,
  (state: Array<Category>) => {
    const horseCategory = state.find(
      (animal: Category) => animal.slug === 'horse',
    );
    return (
      (horseCategory && [
        {
          categoryId: horseCategory.id,
          exclude: TRAVEL_PERMITS,
        },
      ]) ||
      []
    );
  },
);

export const selectUtahState = createSelector(
  selectResourcesDomain,
  ({ states }: ResourcesState) => {
    return states.find((s: State) => s.code === 'UT') || {};
  },
);

export const selectHorseCategoryId = createSelector(
  selectResourcesDomain,
  ({ animalCategories }: ResourcesState) => {
    const { id } =
      animalCategories.find(({ slug }: Category) => slug === 'horse') || {};
    return id;
  },
);

export const selectInspectionTypes = createSelector(
  selectResourcesDomain,
  ({ inspectionTypes }: ResourcesState) => inspectionTypes,
);

export const selectTravelPermitInspectionTypes = createSelector(
  selectResourcesDomain,
  ({ inspectionTypes }: ResourcesState) =>
    inspectionTypes.filter((type: InspectionType) =>
      TRAVEL_PERMITS.includes(type.slug),
    ),
);

export const selectBreeds = createSelector(
  selectResourcesDomain,
  ({ animalBreeds }: ResourcesState): Array<Breed> => animalBreeds,
);

export const selectColors = createSelector(
  selectResourcesDomain,
  ({ animalColors }: ResourcesState): Array<Color> => animalColors,
);

export const selectGenders = createSelector(
  selectResourcesDomain,
  ({ animalGenders }: ResourcesState): Array<Gender> => animalGenders,
);

export const selectStates = createSelector(
  selectResourcesDomain,
  ({ states }: ResourcesState) => states,
);

export const selectCounties = createSelector(
  selectResourcesDomain,
  ({ counties }: ResourcesState) => counties,
);

export const selectDealers = createSelector(
  (state: ReduxStore) => state.resources.dealers,
  (_, searchKey: string) => searchKey || '',
  (list: Array<Dealer>, searchKey: string) => {
    return list
      .filter(({ license }: $Shape<Dealer>) => {
        return license.includes(searchKey);
      })
      .slice(0, 10);
  },
);

export const selectResourcesPending = createSelector(
  selectResourcesDomain,
  (state: ResourcesState) => state.pending,
);
