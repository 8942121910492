// @flow
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector, type Dispatch } from 'react-redux';
import { Select, Space, Input, Button } from 'antd';
import { HighlightOutlined } from '@ant-design/icons';
import { debounce } from 'lodash';
import styled from 'styled-components';
import type { ReduxStore } from 'flow/redux';
import { type BrandType, getPositionLabel } from 'flow/brands';
import { searchBrands, clearBrands } from 'store/brandsSlice';
import { selectBrands, selectBrandsLoading } from 'selectors/brands';
import NetworkImage from 'components/NetworkImage';
import BrandCreatorModal from './BrandCreatorModal';

type Props = {
  id: string,
  value: ?Array<BrandType>,
  onChange: (Array<BrandType>) => void,
};

const { Option } = Select;

const StyledSpace = styled(Space)`
  display: flex;
  font-size: 0.865em;
  padding-right: 5px;
`;

const BrandsFormField = ({ id, value, onChange }: Props) => {
  const dispatch: Dispatch = useDispatch();

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const brands: Array<$Shape<BrandType>> = useSelector(selectBrands);

  const loading: boolean = useSelector(selectBrandsLoading);

  const { page, query, count } = useSelector((state: ReduxStore) => {
    const { page, query, count } = state.brands;

    return { page, query, count };
  });

  const LIMIT = 20;

  const handleSearch = debounce((value: string) => {
    dispatch(searchBrands({ query: value, page: 0, limit: LIMIT }));
  }, 300);

  const parsedValues = (value || []).map(({ id }: $Shape<BrandType>) => id);

  const options = [
    {
      id: '0',
      brandNumber: '',
      position: 'No Brand',
      letters: [],
      firstNames: [],
      lastNames: [],
      counties: [],
      zipcodes: [],
      isSheep: '',
      expired: 'false',
      imageUrl: '',
    },
    ...brands,
  ];

  (value || []).forEach((brand: $Shape<BrandType>) => {
    if (!options.some((o) => o.id === brand.id)) {
      options.unshift(brand);
    }
  });

  const handleChange = (selectedValues: Array<string>) => {
    const values = options.filter(({ id }: $Shape<BrandType>) => {
      return selectedValues.includes(id);
    });

    onChange(values);
  };

  const handleScroll = (ev) => {
    const wrapper = ev?.target;
    const optionsCont = (wrapper?.children || [])[0];

    if (wrapper && optionsCont) {
      const remaining = optionsCont.offsetHeight - wrapper.scrollTop;
      const end = count <= page * LIMIT;

      // 256 default options list height
      // just added additional buffer to trigger fetching sooner
      if (remaining <= 300 && !loading && !end) {
        dispatch(
          searchBrands({
            query,
            page: page + 1,
            limit: LIMIT,
          }),
        );
      }
    }
  };

  const handleCloseModal = () => setModalOpen(false);

  const handleCreateBrand = (brands: Array<BrandType>) => {
    onChange([...(value || []), ...brands]);

    handleCloseModal();
  };

  useEffect(() => {
    return () => {
      dispatch(clearBrands());
    };
  }, [dispatch]);

  return (
    <Fragment>
      <Input.Group compact style={{ display: 'flex' }}>
        <Select
          mode="multiple"
          placeholder="Select Brand"
          onSearch={handleSearch}
          loading={loading}
          filterOption={false}
          showSearch
          value={parsedValues}
          onChange={handleChange}
          onPopupScroll={handleScroll}
          virtual={false}
          style={{ width: '100%' }}
          id={id}
        >
          {options.map(({ id, imageUrl, position }: $Shape<BrandType>) => (
            <Option key={id} value={id}>
              <StyledSpace size="small">
                <NetworkImage
                  height={22}
                  width={22}
                  src={imageUrl}
                  alt={position}
                />
                <span>{getPositionLabel(position)}</span>
              </StyledSpace>
            </Option>
          ))}
        </Select>

        <Button
          style={{ width: 40, height: 'auto' }}
          icon={<HighlightOutlined />}
          onClick={() => setModalOpen(true)}
        />
      </Input.Group>

      <BrandCreatorModal
        visible={modalOpen}
        onCancel={handleCloseModal}
        onConfirm={handleCreateBrand}
      />
    </Fragment>
  );
};

BrandsFormField.defaultProps = {
  value: [],
  onChange: () => {},
  id: '',
};

export default BrandsFormField;
