// @flow
import React from 'react';
import { Space, Tabs } from 'antd';
import {
  Switch,
  Route,
  useLocation,
  useHistory,
  type Location,
  type History,
} from 'react-router-dom';
import Page from 'components/Page';
import BrandInspections from 'features/BrandInspections';
import OfflineBrandInspections from 'features/OfflineBrandInspections';
import PreSaleInspections from 'features/PreSaleInspections';
import OfflinePreSaleInspections from 'features/OfflinePreSaleInspections';
import TravelPermits from 'features/TravelPermits';
import OfflineTravelPermits from 'features/OfflineTravelPermits';
import DateRangeFilter from 'components/DateRangeFilter';
import SearchFilter from 'components/SearchFilter';

const { TabPane } = Tabs;

const UnpaidCertificatesPage = () => {
  const { pathname }: Location = useLocation();

  const history: History = useHistory();

  const handleChange = (key: string) => {
    history.push(key);
  };

  return (
    <Page>
      <Tabs activeKey={pathname} onChange={handleChange}>
        <TabPane tab="Inspections" key="/unpaid/inspections" />
        <TabPane tab="Offline Inspections" key="/unpaid/offline-inspections" />
        <TabPane tab="Pre-sale" key="/unpaid/presale" />
        <TabPane tab="Offline Pre-sale" key="/unpaid/offline-presale" />
        <TabPane tab="Travel Permits" key="/unpaid/travel-permits" />
        <TabPane
          tab="Offline Travel Permits"
          key="/unpaid/offline-travel-permits"
        />
      </Tabs>

      <Space align="center" style={{ marginBottom: 10 }}>
        <SearchFilter />

        <DateRangeFilter />
      </Space>

      <Switch>
        <Route exact path="/unpaid/inspections">
          <BrandInspections paymentStatus="pay_later" />
        </Route>

        <Route exact path="/unpaid/offline-inspections">
          <OfflineBrandInspections paymentStatus="pay_later" />
        </Route>

        <Route exact path="/unpaid/presale">
          <PreSaleInspections paymentStatus="pay_later" />
        </Route>

        <Route exact path="/unpaid/offline-presale">
          <OfflinePreSaleInspections paymentStatus="pay_later" />
        </Route>

        <Route exact path="/unpaid/travel-permits">
          <TravelPermits paymentStatus="pay_later" />
        </Route>

        <Route exact path="/unpaid/offline-travel-permits">
          <OfflineTravelPermits paymentStatus="pay_later" />
        </Route>
      </Switch>
    </Page>
  );
};

export default UnpaidCertificatesPage;
