// @flow
import React from 'react';
import { useUpdateEffect } from 'react-use';
import { useSelector } from 'react-redux';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import type { ReduxStore } from 'flow/redux';
import * as S from './styles';

const LoadingMask = () => {
  const loading: boolean = useSelector((state: ReduxStore) => {
    return state.loadingMask.loading;
  });

  useUpdateEffect(() => {
    if (document.body?.style) {
      if (loading) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    }
  }, [loading]);

  return (
    <S.Wrapper $loading={loading}>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 45 }} spin />} />
      <S.Message>Saving, please be patient...</S.Message>
    </S.Wrapper>
  );
};

export default LoadingMask;
