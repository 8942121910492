// @flow
import React, { Fragment } from 'react';
import type { Dispatch } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import type { ReduxStore } from 'flow/redux';
import type { LineItem } from 'flow/inspection';
import type { LineItemCombined, LineItemFootnote } from 'flow/footnotes';
import { FEE_DESCRIPTIONS } from 'constants/inspection';
import { selectOverrideLineItems } from 'selectors/inspection';
import { removeOverridenLineItem } from 'pages/BrandInspectionPage/inspectionSlice';
import {
  combineFeesForMessaging,
  formatPrice,
  getCertificateType,
  lineItemToLineItemCombine,
} from 'components/Footnotes/util';
import Footnote from 'components/Footnotes/Footnote';

const OVERRIDES_DISPLAYED_IN_SUBTOTALS = ['beef_tag_fee'];

/**
 * filter out any overrides that are already accounted for somewhere else
 * @param slug to test
 * @returns {boolean} true if the line item should be included
 */
export const removeAlreadyDisplayedOverrides = ({ slug }: $Shape<LineItem>) =>
  OVERRIDES_DISPLAYED_IN_SUBTOTALS.indexOf(slug) < 0;

/**
 * Prepare the override message for display
 *
 * @param lineNumbers used in the remove function
 * @param slug used to look up the fee type description
 * @param price for the override
 * @param reason for the override
 * @returns {string} for communicating the override to the certificate receiver
 */
const formatOverrideMessage = ({
  lineNumbers,
  slug,
  price,
  reason,
}: LineItemCombined): LineItemFootnote => {
  const feeAddedFor = reason ? `fee added for ${reason}` : '';
  return {
    message: `${formatPrice(price)} ${FEE_DESCRIPTIONS[slug]} ${feeAddedFor}`,
    lineNumbers,
    slug,
  };
};

type Props = {
  editable: ?boolean,
};

const OverriddenFees = ({ editable }: Props) => {
  const dispatch: Dispatch = useDispatch();

  const lineItems: Array<$Shape<LineItem>> =
    useSelector((state: ReduxStore) =>
      selectOverrideLineItems(state, getCertificateType(editable)),
    ) || [];

  const handleRemoveOverrideFeeFromTable = (values: {
    lineNumber: ?string,
    slug: string,
  }) => {
    dispatch(removeOverridenLineItem(values));
  };

  return (
    <Fragment>
      {!!lineItems &&
        lineItems
          .filter(removeAlreadyDisplayedOverrides) // like beef tags that have their own line item
          .map(lineItemToLineItemCombine)
          .reduce(combineFeesForMessaging, [])
          .map(formatOverrideMessage)
          .map((footnote: LineItemFootnote, i: number) => (
            <Footnote
              key={i}
              footnote={footnote}
              editable={editable}
              onRemove={handleRemoveOverrideFeeFromTable}
            />
          ))}
    </Fragment>
  );
};

OverriddenFees.defaultProps = {
  editable: false,
};

export default OverriddenFees;
