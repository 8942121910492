// @flow
import axios from 'axios';
import applyCaseMiddleware from 'axios-case-converter';
import { tokenUtil } from '@acclaim/udaf-kit';
import {
  ENVIRONMENT,
  getAuthUrl,
  getBrandsApiUrl,
  getInspectionsApiUrl,
} from './urls';
import createError from './logger';
import { inspectionsDb } from './idb';

export default axios.create({
  baseURL: getBrandsApiUrl(),
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export const clearCredentials = async () => {
  await inspectionsDb.user.clear();

  tokenUtil.removeToken();

  window.localStorage.removeItem('user-id');
  return Promise.resolve(true);
};

const handle401 = (status: ?number): void => {
  if (status === 401) {
    clearCredentials();
    window.location.href = getAuthUrl();
  }
};

export const inspectionClient = () => {
  const client = applyCaseMiddleware(
    axios.create({
      baseURL: getInspectionsApiUrl(),
      headers: {
        Authorization: `Bearer ${tokenUtil.getToken()}`,
        'Content-Type': 'application/json',
        'x-app-version': ENVIRONMENT.build.version,
      },
    }),
  );

  client.interceptors.response.use(null, (err) => {
    const { response } = err;

    if (response?.status === 401) {
      handle401(response?.status);
    } else if (response) {
      createError({
        payload: response?.config?.data || '',
        endpoint: response?.request?.responseURL || '',
        errorStack: response?.request?.responseText || '',
      });
    }

    return Promise.reject(err);
  });

  return client;
};

export function createAuthClient(contentType: string) {
  const authApi = axios.create({
    baseURL: getBrandsApiUrl(),
    headers: {
      Authorization: `Bearer ${tokenUtil.getToken()}`,
      Accept: 'application/json',
      'Content-Type': contentType || 'application/json',
    },
  });

  authApi.interceptors.response.use(null, (err) => {
    const { response } = err;

    if (response?.status === 401) {
      handle401(response?.status);
    } else if (response) {
      createError({
        payload: response?.config?.data || '',
        endpoint: response?.request?.responseURL || '',
        errorStack: response?.request?.responseText || '',
      });
    }

    return Promise.reject(err);
  });

  return authApi;
}
