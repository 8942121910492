// @flow
import React from 'react';
import { useUpdateEffect } from 'react-use';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import type { ReduxStore } from 'flow/redux';
import { PAYMENT_STATE } from './slice';

const StyledWrapper = styled.div`
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.75);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  opacity: 0;

  ${(props) =>
    props.$visible &&
    `
    visibility: visible;
    opacity: 1;
  `}
`;

export const Message = styled.p`
  font-size: 18px;
  font-weight: 600;
  font-style: italic;
  margin: 10px 0;
  text-align: center;
`;

const PaymentStateLoader = () => {
  const paymentState = useSelector((state: ReduxStore) => {
    return state.paymentStateLoader.state;
  });

  const visible = paymentState !== PAYMENT_STATE.IDLE;

  useUpdateEffect(() => {
    if (document.body?.style) {
      if (visible) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    }
  }, [visible]);

  const processMessage = {
    idle: '',
    pending: 'Processing payment. Please be patient...',
    failed: 'Payment failed. Please try again.',
    success: 'Payment success!',
  }[paymentState];

  return (
    <StyledWrapper $visible={visible}>
      <Spin indicator={<LoadingOutlined style={{ fontSize: 45 }} spin />} />
      <Message>
        {processMessage}
      </Message>
    </StyledWrapper>
  );
};

export default PaymentStateLoader;
