// @flow
export default function getPlatform(): 'tablet' | 'desktop' {
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent,
    ) ||
    (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 0) ||
    navigator.platform === 'iPad'
  ) {
    return 'tablet';
  }

  return 'desktop';
}
