// @flow
import type { BrandQueryParts } from '../../flow/brands';

const DEBUG = false;
const TRACE = false;

const LOG_QUERY_PARTS = 'the rest of the parts parts:';
const BRAND_NUMBER_PATTERN = /[12457]-[a-zA-Z0-9]{4}-\d{2}/i;
const ALPHABET_CHARS_ONLY = /^[a-zA-Z]+$/i;
const NUMBER_PATTERN = /\d+/;
const VOWELS_ONLY = /^[aeiouAEIOU]+$/i;
const CONSONANTS_ONLY = /^[^aeiouAEIOU]+$/i;
const LONG_LETTERS_HACK = new Set()
  .add('//((/')
  .add('-7v7-')
  .add('arrow')
  .add('burst')
  .add('chair')
  .add('clove')
  .add('crown')
  .add('fishs')
  .add('heart')
  .add('horse')
  .add('utah')
  .add('texas')
  .add('-i-i-')
  .add('-iii-')
  .add('iii(c')
  .add('iiii-')
  .add('iiii(')
  .add('iiiii')
  .add('iiiir')
  .add('iii(j')
  .add('iii(n')
  .add('iiio-')
  .add('iii(p')
  .add('iii(t')
  .add('-jae-')
  .add('(juji')
  .add('l//lo')
  .add('//o//')
  .add('ooooi')
  .add('---s-')
  .add('shirt')
  .add('s(iii')
  .add('spade')
  .add('tjhb(')
  .add('tjktj')
  .add('-tjr-')
  .add('-tjtj')
  .add('tooth')
  .add('(yl--')
  .add('xxx');
const COUNTIES = new Set()
  .add('beaver')
  .add('box elder')
  .add('cache')
  .add('carbon')
  .add('daggett')
  .add('davis')
  .add('duchesne')
  .add('emery')
  .add('garfield')
  .add('grand')
  .add('iron')
  .add('juab')
  .add('kane')
  .add('millard')
  .add('morgan')
  .add('piute')
  .add('rich')
  .add('salt lake')
  .add('san juan')
  .add('sanpete')
  .add('sevier')
  .add('summit')
  .add('tooele')
  .add('uintah')
  .add('utah')
  .add('wasatch')
  .add('washington')
  .add('wayne')
  .add('weber')
  .add('out-of-state');

const removeEmptyTerms = (term: string): boolean => !!term;
const getChars = (letters: string): Set<string> => new Set(letters.split(''));
const isANumber = (value: string): boolean => NUMBER_PATTERN.test(value);
const onlyAlphabet = (word: string): boolean => ALPHABET_CHARS_ONLY.test(word);
const notAllVowels = (word: string): boolean => !VOWELS_ONLY.test(word);
const notAllConsonants = (word: string): boolean => !CONSONANTS_ONLY.test(word);
const partsSplitOnDash = (value: string): Set<string> =>
  new Set(value.split('-').filter(removeEmptyTerms));
const isBrandNumber = (possibleBrandNumber: string): boolean =>
  BRAND_NUMBER_PATTERN.test(possibleBrandNumber);

const getNames = (queryParts: Set<string>): Array<string> => {
  const names: Array<string> = [...queryParts];
  if (DEBUG) console.log('possible names: {}', names);
  if (TRACE) console.log(LOG_QUERY_PARTS, queryParts);
  return names;
};

const getDefiniteLetters = (queryParts: Set<string>): Array<string> => {
  const definiteBrandLetters: Array<string> = [];

  // find all the groups of letters that are likely a name
  const notADefiniteBrandLetterGroup: Set<string> = new Set(
    [...queryParts]
      .filter((word) => word.length > 2)
      .filter(onlyAlphabet)
      .filter(notAllVowels)
      .filter(notAllConsonants),
  );

  // all groups that are definitely not a name should be considered a brand letter group
  const definiteBrandLetterWords: Set<string> = new Set(
    [...queryParts].filter(
      (word) =>
        LONG_LETTERS_HACK.has(word) || !notADefiniteBrandLetterGroup.has(word),
    ),
  );

  // remove brand letter groups from query parts
  definiteBrandLetterWords.forEach((word: string): boolean =>
    queryParts.delete(word),
  );

  // convert the groups to a Set of chars
  definiteBrandLetterWords.forEach((word) => {
    const escapedWord = word
      .replace('\\', '/')
      .replace(')', '(')
      .replace('0', 'o');

    getChars(escapedWord).forEach((character) =>
      definiteBrandLetters.push(character),
    );
  });

  if (DEBUG) console.log('definite brand letters:', definiteBrandLetters);
  if (TRACE) console.log(LOG_QUERY_PARTS, queryParts);

  return definiteBrandLetters;
};

const getLetters = (
  queryParts: Set<string>,
  possibleNames: Array<string>,
): Array<string> => {
  // if the person entered two identifiers that qualify as names,
  // it's more likely than not, that they intend to search by name,
  // so don't muddy the query by parsing for letters
  if (possibleNames && possibleNames.length > 1) {
    return [];
  }
  const letters: Array<string> = [];
  [...queryParts]
    .filter(
      (part) =>
        // if the part is less than 5 characters it could be a name
        part.length < 5 &&
        // if the part is 4+ characters and already considered to be a name, don't count it as letters
        !(part.length > 3 && possibleNames.includes(part)),
    )
    .forEach((sample) =>
      getChars(sample).forEach((character) => letters.push(character)),
    );

  if (DEBUG) console.log('possible brand letters:', letters);
  if (TRACE) console.log(LOG_QUERY_PARTS, queryParts);

  return letters;
};

const getCountiesFromString = (query: string): Array<string> => {
  const counties: Array<string> = [];
  COUNTIES.forEach((county) => {
    if (query.includes(county)) {
      counties.push(county);
    }
  });
  return counties;
};

const getCounties = (
  queryParts: Set<string>,
  lowerCaseQuery: string,
): Array<string> => {
  const counties: Array<string> = getCountiesFromString(lowerCaseQuery);
  counties.forEach((county) =>
    county
      .split(' ')
      .forEach((countyNamePart) => queryParts.delete(countyNamePart)),
  );

  if (DEBUG) console.log('definite counties:', counties);
  if (TRACE) console.log(LOG_QUERY_PARTS, queryParts);

  return counties;
};

const isPossibleZipcode = (potentialZipcode: string): boolean => {
  const startingSize: number = potentialZipcode.length;
  if (startingSize > 10 || startingSize < 5) {
    return false;
  }
  const parts: Set<string> = partsSplitOnDash(potentialZipcode);
  const partCount: number = parts.size;
  return (
    partCount < 3 &&
    [...parts].filter((part) => part.length < 11).filter(isANumber).length ===
      partCount
  );
};

const getPossibleZipcodes = (queryParts: Set<string>): Array<string> => {
  const zipcodeTermsToRemove: Set<string> = new Set();
  const couldBePartOfAZipcode: Array<string> = [...queryParts].filter(
    (term) => isPossibleZipcode(term) && zipcodeTermsToRemove.add(term),
  );

  zipcodeTermsToRemove.forEach((zipcode) => queryParts.delete(zipcode));

  if (DEBUG) console.log('could be part of a zipcode:', couldBePartOfAZipcode);
  if (TRACE) console.log(LOG_QUERY_PARTS, queryParts);

  return couldBePartOfAZipcode;
};

const getDefiniteBrandNumbers = (queryParts: Set<string>): Array<string> => {
  const brandNumberTermsToRemove: Set<string> = new Set();
  const definiteBrandNumbers: Array<string> = [...queryParts]
    .filter((term) => isBrandNumber(term) && brandNumberTermsToRemove.add(term))
    .map((term) => term.toUpperCase());

  brandNumberTermsToRemove.forEach((brandNumber) =>
    queryParts.delete(brandNumber),
  );

  // todo make sure this doesn't do anything before removing it
  // queryParts = queryParts.stream()
  //   .filter(removeEmptyTerms)
  //   .collect(Collectors.toSet());

  if (DEBUG) console.log('definite brand numbers:', definiteBrandNumbers);
  if (TRACE) console.log(LOG_QUERY_PARTS, queryParts);

  return definiteBrandNumbers;
};

const isPossibleBrandNumber = (possibleBrandNumber: string): boolean => {
  const startingSize: number = possibleBrandNumber.length;
  if (startingSize > 8 || startingSize < 4) {
    return false;
  }
  const parts: Set<string> = partsSplitOnDash(possibleBrandNumber);

  const partCount: number = parts.size;
  return (
    partCount < 4 &&
    [...parts].filter((part) => part.length < 5).filter(isANumber).length ===
      partCount
  );
};

const getBrandNumberParts = (queryParts: Set<string>): Array<string> => {
  const partBrandNumberTermsToRemove: Set<string> = new Set();
  const couldBePartOfABrandNumber: Array<string> = [...queryParts].filter(
    (term) =>
      isPossibleBrandNumber(term) && partBrandNumberTermsToRemove.add(term),
  );

  partBrandNumberTermsToRemove.forEach((brandNumberPart) =>
    queryParts.delete(brandNumberPart),
  );

  const brandNumberPartsToUpperCase = couldBePartOfABrandNumber.map((term) =>
    term.toUpperCase(),
  );

  if (DEBUG)
    console.log(
      'could be part of a brand number:',
      brandNumberPartsToUpperCase,
    );
  if (TRACE) console.log(LOG_QUERY_PARTS, queryParts);

  return brandNumberPartsToUpperCase;
};

const parse = (
  queryParts: Set<string>,
  lowerCaseQuery: string,
): BrandQueryParts => {
  const brandNumbers = getDefiniteBrandNumbers(queryParts);
  const brandNumberParts = getBrandNumberParts(queryParts);
  const possibleZipcodes = getPossibleZipcodes(queryParts);
  const counties = getCounties(queryParts, lowerCaseQuery);
  const brandLetters = getDefiniteLetters(queryParts);
  const possibleNames = getNames(queryParts);
  const possibleBrandLetters = getLetters(queryParts, possibleNames);
  const hasSearchTerms = !(
    !brandNumbers.length &&
    !brandNumberParts.length &&
    !possibleZipcodes.length &&
    !counties.length &&
    !possibleNames.length &&
    !possibleBrandLetters.length &&
    !brandLetters.length
  );
  return {
    brandNumbers,
    brandNumberParts,
    possibleZipcodes,
    counties,
    definiteBrandLetters: brandLetters,
    possibleNames,
    possibleBrandLetters,
    hasSearchTerms,
  };
};

/**
 * A query might look like
 * '1-0018-01 0017 Salt Lake 1-0018-01 LH Jason Buchanan -0012  1-01 Weber 84315 84343-1122 Right Hip Head Sheep Earmark'
 */
export const parseBrandQueryParts = (query: string): BrandQueryParts => {
  if (!query) {
    return {};
  }
  const lowerCaseQuery = query
    .replace(',', ' ')
    .replace('  ', ' ')
    .toLowerCase()
    .trim();

  const queryParts: Set<string> = new Set(lowerCaseQuery.split(' '));

  return parse(queryParts, lowerCaseQuery);
};
