// @flow
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import type { ReduxStore } from 'flow/redux';
import { INSPECTION_UPDATE_HISTORY } from 'flow/inspection';
import type { Filters } from 'flow/search';
import { showErrorMessage } from 'utils/showMessage';
import storeResourceData from 'utils/storeResourceData';
import { filterInspections } from 'service/search/util';
import { getDateParams, stringToTimestamp } from 'utils/dates';
import { syncIfNeeded, tableHasNoRecords } from 'service/sync';
import { monthUtil } from '@acclaim/udaf-kit';

const {
  TYPES: { PRESALES },
} = INSPECTION_UPDATE_HISTORY;

const adapter = createEntityAdapter();

const initialState = adapter.getInitialState({
  loading: false,
  total: 0,
});

export const { selectAll: selectPreSaleInspections } = adapter.getSelectors(
  (state: ReduxStore) => {
    return state.preSaleInspections;
  },
);

export const fetchPreSaleInspections = createAsyncThunk(
  'preSaleInspections/fetchPreSaleInspections',
  async (filters: ?Filters, { extra, rejectWithValue, getState }) => {
    const { from, to, query, limit, offset, paymentStatus } = filters || {};
    const { inspectionClient, inspectionsDb: db, createError } = extra;
    const {
      me,
      initializer: { online },
    } = getState();
    const userId = me.data?.id;
    const { preSaleInspections: presalesTable } = db;

    try {
      const explicitReload = await tableHasNoRecords(userId, presalesTable);
      return syncIfNeeded(
        userId,
        PRESALES,
        online,
        explicitReload,
        () => Promise.resolve(presalesTable),
        (everythingAfterDate: ?string, truncateData: boolean) => {
          const params = { ...getDateParams(everythingAfterDate), userId };
          params.monthsData = monthUtil.getmonthToken();
          return inspectionClient()
            .get('/presale/', { params })
            .then(async ({ data }) => {
              const presales = data.map((presale) => ({
                ...presale,
                presaleDate: stringToTimestamp(presale.presaleDate),
              }));
              await storeResourceData(
                db,
                'preSaleInspections',
                presales,
                truncateData,
              );
              return presalesTable;
            });
        },
        true,
      ).then((presales) =>
        filterInspections({
          range: { from, to, key: 'presaleDate' },
          query,
          limit,
          offset,
          paymentStatus: paymentStatus || 'all',
          table: presales,
        }),
      );
    } catch (err) {
      createError({
        payload: '',
        endpoint: 'OFFLINE: offlineTravelPermits/fetchOfflineTravelPermits',
        errorStack: err?.stack,
      });

      showErrorMessage(err?.message);

      return rejectWithValue(err);
    }
  },
);

const preSaleInspectionsSlice = createSlice({
  name: 'preSaleInspections',
  initialState,
  reducers: {
    clearAll: (state) => {
      state.total = 0;
      adapter.removeAll(state);
    },
  },
  extraReducers: {
    [fetchPreSaleInspections.pending]: (state) => {
      state.loading = true;
    },
    [fetchPreSaleInspections.fulfilled]: (state, { payload }) => {
      const { total, result } = payload;

      state.loading = false;
      state.total = total;
      adapter.setAll(state, result);
    },
    [fetchPreSaleInspections.rejected]: (state) => {
      state.loading = false;
      state.total = 0;
      adapter.removeAll(state);
    },
  },
});

export const { clearAll } = preSaleInspectionsSlice.actions;

export default preSaleInspectionsSlice.reducer;
