// @flow
import React from 'react';
import { Button } from 'antd';

type VoidButtonProps = {
  id: string,
  disabled?: boolean,
  onClick: () => void,
};

type PrintButtonProps = {
  id: string,
  label: string,
  disabled?: boolean,
  onClick: () => void,
};

export const PrintButton = ({
  id,
  label,
  disabled,
  onClick,
}: PrintButtonProps) => (
  <Button
    id={id}
    onClick={onClick}
    disabled={disabled}
    ghost
    htmlType="button"
    type="primary"
  >
    {label}
  </Button>
);

PrintButton.defaultProps = {
  disabled: false,
};

export const VoidButton = ({ id, disabled, onClick }: VoidButtonProps) => (
  <Button
    id={id}
    onClick={onClick}
    disabled={disabled}
    htmlType="button"
    type="danger"
  >
    Void
  </Button>
);

VoidButton.defaultProps = {
  disabled: false,
};
