// @flow
import styled from 'styled-components';
import { Section } from 'components/CertificateTemplate';

// ratio for Letter format: 1.2941176471
// const RATIO = 0.7727272;
// const RATIO = 1.2941176471;

export const Legalize = styled.p`
  font-weight: 400;
  margin: 0;
`;

export const Title = styled.span`
  text-align: center;
  font-weight: 100;
  text-transform: uppercase;
  margin: 0;
`;

export const PayFor = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Total = styled(PayFor)`
  border-top: 1px solid #999999;
  margin-top: 0.5em;
  padding: 0.5em 0 1.5em;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  em {
    color: #999999;
  }
`;

export const HeadSubtotal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  strong:last-child {
    text-align: right;
  }
`;

export const TransportContainer = styled(Section)`
  display: flex;
  flex-direction: row;
`;

export const TransportPart = styled.div`
  padding: 0 1em 0 0;
  label {
    display: block;
    font-weight: 600;
    margin-right: 0.6em;
  }
`;

export const Pill = styled.span`
  display: inline-block;
  white-space: nowrap;
  color: #002d06;
  padding: 0;
  margin: 0 0.2em;
  line-height: 0.7em;

  > img {
    width: 1.25em;
    height: 1.25em;
    margin-right: 0.2em;
  }
`;

export const Table = styled.table`
  border-collapse: separate;
  border-spacing: 0;
  padding: ${(props) => props.padding || '0'};
  margin: ${(props) => props.margin || '0'};

  thead {
    background-color: #ffffff;

    tr {
      background-color: #296f3d;
    }

    th {
      font-size: 0.8em;
      line-height: 1em;
      font-weight: 800;
      padding: 0.25em 0.5em;
      text-transform: uppercase;
      color: #ffffff;
      border-bottom: 1px solid #15391f;
      span {
        font-weight: 600;
      }
    }

    th:nth-child(2) {
      width: 12%;
    }

    th:nth-child(3) {
      width: 15%;
    }

    th:last-child {
      width: 10%;
    }

    th:first-child {
      text-align: left;
    }

    th:not(:first-child) {
      text-align: center;
      width: auto;
    }

    th:not(:last-child) {
      border-right: 1px solid #15391f;
    }

    ${(props) =>
      props.waive &&
      // NOSONAR
      `
      th:first-child {
        width: 35%;
      }

      th:nth-child(2) {
        width: 20%;
      }

      th:last-child {
        width: 45%;
      }
    `}
  }

  tbody {
    tr:nth-child(even) {
      background-color: rgba(226, 226, 226, 0.5);
    }

    tr:last-child {
      background-color: rgba(226, 226, 226, 0.4);
    }

    tr:last-child td {
      border-top: 1px solid #dddddd;
    }

    td {
      padding: 0.2em 0.8838em;
    }

    td:not(:last-child) {
      border-right: 1px solid #e2e4ee;
    }

    td:not(:first-child) {
      text-align: center;
    }
  }

  tfoot {
    background-color: transparent;

    td {
      padding: 1em 2.3em 0 1em;
      vertical-align: top;
    }
  }
`;
