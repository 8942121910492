// @flow
export default (
  input: string,
  option: {
    key: string,
    value: number | string,
    children: string,
  },
) => {
  return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
};
