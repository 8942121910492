// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import { Space, Avatar } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import type { UserType } from 'flow/user';
import { selectMe } from 'selectors/me';

const StyledName = styled.p`
  font-size: 0.875em;
  text-transform: uppercase;
  margin: 0;
  line-height: 1em;
  font-weight: 600;
  letter-spacing: 1.2px;

  @media (max-width: 768px) {
    font-size: 0.75em;
  }
  @media (max-width: 500px) {
    display: none;
  }
`;

const StyledSpace = styled(Space)`
  @media (max-width: 350px) {
    display: none;
  }
`;
const StyledAvatar = styled(Avatar)`
  @media (max-width: 500px) {
    margin-right: -8px;
  }
`;

const User = () => {
  const { imageUrl, name }: $Shape<UserType> = useSelector(selectMe);

  return (
    <StyledSpace size="small" align="center">
      <StyledAvatar icon={<UserOutlined />} src={imageUrl} size={30} />
      <StyledName>{(name || '').split(' ')[0]}</StyledName>
    </StyledSpace>
  );
};

export default User;
