// @flow
import React, { type ElementRef, type Node } from 'react';
import { Select } from 'antd';
import type { Gender } from 'flow/resources';
import useResources from 'utils/hooks/useResources';
import selectFilterOptions from 'utils/form/selectFilterOptions';

const { Option } = Select;

type Props = {
  value: string,
  category: number,
  onChange: (string) => void,
  forwardedRef: ElementRef<*>,
  size?: 'small' | 'medium' | 'large',
  placeholder?: string,
};

const AnimalGendersFormField = ({ forwardedRef, category, ...rest }: Props) => {
  const genders: Array<Gender> = useResources<Gender>('animalGenders');

  const filteredOptions = genders.filter((g) => {
    return g.category === category;
  });

  return (
    <Select
      {...rest}
      ref={forwardedRef}
      virtual={false}
      showSearch
      filterOption={selectFilterOptions}
    >
      {filteredOptions.map(({ id, gender }: $Shape<Gender>) => (
        <Option value={id} key={id}>
          {gender}
        </Option>
      ))}
    </Select>
  );
};

AnimalGendersFormField.defaultProps = {
  size: 'medium',
  placeholder: 'Choose Gender',
};

export default React.forwardRef<*, Node>((props: Props, ref: ElementRef<*>) => {
  return <AnimalGendersFormField forwardedRef={ref} {...props} />;
});
