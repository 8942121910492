// @flow
export default {
  owner: {
    name: '',
    address: '',
    city: '',
    state: null,
    county: null,
    zipCode: '',
    phone: '',
  },
  animal: {
    brands: [],
    gender: null,
    breed: null,
    color: null,
  },
  year: '',
  registeredName: '',
  clearance: '',
  animalPhoto: [],
  markings: {
    left: { image: '', notes: '' },
    right: { image: '', notes: '' },
    face: { image: '', notes: '' },
  },
  // NOTE:
  // markings is converted to actual schema before saving
  // [
  //   { location: 'left', image: '', notes: '' },
  //   { location: 'right', image: '', notes: '' },
  //   { location: 'face', image: '', notes: '' },
  // ],
  sellerSignature: '',
  sellerSignatureDate: '',
  inspectorSignature: '',
  inspectorSignatureDate: '',
  photos: [],
  payment: {
    paymentType: '',
    checkNumber: '',
  },
};
