// @flow
import type { County, State } from 'flow/resources';
import type {
  Person,
  PrimitivePerson,
  PrimitiveRequiredPerson,
  RequiredPerson,
} from 'flow/person';

export const getPrimitivePerson = (contact: Person): PrimitivePerson => {
  const { name, address, city, county, state, zipCode, phone } = contact;
  return {
    name,
    address,
    city,
    county: county && county?.id,
    state: state && state?.id,
    zipCode,
    phone,
  };
};

export const getPrimitiveRequiredPerson = (
  contact: RequiredPerson,
): PrimitiveRequiredPerson => ({
  ...contact,
  county: contact.county?.id,
  state: contact.state?.id || 48,
});

export const getRequiredPerson = (
  contact: PrimitiveRequiredPerson,
  counties: Array<County>,
  states: Array<State>,
): RequiredPerson => {
  const foundCounty = counties.find((c) => c.id === contact.county);
  const foundState = states.find((s) => s.id === contact.state);
  if (foundState === undefined) {
    throw new Error(`State with id ${contact.state} not found!`);
  }
  const { name, address, city, zipCode, phone } = contact;
  return {
    name,
    address,
    city,
    county: foundCounty,
    state: foundState,
    zipCode,
    phone,
  };
};

export const getPerson = (
  contact: PrimitivePerson,
  counties: Array<County>,
  states: Array<State>,
): Person => ({
  ...contact,
  county: counties.find((c) => c.id === contact.county),
  state: states.find((s) => s.id === contact.state),
});
