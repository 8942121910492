// @flow
import React, { Fragment } from 'react';
import type { LineItem, DisplayLineItem, SubtotalType } from 'flow/inspection';
import { hasBrand } from 'utils/animals';
import formatMoney, { centsToDollars } from 'utils/formatters/formatMoney';
import Brands from 'components/Brands';
import {
  LINE_ITEM_TYPE,
  ENABLE_CONSIGNMENT_ADDITIONAL_UI,
} from 'constants/inspection';
import { FootnotesContainer } from 'components/Footnotes';
import Footnote from 'features/MinimumFeeFootnote/footnote';
import { HeadSubtotal, Legalize, PayFor, Table, Total } from './styles';

type Props = {
  displayLineItems: Array<DisplayLineItem>,
  inspectionMinimumFee: LineItem,
  subtotal: SubtotalType,
  hasProductionOrConsignment: boolean,
  showLineItems: boolean,
};

const includesFee = (fee?: number): boolean => (fee || 0) > 0;

const hasMoreThanOneLineItemSubtotal = (
  hasBeefTag: boolean,
  hasProcessingFee: boolean,
  hasBeefCouncilOrPredatorFee: boolean,
  showPaidInOffice: boolean,
): boolean => {
  // default for the inspection fee
  let subtotalEntryCount = 1;
  subtotalEntryCount += hasBeefTag ? 1 : 0;
  subtotalEntryCount += hasProcessingFee ? 1 : 0;

  // for beef council promotion and predator control
  subtotalEntryCount += hasBeefCouncilOrPredatorFee ? 2 : 0;

  subtotalEntryCount += showPaidInOffice ? 1 : 0;

  return subtotalEntryCount > 1;
};

const buildPriceColumn = (values: LineItem) => {
  const isOverride: boolean = values.type === LINE_ITEM_TYPE.OVERRIDE;
  const textDecoration = values?.waived ? 'line-through' : 'none';

  if (isOverride) {
    return (
      <td>
        <span style={{ textDecoration: 'line-through' }}>
          {formatMoney({ value: centsToDollars(values?.price || 0) })}
        </span>
        <span>&nbsp;&#x2192;&nbsp;</span>
        <span style={{ textDecoration }}>
          {formatMoney({ value: centsToDollars(values?.price || 0) })}
        </span>
      </td>
    );
  }

  return (
    <td>
      <span style={{ textDecoration }}>
        {formatMoney({ value: centsToDollars(values?.price || 0) })}
      </span>
    </td>
  );
};

const LineItems = ({
  displayLineItems,
  subtotal,
  hasProductionOrConsignment,
  inspectionMinimumFee,
  showLineItems,
}: Props) => {
  const {
    totalBeefCouncilFee,
    totalBeefTagFee,
    totalHeads,
    totalInspectionFee,
    totalPredatorControlFee,
    totalProcessingFee,
  } = subtotal;
  const paidInOffice =
    hasProductionOrConsignment && ENABLE_CONSIGNMENT_ADDITIONAL_UI ? 25000 : 0;
  const initialTotal =
    totalInspectionFee +
    totalBeefCouncilFee +
    totalPredatorControlFee +
    totalBeefTagFee +
    totalProcessingFee -
    paidInOffice;
  const total = includesFee(initialTotal) ? initialTotal : 0;

  const showPaidInOffice =
    hasProductionOrConsignment && ENABLE_CONSIGNMENT_ADDITIONAL_UI;

  const isCattle = displayLineItems.some(
    (lineItem: DisplayLineItem) => lineItem.name.toLowerCase() === 'cattle',
  );

  const hasBeefTag = includesFee(
    displayLineItems[0]?.beefTag?.calculatedFee?.overrideFee,
  );

  const hasProcessingFee = includesFee(totalProcessingFee);
  const hasBeefCouncilOrPredatorFee =
    isCattle && includesFee(totalBeefCouncilFee + totalPredatorControlFee);

  const includesMoreThanOneSubtotalLineItem = hasMoreThanOneLineItemSubtotal(
    hasBeefTag,
    hasProcessingFee,
    hasBeefCouncilOrPredatorFee,
    showPaidInOffice,
  );

  return (
    <Table margin=".75em 0 0">
      {showLineItems && (
        <Fragment>
          <thead>
            <tr>
              <th width="60%">
                Details <span>Qty / Animal / Details</span>
              </th>
              {hasBeefTag && <th>Beef Tag</th>}
              {isCattle && (
                <Fragment>
                  <th>Beef Council</th>
                  <th>Predator Control</th>
                </Fragment>
              )}
              <th>Inspection</th>
            </tr>
          </thead>
          <tbody>
            {displayLineItems.map(
              ({
                lineNumber,
                quantity,
                brands,
                color,
                breed,
                gender,
                name,
                age,
                rfid,
                tagColor,
                tagNo,
                usda,
                notes,
                inspection,
                beefCouncil,
                beefTag,
                predatorControl,
              }: DisplayLineItem) => {
                const isElk = name && name.toLowerCase() === 'elk';
                const hasTag = tagColor || tagNo;
                const displayBrands = !isElk || (isElk && hasBrand(brands));
                const plural = !isElk && quantity > 1 ? 's' : '';

                let description = '';

                if (color) {
                  description += ` ${color}`;
                }

                if (breed) {
                  description += ` ${breed}`;
                }

                if (gender) {
                  description += ` ${gender}${plural}`;
                }

                if (name) {
                  description += isElk ? ` ${name}` : '';
                }

                if (age) {
                  description = ` ${age} year old ${description}`;
                }

                if (quantity) {
                  const countPrefix = quantity === 1 ? 'A' : `${quantity}`;
                  description = `${countPrefix} ${description}`;
                }

                if (hasTag) {
                  const tagColorAndPrefix = tagColor ? `a ${tagColor}` : '';
                  const tagNoAndPrefix = tagNo ? ` #${tagNo}` : '';
                  description += ` with ${tagColorAndPrefix} Tag${tagNoAndPrefix}`;
                }

                if (isElk) {
                  if (displayBrands) {
                    description += ` and brand `;
                  }
                } else {
                  description += ` with brands: `;
                }

                return (
                  <tr key={lineNumber}>
                    <td>
                      {description}
                      {displayBrands && <Brands brands={brands} />}
                      {notes && (
                        <span>
                          <em>&nbsp;({notes})&nbsp;</em>
                        </span>
                      )}
                      {rfid && (
                        <span>
                          <strong> RFID</strong>: {rfid}
                        </span>
                      )}
                      {usda && (
                        <span>
                          <strong> USDA</strong>: {usda}
                        </span>
                      )}
                    </td>
                    {isCattle && (
                      <Fragment>
                        {hasBeefTag && buildPriceColumn(beefTag)}
                        {buildPriceColumn(beefCouncil)}
                        {buildPriceColumn(predatorControl)}
                      </Fragment>
                    )}
                    {buildPriceColumn(inspection)}
                  </tr>
                );
              },
            )}
            <tr>
              <td colSpan="1">
                <HeadSubtotal>
                  <strong>{totalHeads} head</strong>
                  <strong>Subtotal</strong>
                </HeadSubtotal>
              </td>
              {hasBeefTag && (
                <td>
                  <strong>
                    {formatMoney({ value: centsToDollars(totalBeefTagFee) })}
                  </strong>
                </td>
              )}
              {isCattle && (
                <Fragment>
                  <td>
                    <strong>
                      {formatMoney({
                        value: centsToDollars(totalBeefCouncilFee),
                      })}
                    </strong>
                  </td>
                  <td>
                    <strong>
                      {formatMoney({
                        value: centsToDollars(totalPredatorControlFee),
                      })}
                    </strong>
                  </td>
                </Fragment>
              )}
              <td>
                <strong>
                  {formatMoney({ value: centsToDollars(totalInspectionFee) })}
                  {!!inspectionMinimumFee && !inspectionMinimumFee?.waived && (
                    <Footnote />
                  )}
                </strong>
              </td>
            </tr>
          </tbody>
        </Fragment>
      )}
      <tfoot>
        <tr>
          <td>
            <Legalize>
              <strong>Validation of brand certificates</strong>: Brand
              inspection certificates, except for “Change of ownership”and
              “Travel permits” shall be automatically cancelled and void one
              week after time of issuance. This is certificate void if altered.
            </Legalize>
          </td>
          <td colSpan={hasBeefTag ? 4 : 3}>
            {showLineItems && (
              <Fragment>
                {hasBeefTag && (
                  <PayFor>
                    <strong>Beef Tag</strong>
                    <strong>
                      {formatMoney({
                        value: centsToDollars(totalBeefTagFee),
                      })}
                    </strong>
                  </PayFor>
                )}
                {hasProcessingFee && (
                  <PayFor>
                    <strong>Processing Fee</strong>
                    <strong>
                      {formatMoney({
                        value: centsToDollars(totalProcessingFee),
                      })}
                    </strong>
                  </PayFor>
                )}
                {isCattle && (
                  <Fragment>
                    {includesFee(totalBeefCouncilFee) && (
                      <PayFor>
                        <strong>Beef Council</strong>
                        <strong>
                          {formatMoney({
                            value: centsToDollars(totalBeefCouncilFee),
                          })}
                        </strong>
                      </PayFor>
                    )}
                    {includesFee(totalPredatorControlFee) && (
                      <PayFor>
                        <strong>Predator Control</strong>
                        <strong>
                          {formatMoney({
                            value: centsToDollars(totalPredatorControlFee),
                          })}
                        </strong>
                      </PayFor>
                    )}
                  </Fragment>
                )}
              </Fragment>
            )}
            {includesMoreThanOneSubtotalLineItem && (
              <PayFor>
                <strong>Inspection Fee</strong>
                <strong>
                  {formatMoney({ value: centsToDollars(totalInspectionFee) })}
                </strong>
              </PayFor>
            )}

            {showPaidInOffice && (
              <PayFor>
                <strong>Was already paid in the office</strong>
                <strong>$250.00</strong>
              </PayFor>
            )}
            <Total>
              <strong>Inspection Charge Total</strong>
              <strong>{formatMoney({ value: centsToDollars(total) })}</strong>
            </Total>
            {!!inspectionMinimumFee && !inspectionMinimumFee?.waived && (
              <FootnotesContainer>
                <em>&dagger; There is a $20.00 minimum inspection fee</em>
              </FootnotesContainer>
            )}
          </td>
        </tr>
      </tfoot>
    </Table>
  );
};

export default LineItems;
