// @flow
export type BrandType = {
  id: string,
  brandNumber: string,
  position: string,
  letters: Array<string>,
  firstNames: Array<string>,
  lastNames: Array<string>,
  counties: Array<string>,
  zipcodes: Array<string>,
  isSheep: string,
  expired: string,
  imageUrl: string,
  ref?: string,
};

export type Page = {
  offset: number,
  limit: number,
};

export const BRAND_UPDATE_HISTORY = {
  ID: 'id',
  DATE: 'date',
};

export const BRAND = {
  ID: 'id',
  BRAND_NUMBER: 'brandNumber',
  POSITION: 'position',
  LETTERS: 'letters',
  FIRST_NAMES: 'firstNames',
  LAST_NAMES: 'lastNames',
  COUNTIES: 'counties',
  ZIPCODES: 'zipcodes',
  IMAGE_URL: 'imageUrl',
  IS_SHEEP: 'isSheep',
  EXPIRED: 'expired',
};

export const POSITION_LOOKUP = new Map<string, string>();

POSITION_LOOKUP.set('ls', 'Left Shoulder')
  .set('rs', 'Right Shoulder')
  .set('lr', 'Left Rib')
  .set('rr', 'Right Rib')
  .set('lh', 'Left Hip')
  .set('rh', 'Right Hip')
  .set('lj', 'Left Jaw')
  .set('rj', 'Right Jaw')
  .set('ln', 'Left Neck')
  .set('rn', 'Right Neck')
  .set('li', 'Lip')
  .set('d1', 'District 1')
  .set('d2', 'District 2')
  .set('d3', 'District 3')
  .set('d4', 'District 4')
  .set('fa', 'Face')
  .set('cb', 'Center Back')
  .set('wi', 'Withers')
  .set('ru', 'Rump')
  .set('se', 'Sheep Earmark')
  .set('hd', 'Head');

export const getPositionLabel = (position: string) =>
  POSITION_LOOKUP.get(position.toLowerCase()) || position;

export const decoratePosition = (brand: BrandType) => ({
  ...brand,
  position: POSITION_LOOKUP.get(brand.position),
});

export type BrandQueryParts = {
  brandNumbers: ?Array<string>,
  brandNumberParts: ?Array<string>,
  possibleZipcodes: ?Array<string>,
  counties: ?Array<string>,
  possibleNames: ?Array<string>,
  definiteBrandLetters: ?Array<string>,
  possibleBrandLetters: ?Array<string>,
  hasSearchTerms: boolean,
};
