// @flow
import type { WaiveFeeType } from 'flow/inspection';
import { dollarToCents } from './formatters/formatMoney';

export const convertWaivedFeesToNumber = (waivedFees: ?Array<*>): Array<*> =>
  (waivedFees || []).map((w: WaiveFeeType) => {
    const currentAmount = w?.amount || 0;
    const isDollarFormat =
      !!(currentAmount % 1) || String(currentAmount).length <= 2;
    const amount = isDollarFormat ? dollarToCents(currentAmount) : w.amount;

    return {
      ...w,
      amount,
    };
  });
