// @flow
import React from 'react';
import {
  Switch,
  Route,
  useLocation,
  useHistory,
  type Location,
  type History,
} from 'react-router-dom';
import { Tabs, Space } from 'antd';
import AdminInspections from 'features/AdminInspections';
import AdminYearlyTravelPermits from 'features/AdminYearlyTravelPermits';
import AdminLifetimeTravelPermits from 'features/AdminLifetimeTravelPermits';
import AdminPreSaleInspections from 'features/AdminPreSaleInspections';
import Page from 'components/Page';
import SearchFilter from 'components/SearchFilter';
import PaymentTypeFilter from 'components/PaymentTypeFilter';
import DateRangeFilter from 'components/DateRangeFilter';

const { TabPane } = Tabs;

const AdminEntitiesPage = () => {
  const { pathname }: Location = useLocation();

  const history: History = useHistory();

  const handleChange = (key: string) => {
    history.push(key);
  };

  return (
    <Page>
      <Tabs activeKey={pathname} onChange={handleChange}>
        <TabPane tab="Brand Inspections" key="/admin/inspections" />

        <TabPane tab="Yearly Travel Permits" key="/admin/yearly-permits" />

        <TabPane tab="Lifetime Travel Permits" key="/admin/lifetime-permits" />

        <TabPane tab="Pre-sale Inspections" key="/admin/pre-sale" />
      </Tabs>

      <Space align="center" style={{ marginBottom: 10 }}>
        <SearchFilter />

        <DateRangeFilter />

        <PaymentTypeFilter />
      </Space>

      <Switch>
        <Route exact path="/admin/inspections">
          <AdminInspections />
        </Route>

        <Route exact path="/admin/yearly-permits">
          <AdminYearlyTravelPermits />
        </Route>

        <Route exact path="/admin/lifetime-permits">
          <AdminLifetimeTravelPermits />
        </Route>

        <Route exact path="/admin/pre-sale">
          <AdminPreSaleInspections />
        </Route>
      </Switch>
    </Page>
  );
};

export default AdminEntitiesPage;
