// @flow
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { tokenUtil } from '@acclaim/udaf-kit';
import { clearCredentials } from 'service/client';
import ContentLoader from 'components/ContentLoader';

const LoginWithToken = () => {
  const history = useHistory();
  const location = useLocation();

  const handleAuthentication = async () => {
    await clearCredentials();

    const urlParams = new URLSearchParams(location.search);
    const token = urlParams.get('id');

    tokenUtil.removeToken();

    clearCredentials();

    if (token) {
      tokenUtil.setToken(token);
    }

    history.push('/');
  };

  useEffectOnce(() => {
    handleAuthentication();
  });

  return <ContentLoader message="Authenticating, please wait..." />;
};

export default LoginWithToken;
