// @flow
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { showErrorMessage } from 'utils/showMessage';
import { push } from 'connected-react-router';

export const getMe = createAsyncThunk(
  'me/getMe',
  async (_, { extra, rejectWithValue, dispatch }) => {
    const { createAuthClient, inspectionsDb: db } = extra;
    const client = createAuthClient();

    try {
      const { data } = await client.get('/user/me');

      window.localStorage.setItem('user-id', data.id);

      await db.user.put(data);

      return data;
    } catch (err) {
      const userId = window.localStorage.getItem('user-id');

      if (userId) {
        const data = await db.user.get(parseInt(userId, 10));

        if (data) {
          return data;
        }
      }

      dispatch(push('/logout'));

      return rejectWithValue(err.response.data);
    }
  },
);

export const updateSignature = createAsyncThunk(
  'me/updateSignature',
  async (signature: string, { extra, rejectWithValue, dispatch }) => {
    const client = extra.createAuthClient();

    try {
      await client.put('/user/signature', signature);

      dispatch(getMe());

      return true;
    } catch (err) {
      const { response } = err;

      showErrorMessage(response?.data?.message);

      return rejectWithValue(response.data);
    }
  },
);

export const logoutInspectionService = createAsyncThunk(
  'me/logoutInspectionService',
  async (_, { extra, rejectWithValue }) => {
    const { inspectionClient } = extra;
    const client = inspectionClient();

    try {
      await client.post('/auth/logout');
      return true;
    } catch (err) {
      const { response } = err;

      showErrorMessage(response?.message);

      return rejectWithValue(response.data);
    }
  },
);

const meSlice = createSlice({
  name: 'me',
  initialState: {
    data: {},
    pending: true,
  },
  reducers: {},
  extraReducers: {
    [getMe.fulfilled]: (state, action) => {
      state.data = action.payload;
      state.pending = false;
    },
    [getMe.rejected]: (state) => {
      state.pending = false;
    },
  },
});

export default meSlice.reducer;
