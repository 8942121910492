// @flow
import React from 'react';
import styled from 'styled-components';
import WaivedFees from 'components/Footnotes/WaivedFees';
import OverriddenFees from 'components/Footnotes/OverriddenFees';

type Props = {
  editable: ?boolean,
};

const Footnotes = ({ editable }: Props) => (
  <FootnotesContainer>
    <WaivedFees editable={editable} />
    <OverriddenFees editable={editable} />
  </FootnotesContainer>
);

export const FootnotesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  color: #999999;
`;

Footnotes.defaultProps = {
  editable: false,
};

export default Footnotes;
