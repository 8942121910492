// @flow
import React, { Fragment } from 'react';
import { Row, Col, Form, Radio, Input, Typography } from 'antd';
import { useSelector } from 'react-redux';
import { selectDisplayCreditCardOption } from 'selectors/inspection';

type Props = {
  hasReleasedAtNoCharge?: boolean,
};

const PaymentOptionsFormField = ({ hasReleasedAtNoCharge }: Props) => {
  const displayCreditCardOption = useSelector(selectDisplayCreditCardOption);

  return (
    <Fragment>
      <Typography.Title level={5}>Payment</Typography.Title>
      <Form.Item
        name={['payment', 'paymentType']}
        rules={[
          {
            required: true,
            message: 'Please select a payment option',
          },
        ]}
      >
        <Radio.Group>
          <Radio value="check">Check</Radio>
          {displayCreditCardOption && (
            <Radio value="creditcard">Credit Card</Radio>
          )}
          <Radio value="cash">Cash</Radio>
          <Radio value="pay_later">Pay Later</Radio>
          <Radio value="invoice">Invoice</Radio>
          {hasReleasedAtNoCharge && (
            <Radio value="released_at_no_charge">Released at no charge</Radio>
          )}
        </Radio.Group>
      </Form.Item>
      <Form.Item
        shouldUpdate={(prevValues, curValues) =>
          prevValues.payment?.paymentType !== curValues.payment?.paymentType
        }
        noStyle
      >
        {(form) => {
          const { paymentType } = form.getFieldValue('payment') || {};

          if (paymentType === 'check') {
            return (
              <Row gutter={12}>
                <Col lg={8} md={10}>
                  <Form.Item
                    label="Check Number"
                    name={['payment', 'checkNumber']}
                    rules={[
                      {
                        required: true,
                        message: 'Check Number is required',
                      },
                    ]}
                  >
                    <Input
                      placeholder="XXXXXXXXXXXXXXX"
                      style={{ maxWidth: '300px' }}
                    />
                  </Form.Item>
                </Col>
                <Col lg={8} md={10}>
                  <Form.Item
                    label="Payee"
                    name={['payment', 'checkPayee']}
                    rules={[
                      {
                        required: true,
                        message: 'Payee is required',
                      },
                    ]}
                  >
                    <Input placeholder="" style={{ maxWidth: '300px' }} />
                  </Form.Item>
                </Col>
              </Row>
            );
          }

          if (paymentType === 'pay_later' || paymentType === 'invoice' ) {
            return (
              <Form.Item
                label="Billed to:"
                name={['payment', 'name']}
                rules={[
                  {
                    required: true,
                    message: 'Required',
                  },
                ]}
              >
                <Input placeholder="" style={{ maxWidth: '300px' }} />
              </Form.Item>
            );
          }

          if (
            paymentType === 'released_at_no_charge' &&
            hasReleasedAtNoCharge
          ) {
            return (
              <Col span={8}>
                <Form.Item
                  label="Released at no charge reason"
                  name="releasedAtNoChargeReason"
                  rules={[
                    {
                      required: true,
                      message: 'Reason is required',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
            );
          }

          return null;
        }}
      </Form.Item>
    </Fragment>
  );
};

PaymentOptionsFormField.defaultProps = {
  hasReleasedAtNoCharge: false,
};

export default PaymentOptionsFormField;
