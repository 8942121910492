import { Collection } from 'dexie';
import moment from 'moment';
import type { Page } from 'flow/brands';
import type { Payment } from 'flow/payment';
import { inspectionsDb } from 'service/idb';

const ALL_PAYMENT_OPTIONS = [
  'creditcard',
  'check',
  'cash',
  'released_at_no_charge',
  'pay_later',
  'invoice',
];

export const total = (results: Collection): number =>
  results.clone().distinct().count();

export const getPaged = (
  nonPagedRecords: Collection,
  { offset, limit }: Page,
): Collection => {
  return (typeof offset === 'number' && typeof limit === 'number'
    ? nonPagedRecords.offset(offset).limit(limit)
    : nonPagedRecords
  ).toArray();
};

type DateRange = {
  from: ?string,
  to: ?string,
  key: string,
};

const hasMatch = (query: string, ...fields: Array<?string>): boolean =>
  fields.some((field) => (field || '').toLowerCase().includes(query));

export const filterDraftInspections = (
  range: DateRange,
  query: string,
  table,
) => {
  const { from, to, key: dateKey } = range;

  const searchQuery = (query || '').toLowerCase();

  const code = {
    inspections: 'inspectionCode',
    offlineInspections: 'inspectionCode',
    draftInspections: 'inspectionCode',
    preSaleInspections: 'presaleCode',
    offlinePreSaleInspections: 'presaleCode',
    travelPermit: 'permitCode',
    offlineTravelPermit: 'permitCode',
  }[table.name];

  if (from && to) {
    return table
      .where(dateKey)
      .aboveOrEqual(from)
      .and((data) => {
        return moment(data[dateKey]).isSameOrBefore(to);
      })
      .and((data) => {
        const { owner, buyer } = data;

        return hasMatch(
          searchQuery,
          owner?.name,
          buyer?.name,
          data[code],
          data?.registeredName,
        );
      })
      .toArray();
  }

  return table
    .filter((data) => {
      const { owner, buyer } = data;

      return hasMatch(
        searchQuery,
        owner?.name,
        buyer?.name,
        data[code],
        data?.registeredName,
      );
    })
    .toArray();
};

export const filterInspections = async ({
  range,
  query,
  paymentStatus,
  table,
  limit,
  offset,
  slug,
}: {
  range: DateRange,
  query: string,
  paymentStatus: string,
  limit: number,
  offset: number,
  table: *,
  slug?: string,
}) => {
  const { from, to, key: dateKey } = range;

  const paymentTypes =
    paymentStatus === 'all' ? ALL_PAYMENT_OPTIONS : [paymentStatus];

  const searchQuery = (query || '').toLowerCase();

  const code = {
    inspections: 'inspectionCode',
    offlineInspections: 'inspectionCode',
    draftInspections: 'inspectionCode',
    preSaleInspections: 'presaleCode',
    offlinePreSaleInspections: 'presaleCode',
    travelPermit: 'permitCode',
    offlineTravelPermit: 'permitCode',
  }[table.name];

  const offlinePayments = await inspectionsDb.offlinePayments.toArray();

  const filterBySearchQueries = (data: *) => {
    const { owner, buyer } = data;

    const matchingByQuery = hasMatch(
      searchQuery,
      owner?.name,
      buyer?.name,
      data[code],
      data?.registeredName,
    );

    if (slug) {
      return data.type.slug === slug && matchingByQuery;
    }

    return matchingByQuery;
  };

  const filterByPaymentType = (data: *) => {
    const { payment } = data;
    const offlinePayment =
      offlinePayments.find((p: Payment) => p.id === payment.id) || {};
    const updatedPayment = { ...payment, ...offlinePayment };

    return paymentTypes.includes(updatedPayment.paymentType);
  };

  let tableQuery = table
    .orderBy(dateKey)
    .reverse()
    .filter(filterBySearchQueries)
    .filter(filterByPaymentType);

  if (from && to) {
    tableQuery = table
      .where(dateKey)
      .aboveOrEqual(from)
      .and((data) => {
        const inRangeOfEndDate = moment(data.permitDate).isSameOrBefore(to);

        if (slug) {
          return inRangeOfEndDate && data.type.slug === slug;
        }

        return inRangeOfEndDate;
      })
      .and(filterBySearchQueries)
      .reverse()
      .filter(filterByPaymentType);
  }

  const totalCount = await tableQuery.clone().count();

  const result = await tableQuery.offset(offset).limit(limit).toArray();

  return { total: totalCount, result };
};
