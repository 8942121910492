// @flow
import React, { type Node } from 'react';
import styled from 'styled-components';

type Props = {
  children: Node,
};

const StyledPage = styled.div`
  padding: 2em 1em 2em;
  max-width: 1200px;
  margin: auto;
  min-height: calc(100vh - 84px);
  @media print {
    padding: 0;
    margin: 0;
    min-height: auto;
    max-width: unset;
  }
`;

const Page = ({ children, ...rest }: Props) => {
  return <StyledPage {...rest}>{children}</StyledPage>;
};

export default Page;
