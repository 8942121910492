// @flow
import React, { useEffect } from 'react';
import { useGeolocation } from 'react-use';
import { Input } from 'antd';
import formatGpsCoordinates from 'utils/formatters/formatGpsCoordinates';

type Props = {
  value?: string,
  onChange?: (string) => void,
  id: string,
};

const GpsFormField = ({ value, onChange, id }: Props) => {
  const { latitude, longitude } = useGeolocation();

  useEffect(() => {
    if (latitude && longitude && onChange) {
      onChange(`${longitude} ${latitude}`);
    }
  }, [latitude, longitude, onChange]);

  return <Input readOnly value={formatGpsCoordinates(value)} id={id} />;
};

GpsFormField.defaultProps = {
  value: '',
  onChange: () => {},
  id: '',
};

export default GpsFormField;
