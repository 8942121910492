// @flow
import {
  useLocation,
  useHistory,
  type History,
  type Location,
} from 'react-router-dom';
import type { PaymentType } from 'flow/payment';

const QUERY_KEY = 'paymentType';

const usePaymentTypeQuery = () => {
  const { search, pathname }: Location = useLocation();

  const { push }: History = useHistory();

  const setPaymentType = (type: ?PaymentType) => {
    const params = new URLSearchParams(search);

    if (type) {
      params.set(QUERY_KEY, type);
    } else {
      params.delete(QUERY_KEY);
    }

    push({
      pathname,
      search: `?${params.toString()}`,
    });
  };

  const queryParams = new URLSearchParams(search);

  return [queryParams.get(QUERY_KEY) || '', setPaymentType];
};

export default usePaymentTypeQuery;
