// @flow
type Details = {
  name: ?string,
  address: ?string,
  city: ?string,
  state: ?string,
  county: ?string,
  zipCode: ?string,
  phone?: ?string,
};

export default ({ address, city, state, county, zipCode }: $Shape<Details>) => {
  let buffer = '';

  if (address) buffer = `${buffer}${address} `;

  if (city) {
    if (state || zipCode || county) {
      buffer = `${buffer}${city}, `;
    } else {
      buffer = `${buffer}${city} `;
    }
  }

  if (state) buffer = `${buffer}${state} `;

  if (zipCode) buffer = `${buffer}${zipCode} `;

  if (county) buffer = `${buffer}${county} `;

  return buffer;
};
