export const LIVESTOCK_PARTS = {
  LEFT_THIGH: 'LH',
  LEFT_RIB: 'LR',
  LEFT_SHOULDER: 'LS',
  RIGHT_THIGH: 'RH',
  RIGHT_RIB: 'RR',
  RIGHT_SHOULDER: 'RS',
  HORSE_RIGHT_JAW: 'RJ',
  WITHERS: 'Wi',
  CENTER: 'CB',
  RUMP: 'Ru',
  SHEEP_EARMARK: 'SE',
};
