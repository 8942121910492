// @flow
import { createSlice } from '@reduxjs/toolkit';

export type PaymentState = 'idle' | 'pending' | 'failed' | 'success';

export const PAYMENT_STATE = {
  IDLE: 'idle',
  PENDING: 'pending',
  FAILED: 'failed',
  SUCCESS: 'success',
};

const paymentStateLoaderSlice = createSlice({
  name: 'paymentStateLoader',
  initialState: {
    state: 'idle',
  },
  reducers: {
    setPaymentState: (state, { payload }: { payload: PaymentState }) => {
      state.state = payload;
    },
  },
});

export const { setPaymentState } = paymentStateLoaderSlice.actions;

export default paymentStateLoaderSlice.reducer;
