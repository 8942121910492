// @flow
import { useSelector } from 'react-redux';
import type { ReduxStore } from 'flow/redux';

type Resource =
  | 'animalCategories'
  | 'animalBreeds'
  | 'animalColors'
  | 'animalGenders'
  | 'animalTags'
  | 'inspectionTypes'
  | 'counties'
  | 'states'
  | 'dealers'
  | 'services'
  | 'serviceFees';

const useResources = <T>(resource: Resource): Array<T> => {
  const data = useSelector<Array<T>>(({ resources }: ReduxStore) => {
    return resources[resource];
  });

  return data;
};

export default useResources;
