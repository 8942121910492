// @flow
import React, { Fragment } from 'react';
import type { InspectionType } from 'flow/resources';

type Props = {
  types: Array<InspectionType>,
};

const SelectedInspectionTypes = ({ types }: Props) => {
  const typesToDisplay = types
    .map(({ slug, inspectionType, type: t }) => {
      if (slug === 'other' && inspectionType) {
        return `${t}: ${inspectionType}`;
      }
      return t;
    })
    .join(', ');
  return <Fragment>{typesToDisplay}</Fragment>;
};

export default SelectedInspectionTypes;
