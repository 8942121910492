// @flow
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { push } from 'connected-react-router';
import type { TravelPermitType } from 'flow/travelPermit';
import { showErrorMessage } from 'utils/showMessage';
import { syncOfflineData } from 'features/SyncOfflineMenuItem/syncOfflineSlice';
import { ENVIRONMENT } from 'service/urls';
import { toggleLoading } from 'features/LoadingMask/slice';

export const createTravelPermit = createAsyncThunk(
  'createTravelPermit/createTravelPermit',
  async (
    formData: TravelPermitType,
    { extra, rejectWithValue, getState, dispatch },
  ) => {
    const { inspectionsDb: db, createError } = extra;
    const { me } = getState();
    const { data: user } = me;

    const permit = {
      ...formData,
      user,
      clientVersion: ENVIRONMENT.build.version,
    };

    try {
      dispatch(toggleLoading());

      const id = await db.offlineTravelPermit.put(permit);

      await dispatch(syncOfflineData());

      const sycnedEntity = await db.travelPermit.get({ publicId: id });

      dispatch(push(`/travel-permits/${sycnedEntity?.id || id}`));

      dispatch(toggleLoading());

      return permit;
    } catch (err) {
      dispatch(toggleLoading());

      createError({
        payload: JSON.stringify(permit),
        endpoint: 'OFFLINE: createTravelPermit/createTravelPermit',
        errorStack: err?.stack,
      });

      showErrorMessage(err?.message);

      return rejectWithValue(err);
    }
  },
);

const createTravelPermitSlice = createSlice({
  name: 'createTravelPermit',
  initialState: {
    data: {},
    loading: false,
  },
  extraReducers: {
    [createTravelPermit.pending]: (state) => {
      state.loading = true;
    },
    [createTravelPermit.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.loading = false;
    },
    [createTravelPermit.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const { clearTravelPermit } = createTravelPermitSlice.actions;

export default createTravelPermitSlice.reducer;
