// @flow
import React from 'react';
import styled from 'styled-components';
import type {
  AddressDetails,
  Inspection,
  LineItem,
  DisplayLineItem,
  SubtotalType,
} from 'flow/inspection';
import type { InspectionType } from 'flow/resources';
import type { Person } from 'flow/person';
import { FLAG_DISABLE_GPS_ON_CERTIFICATES } from 'constants/inspection';
import { getInspectionUrl } from 'service/urls';
import Footnotes from 'components/Footnotes';
import SelectedInspectionTypes from 'components/SelectedInspectionTypes';
import SignatureField from 'components/SignatureField';
import Template, {
  Address,
  Content,
  DisplayPayment,
  InspectionHeader,
  InspectorOwnerBuyer,
  Section,
  SignatureContainer,
  SignatureRow,
} from 'components/CertificateTemplate';
import LineItems from './LineItems';
import { TransportContainer, TransportPart } from './styles';

type Props = {
  inspection: Inspection,
  displayLineItems: Array<DisplayLineItem>,
  subtotal: SubtotalType,
  hasProductionOrConsignment: boolean,
  inspectionMinimumFee: $Shape<LineItem>,
  printType: string,
};

const InspectionCertificateTemplate = ({
  inspection,
  displayLineItems,
  subtotal,
  hasProductionOrConsignment,
  inspectionMinimumFee,
  printType,
}: Props) => {
  const inspectionUrl = getInspectionUrl();

  const {
    inspectionCode,
    publicId,
    soldTo,
    premiseId,
    consignedTo,
    carrier,
    destination,
    licenseNo,
    gpsCoordinate,
    buyer,
    inspectionDate,
    types,
    user,
    payment,
    clientVersion,
    serviceVersion,
    isVoid,
  } = inspection;

  const coords = (gpsCoordinate || '').split(' ');

  const hasCoordinates =
    !FLAG_DISABLE_GPS_ON_CERTIFICATES && coords.length === 2;

  const showLineItems = !types.some(
    (t: InspectionType) => t.slug === 'calf-pool-receiving-station-location',
  );

  const displayText = (value: ?string) => {
    return value || 'N/A';
  };

  const buyerOwnerOrTransport =
    {
      buyer: 'Buyer',
      owner: 'Owner',
      transportation: 'Transport',
    }[printType] || '';

  return (
    <Template>
      <InspectionHeader
        inspectionTitle="BRAND INSPECTION CERTIFICATE"
        code={inspectionCode}
        date={inspectionDate}
        url={`${inspectionUrl}/public/inspection/${publicId}`}
        version={{ clientVersion, serviceVersion }}
        isVoid={isVoid}
      />
      <Content>
        <InspectorOwnerBuyer>
          <Owner inspection={inspection} />
          {buyer && <Buyer buyer={buyer} />}

          <Section>
            <h5>INSPECTED FOR:</h5>
            <strong>
              <SelectedInspectionTypes types={types} />
            </strong>
            {!!premiseId && (
              <p>
                <label>Premise No.</label> {premiseId}
              </p>
            )}
            {hasCoordinates && (
              <p>
                <label>GPS</label> {coords[0]}, {coords[1]}
              </p>
            )}
            {!!buyerOwnerOrTransport && (
              <PrintType>{buyerOwnerOrTransport}&apos;s copy</PrintType>
            )}
          </Section>
        </InspectorOwnerBuyer>

        {(!!soldTo ||
          !!consignedTo ||
          !!carrier ||
          !!destination ||
          !!licenseNo) && (
          <Section>
            <h5>TRANSPORT:</h5>
            <TransportContainer>
              {!!soldTo && (
                <TransportPart>
                  <label>Sold to:</label> {displayText(soldTo)}
                </TransportPart>
              )}

              {!!consignedTo && (
                <TransportPart>
                  <label>Consigned to:</label> {displayText(consignedTo)}
                </TransportPart>
              )}

              {!!carrier && (
                <TransportPart>
                  <label>Transported by:</label> {displayText(carrier)}
                </TransportPart>
              )}

              {!!destination && (
                <TransportPart>
                  <label>Destination:</label> {displayText(destination)}
                </TransportPart>
              )}

              {!!licenseNo && (
                <TransportPart>
                  <label>License #:</label> {displayText(licenseNo)}
                </TransportPart>
              )}
            </TransportContainer>
          </Section>
        )}

        <LineItems
          displayLineItems={displayLineItems}
          subtotal={subtotal}
          hasProductionOrConsignment={hasProductionOrConsignment}
          inspectionMinimumFee={inspectionMinimumFee}
          showLineItems={showLineItems}
        />
        {showLineItems && <Footnotes />}

        <DisplayPayment payment={payment} />

        <SignatureRow>
          <SignatureContainer>
            <SignatureField label={`Inspected by ${user.name}`} x={false} />
          </SignatureContainer>

          <SignatureContainer>
            <SignatureField label="Owner / Agent sign here" />
          </SignatureContainer>
        </SignatureRow>
      </Content>
    </Template>
  );
};

type OwnerProps = {
  inspection: Inspection,
};

const Owner = ({ inspection }: OwnerProps) => {
  const { owner, dealer } = inspection;
  const ownerAddress = {
    street: owner?.address,
    city: owner?.city,
    county: owner?.county?.name,
    state: owner?.state?.name,
    zipCode: owner?.zipCode,
  };
  return (
    <Section>
      <h5>OWNER:</h5>
      <strong>{owner?.name}</strong>
      {owner.phone && <em>{owner.phone}</em>}
      {!!dealer && (
        <p>
          <label>Dealer License</label> #{dealer?.license}
        </p>
      )}
      <Address address={ownerAddress} />
    </Section>
  );
};

type BuyerProps = {
  buyer: Person,
};

const Buyer = ({ buyer }: BuyerProps) => {
  const { name, phone, address: street, city, county, state, zipCode } = buyer;

  const address: AddressDetails = {
    county: county?.name,
    state: state?.name,
    street,
    city,
    zipCode,
  };

  const addressNotEmpty =
    !!address?.street ||
    !!address?.city ||
    !!address?.state ||
    !!address?.county ||
    !!address?.zipCode;

  const hasBuyerValues = !!name || addressNotEmpty || !!phone;

  return (
    (hasBuyerValues && (
      <Section>
        <h5>BUYER:</h5>
        <strong>{name}</strong>
        {phone && <em>{phone}</em>}
        <Address address={address} />
      </Section>
    )) ||
    ''
  );
};

const PrintType = styled.span`
  display: inline-block;
  color: #888888;
  border: 0.1em solid #888888;
  margin: 0.5em 0;
  padding: 0 0.3em;
  text-align: center;
  font-weight: 500;
  text-transform: uppercase;
`;

export default InspectionCertificateTemplate;
