// @flow
import type { LineItem } from 'flow/inspection';
import type { LineItemCombined } from 'flow/footnotes';
import formatMoney, { centsToDollars } from 'utils/formatters/formatMoney';

export const lineItemToLineItemCombine = ({
  lineNumber,
  slug,
  reason,
  price,
}: $Shape<LineItem>): LineItemCombined => {
  const lineNumbers = lineNumber ? [lineNumber] : [];
  return {
    lineNumbers,
    price,
    slug,
    reason,
  };
};

/**
 * Combines fees with liked named descriptions
 * @param fees - collector array
 * @param fee - with the slug, price and reason to test
 * @returns {Array<$Shape<LineItem>>|[]} an array of [{slug, price, reason}]
 */
export const combineFeesForMessaging = (
  fees: Array<LineItemCombined>,
  fee: LineItemCombined,
): Array<LineItemCombined> => {
  const feesArray = fees;
  const {
    lineNumbers: passedLineNumbers,
    slug: passedSlug,
    price: passedPrice,
    reason: passedReason,
  } = fee;
  for (let i = 0; i < feesArray.length; i += 1) {
    const { lineNumbers, slug, price, reason } = feesArray[i];
    if (slug === passedSlug && reason === passedReason) {
      feesArray[i] = {
        lineNumbers: [...lineNumbers, ...passedLineNumbers],
        price: price + passedPrice,
        slug,
        reason,
      };
      return feesArray;
    }
  }
  return [
    ...feesArray,
    {
      lineNumbers: [...passedLineNumbers],
      price: passedPrice,
      slug: passedSlug,
      reason: passedReason,
    },
  ];
};

export const formatPrice = (price: number): string =>
  formatMoney({ value: centsToDollars(price) });

/**
 * Used to decide on the certificate state variable. An editable certificate
 * is said to be in process and can be changed. A non-editable certificate
 * has been persisted to the server and should not be changed again.
 *
 * @param editable
 * @returns {string} 'inspection' if true, or 'inspectionCertificate' if false
 */
export const getCertificateType = (editable: ?boolean): string =>
  editable ? 'inspection' : 'inspectionCertificate';
