// @flow
import React from 'react';
import { useUpdateEffect } from 'react-use';
import { Form, Modal, Input, Button, Space } from 'antd';

type Props = {
  open: boolean,
  defaultReason: string,
  onClose: () => void,
  onSubmit: ({ reason: string }) => void,
  onClear: () => void,
};

const WaiveModal = ({
  open,
  defaultReason,
  onClose,
  onSubmit,
  onClear,
}: Props) => {
  const [form] = Form.useForm();

  const handleSubmit = (values) => {
    onSubmit(values);

    form.resetFields();
  };

  useUpdateEffect(() => {
    if (open && defaultReason) {
      form.setFieldsValue({ reason: defaultReason });
    } else {
      form.resetFields();
    }
  }, [open]);

  return (
    <Modal title="Waive fee" visible={open} footer={null} onCancel={onClose}>
      <Form
        onFinish={handleSubmit}
        initialValues={{}}
        form={form}
        name="WaiveFeeForm"
        colon={false}
        layout="vertical"
      >
        <Form.Item
          label="Reason"
          name="reason"
          rules={[
            {
              required: true,
              message: 'Reason is required',
            },
          ]}
        >
          <Input readOnly={!!defaultReason} />
        </Form.Item>
        <Space size="small">
          {defaultReason ? (
            <Button type="primary" onClick={onClear}>
              Clear
            </Button>
          ) : (
            <Button type="primary" htmlType="submit">
              Confirm
            </Button>
          )}
          <Button type="ghost" onClick={onClose}>
            Cancel
          </Button>
        </Space>
      </Form>
    </Modal>
  );
};

export default WaiveModal;
