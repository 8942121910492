// @flow
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
} from '@reduxjs/toolkit';
import omitBy from 'lodash/omitBy';
import type { ReduxStore } from 'flow/redux';
import type { PaymentType } from 'flow/payment';
import { showErrorMessage } from 'utils/showMessage';
import { timestampToString } from 'utils/dates';
import { inspectionAndTotal } from 'utils/computeFees';
import { monthUtil } from '@acclaim/udaf-kit';

const adapter = createEntityAdapter();

const initialState = adapter.getInitialState({
  loading: false,
  total: 0,
});

export const { selectAll: selectInspections } = adapter.getSelectors(
  (state: ReduxStore) => {
    return state.adminInspections;
  },
);

export const selectComputedInspections = createSelector(
  selectInspections,
  inspectionAndTotal,
);

export const fetchInspections = createAsyncThunk(
  'adminInspections/fetchInspections',
  async (
    filters: ?{
      limit: number,
      offset: number,
      from: ?number,
      to: ?number,
      query: ?string,
      paymentType: ?PaymentType,
    },
    { extra, rejectWithValue },
  ) => {
    const { limit, offset, from, to, query, paymentType } = filters || {};
    const { inspectionClient } = extra;

    try {
      const { data } = await inspectionClient().get('/inspections/', {
        params: {
          limit,
          offset,
          monthsData: monthUtil.getmonthToken(),
          ...omitBy(
            {
              start: timestampToString(from),
              end: timestampToString(to),
              q: query,
              paymentType,
            },
            (v: string) => !v,
          ),
        },
      });

      return data;
    } catch (err) {
      showErrorMessage(err?.response?.data?.message);

      return rejectWithValue(err);
    }
  },
);

const adminInspectionsSlice = createSlice({
  name: 'adminInspections',
  initialState,
  reducers: {
    clearAll: adapter.removeAll,
  },
  extraReducers: {
    [fetchInspections.pending]: (state) => {
      state.loading = true;
    },
    [fetchInspections.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.total = payload.count;
      adapter.setAll(state, payload.results);
    },
    [fetchInspections.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const selectLoading = (state: ReduxStore) =>
  state.adminInspections.loading;

export const selectTotal = (state: ReduxStore) => state.adminInspections.total;

export const { clearAll } = adminInspectionsSlice.actions;

export default adminInspectionsSlice.reducer;
