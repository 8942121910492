// @flow
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { UdafContext, UdafAuthInterceptor } from '@acclaim/udaf-kit';
import '@acclaim/udaf-kit/dist/index.css';
import './theme/app.less';
import App from 'app/index';
import store, { history } from 'store';
import { toggleNotifierVisibility } from 'features/AppUpdate/appUpdateSlice';
import Auth from 'pages/Auth';
import PublicPage from 'pages/PublicPage';
import LogoutPage from 'pages/LogoutPage';
import LoginWithTokenPage from 'pages/LoginWithTokenPage';
import CallbackPage from 'pages/CallbackPage';
import AppUpdate from 'features/AppUpdate';
import Initializer from 'features/Initializer';
import PrivateRoute from 'components/PrivateRoute';
import ErrorBoundary from 'components/ErrorBoundary';
import UpdateLogs from 'components/UpdateLogs';
import * as serviceWorker from './serviceWorkerRegistration';
import { getAuthUrl, getBrandsApiUrl } from './service/urls';
// import reportWebVitals from './reportWebVitals';

const OPTIONS = {
  API_BASE_URL: getBrandsApiUrl(),
  AUTH_URL: getAuthUrl(),
};

ReactDOM.render(
  <UdafContext.Provider value={OPTIONS}>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <AppUpdate />

        <UpdateLogs />

        <Switch>
          <Route exact path="/auth-redirect">
            <Auth />
          </Route>

          <Route exact path="/authenticate" component={UdafAuthInterceptor} />

          <Route exact path="/logout">
            <LogoutPage />
          </Route>

          <Route exact path="/login">
            <LoginWithTokenPage />
          </Route>

          <Route exact path="/payment/summary">
            <CallbackPage />
          </Route>

          <Route
            exact
            path={[
              '/public/inspection/:id',
              '/public/pre-sale/:id',
              '/public/travel-permit/:id',
            ]}
          >
            <PublicPage />
          </Route>

          <PrivateRoute path="/">
            <ErrorBoundary>
              <Initializer>
                <App />
              </Initializer>
            </ErrorBoundary>
          </PrivateRoute>

          <Redirect to="/" />
        </Switch>
      </ConnectedRouter>
    </Provider>
  </UdafContext.Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.register({
  onUpdate: (registration) => {
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', (event) => {
        if (event.target.state === 'activated') {
          window.localStorage.setItem('show-changelog', 'true');
          store.dispatch(toggleNotifierVisibility());
        }
      });

      waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
    }
  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
