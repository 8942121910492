// @flow
import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from '@reduxjs/toolkit';
import omitBy from 'lodash/omitBy';
import type { ReduxStore } from 'flow/redux';
import type { InspectionType } from 'flow/resources';
import type { PaymentType } from 'flow/payment';
import { showErrorMessage } from 'utils/showMessage';
import { YEARLY_TRAVEL_PERMIT } from 'selectors/resources';
import { timestampToString } from 'utils/dates';
import { monthUtil } from '@acclaim/udaf-kit';

const adapter = createEntityAdapter();

const initialState = adapter.getInitialState({
  loading: false,
  total: 0,
});

export const { selectAll: selectYearlyTravelPermits } = adapter.getSelectors(
  (state: ReduxStore) => {
    return state.adminYearlyTravelPermits;
  },
);

export const fetchYearlyTravelPermits = createAsyncThunk(
  'adminYearlyTravelPermits/fetchYearlyTravelPermits',
  async (
    filters: ?{
      limit: number,
      offset: number,
      from: ?number,
      to: ?number,
      query: ?string,
      paymentType: ?PaymentType,
    },
    { extra, rejectWithValue, getState },
  ) => {
    const { limit, offset, from, to, query, paymentType } = filters || {};
    const { inspectionClient } = extra;
    const { resources } = getState();

    const yearlyTravelPermit =
      (resources?.inspectionTypes || []).find(({ slug }: InspectionType) => {
        return slug === YEARLY_TRAVEL_PERMIT;
      }) || {};

    try {
      const { data } = await inspectionClient().get('/travel_permit/', {
        params: {
          limit,
          offset,
          monthsData: monthUtil.getmonthToken(),
          ...omitBy(
            {
              type: yearlyTravelPermit?.id,
              start: timestampToString(from),
              end: timestampToString(to),
              query,
              paymentType,
            },
            (v: string) => !v,
          ),
        },
      });

      return data;
    } catch (err) {
      showErrorMessage(err?.response?.data?.message);

      return rejectWithValue(err);
    }
  },
);

const adminYearlyTravelPermitsSlice = createSlice({
  name: 'adminYearlyTravelPermits',
  initialState,
  reducers: {
    clearAll: adapter.removeAll,
  },
  extraReducers: {
    [fetchYearlyTravelPermits.pending]: (state) => {
      state.loading = true;
    },
    [fetchYearlyTravelPermits.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.total = payload.count;
      adapter.setAll(state, payload.results);
    },
    [fetchYearlyTravelPermits.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export const selectLoading = (state: ReduxStore) =>
  state.adminYearlyTravelPermits.loading;

export const selectTotal = (state: ReduxStore) =>
  state.adminYearlyTravelPermits.total;

export const { clearAll } = adminYearlyTravelPermitsSlice.actions;

export default adminYearlyTravelPermitsSlice.reducer;
