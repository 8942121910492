import { configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import { brandsDb, inspectionsDb, codesDb } from 'service/idb';
import client, { inspectionClient, createAuthClient } from 'service/client';
import createError from 'service/logger';

import inspectionSlice from 'pages/BrandInspectionPage/inspectionSlice';
import inspectionCertificateSlice from 'pages/BrandInspectionCertificatePage/inspectionCertificateSlice';

import createHorsePreSaleSlice from 'pages/CreateHorsePreSalePage/createHorsePreSaleSlice';
import horsePreSaleSlice from 'pages/HorsePreSalePage/horsePreSaleSlice';

import createTravelPermitSlice from 'pages/CreateTravelPermitPage/createTravelPermitSlice';
import travelPermitSlice from 'pages/TravelPermitPage/travelPermitSlice';

// refactored slices
// Brand Inspections
import inspections from 'features/BrandInspections/inspectionsSlice';
import offlineInspections from 'features/OfflineBrandInspections/offlineInspectionsSlice';
import draftInspections from 'features/DraftBrandInspections/draftInspectionsSlice';

// Travel Permits
import lifetimeTravelPermits from 'features/LifetimeTravelPermits/lifetimeTravelPermitsSlice';
import yearlyTravelPermits from 'features/YearlyTravelPermits/yearlyTravelPermitsSlice';
import offlineTravelPermits from 'features/OfflineTravelPermits/offlineTravelPermitsSlice';
import travelPermits from 'features/TravelPermits/travelPermitsSlice';

// Pre-Sale Inspections
import preSaleInspections from 'features/PreSaleInspections/preSaleInspectionsSlice';
import offlinePreSaleInspections from 'features/OfflinePreSaleInspections/offlinePreSaleInspectionsSlice';

import syncOffline from 'features/SyncOfflineMenuItem/syncOfflineSlice';

import settlePayment from 'features/SettlePaymentForm/settlePaymentSlice';

import adminLogs from 'features/AdminLogs/adminLogsSlice';
import adminLog from 'features/AdminLog/adminLogSlice';
import appUpdate from 'features/AppUpdate/appUpdateSlice';

import adminInspections from 'features/AdminInspections/slice';
import adminYearlyTravelPermits from 'features/AdminYearlyTravelPermits/slice';
import adminLifetimeTravelPermits from 'features/AdminLifetimeTravelPermits/slice';
import adminPreSaleInspections from 'features/AdminPreSaleInspections/slice';

import initializer from 'features/Initializer/initializerSlice';
import loadingMask from 'features/LoadingMask/slice';
import paymentStateLoader from 'features/PaymentStateLoader/slice';
import paymentHistory from 'features/PaymentHistory/slice';

import meReducer from './meSlice';
import brandsSlice from './brandsSlice';
import resourcesSlice from './resourcesSlice';

export const history = createBrowserHistory();

export default configureStore({
  reducer: {
    router: connectRouter(history),
    me: meReducer,
    brands: brandsSlice,
    inspection: inspectionSlice,
    inspectionCertificate: inspectionCertificateSlice,
    createHorsePreSale: createHorsePreSaleSlice,
    horsePreSale: horsePreSaleSlice,
    createTravelPermit: createTravelPermitSlice,
    travelPermit: travelPermitSlice,
    resources: resourcesSlice,

    // refactored slices
    inspections,
    offlineInspections,
    draftInspections,

    lifetimeTravelPermits,
    yearlyTravelPermits,
    offlineTravelPermits,
    travelPermits,

    preSaleInspections,
    offlinePreSaleInspections,

    syncOffline,

    settlePayment,

    adminLogs,
    adminLog,
    adminInspections,
    adminPreSaleInspections,
    adminYearlyTravelPermits,
    adminLifetimeTravelPermits,

    appUpdate,

    initializer,
    loadingMask,
    paymentStateLoader,
    paymentHistory,
  },
  middleware: [
    routerMiddleware(history),
    thunk.withExtraArgument({
      codesDb,
      inspectionsDb,
      brandsDb,
      client,
      inspectionClient,
      createAuthClient,
      createError,
    }),
  ],
});
