// @flow
import React from 'react';
import { Switch, Route } from 'react-router-dom';
import AdminLogs from 'features/AdminLogs';
import AdminLog from 'features/AdminLog';
import Page from 'components/Page';

const AdminLogsPage = () => {
  return (
    <Page>
      <Switch>
        <Route exact path="/admin/logs">
          <AdminLogs />
        </Route>

        <Route exact path="/admin/logs/:id">
          <AdminLog />
        </Route>
      </Switch>
    </Page>
  );
};

export default AdminLogsPage;
