// @flow
import React, { Fragment } from 'react';
import QRCode from 'qrcode.react';
import styled from 'styled-components';
import { ScissorOutlined } from '@ant-design/icons';
import udafLogo from 'assets/logo.png';
import sealOfUtah from 'assets/seal-of-utah.png';
import utahText from 'assets/img-utah-text.png';
import {
  isReleasedAtNoCharge,
  PAYMENT_DESCRIPTIONS,
  PAYMENT_LABEL_ONLY,
} from 'constants/inspection';
import type { Payment } from 'flow/payment';
import type { ClientServerVersion } from 'flow/version';
import type { AddressDetails } from 'flow/inspection';
import type { BrandType } from 'flow/brands';
import { getPositionLabel } from 'flow/brands';
import type { RequiredPerson } from 'flow/person';
import type { AnimalType } from 'flow/animal';

const Template = styled.div`
  color: #002d06;
  background: url(${utahText}) top left/.65in repeat;
  padding: 0.25in;
`;

type Props = {
  inspectionTitle: string,
  code: string,
  date: ?string,
  url: string,
  version: ClientServerVersion,
  isVoid: boolean,
  logoSize: ?string,
  sealSize: ?string,
  qrSize: ?string,
  hideSeal: boolean,
};

export const InspectionHeader = ({
  inspectionTitle,
  code,
  date,
  url,
  version,
  isVoid,
  logoSize,
  sealSize,
  qrSize,
  hideSeal,
}: Props) => {
  const { clientVersion, serviceVersion } = version;
  return (
    <Header>
      <Logo>
        <img
          src={udafLogo}
          alt="Utah Department of Agriculture and Food"
          height={logoSize}
          width={logoSize}
        />
      </Logo>

      <TitleSection>
        <h4>
          <NoWrap>Utah Department of</NoWrap>{' '}
          <NoWrap>Agriculture and Food</NoWrap>
        </h4>
        <h2>{inspectionTitle}</h2>
        <Code>{code}</Code>
        <Date>Issued: {date}</Date>
        {isVoid && <Voided>VOIDED</Voided>}
      </TitleSection>

      <QRandSeal>
        {!hideSeal && (
          <Seal>
            <img
              src={sealOfUtah}
              alt="Seal of the State of Utah"
              width={sealSize}
              height={sealSize}
            />
          </Seal>
        )}
        <QRandVersion>
          <Version>
            {!!clientVersion && `c ${clientVersion}`} / &nbsp;
            {!!serviceVersion && `s ${serviceVersion}`}
          </Version>
          <QRContainer>
            <QRCode
              value={url}
              style={{
                display: 'block',
                width: qrSize,
                height: qrSize,
                border: '.4em solid #ffffff',
              }}
            />
            <span>SCAN TO VERIFY</span>
          </QRContainer>
        </QRandVersion>
      </QRandSeal>
    </Header>
  );
};

InspectionHeader.defaultProps = {
  logoSize: '90',
  sealSize: '94',
  qrSize: '.8in',
  hideSeal: false,
};

type DisplayPaymentProps = {
  payment: Payment,
};

export const DisplayPayment = ({ payment }: DisplayPaymentProps) => {
  const { paymentType, checkNumber, checkPayee, creditcardPaymentRef } = payment;

  const paymentTypeDisplay = PAYMENT_DESCRIPTIONS[paymentType] || '';

  const isCheck = paymentType === 'check';
  const checkPayeeLabel = isCheck && !!checkPayee ? checkPayee : '';
  const paymentId = isCheck && !!checkNumber ? `#${checkNumber}` : '';
  const releasedAtNoCharge = isReleasedAtNoCharge(paymentType);

  const paymentDescription =
    PAYMENT_LABEL_ONLY.indexOf(paymentType) > -1
      ? paymentTypeDisplay
      : paymentType === 'creditcard' ? `paid with ${paymentTypeDisplay} ${creditcardPaymentRef}`
        :  `${checkPayeeLabel} paid with ${paymentTypeDisplay} ${paymentId}`;

  return (
    !releasedAtNoCharge && (
      <PaymentDetails>
        <label>PAYMENT:</label> {paymentDescription}
      </PaymentDetails>
    )
  );
};

type AddressProps = {
  address: $Shape<AddressDetails>,
  displayCounty: boolean,
};

export const Address = ({ address, displayCounty }: AddressProps) => {
  const { street, city, state, county, zipCode } = address;
  let displayedCity = '';

  if (city) {
    if (state || zipCode || county) {
      displayedCity = `${city},`;
    } else {
      displayedCity = city;
    }
  }

  return (
    <Fragment>
      {!!street && <p>{street}</p>}
      {(!!displayedCity || !!state || !!zipCode) && (
        <p>
          {displayedCity} {state} {zipCode}
        </p>
      )}
      {displayCounty && !!county && <p>{county}</p>}
    </Fragment>
  );
};

Address.defaultProps = {
  displayCounty: true,
};

export const Content = styled.div`
  flex: 1;
  display: flex;
  font-size: 0.8em;
  flex-direction: column;
  padding: 0 1.5em;
`;

export const Legalize = styled.p`
  max-width: 2.9in;
  font-weight: 400;
  line-height: 1.2em;
  span {
    display: block;
    padding-top: 0.6em;
    font-size: 0.8em;
  }
`;

export const PaymentDetails = styled.div`
  align-self: flex-end;
  text-align: center;
  white-space: nowrap;
  margin: 1.5em 2em 1em 0;
  color: #002d06;

  label {
    font-weight: 800;
  }
`;

export const InspectorOwnerBuyer = styled.div`
  display: flex;
  flex: auto;
  padding: 0 0 0.75em;
  justify-content: space-between;
  align-items: stretch;
  div {
    padding-right: 1em;
  }
  div:last-child {
    padding-right: 0;
  }
`;

const TearLine = styled.div`
  margin: 0;
  height: 1px;
  border-bottom: 1px dashed #999999 !important;
`;

const CutHereIcon = styled(ScissorOutlined)`
  color: #999999;
  font-size: 16px;
  position: absolute;
  left: 1em;
  top: 0;
  margin-top: -7px;
`;

export const TearHR = () => (
  <div style={{ position: 'relative' }}>
    <CutHereIcon />
    <TearLine />
  </div>
);

type BrandProps = {
  brands: Array<BrandType>,
};

export const Brands = ({ brands }: BrandProps) => {
  const brandCount = brands.length;
  return (
    <BrandsContainer>
      <strong>Brand{brandCount > 1 ? 's' : ''}:</strong>
      {brandCount ? (
        brands.map(({ id, position, imageUrl }: $Shape<BrandType>) => {
          return (
            <Brand key={id}>
              {imageUrl && <img src={imageUrl} alt={position} />}
              {getPositionLabel(position)}
            </Brand>
          );
        })
      ) : (
        <NoBrand>no brand</NoBrand>
      )}
    </BrandsContainer>
  );
};

const NoBrand = styled.div`
  font-weight: 500;
`;

const BrandsContainer = styled.div`
  display: flex;
  flex-direction: row;
  strong {
    display: inline-block;
    margin-top: 0.2em;
    margin-right: 0.5em;
  }
`;

const Brand = styled.div`
  display: inline-block !important;
  white-space: nowrap;
  padding: 0.1em 0.2em 0.1em;
  margin: 0;
  line-height: 0.7em;
  > img {
    width: 1.75em;
    height: 1.75em;
    margin-right: 0.1em;
  }
`;

type OwnerProps = {
  owner: RequiredPerson,
};

export const Owner = ({ owner }: OwnerProps) => {
  const ownerAddress = {
    street: owner?.address,
    city: owner?.city,
    county: owner?.county?.name,
    state: owner?.state?.name,
    zipCode: owner?.zipCode,
  };
  return (
    <Section style={{ lineHeight: '1.3em' }}>
      <h5 style={{ margin: '0' }}>OWNER:</h5>
      <strong>{owner?.name}</strong>
      {owner.phone && <em>{owner.phone}</em>}
      <Address address={ownerAddress} displayCounty={false} />
    </Section>
  );
};

type HorseProps = {
  animal: AnimalType,
  year: string,
  height: ?string,
  registeredName: ?string,
  registrationNo: ?string,
  tattoo: ?string,
  clearance: ?string,
  includeBrands: ?boolean,
};

export const Horse = ({
  animal,
  year,
  height, // from travel
  registeredName,
  registrationNo, // from travel
  tattoo, // from travel
  clearance, // from presale
  includeBrands,
}: HorseProps) => {
  const { brands, gender, breed, color } = animal;
  const displayBreed =
    breed?.breed === 'Quarter' ? 'Quarter Horse' : breed?.breed;
  return (
    <Section style={{ lineHeight: '1.3em' }}>
      <h5>EQUINE:</h5>
      <strong>
        {color?.color} {displayBreed} {gender?.gender}{' '}
        <NoWrap>born in {year}</NoWrap>
      </strong>
      {includeBrands && <Brands brands={brands} />}
      {registeredName && <p>Registered Name: {registeredName}</p>}
      {registrationNo && <p>Registration #: {registrationNo}</p>}
      {tattoo && <p>Tattoo: {tattoo}</p>}
      {clearance && <p>Clearance: {clearance}</p>}
      {height && <p>Height: {height} hands</p>}
    </Section>
  );
};

Horse.defaultProps = {
  height: null,
  registeredName: null,
  registrationNo: null,
  tattoo: null,
  clearance: null,
  includeBrands: false,
};

export const NoWrap = styled.span`
  white-space: nowrap;
`;

export const Section = styled.div`
  flex-grow: 1;
  flex-basis: auto;
  padding: 0;

  h5 {
    display: block;
    white-space: nowrap;
    font-weight: 400;
    color: #002d06;
    padding-bottom: 0.5em;
    margin: 0 0 0.3em 0;
    border-bottom: 1px solid #002d06;
  }

  strong {
    display: block;
    font-weight: 600;
    color: #002d06;
  }

  em {
    display: block;
  }

  label {
    font-weight: 600;
  }

  p {
    margin: 0;
  }
`;

const Header = styled.header`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.5em;
  em {
    color: #999999;
  }
`;

const Logo = styled.div`
  vertical-align: center;
  img {
    margin: auto;
  }
`;

const Seal = styled.div`
  padding: 0 1em;
`;

const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.9em;
  h2 {
    font-family: 'EB Garamond', serif;
    text-transform: uppercase;
    color: #002d06;
    text-align: center;
    margin: 0;
  }
  h4 {
    margin: 0;
    font-weight: 400;
    color: #002d06;
    text-align: center;
    text-transform: uppercase;
  }
  p {
    text-align: center;
    padding: 0;
    margin: 0;
  }
`;

const Title = styled.span`
  text-align: center;
  font-weight: 100;
  text-transform: uppercase;
  margin: 0;
`;

const Date = styled(Title)`
  color: #27783f;
`;

const Voided = styled(Title)`
  width: 5.5em;
  color: #ff0000;
  border: 0.3em solid #ff0000;
  margin: 0.3em auto;
  padding: 0 0.5em;
  font-size: 3em;
  font-weight: 900;
`;

const Code = styled(Title)`
  font-weight: 700;
  color: #ff0000;
`;

const QRandSeal = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const QRContainer = styled.div`
  padding: 0;
  margin-right: 10px;
  text-align: center;
`;

const QRandVersion = styled.div`
  position: relative;
  font-size: 0.55em;
`;

const Version = styled.span`
  position: absolute;
  transform: rotate(90deg);
  font-weight: lighter;
  font-size: 0.8em;
  top: 35%;
  right: -25px;
  margin: 0;
  padding: 0;
  color: #888888;
`;

export const SignatureRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 0.5in;
  margin: 0 0 2em;
`;

export const SignatureContainer = styled.div`
  width: 2.3in;
  padding: 0;

  em {
    text-align: center;
    color: #666666;
  }
`;

export default Template;
