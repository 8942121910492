// @flow
import React from 'react';
import { Empty } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledWrapper = styled.span`
  max-width: 250px;
  display: inline-block;
`;

type Props = {
  seeTabLabel?: string,
  seeTabUrl?: string,
  template?: *,
};

const EmptyOfflineMessage = ({ seeTabLabel, seeTabUrl, template }: Props) => {
  if (template) {
    return (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={<StyledWrapper>{template}</StyledWrapper>}
      />
    );
  }

  if (seeTabUrl && seeTabLabel) {
    return (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={
          <StyledWrapper>
            Your inspections have been completely synchronized, check the{' '}
            <Link to={seeTabUrl}>{seeTabLabel} tab</Link> to review those
            inspections.
          </StyledWrapper>
        }
      />
    );
  }

  return (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={
        <StyledWrapper>
          Your inspections have been completely synchronized.
        </StyledWrapper>
      }
    />
  );
};

EmptyOfflineMessage.defaultProps = {
  seeTabLabel: '',
  seeTabUrl: '',
  template: null,
};

export default EmptyOfflineMessage;
