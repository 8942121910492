// @flow
import React, { Fragment, useState } from 'react';
import { Select, Modal, Form, Input } from 'antd';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';
import type { Dealer } from 'flow/resources';
import { selectDealers } from 'selectors/resources';

type Props = {
  placeholder?: string,
  value: { name: string, license: string },
  onChange: ({ name: string, license: string }) => void,
  id: string,
};

const { Option } = Select;

const formLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const DealerLicenseFormField = ({
  onChange,
  value,
  placeholder,
  id,
}: Props) => {
  const [form] = Form.useForm();
  const [modalOpen, setModalOpen] = useState(false);
  const [searchKey, setSearchKey] = useState('');

  const dealers = useSelector((state) => selectDealers(state, searchKey));

  const resetModalForm = () => {
    setModalOpen(false);
    setSearchKey('');
    form.resetFields();
  };

  const handleSearch = debounce((query: string) => {
    setSearchKey(query);
  }, 300);

  const handleChange = (
    val: string,
    { children, key }: { children: string, key: string },
  ) => {
    if (val === 'add') {
      setModalOpen(true);
      form.setFieldsValue({ name: '', license: searchKey });
    } else {
      onChange({
        id: parseInt(key, 10),
        name: children,
        license: val,
      });
      setSearchKey('');
    }
  };

  const handleCancelModal = () => {
    resetModalForm();
  };

  const handleOkModal = () => {
    form.submit();
  };

  const handleFormFinish = (values) => {
    onChange(values);

    resetModalForm();
  };

  return (
    <Fragment>
      <Select
        loading={false}
        filterOption={false}
        showSearch
        showArrow={false}
        onSearch={handleSearch}
        onChange={handleChange}
        value={value?.license || ''}
        placeholder={placeholder}
        virtual={false}
        id={id}
      >
        {dealers.length > 0 ? (
          <Fragment>
            {dealers.map(({ license, id }: $Shape<Dealer>) => {
              return (
                <Option value={license} key={id}>
                  {license}
                </Option>
              );
            })}
            <Option value="add">Add dealer</Option>
          </Fragment>
        ) : (
          <Option value="add">Add dealer</Option>
        )}
      </Select>
      <Modal
        title="Add dealer"
        visible={modalOpen}
        onCancel={handleCancelModal}
        onOk={handleOkModal}
      >
        <Form
          form={form}
          initialValues={{ name: '', license: searchKey }}
          name="dealerLicenseForm"
          onFinish={handleFormFinish}
          {...formLayout}
        >
          <Form.Item
            label="Dealer Name"
            name="name"
            rules={[{ required: true, message: 'Name is required' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Dealer License No."
            name="license"
            rules={[{ required: true, message: 'License number is required' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </Fragment>
  );
};

DealerLicenseFormField.defaultProps = {
  value: { name: '', license: '' },
  placeholder: 'Search or add dealers license',
  onChange: () => {},
  id: '',
};

export default DealerLicenseFormField;
