// @flow
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useEffectOnce } from 'react-use';
import { Form, Divider, Row, Col, Button } from 'antd';
import Content from 'components/Content';
import type { ServicePayment } from 'flow/payment';
import type { ReduxStore } from 'flow/redux';
import type { InspectionType } from 'flow/resources';
import type { SubtotalType } from 'flow/inspection';
import {
  createInspection,
  applyPaymentOption,
} from 'pages/BrandInspectionPage/inspectionSlice';
import { selectInspection } from 'pages/BrandInspectionPage/selectors';
import PaymentOptionsFormField from 'components/form/PaymentOptionsFormField';
import useCreditCardCheckout, {
  type WrappedCheckoutUtils,
  type PaymentListenerProps,
} from 'utils/hooks/useCreditCardCheckout';
import { selectInspectionTotals } from 'selectors/inspection';
import generateEntityCode from 'utils/generateEntityCode';
import Preview from './components/Preview';
import Animals from './components/Animals';

let generatedCode = {};

const BrandInspectionPreview = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();
  const inspection = useSelector(selectInspection);
  const loading: boolean = useSelector((state: ReduxStore) => {
    return state.inspection.loading;
  });

  const { total }: SubtotalType = useSelector((state: ReduxStore) =>
    selectInspectionTotals(state, 'inspection'),
  );

  const paymentListener = ({ success }: PaymentListenerProps) => {
    // alert(`paymentListenerProps ${JSON.stringify(props)}`)
    if (success) {
      const payment: ServicePayment = form.getFieldsValue();

      dispatch(
        createInspection({
          payment,
          generatedCode,
        }),
      );
    }
  };

  const { checkout }: WrappedCheckoutUtils =
    useCreditCardCheckout(paymentListener);

  const showLineItems = !inspection.types.some(
    (type: InspectionType) =>
      type.slug === 'calf-pool-receiving-station-location',
  );

  const handleSubmit = (values: $Shape<ServicePayment>) => {
    const { payment } = values;
    const isCardPayment = payment?.paymentType === 'creditcard';

    if (isCardPayment) {
      checkout({
        amountInCents: total,
        publicId: generatedCode.publicId,
        entityType: 'inspection',
      });
    } else {
      dispatch(
        createInspection({
          payment: values,
          generatedCode,
        }),
      );
    }
  };

  const handleChange = (value) => {
    if (value) {
      const key = Object.keys(value)[0];

      if (key === 'payment') {
        const paymentType = value?.payment?.paymentType || '';

        if (paymentType) {
          const payment = form.getFieldValue('payment') || {};

          const updatedPayment = {
            payment: {
              ...payment,
              checkNumber: '',
              name: '',
            },
            releasedAtNoCharge: false,
            releasedAtNoChargeReason: '',
          };

          form.setFieldsValue(updatedPayment);

          dispatch(applyPaymentOption(updatedPayment));
        }
      }

      if (key === 'releasedAtNoChargeReason') {
        dispatch(
          applyPaymentOption({
            releasedAtNoCharge: true,
            releasedAtNoChargeReason: value[key],
          }),
        );
      }
    }
  };

  const handleBack = () => {
    history.push('/brand-inspection/animals');
  };

  const fetchCodes = async () => {
    const code = (await generateEntityCode('BI')) || {};

    generatedCode = code;
  };

  useEffectOnce(() => {
    fetchCodes();
  });

  return (
    <Form
      onFinish={handleSubmit}
      onValuesChange={handleChange}
      form={form}
      name="PaymentOptionForm"
      colon={false}
      layout="vertical"
    >
      <Content title="Preview and print">
        <Preview inspection={inspection} />
        <Divider style={{ margin: '10px 0' }} />
        <Animals
          releasedAtNoCharge={inspection.releasedAtNoCharge}
          showLineItems={showLineItems}
        />
        <PaymentOptionsFormField hasReleasedAtNoCharge />
      </Content>
      <Row gutter={10} align="middle" justify="end">
        <Col lg={4} md={5}>
          <Button
            htmlType="button"
            block
            onClick={handleBack}
            type="primary"
            ghost
            id="backToInspectionStepTwo"
          >
            Previous
          </Button>
        </Col>
        <Col lg={4} md={5}>
          <Button
            htmlType="submit"
            type="primary"
            block
            id="saveInspection"
            loading={loading}
          >
            Save
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default BrandInspectionPreview;
