// @flow
import type { ServiceFee, ServiceTypeName } from 'flow/resources';
import type { Inspection, LineItem } from 'flow/inspection';

type DerivedFee = {
  inspection: $Shape<ServiceFee>,
  beefCouncil: $Shape<ServiceFee>,
  predatorControl: $Shape<ServiceFee>,
  beefTag: $Shape<ServiceFee>,
  processing: $Shape<ServiceFee>,
};

export function getServiceTypeFee(
  serviceFees: Array<ServiceFee>,
  type: ServiceTypeName,
): $Shape<ServiceFee> {
  const filteredServiceFees = serviceFees.filter(
    ({ serviceFeeType }: ServiceFee) => serviceFeeType.name === type,
  );

  return filteredServiceFees.reduce(
    (acc: $Shape<ServiceFee>, fee: ServiceFee) => {
      return {
        additionalFee:
          fee.additionalFee > acc.additionalFee
            ? fee.additionalFee
            : acc.additionalFee,
        additionalFeeType:
          fee.additionalFee > acc.additionalFee
            ? fee.additionalFeeType
            : acc.additionalFeeType,
        overrideFee:
          fee.overrideFee > acc.overrideFee ? fee.overrideFee : acc.overrideFee,
        overrideFeeType:
          fee.overrideFee > acc.overrideFee
            ? fee.overrideFeeType
            : acc.overrideFeeType,
        flatRate: fee.flatRate || acc.flatRate,
        maximumQty:
          fee.maximumQty > acc.maximumQty ? fee.maximumQty : acc.maximumQty,
        minimumFee:
          fee.minimumFee > acc.minimumFee ? fee.minimumFee : acc.minimumFee,
        price: fee.price > acc.price ? fee.price : acc.price,
      };
    },
    {
      additionalFee: 0,
      additionalFeeType: null,
      overrideFee: 0,
      overrideFeeType: null,
      flatRate: false,
      maximumQty: 0,
      minimumFee: 0,
      price: 0,
    },
  );
}

export const getFees = (serviceFees: Array<ServiceFee>): DerivedFee => {
  return {
    inspection: getServiceTypeFee(serviceFees, 'inspection_fee'),
    beefCouncil: getServiceTypeFee(serviceFees, 'beef_council_fee'),
    predatorControl: getServiceTypeFee(serviceFees, 'predator_control_fee'),
    beefTag: getServiceTypeFee(serviceFees, 'beef_tag_fee'),
    processing: getServiceTypeFee(serviceFees, 'processing_fee'),
  };
};

export const getPrice = (
  {
    flatRate,
    price,
    additionalFee,
    additionalFeeType,
    maximumQty,
  }: $Shape<ServiceFee>,
  count: number,
): number => {
  if (count > maximumQty && additionalFee && additionalFeeType) {
    const exceededQty = count - maximumQty;
    const basePrice = flatRate
      ? price
      : price * (maximumQty === 0 ? count : maximumQty);

    if (additionalFeeType === 'fixed') {
      return basePrice + additionalFee;
    }

    if (additionalFeeType === 'per_head') {
      return basePrice + additionalFee * exceededQty;
    }
  }

  if (flatRate) {
    return price;
  }

  return price * count;
};

export const inspectionAndTotal = (inspections: Array<Inspection>) => {
  return inspections.map<Inspection>((inspection: $Shape<Inspection>) => {
    const total = (inspection?.lineItems || []).reduce(
      (acc: number, lineItem: LineItem) => {
        return acc + (lineItem.waived ? 0 : lineItem.price);
      },
      0,
    );
    return {
      ...inspection,
      total,
    };
  });
};
