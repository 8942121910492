// @flow
import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  padding: 0 10px;
`;

export const Signature = styled.img`
  position: absolute;
  top: -0.5em;
  height: 85%;
  width: 85%;
`;

export const SignOnTheLine = styled.div`
  padding: 1vw 0 0;
  strong {
    margin-left: 0.3vw;
    font-size: 2em;
    font-weight: 900;
    color: #bed4c1;
    opacity: 0.5;
  }

  hr {
    margin: 0;
    border-top: 1px solid #666666;
    border-bottom: 0;
  }
`;

export const Label = styled.p`
  text-align: center;
  color: #666666;
  opacity: 0.8;
  margin: 0.5vw 0.5vw 0 0;
`;
