// @flow
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Dexie from 'dexie';
import { INSPECTION_UPDATE_HISTORY } from 'flow/inspection';
import { INSPECTION_TABLES } from 'constants/db';
import type {
  Breed,
  Category,
  Color,
  ColorTag,
  County,
  Dealer,
  Gender,
  InspectionType,
  ResourcesState,
  Service,
  ServiceFee,
  State,
} from 'flow/resources';
import storeResourceData from 'utils/storeResourceData';
import { fetchInspections } from 'features/BrandInspections/inspectionsSlice';
import { fetchPreSaleInspections } from 'features/PreSaleInspections/preSaleInspectionsSlice';
import { filterLifetimeTravelPermits } from 'features/LifetimeTravelPermits/lifetimeTravelPermitsSlice';
import { fetchYearlyTravelPermits } from 'features/YearlyTravelPermits/yearlyTravelPermitsSlice';
import { RESOURCE_REQUIRES_NO_USER, syncIfNeeded } from 'service/sync';

const {
  TYPES: { RESOURCES },
} = INSPECTION_UPDATE_HISTORY;

const {
  ANIMAL_CATEGORIES,
  ANIMAL_BREEDS,
  ANIMAL_COLORS,
  ANIMAL_GENDERS,
  ANIMAL_TAGS,
  COUNTIES,
  STATES,
  DEALERS,
  INSPECTION_TYPES,
  SERVICES,
  SERVICE_FEES,
} = INSPECTION_TABLES;

const alphabetically = (a: string, b: string): number => {
  const nameA = a.toUpperCase();
  const nameB = b.toUpperCase();

  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  return 0;
};

type NamedType = County | State | Dealer;
const alphabeticByName = (a: NamedType, b: NamedType): number =>
  alphabetically(a.name, b.name);

const alphabeticByCategory = (a: Category, b: Category): number =>
  alphabetically(a.category, b.category);

const alphabeticByBreed = (a: Breed, b: Breed): number =>
  alphabetically(a.breed, b.breed);

type ColorType = Color | ColorTag;
const alphabeticByColor = (a: ColorType, b: ColorType): number =>
  alphabetically(a.color, b.color);

const alphabeticByGender = (a: Gender, b: Gender): number =>
  alphabetically(a.gender, b.gender);

const alphabeticByType = (a: InspectionType, b: InspectionType): number =>
  alphabetically(a.slug, b.slug);

const otherLast = ({ slug }: InspectionType) => (slug === 'other' ? 1 : -1);

const get = (db: Dexie, tableName: string) => {
  return db[tableName].toArray();
};

// todo figure out how to do the orderBy with Dexie which would be more optimal
const getResourcesObject = (
  animalCategories: Array<Category>,
  animalBreeds: Array<Breed>,
  animalColors: Array<Color>,
  animalGenders: Array<Gender>,
  animalTags: Array<ColorTag>,
  inspectionTypes: Array<InspectionType>,
  counties: Array<County>,
  states: Array<State>,
  dealers: Array<Dealer>,
  services: Array<Service>,
  serviceFees: Array<ServiceFee>,
) => ({
  animalCategories: animalCategories.sort(alphabeticByCategory),
  animalBreeds: animalBreeds.sort(alphabeticByBreed),
  animalColors: animalColors.sort(alphabeticByColor),
  animalGenders: animalGenders.sort(alphabeticByGender),
  animalTags: animalTags.sort(alphabeticByColor),
  inspectionTypes: inspectionTypes
    .sort(alphabeticByType)
    .reverse()
    .sort(otherLast),
  counties: counties.sort(alphabeticByName),
  states: states.sort(alphabeticByName),
  dealers: dealers.sort(alphabeticByName),
  services,
  serviceFees,
});

const getResourcesFrom = (db: Dexie) =>
  Promise.all([
    get(db, ANIMAL_CATEGORIES),
    get(db, ANIMAL_BREEDS),
    get(db, ANIMAL_COLORS),
    get(db, ANIMAL_GENDERS),
    get(db, ANIMAL_TAGS),
    get(db, INSPECTION_TYPES),
    get(db, COUNTIES),
    get(db, STATES),
    get(db, DEALERS),
    get(db, SERVICES),
    get(db, SERVICE_FEES),
  ]).then(
    ([
      animalCategories,
      animalBreeds,
      animalColors,
      animalGenders,
      animalTags,
      inspectionTypes,
      counties,
      states,
      dealers,
      services,
      serviceFees,
    ]) =>
      getResourcesObject(
        animalCategories,
        animalBreeds,
        animalColors,
        animalGenders,
        animalTags,
        inspectionTypes,
        counties,
        states,
        dealers,
        services,
        serviceFees,
      ),
  );

const resourcesAreNotPresent = (resources: $Shape<ResourcesState>): boolean => {
  const resourceArrays: Array<*> = Object.keys(resources)
    .map((key) => resources[key])
    .filter((resource) => Array.isArray(resource));
  return !resourceArrays.every((resource) => resource.length > 0);
};

const mapResponseToResources = ([
  { data: animalCategories },
  { data: animalBreeds },
  { data: animalColors },
  { data: animalGenders },
  { data: animalTags },
  { data: inspectionTypes },
  { data: counties },
  { data: states },
  { data: dealers },
  { data: services },
  { data: serviceFees },
]) =>
  getResourcesObject(
    animalCategories,
    animalBreeds,
    animalColors,
    animalGenders,
    animalTags,
    inspectionTypes,
    counties,
    states,
    dealers,
    services,
    serviceFees,
  );

const storeResourcesLocally = (db: Dexie) => (resources) => {
  return Promise.all([
    storeResourceData(db, ANIMAL_CATEGORIES, resources.animalCategories, true),
    storeResourceData(db, ANIMAL_BREEDS, resources.animalBreeds, true),
    storeResourceData(db, ANIMAL_COLORS, resources.animalColors, true),
    storeResourceData(db, ANIMAL_GENDERS, resources.animalGenders, true),
    storeResourceData(db, ANIMAL_TAGS, resources.animalTags, true),
    storeResourceData(db, INSPECTION_TYPES, resources.inspectionTypes, true),
    storeResourceData(db, COUNTIES, resources.counties, true),
    storeResourceData(db, STATES, resources.states, true),
    storeResourceData(db, DEALERS, resources.dealers, true),
    storeResourceData(db, SERVICES, resources.services, true),
    storeResourceData(db, SERVICE_FEES, resources.serviceFees, true),
  ]).then(() => resources);
};

const fetchResourcesFromApi = async (db: Dexie, client) =>
  Promise.all([
    client.get('/animals/categories/'),
    client.get('/animals/breeds/'),
    client.get('/animals/colors/'),
    client.get('/animals/genders/'),
    client.get('/animals/tags/'),
    client.get('/inspections/types/'),
    client.get('/counties/'),
    client.get('/states/'),
    client.get('/dealers/'),
    client.get('/services/'),
    client.get('/service-fees/'),
  ])
    .then(mapResponseToResources)
    .then(storeResourcesLocally(db));

export const fetchResources = createAsyncThunk(
  'resources/fetchResources',
  async (
    props: ?{ isPublic: boolean },
    { extra, rejectWithValue, dispatch, getState },
  ) => {
    const { inspectionClient, inspectionsDb: db } = extra;
    const client = inspectionClient();
    const isPublic = !!props?.isPublic;
    const { online } = getState().initializer;

    try {
      let resources = await getResourcesFrom(db);
      const explicitReload = resourcesAreNotPresent(resources);
      resources = await syncIfNeeded(
        RESOURCE_REQUIRES_NO_USER,
        RESOURCES,
        online,
        explicitReload,
        () => Promise.resolve(resources),
        () => fetchResourcesFromApi(db, client),
      );

      if (!isPublic) {
        await Promise.all([
          dispatch(fetchInspections()),
          dispatch(fetchPreSaleInspections()),
          // gets travel permits
          dispatch(fetchYearlyTravelPermits()),
        ])
          // we already retrieved travel permits in the fetchYearlyTravelPermits() method
          .then(dispatch(filterLifetimeTravelPermits()));
      }

      return resources;
    } catch (err) {
      console.error('There was a problem retrieving resources', err);
      return rejectWithValue(err);
    }
  },
);

const resourcesSlice = createSlice({
  name: 'resources',
  initialState: {
    animalCategories: [],
    animalBreeds: [],
    animalColors: [],
    animalGenders: [],
    animalTags: [],
    inspectionTypes: [],
    counties: [],
    states: [],
    dealers: [],
    services: [],
    serviceFees: [],
    pending: true,
    disconnected: false,
  },
  reducers: {},
  extraReducers: {
    [fetchResources.fulfilled]: (state: ResourcesState, { payload }) => {
      const {
        animalCategories,
        animalBreeds,
        animalColors,
        animalGenders,
        animalTags,
        inspectionTypes,
        counties,
        states,
        dealers,
        services,
        serviceFees,
      } = payload;

      state.animalCategories = animalCategories;
      state.animalBreeds = animalBreeds;
      state.animalColors = animalColors;
      state.animalGenders = animalGenders;
      state.animalTags = animalTags;
      state.inspectionTypes = inspectionTypes;
      state.counties = counties;
      state.states = states;
      state.dealers = dealers;
      state.services = services;
      state.serviceFees = serviceFees;
      state.pending = false;
    },
    [fetchResources.rejected]: (state: ResourcesState) => {
      state.pending = false;
      state.disconnected = true;
    },
  },
});

export default resourcesSlice.reducer;
