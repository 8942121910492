// @flow
import React, { type ElementRef, type Node } from 'react';
import { Select } from 'antd';
import type { County } from 'flow/resources';
import useResources from 'utils/hooks/useResources';
import selectFilterOptions from 'utils/form/selectFilterOptions';

const { Option } = Select;

type Props = {
  value: string,
  state: ?number,
  onChange: (string) => void,
  forwardedRef: ElementRef<*>,
  size?: 'small' | 'medium' | 'large',
  placeholder?: string,
};

const CountiesFormField = ({ state, forwardedRef, ...rest }: Props) => {
  const counties: Array<County> = useResources<County>('counties');

  return (
    <Select
      {...rest}
      ref={forwardedRef}
      virtual={false}
      showSearch
      filterOption={selectFilterOptions}
    >
      {counties
        .filter((c: $Shape<County>) => {
          if (!state) return true;

          return !c.state || c.state === state;
        })
        .map(({ id, name }: $Shape<County>) => (
          <Option value={id} key={id}>
            {name}
          </Option>
        ))}
    </Select>
  );
};

CountiesFormField.defaultProps = {
  size: 'medium',
  placeholder: 'Choose County',
};

export default React.forwardRef<*, Node>((props: Props, ref: ElementRef<*>) => {
  return <CountiesFormField forwardedRef={ref} {...props} />;
});
