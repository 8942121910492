// @flow
import React from 'react';
import { useLocation, type Location } from 'react-router-dom';
import styled from 'styled-components';
import {
  ENVIRONMENT,
  IS_PROD_ENVIRONMENT,
  IS_STAGING_ENVIRONMENT,
} from 'service/urls';
import Brand from './components/Brand';
import Sidebar from './components/Sidebar';

const StyledHeader = styled.header.attrs({
  className: 'hidden-print',
})`
  position: relative;
  border-bottom: 1px solid rgba(0, 36, 17, 0.1);
  box-shadow: 0 12px 34px 0 rgba(224, 228, 226, 0.17);
`;

const StyledNav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em;
  max-width: 1200px;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding: 0.75em 1em;
  }
`;

const StyledTitle = styled.h1`
  font-size: 1.25em;
  font-weight: bold;
  justify-self: center;
  margin-bottom: 0;
  position: absolute;
  text-align: center;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 500px) {
    left: 0;
    top: 0;
    transform: translate(0, 0);
    margin-left: 60px;
    margin-top: 10px;
    font-size: 0.9em;
    white-space: normal;
  }

  @media (max-width: 768px) {
    font-size: 0.9em;
  }
`;

const Navigation = () => {
  const { pathname }: $Shape<Location> = useLocation();

  const getPageTitle = () => {
    if (pathname === '/') {
      return 'Dashboard';
    }

    if (pathname.startsWith('/brand-inspection')) {
      return 'Livestock Brand Inspection';
    }

    if (pathname.startsWith('/travel-permit')) {
      return 'Horse Travel Permit';
    }

    if (pathname.startsWith('/horse-pre-sale')) {
      return 'Horse Pre-sale Inspection';
    }

    if (pathname.startsWith('/inspections')) {
      return 'Brand Inspection Listings';
    }

    if (pathname.startsWith('/travel-permits')) {
      return 'Horse Travel Permits';
    }

    if (pathname.startsWith('/horse-presale-listing')) {
      return 'Horse Pre-sale Inspections';
    }

    if (pathname.startsWith('/admin')) {
      return 'Admin';
    }

    if (pathname.startsWith('/unpaid')) {
      return 'Unpaid Certificates';
    }

    return '';
  };
  return (
    <StyledHeader
      style={{ backgroundColor: IS_PROD_ENVIRONMENT ? '#ffffff' : '#ff9595' }}
    >
      <StyledNav>
        <Brand />
        <StyledTitle>
          {!IS_PROD_ENVIRONMENT && (
            <div
              style={{
                fontWeight: 'bolder',
                color: '#ffffff',
                textTransform: 'uppercase',
              }}
            >
              {IS_STAGING_ENVIRONMENT ? 'TESTING' : ENVIRONMENT.current}{' '}
              ENVIRONMENT
            </div>
          )}
          {getPageTitle()}
        </StyledTitle>
        <Sidebar />
      </StyledNav>
    </StyledHeader>
  );
};

export default Navigation;
