// @flow
import React, { useState, Fragment } from 'react';
import { useSelector, useDispatch, type Dispatch } from 'react-redux';
import styled from 'styled-components';
import { Space } from 'antd';
import type {
  LineItem,
  LineItemSlug,
  DisplayLineItem,
  SubtotalType,
} from 'flow/inspection';
import type { ReduxStore } from 'flow/redux';
import InspectionLineItems from 'components/InspectionLineItems';
import InspectionTotal from 'components/InspectionTotal';
import Footnotes from 'components/Footnotes';
import {
  selectInspectionLineItems,
  selectInspectionTotals,
  selectInspectionMinimumLineItem,
  selectHasProductionOrConsignment,
} from 'selectors/inspection';
import { LINE_ITEM_TYPE } from 'constants/inspection';
import MinimumFeeFootnote from 'features/MinimumFeeFootnote';
import {
  waiveLineItem,
  unwaiveLineItem,
  overrideLineItem,
  removeOverridenLineItem,
} from '../../../inspectionSlice';
import WaiveModal from './WaiveModal';
import OverrideFeesModal from './OverrideFeesModal';

type Props = {
  releasedAtNoCharge: boolean,
  showLineItems: boolean,
};

const Animals = ({ releasedAtNoCharge, showLineItems }: Props) => {
  const [waiveRecord, setWaiveRecord] = useState<LineItem | null>(null);

  const [waiveOrOverrideType, setWaiveOrOverrideType] =
    useState<LineItemSlug | null>(null);

  const [overrideRecord, setOverrideRecord] = useState<LineItem | null>(null);

  const dispatch: Dispatch = useDispatch();

  const displayLineItems: Array<DisplayLineItem> = useSelector(
    (state: ReduxStore) => {
      return selectInspectionLineItems(state, 'inspection');
    },
  );

  const isCattle = displayLineItems.some(
    (lineItem: DisplayLineItem) => lineItem.name.toLowerCase() === 'cattle',
  );

  const hasBeefTag =
    (displayLineItems[0]?.beefTag?.calculatedFee?.overrideFee || 0) > 0;

  const subtotal: SubtotalType = useSelector((state: ReduxStore) =>
    selectInspectionTotals(state, 'inspection'),
  );

  const inspectionMinimumFee: LineItem = useSelector((state: ReduxStore) =>
    selectInspectionMinimumLineItem(state, 'inspection'),
  );

  const hasProductionOrConsignment: boolean = useSelector((state: ReduxStore) =>
    selectHasProductionOrConsignment(state, 'inspection'),
  );

  const {
    totalBeefCouncilFee,
    totalPredatorControlFee,
    totalInspectionFee,
    totalBeefTagFee,
    totalProcessingFee,
    total,
  } = subtotal;

  const handleCloseModal = () => {
    setWaiveRecord(null);
    setWaiveOrOverrideType(null);
    setOverrideRecord(null);
  };

  const handleActionCellClick = (
    record: DisplayLineItem,
    type: LineItemSlug,
  ) => {
    const feeType = {
      inspection_fee: 'inspection',
      predator_control_fee: 'predatorControl',
      beef_council_fee: 'beefCouncil',
      beef_tag_fee: 'beefTag',
      released_at_no_charge: '',
      inspection_minimum_fee: '',
      processing_fee: '',
    }[type];

    if (feeType && !releasedAtNoCharge) {
      const fee: LineItem = record[feeType];

      const { calculatedFee, price, reason } = fee;

      const hasNoAmount: boolean =
        price === 0 &&
        calculatedFee?.additionalFee === 0 &&
        calculatedFee?.minimumFee === 0;

      const overrideFee: number = fee?.calculatedFee?.overrideFee || 0;

      setWaiveOrOverrideType(type);

      if (
        (hasNoAmount && overrideFee > 0) ||
        fee.type === LINE_ITEM_TYPE.OVERRIDE
      ) {
        setOverrideRecord({
          ...fee,
          reason: reason || '',
        });
      } else if (fee.type === LINE_ITEM_TYPE.SERVICE && price !== 0) {
        setWaiveRecord({
          ...fee,
          reason: reason || '',
        });
      }
    }
  };

  const handleInspectionMinimumWaiving = () => {
    if (inspectionMinimumFee) {
      setWaiveOrOverrideType(inspectionMinimumFee.slug);

      setWaiveRecord({
        ...inspectionMinimumFee,
        reason: inspectionMinimumFee?.reason || '',
      });
    }
  };

  const handleWaiveFee = ({ reason }: { reason: string }) => {
    dispatch(
      waiveLineItem({
        lineNumber: waiveRecord?.lineNumber,
        reason,
        slug: waiveOrOverrideType,
      }),
    );

    handleCloseModal();
  };

  const handleRemoveWaiveFee = () => {
    dispatch(
      unwaiveLineItem({
        lineNumber: waiveRecord?.lineNumber,
        slug: waiveOrOverrideType,
      }),
    );

    handleCloseModal();
  };

  const handleOverrideFee = ({ reason }: { reason: string }) => {
    dispatch(
      overrideLineItem({
        defaultLineItem: overrideRecord,
        reason,
      }),
    );

    handleCloseModal();
  };

  const handleRemoveOverrideFee = () => {
    dispatch(
      removeOverridenLineItem({
        lineNumber: overrideRecord?.lineNumber,
        slug: overrideRecord?.slug,
      }),
    );

    handleCloseModal();
  };

  const handleRowAction = (
    record: $Shape<DisplayLineItem>,
    type: LineItemSlug,
  ) => {
    return {
      onClick: () => {
        handleActionCellClick(record, type);
      },
    };
  };

  const minimumFeeFootnote = !!inspectionMinimumFee &&
    !inspectionMinimumFee?.waived && <MinimumFeeFootnote />;

  return (
    <Fragment>
      <Space size="small" direction="vertical">
        {showLineItems && (
          <Fragment>
            <InspectionLineItems
              displayLineItems={displayLineItems}
              subtotal={subtotal}
              inspectionMinimumFee={inspectionMinimumFee}
              handleInspectionMinimumWaiving={handleInspectionMinimumWaiving}
              handleRowAction={handleRowAction}
              hasBeefTag={hasBeefTag}
              isCattle={isCattle}
            />
          </Fragment>
        )}
        <InspectionTotal
          totalBeefCouncilFee={totalBeefCouncilFee}
          totalPredatorControlFee={totalPredatorControlFee}
          totalInspectionFee={totalInspectionFee}
          totalBeefTagFee={totalBeefTagFee}
          totalProcessingFee={totalProcessingFee}
          total={total}
          hasBeefTag={hasBeefTag}
          hasProductionOrConsignment={hasProductionOrConsignment}
          footnote={minimumFeeFootnote}
          isCattle={isCattle}
          showLineItems={showLineItems}
        />
        {showLineItems && (
          <StyledFootnotes>
            <Footnotes editable />
          </StyledFootnotes>
        )}
      </Space>
      <WaiveModal
        open={!!waiveRecord}
        defaultReason={waiveRecord?.reason || ''}
        onClose={handleCloseModal}
        onSubmit={handleWaiveFee}
        onClear={handleRemoveWaiveFee}
      />
      <OverrideFeesModal
        open={!!overrideRecord}
        record={overrideRecord}
        onClose={handleCloseModal}
        onSubmit={handleOverrideFee}
        onClear={handleRemoveOverrideFee}
      />
    </Fragment>
  );
};

const StyledFootnotes = styled.div`
  p {
    color: #cccccc;
    font-weight: 600;
    margin-right: 1em;
  }
`;

export default Animals;
