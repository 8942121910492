import type { InspectionType } from 'flow/resources';
import type { ServiceInspectionType } from 'flow/inspection';

export const getInspectionType = (
  typeId: number,
  allPossibleTypes: Array<InspectionType>,
  otherType: ?string,
): InspectionType => {
  const typeObj = allPossibleTypes.find((t) => t.id === typeId);
  if (typeObj === undefined) {
    throw new Error(`InspectionType not found for id ${typeId}!`);
  }
  return {
    ...typeObj,
    inspectionType:
      typeObj && typeObj.slug === 'other' && otherType ? otherType : '',
  };
};

export const getInspectionTypes = (
  typesFromInspection: Array<number>,
  allPossibleTypes: Array<InspectionType>,
  otherType: ?string,
): Array<InspectionType> =>
  typesFromInspection.map((typeId) =>
    getInspectionType(typeId, allPossibleTypes, otherType),
  );

export const getServiceInspectionTypes = (
  types: Array<InspectionType>,
): Array<ServiceInspectionType> =>
  types.map(({ id, inspectionType }) => ({ type: id, inspectionType }));

export const getInspectionTypeId = ({ id }: InspectionType): number => id;

export const getInspectionTypeIds = (
  types: Array<InspectionType>,
): Array<number> => types.map(getInspectionTypeId);
