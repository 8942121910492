// @flow
import React from 'react';
import { useEffectOnce } from 'react-use';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';
import { fetchResources } from 'store/resourcesSlice';
import { selectResourcesPending } from 'selectors/resources';
import BrandInspectionCertificatePage from 'pages/BrandInspectionCertificatePage';
import HorsePreSalePage from 'pages/HorsePreSalePage';
import TravelPermitPage from 'pages/TravelPermitPage';
import ContentLoader from 'components/ContentLoader';

const PublicPage = () => {
  const dispatch = useDispatch();

  const isResourcesPending = useSelector(selectResourcesPending);

  useEffectOnce(() => {
    dispatch(fetchResources({ isPublic: true }));
  });

  if (isResourcesPending) {
    return <ContentLoader message="Initializing, please wait..." />;
  }

  return (
    <main>
      <Switch>
        <Route exact path="/public/inspection/:id">
          <BrandInspectionCertificatePage publicTemplate />
        </Route>

        <Route exact path="/public/pre-sale/:id">
          <HorsePreSalePage publicTemplate />
        </Route>

        <Route exact path="/public/travel-permit/:id">
          <TravelPermitPage publicTemplate />
        </Route>

        <Redirect to="/" />
      </Switch>
    </main>
  );
};

export default PublicPage;
