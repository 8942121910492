// @flow
import { type Dispatch } from 'react-redux';
import { Table } from 'dexie';
import type { SyncApi } from 'flow/sync';
import { INSPECTION_UPDATE_HISTORY } from 'flow/inspection';
import { showErrorMessage, showSuccessMessage } from 'utils/showMessage';
import { deleteEntityCode } from 'utils/generateEntityCode';
import { getHistoryDateString } from 'utils/dates';
import { inspectionClient } from 'service/client';
import { inspectionsDb as db } from 'service/idb';
import createError from 'service/logger';

export const RESOURCE_REQUIRES_NO_USER = -1;

type HasDate = {
  date: string,
};

export const ALREADY_SYNCED = 'alreadySynced';
const ERROR_PREFIX = 'ERROR';
const { ID, TYPE, DATE } = INSPECTION_UPDATE_HISTORY;

const getLatestVersionDate = (
  versions: Array<$Shape<HasDate>>,
): Promise<?string> => {
  const version =
    versions && versions.length > 0 && versions[versions.length - 1];
  const versionDate = version ? version[DATE] : undefined;
  return Promise.resolve(versionDate);
};

/**
 * Returns the last synced date in the format "YYYYMMDD"
 * for example: "20210510" would be for May 10, 2021
 *
 * Returns undefined if no history record is found.
 * @param userId the current users id
 * @param history the history table to check
 * @param thisSyncDate the date sync was requested
 * @param type of inspection
 * @returns {Promise<void>} the last synced date string or undefined
 */
export const getLastSyncDate = (
  userId: number,
  history: Table,
  thisSyncDate: string,
  type: string,
): Promise<?string> =>
  history
    .where(TYPE)
    .equals(type)
    .and(({ user }) => user === userId)
    .sortBy(ID)
    .then(getLatestVersionDate);

export const tableHasNoRecords = async (userId: number, table: Table) => {
  if (!table) {
    return true;
  }
  const recordCount = await table.where('user.id').equals(userId).count();
  return recordCount < 1;
};

type FetchLatest = (
  everythingAfterDate: ?string,
  truncateData: boolean,
) => Promise<*>;

export const syncIfNeeded = (
  userId: number,
  resourceType: string,
  online: boolean,
  reload: ?boolean,
  getLocal: () => Promise<*>,
  fetchLatest: FetchLatest,
  reloadTodays: ?boolean,
): Promise<*> => {
  const thisSyncDate = getHistoryDateString();
  return getLastSyncDate(userId, db.history, thisSyncDate, resourceType).then(
    (lastSyncDate) => {
      const syncHasNeverHappened = !lastSyncDate;
      const checkForDeltas = reloadTodays
        ? lastSyncDate <= thisSyncDate
        : lastSyncDate < thisSyncDate;
      // this can be removed after every inspector is updated to the latest version
      const lastSyncHappenedBeforeDbPhotoResize =
        !!lastSyncDate && lastSyncDate <= '20210618';
      const fetchEverything =
        reload || syncHasNeverHappened || lastSyncHappenedBeforeDbPhotoResize;
      if (fetchEverything || checkForDeltas) {
        if (online) {
          const everythingAfterDate = !fetchEverything ? lastSyncDate : null;
          return fetchLatest(everythingAfterDate, fetchEverything).then(
            (resources) => {
              db.history.put({
                type: resourceType,
                user: userId,
                date: thisSyncDate,
              });
              return resources;
            },
          );
        }
        if (fetchEverything) {
          showErrorMessage(
            `Failed to retrieve ${resourceType} from server. Please connect to the Internet and retry.`,
          );
        }
      }
      return getLocal();
    },
  );
};

const alreadyExistsMessage = (message: string): boolean =>
  message.includes('already exists');

export const publicIdAlreadyExists = (
  answer: boolean,
  field: { publicId?: Array<string> },
): boolean =>
  (!!field.publicId && !!field.publicId.find(alreadyExistsMessage)) || answer;

/**
 * Handles the job of syncing one Inspection in a consistent manner.
 *
 * @param api is an instance of SyncApi that provides all the details required to sync
 * @param dispatch is needed for dispatching hand drawn brands persistence
 * @returns a Promise that resolves to an instance of the persisted inspection
 */
export const atomicSync =
  <SuppliedInspection, ReturnedInspection>(
    api: SyncApi<SuppliedInspection, ReturnedInspection>,
    dispatch: Dispatch,
  ) =>
  async (inspection: SuppliedInspection): Promise<ReturnedInspection> => {
    const {
      apiUrl,
      offlineTable,
      serviceCacheTable,
      getId,
      apiAdapter,
      persistHandDrawnBrands,
      customResponseHandler,
      getCodeKey,
    } = api;
    const id = getId(inspection);
    const codeKey = getCodeKey(inspection);

    return inspectionClient()
      .post(apiUrl, apiAdapter(inspection))
      .then(async ({ data: returnedInspection }) => {
        persistHandDrawnBrands(dispatch, inspection);
        await db.transaction(
          'rw!',
          db[offlineTable],
          db[serviceCacheTable],
          async () => {
            await db[serviceCacheTable].put(returnedInspection);
            if (customResponseHandler) {
              await customResponseHandler(returnedInspection);
            }
            return db[offlineTable].delete(id);
          },
        );

        // delete assigned code in offline blocks
        await deleteEntityCode(codeKey);

        return returnedInspection;
      })
      .catch(async (err) => {
        const response = err?.response;

        if (response?.status === 400) {
          let data = response?.data;
          if (data && !data.reduce) {
            data = [data]; // the service may return an array or an object
          }
          if (data?.reduce(publicIdAlreadyExists, false)) {
            // cleanup duplicates that have already made it to the backend
            // this is very low risk because we also persist the error and the data
            // in logger.js
            db[offlineTable].delete(id);
            return ALREADY_SYNCED; // no more err
          }
        }

        createError({
          payload: JSON.stringify(inspection) || id,
          endpoint: `OFFLINE: ${offlineTable}/sync${offlineTable}/clear`,
          errorStack: err?.stack,
        });

        // delete assigned code in offline blocks
        await deleteEntityCode(codeKey);

        const status = err?.response?.status || '';
        const message = err?.message || 'no error message provided';
        return `${ERROR_PREFIX}${status}: ${message} occurred while persisting inspection with public id ${id}`;
      });
  };

const isError = (e) => typeof e === 'string' && e.startsWith(ERROR_PREFIX);

export const displaySyncResults =
  (callback: function) =>
  (result: Array<*>): Promise<*> => {
    const syncedPaymentsCount = result.filter(
      (e) => e.payment && !e.error,
    ).length;
    const failedPaymentsCount = result.filter(
      (e) => e.payment && !!e.error,
    ).length;
    const alreadySyncedCount = result.filter(
      (e) => e === ALREADY_SYNCED,
    ).length;
    const errorCount =
      result.filter((e) => isError(e)).length - alreadySyncedCount;
    const successCount =
      result.length -
      (errorCount +
        alreadySyncedCount +
        syncedPaymentsCount +
        failedPaymentsCount);

    if (successCount > 0) {
      showSuccessMessage(`Successfully synced ${successCount} inspections.`);
    }

    if (syncedPaymentsCount > 0) {
      showSuccessMessage(
        `Successfully synced ${syncedPaymentsCount} payment${
          syncedPaymentsCount > 1 ? 's' : ''
        }.`,
      );
    }

    if (errorCount > 0) {
      showErrorMessage(
        `Unable to sync ${errorCount} inspections. Please try again.`,
      );
    }

    if (failedPaymentsCount > 0) {
      showErrorMessage(
        `Unable to sync ${failedPaymentsCount} payment${
          failedPaymentsCount > 1 ? 's' : ''
        }. Please try again.`,
      );
    }

    if (callback) {
      return callback();
    }

    return Promise.resolve();
  };
