// @flow
import React, { Fragment } from 'react';
import { Form, Input, Row, Col } from 'antd';
import FormTitle from 'components/FormTitle';
import CountiesFormField from 'components/form/CountiesFormField';
import StatesFormField from 'components/form/StatesFormField';
import { validateZipCode } from 'utils/validators/zipCode';

const OwnerForm = () => {
  return (
    <Fragment>
      <FormTitle level={4}>Owner</FormTitle>
      <Row gutter={12}>
        <Col lg={4} md={8}>
          <Form.Item
            label="Name"
            name={['owner', 'name']}
            rules={[
              {
                required: true,
                message: 'Name is required',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col lg={4} md={8}>
          <Form.Item label="Street Address" name={['owner', 'address']}>
            <Input />
          </Form.Item>
        </Col>

        <Col lg={4} md={8}>
          <Form.Item
            label="City"
            name={['owner', 'city']}
            rules={[
              {
                required: true,
                message: 'City is required',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col lg={4} md={8}>
          <Form.Item
            label="State"
            name={['owner', 'state']}
            rules={[
              {
                required: true,
                message: 'State is required',
              },
            ]}
          >
            <StatesFormField />
          </Form.Item>
        </Col>

        <Col lg={4} md={8}>
          <Form.Item
            shouldUpdate={(prevValues, curValues) => {
              return prevValues.owner?.state !== curValues.owner?.state;
            }}
            noStyle
          >
            {(form) => {
              const state = form.getFieldValue(['owner', 'state']);

              return (
                <Form.Item label="County" name={['owner', 'county']}>
                  <CountiesFormField state={state} />
                </Form.Item>
              );
            }}
          </Form.Item>
        </Col>

        <Col lg={4} md={8}>
          <Form.Item
            label="Zip Code"
            name={['owner', 'zipCode']}
            rules={[validateZipCode]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
    </Fragment>
  );
};

export default OwnerForm;
