// @flow
import React from 'react';
import { useSelector, useDispatch, type Dispatch } from 'react-redux';
import { useEffectOnce, useMount, useUnmount } from 'react-use';
import { Alert, Button } from 'antd';
import type { ReduxStore } from 'flow/redux';
import ContentLoader from 'components/ContentLoader';
import { syncOfflineData } from 'features/SyncOfflineMenuItem/syncOfflineSlice';
import { createDeviceId, ping } from './initializerSlice';

type Props = {
  children: *,
};

let interval;

const getErrorMessage = (count: number) => {
  if (count === 3) {
    return 'You only have three available Certificate Numbers left. You will receive additional Certificate Numbers next time you sync.';
  }

  if (count === 2) {
    return 'You only have two available Certificate Numbers left. You will receive additional Certificate Numbers next time you sync.';
  }

  if (count === 1) {
    return 'This is the last Certificate you may write before syncing.';
  }

  return 'You have run out of Certificate Numbers. In order to write more inspections, please connect to the Internet and sync.';
};

const Initializer = ({ children }: Props) => {
  const dispatch: Dispatch = useDispatch();

  const pending: boolean = useSelector((state: ReduxStore) => {
    return state.initializer.pending;
  });

  const codesPending: boolean = useSelector((state: ReduxStore) => {
    return state.initializer.codesPending;
  });

  const codesCount: number = useSelector((state: ReduxStore) => {
    return state.initializer.codesCount;
  });

  const deviceId: string = useSelector((state: ReduxStore) => {
    return state.initializer.deviceId;
  });

  const syncing: boolean = useSelector((state: ReduxStore) => {
    return state.syncOffline.syncing;
  });

  const checkNetwork = () => dispatch(ping());

  useMount(() => {
    checkNetwork();

    interval = setInterval(checkNetwork, 30000);
  });

  useUnmount(() => {
    clearInterval(interval);
  });

  useEffectOnce(() => {
    dispatch(createDeviceId());
  });

  if (pending || codesPending) {
    return <ContentLoader message="Initializing, please wait..." />;
  }

  const showError = codesCount <= 3;

  const handleSync = () => {
    dispatch(syncOfflineData(true));
  };

  return (
    <div>
      {showError && (
        <Alert
          type="error"
          message={getErrorMessage(codesCount)}
          action={
            codesCount === 0 && (
              <Button
                size="small"
                danger
                onClick={handleSync}
                loading={syncing}
              >
                Sync Now
              </Button>
            )
          }
          banner
        />
      )}

      {deviceId ? (
        children
      ) : (
        <center>
          There is a problem loading the device id. Please try again.
        </center>
      )}
    </div>
  );
};

export default Initializer;
