// @flow
import React from 'react';
import { useDispatch, useSelector, type Dispatch } from 'react-redux';
import { Button, Menu } from 'antd';
import { LoadingOutlined, CloudSyncOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import type { ReduxStore } from 'flow/redux';
import { syncOfflineData } from './syncOfflineSlice';

export const DISPLAY_SYNC_RESULTS = true;

const StyledLink = styled(Button).attrs({
  type: 'link',
})`
  padding: 0;
  color: inherit;
`;

const SyncOfflineMenuItem = ({ ...rest }: *) => {
  const dispatch: Dispatch = useDispatch();

  const syncing: boolean = useSelector((state: ReduxStore) => {
    return state.syncOffline.syncing;
  });

  const handleSync = () => {
    dispatch(syncOfflineData(DISPLAY_SYNC_RESULTS));
  };

  return (
    <Menu.Item
      icon={syncing ? <LoadingOutlined /> : <CloudSyncOutlined />}
      disabled={syncing}
      {...rest}
    >
      <StyledLink disabled={syncing} onClick={handleSync}>
        Sync Offline Data
      </StyledLink>
    </Menu.Item>
  );
};

export default SyncOfflineMenuItem;
